import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStockAccount, updateStockAccount } from '../actions/StockAccountActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import StockAccountForm from '../components/StockAccountForm';

import Configuration from '../Config';

const validation = Configuration.Validations.editStockAccount;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditStockAccount extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getStockAccount(this.id));
    }

    handleSave = (values) => {
        this.props.dispatch(updateStockAccount(this.props.stockAccount.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/stock-accounts">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista conturi
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/stock-accounts/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Cont - <strong>{this.props.stockAccount.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <StockAccountForm 
                            isEdit={true}
                            initialValues={this.props.stockAccount}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.stockAccounts.errors.type === 'validation' ? mapValidationErrors(state.stockAccounts.errors.fields): [],
        stockAccount: state.stockAccounts.current
    }
}

export default connect(mapStateToProps)(EditStockAccount);