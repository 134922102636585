import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getShopSales } from '../actions/ShopSalesActions';
import DataTable from '../../common/DataTable/DataTable';
import { Badge, Button, Pane } from 'evergreen-ui';

class ListShopSales extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getShopSales(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Button iconBefore="add" intent="none" appearance="primary">
                        Importa Vanzari
                    </Button>
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='id'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id', visible: false},
                    {id: 'confirmed', label: 'Status', decorator: (values) => (
                        values.confirmed ? 
                            <Badge color="green">Confirmata</Badge>
                        :  
                            <Badge color="red">Neconfirmata</Badge>
                    )},
                    {id: 'documentId', label: 'Document', decorator: (value) => 'Bon Fiscal ' + value.documentId},
                    {id: 'quantity', label: 'Produse'},
                    {id: 'totalPrice', label: 'Valoare', decorator: (value) => (Math.round(value.totalPrice * 100) / 100) +' RON'},
                    {id: 'saleDate', label: 'Data Vanzare', decorator: 'datetime'},
                ]}

                data={this.props.data}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/shop-sales/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.shopSales;
}

export default connect(mapStateToProps)(ListShopSales);