import React, { Component } from 'react';
import { Button, Dialog, Pane, TextInputField } from 'evergreen-ui';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Request from '../../../util/Api';

const exportNir = async (start, end) => {
    const data = await Request.get(`/stock-operations-bulk/nir-export?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}`);
    const file_path = data.data;
    var a = document.createElement('A');
    a.href = file_path;
    a.download = data.filename;
    document.body.appendChild(a);
    a.click();
}



class IntervalModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            start: moment(),
            end: moment()
        }

        this.onClose = this.onClose.bind(this);
    }

    onClose = () => {
        this.props.onClose();
    }

    render () {
      return (<Dialog
        shouldCloseOnOverlayClick={false}
        width={800}
        isShown={this.props.open}
        title= {"Descarca NIR-uri pentru interval"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                <DatePicker
                    marginBottom={0}
                    dateFormat="dd/MM/yyyy"
                    selected={moment(this.state.start).toDate() || null}
                    onChange={value =>
                        this.setState({
                            start: moment(value).isValid() ? moment(value) : moment()
                        })
                    }
                    customInput={<TextInputField
                        label="Data Inceput"
                    />}
                />

                <DatePicker
                    marginBottom={0}
                    dateFormat="dd/MM/yyyy"
                    selected={moment(this.state.end).toDate() || null}
                    onChange={value =>
                        this.setState({
                            end: moment(value).isValid() ? moment(value) : moment()
                        })
                    }
                    customInput={<TextInputField
                        label="Data Sfarsit"
                    />}
                />

            </Pane>
            
            <Button marginRight={10} type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>

            <Button marginRight={10} type="button" onClick={() => {
                exportNir(this.state.start, this.state.end);
            }}>Descarca</Button>
      </Dialog>)
    }
}

export default IntervalModal;