import React from 'react';
import InfoBlock from '../../common/InfoBlock/InfoBlock';
import { Badge, Icon, Pane, Button, DocumentIcon, BoxIcon, OfficeIcon, UserIcon } from 'evergreen-ui';
import InfoLine from '../../common/InfoBlock/InfoLine';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';

const AddressInfoBlock = (props) => (
    <InfoBlock 
        margin={0}
        marginRight={16} 
        label={props.address.addressName} 
        header={<Pane display="flex" alignItems="center">
                {props.address.isBilling ? 
                    <Badge color="blue" marginRight={16}>
                        <Icon icon={DocumentIcon} size={10} marginRight={5}/>
                        Facturare</Badge> : null}
                {props.address.isDelivery ? 
                    <Badge color="green" marginRight={16}>
                        <Icon icon={BoxIcon} size={10} marginRight={5}/>
                        Livrare</Badge> : null}
                {props.address.isCompany ? 
                    <Badge color="purple" marginRight={16}>
                        <Icon icon={OfficeIcon} size={10} marginRight={5}/>
                        PJ</Badge> :
                    <Badge color="orange" marginRight={16}>
                        <Icon icon={UserIcon} size={10} marginRight={5}/>
                        PF</Badge>}

                <Button appearance="primary" iconBefore="edit" marginRight={16} onClick={() => props.onModify(props.address)}>
                    Modifica
                </Button>

                <DeleteDialog
                    onDelete={() => props.onDelete(props.address)}
                    buttonLabel="Sterge"
                    title="Confirmare stergere adresa"
                    confirmLabel="Sterg definitiv adresa"
                    message="Esti sigur ca vrei sa stergi adresa?"/>
        </Pane>}
    >
        <Pane display="flex" flexDirection="row">
            <Pane flex="50%" marginRight={16}>
                <InfoLine label="Email" value={props.address.email}/>
                {props.address.isCompany ? <>
                    <InfoLine label="Companie" value={props.address.companyName}/> 
                    <InfoLine label="CUI" value={props.address.vatNumber}/>
                    <InfoLine label="NORC" value={props.address.registryNumber}/>
                </> : null}
                <InfoLine label="Oras" value={props.address.city}/>
                <InfoLine label="Observatii" value={props.address.other}/>
            </Pane>
            <Pane flex="50%">
                <InfoLine label="Nume" value={props.address.name}/>
                <InfoLine label="Adresa" value={props.address.address}/>
                <InfoLine label="Telefon" value={props.address.phoneNumber}/>
                <InfoLine label="Tara" value={props.address.country}/>
            </Pane>
        </Pane>
    </InfoBlock>
)

export default AddressInfoBlock;