import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListPaymentTypes from './containers/ListPaymentTypes';
import CreatePaymentType from './containers/CreatePaymentType';
import EditPaymentType from './containers/EditPaymentType';
import ViewPaymentType from './containers/ViewPaymentType';

const routes = [
    {
        type: 'crud',
        list: ListPaymentTypes,
        create: CreatePaymentType,
        edit: EditPaymentType,
        view: ViewPaymentType
    }
]

export default ModuleBootstrapper.getRoutes("payment-types", routes)