import React, { Component } from 'react';
import ObjectPickerDialog from '../../common/ObjectPicker/ObjectPickerDialog';
import PaymentTypeSearchCard from './PaymentTypeSearchCard';
import { Button, Pane } from 'evergreen-ui';

import Request from '../../../util/Api';

import Config from '../Config';

class PaymentTypePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false
        }
    }

    toggleSearch(show = true) {
        this.setState({
            showSearch: show
        })
    }

    searchFunction() {
        return Request.get(Config.Endpoints.paymentTypes);
    }

    mapperFunction(value) {
        return value.data.map(entry => ({...entry}));
    }

    handleSubmit = (selection) => {
        this.props.onSubmit(selection);
    }

    handleDelete = (product) => {
        const values = this.props.values.filter(value => value !== product);
        this.props.onSubmit(values);
    }


    render() {
        return (<Pane>
                <ObjectPickerDialog
                    single={this.props.single || false}
                    search={false}
                    label="Selecteaza o metoda de plata"
                    open={!!this.state.showSearch}
                    onClose={() => { this.toggleSearch(false); }}
                    onSubmit={(selection) => { this.handleSubmit(selection); }}
                    searchFunction = {this.searchFunction}
                    mapFunction = {this.mapperFunction}
                    resultComponent = {(props) => <PaymentTypeSearchCard {...props}/>}/>

                <Button 
                    type="button"
                    appearance="primary"
                    iconBefore="add"
                    onClick={() => { this.toggleSearch(true)}}>
                    {this.props.single ? "Selecteaza Metoda de Plata" : "Adauga Metode de Plata"}
                </Button>
            </Pane>
        );
    }

}

export default PaymentTypePicker;