import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListSuppliers from './containers/ListSuppliers';
import CreateSupplier from './containers/CreateSupplier';
import EditSupplier from './containers/EditSupplier';
import ViewSupplier from './containers/ViewSupplier';

const routes = [
    {
        type: 'crud',
        list: ListSuppliers,
        create: CreateSupplier,
        edit: EditSupplier,
        view: ViewSupplier
    }
]

export default ModuleBootstrapper.getRoutes("suppliers", routes)