import moment from 'moment';

import Base from './Base';

export default class PaymentType extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        if (typeof props === 'number') {
            props = { id: props };
        }
        if (!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.productId = this.assign('productId', props);
        this.quantity = this.assign('quantity', props);
        this.quantityAvailable = this.assign('quantityAvailable', props);
        this.availabilityDate = props.availabilityDate ? moment(props.availabilityDate) : this.availabilityDate;

        
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    
        this.product = props.product;
    }

    export() {
        return {
            productId: this.productId,
            quantity: this.quantity,
            quantityAvailable: this.quantityAvailable,
            availabilityDate: this.availabilityDate
        }
    }
}