import React from 'react';

import { Checkbox, TextInputField, Pane, Heading, Textarea } from "evergreen-ui"
import { SketchPicker } from 'react-color';

const CarouselShowcaseForm = ({errors, setFieldValue, handleChange, handleBlur, values}) => {
    values.properties = values.properties || {};

    return (
        <>
            <Pane flexGrow={1} marginRight={10}>
                    <Checkbox 
                        name="properties.hasFullLink" 
                        label="Are link?"
                        checked={values.properties.hasFullLink || false}
                        onChange={handleChange}/>
                    {values.properties.hasFullLink ? <>
                        <TextInputField
                            required
                            label="Link buton"
                            name="properties.fullLinkHref"
                            description="Linkul slide-ului"
                            value={values.properties.fullLinkHref || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextInputField
                            required
                            label="Ruta buton"
                            name="properties.fullLinkAs"
                            description="Ruta interna a linkului"
                            value={values.properties.fullLinkAs || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> 


        
                    </> : null}
                </Pane>
            <Pane flexGrow={1} marginRight={10}>
                <TextInputField
                            label="Aliniere Verticala Background"
                            name="properties.backgroundAlignment"
                            description="50 = central; 0 = sus; 100 = jos"
                            value={values.properties.backgroundAlignment || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> 
            </Pane>
            <Pane display="flex">
                <Pane flexGrow={1} marginRight={10}>
                    <Checkbox 
                        name="properties.hasTitle" 
                        label="Are titlu?"
                        checked={values.properties.hasTitle || false}
                        onChange={handleChange}/>
                    {values.properties.hasTitle ? <>
                        <Textarea
                                required
                                label="Titlu"
                                name="properties.title"
                                description="Titlul slide-ului"
                                value={values.properties.title || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={400}>Culoare Titlu</Heading>
                        </Pane>
                        
                        <SketchPicker 
                            disableAlpha={true}
                            color={values.properties.titleColor}
                            onChange={(color, event) => {
                                setFieldValue('properties.titleColor', color.hex);  
                            }}
                        />
                    </> : null}
                </Pane>
                <Pane flexGrow={1} marginRight={10}>
                    <Checkbox 
                            name="properties.hasSubtitle" 
                            label="Are subtitlu?"
                            checked={values.properties.hasSubtitle || false}
                            onChange={handleChange}/>

                    {values.properties.hasSubtitle ? <>
                        <Textarea
                            required
                            label="Subtitlu"
                            name="properties.subtitle"
                            description="Subtitlul slide-ului"
                            value={values.properties.subtitle || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> 
                        
                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={400}>Culoare Subtitlu</Heading>
                        </Pane>
                        
                        <SketchPicker 
                            disableAlpha={true}
                            color={values.properties.subtitleColor}
                            onChange={(color, event) => {
                                setFieldValue('properties.subtitleColor', color.hex);  
                            }}
                        /> 
                    </>: null}
                </Pane>
                <Pane flexGrow={1}>
                    <Checkbox 
                            name="properties.hasText" 
                            label="Are text?"
                            checked={values.properties.hasText || false}
                            onChange={handleChange}/>

                    {values.properties.hasText ? <>
                        <Textarea
                                required
                                label="Text"
                                name="properties.text"
                                description="Textul slide-ului"
                                value={values.properties.text || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                        /> 

                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={400}>Culoare Text</Heading>
                        </Pane>
                        
                        <SketchPicker 
                            disableAlpha={true}
                            color={values.properties.textColor}
                            onChange={(color, event) => {
                                setFieldValue('properties.textColor', color.hex);  
                            }}
                        />  
                    </>: null}
                </Pane>
            </Pane>
            
            <Pane display="flex">
                <Pane margin={10} flexGrow={1}>
                    <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                        <Heading size={500}>Buton Primar</Heading>
                    </Pane>
                    <Checkbox 
                        name="properties.hasPrimaryButton" 
                        label="Are buton primar?"
                        checked={values.properties.hasPrimaryButton || false}
                        onChange={handleChange}
                    />
                    {values.properties.hasPrimaryButton ? <>
                        <TextInputField
                            required
                            label="Text buton Primar"
                            name="properties.primaryButtonText"
                            description="Textul butonului primar"
                            value={values.properties.primaryButtonText || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextInputField
                            required
                            label="Link buton Primar"
                            name="properties.primaryButtonHref"
                            description="Linkul butonului primar"
                            value={values.properties.primaryButtonHref || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextInputField
                            required
                            label="Ruta buton Primar"
                            name="properties.primaryButtonAs"
                            description="Ruta interna a linkului"
                            value={values.properties.primaryButtonAs || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> 

                        <Pane display="flex">
                            <Pane marginRight={10}>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={400}>Culoare Buton Primar</Heading>
                                </Pane>
                                <SketchPicker 
                                    disableAlpha={true}
                                    color={values.properties.primaryButtonColor}
                                    onChange={(color, event) => {
                                        setFieldValue('properties.primaryButtonColor', color.hex);  
                                    }}
                                /> 
                            </Pane>
                            
                            <Pane>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={400}>Culoare Text Buton Primar</Heading>
                                </Pane>
                                <SketchPicker 
                                    disableAlpha={true}
                                    color={values.properties.primaryButtonTextColor}
                                    onChange={(color, event) => {
                                        setFieldValue('properties.primaryButtonTextColor', color.hex);  
                                    }}
                                /> 
                            </Pane>
                        </Pane>
                    </> : null}
                </Pane>
            
                <Pane margin={10} flexGrow={1}>
                    <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                        <Heading size={500}>Buton Secundar</Heading>
                    </Pane>
                    <Checkbox 
                        name="properties.hasSecondaryButton" 
                        label="Are buton secundar?"
                        checked={values.properties.hasSecondaryButton || false}
                        onChange={handleChange}
                    />
                    {values.properties.hasSecondaryButton ? <>
                        <TextInputField
                            required
                            label="Text buton Secundar"
                            name="properties.secondaryButtonText"
                            description="Textul butonului secundar"
                            value={values.properties.secondaryButtonText || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextInputField
                            required
                            label="Link buton Secundar"
                            name="properties.secondaryButtonHref"
                            description="Linkul butonului secundar"
                            value={values.properties.secondaryButtonHref || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextInputField
                            required
                            label="Ruta buton Secundar"
                            name="properties.secondaryButtonAs"
                            description="Ruta interna a linkului"
                            value={values.properties.secondaryButtonAs || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> 

                        <Pane display="flex">
                            <Pane marginRight={10}>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={400}>Culoare Buton Secundar</Heading>
                                </Pane>
                                <SketchPicker 
                                    disableAlpha={true}
                                    color={values.properties.secondaryButtonColor}
                                    onChange={(color, event) => {
                                        setFieldValue('properties.secondaryButtonColor', color.hex);  
                                    }}
                                /> 
                            </Pane>
                            
                            <Pane>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={400}>Culoare Text Buton Secundar</Heading>
                                </Pane>
                                <SketchPicker 
                                    disableAlpha={true}
                                    color={values.properties.secondaryButtonTextColor}
                                    onChange={(color, event) => {
                                        setFieldValue('properties.secondaryButtonTextColor', color.hex);  
                                    }}
                                /> 
                            </Pane>
                        </Pane>
                    </> : null}
                </Pane>
                
            </Pane>
        </>
    )
}

export default CarouselShowcaseForm;
