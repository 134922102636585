import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane, Heading } from 'evergreen-ui';

export default class UserForm extends Component {
    constructor(props) {
        super(props);

        this.types = [
            {
                id: "text",
                name: "Linie Text"
            },
            {
                id: "text-area",
                name: "Bloc Text"
            },
            {
                id: "rich-text-area",
                name: "Bloc Rich Text "
            },
            {
                id: "enum",
                name: "Lista"
            },
            {
                id: "multiple-select-enum",
                name: "Lista Multipla"
            },
            {
                id: "link",
                name: "Link"
            }
        ]
        
    }
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <TextInputField
                            required
                            label="Identificator"
                            name="textId"
                            description="Cheia atributului"
                            value={values.textId || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.textId}/>
                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele cu care va fi afisat atributul"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>
                        <Checkbox 
                            name="countable" 
                            label="Countable"
                            checked={values.countable || false}
                            onChange={handleChange}/>
                        <TextInputField
                            required
                            label="Descriere"
                            name="description"
                            description="Text ajutator care va fi afisat in admin pentru atribut"
                            value={values.description || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.description}/>
                         <SelectField
                            label="Tip"
                            name="type"
                            description="Tipul atributului"
                            value={values.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.type}
                        >
                            <option value="">Selecteaza tipul atributului</option>
                            {this.types.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}                                
                        </SelectField>

                        {values.type === "enum" || values.type === "multiple-select-enum" ? (<>
                        <Heading size={400}>
                            Valori Posibile
                        </Heading>
                        <FieldArray name="values" render={arrayHelpers => (
                            <div>
                                {(  values.values && 
                                    values.values.length > 0) ? (values.values).map((value, index) => (
                                    <div key={index}>
                                        <TextInputField name={`values.${index}`} 
                                            label={`Valoare ${index}`}
                                            value={value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            />
                                        <Button type="button"
                                            marginRight={8}
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        > Sterge </Button>
                                        <Button type="button"
                                            onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                        
                                        > Valoare Noua </Button>
                                    </div>
                                )) : (
                                    <Button type="button" onClick={() => arrayHelpers.push('')}>
                                        Adauga Valoare
                                    </Button>
                                )}
                            </div>
                        )}/></>) : null}

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}