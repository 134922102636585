import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCardExpansions from './containers/ListCardExpansions';
import CreateCardExpansion from './containers/CreateCardExpansion';
import EditCardExpansion from './containers/EditCardExpansion';
import ViewCardExpansion from './containers/ViewCardExpansion';

const routes = [
    {
        type: 'crud',
        list: ListCardExpansions,
        create: CreateCardExpansion,
        edit: EditCardExpansion,
        view: ViewCardExpansion
    }
]

export default ModuleBootstrapper.getRoutes("card-expansions", routes)