import React, { Component } from 'react';
import { TextInputField, SelectField, Button, Pane, Heading } from 'evergreen-ui';
import { StockOperationTypes } from '../models/StockOperationTypes';
import DatePicker from "react-datepicker";
import moment from "moment";
import OperationDetailsInfo from './OperationDetailsInfo';
import { StockOperationLabels } from '../models/StockOperationLabels';

export default class OperationDetailsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false
        }
    }

    render() {
        const setFieldValue = this.props.setFieldValue;
        const errors = this.props.errors;
        const values = this.props.values;
        const handleChange = this.props.handleChange;
        const handleBlur = this.props.handleBlur;

        const hideDetails = this.props.view === 'edit' && this.state.showDetails === false;

        return <Pane>
            <Pane padding="5px" marginBottom="10px">
                <Heading size={500}>
                    DETALII OPERATIE   &nbsp;&nbsp; {hideDetails ?
                        <Button onClick={() => {
                            this.setState({ showDetails: true })
                        }} type="button">Modifica</Button>
                        : this.props.view === 'edit' && !hideDetails ?
                            <Button onClick={() => {
                                this.setState({ showDetails: false })
                            }} type="button">Blocheaza</Button>
                            : null}
                </Heading>

                {hideDetails ? <>
                    <OperationDetailsInfo
                        stockOperation={values}
                        stockAccounts={this.props.stockAccounts}
                        suppliers={this.props.suppliers}/>
                </> : <>
                    <Pane display="flex" justifyContent="spaceBetween" marginTop={10}>
                        <SelectField
                            flex={1}
                            marginLeft={5}
                            label="Tip Operatie"
                            name="type"
                            description=""
                            value={values.type}
                            onChange={(e) => {
                                setFieldValue("type", e.target.value);
                            }}
                            onBlur={handleBlur}
                            validationMessage={errors.type}
                        >
                            <option value="">Selecteaza</option>
                            {StockOperationTypes.map(type =>
                                <option key={`optype=${type.id}`} value={type.id}>{type.label}</option>)}
                        </SelectField>
                    </Pane>

                    <Pane display="flex" justifyContent="spaceBetween" marginTop={10}>
                        <SelectField
                            flex={1}
                            marginLeft={5}
                            label="Label Operatie"
                            name="label"
                            description=""
                            value={values.label}
                            onChange={(e) => {
                                setFieldValue("label", e.target.value);
                            }}
                            onBlur={handleBlur}
                            validationMessage={errors.type}
                        >
                            <option value="">Selecteaza</option>
                            {StockOperationLabels.map(label =>
                                <option key={`labeltype=${label.id}`} value={label.id}>{label.label}</option>)}
                        </SelectField>
                    </Pane>


                    <SelectField
                        flex={1}
                        marginLeft={5}
                        label="Gestiune Implicita"
                        name="stockAccountId"
                        description="Gestiunea setata automat pe toate produsele (se poate schimba manual)"
                        value={values.stockAccountId}
                        onChange={(e) => {
                            setFieldValue("stockAccountId", e.target.value);
                        }}
                        onBlur={handleBlur}
                        validationMessage={errors.stockAccountId}
                    >
                        <option value="">Selecteaza o gestiune</option>
                        {this.props.stockAccounts.map(stockAccount => (
                            <option key={`stock-account-${stockAccount.id}`} value={parseInt(stockAccount.id)}>{stockAccount.name}</option>
                        ))}
                    </SelectField>

                    <SelectField
                        flex={1}
                        marginLeft={5}
                        label="Furnizor"
                        name="supplierId"
                        description="Furnizorul Comenzii"
                        value={values.supplierId}
                        onChange={(e) => {
                            setFieldValue("supplierId", parseInt(e.target.value));
                        }}
                        onBlur={handleBlur}
                        validationMessage={errors.supplierId}
                    >
                        <option value="">Selecteaza un furnizor</option>
                        {this.props.suppliers.map(supplier => (
                            <option key={`supplier-${supplier.id}`} value={parseInt(supplier.id)}>{supplier.name}</option>
                        ))}
                    </SelectField>

                    <TextInputField
                        flex={1}
                        marginLeft={5}
                        required
                        label="Detalii Operatie"
                        name="typeDetails"
                        value={values.typeDetails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.typeDetails} />

                    <TextInputField
                        flex={1}
                        marginLeft={5}
                        required
                        label="Serie si Nr Factura / Document"
                        name="documentName"
                        description="Seria si numarul facturii emise de furnizor"
                        value={values.documentName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.documentName} />

                    <DatePicker
                        marginBottom={0}
                        dateFormat="dd/MM/yyyy"
                        selected={moment(values.documentDate).toDate() || null}
                        onChange={value =>
                            setFieldValue('documentDate', moment(value).isValid() ? value : moment())
                        }
                        customInput={<TextInputField
                            label="Data Factura"
                            validationMessage={errors.documentDate}
                        />}
                    />

                    <SelectField
                        flex={1}
                        marginLeft={5}
                        label="Moneda"
                        name="currency"
                        description="Moneda in care a fost emisa factura"
                        value={values.currency}
                        onChange={(e) => {
                            setFieldValue("currency", e.target.value);
                        }}
                        onBlur={handleBlur}
                        validationMessage={errors.currency}

                    >
                        <option value="LEU">Leu (LEU)</option>
                        <option value="EUR">Euro (EUR)</option>
                        <option value="GBP">Lira Sterlina (GBP)</option>
                        <option value="YEN">Yen </option>

                    </SelectField>

                    <TextInputField
                        flex={1}
                        marginLeft={5}
                        required
                        label="Rata Schimb"
                        name="exchange"
                        description="Rata schimb cu LEU la data facturarii"
                        value={values.exchange}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.exchange} />
                </>}
            </Pane>
        </Pane>
    }
}
