import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';
import moment from "moment";

const config =  {
    name: 'offers',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' },
        { type: 'request', name: 'generateVouchers' }
    ],
    endpoints: {
        offers: '/offers',
        offer: '/offers/[id]',
        generateVouchers: '/offers/[id]/generate-vouchers'
    },
    defaults: {
        createOffer: {
            name: "",
            stage: "product",
            valueType: "percentage",
            value: 0,
            startDate: moment(),
            endDate: moment(),
            active: false,
            cumulative: false,
            isVoucher: false,
            rules: [],
            position: 1
        }
    },
    validations: {
        offer: yup.object().shape({
            name: yup.string().required("Numele este obligatoriu"),
            stage: yup.string().oneOf(['product', 'cart', 'order']),
            valueType: yup.string().oneOf(['flat', 'percentage']),
            value: yup.number().moreThan(0, "Valoarea trebuie sa fie mai mare ca 0"),
            startDate: yup.date().required("Data de inceput este necesara"),
            endDate: yup.date().required("Data de sfarsit este necesara").min(yup.ref('startDate'), "Data de sfarsit nu poate fi mai devreme decat data de inceput"),
            active: yup.boolean(),
            isVoucher: yup.boolean(),
            cumulative: yup.boolean(),
            position: yup.number().required("Pozitia este necesara")
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;