import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Pane, Heading, Button, Text } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import { deleteStockOperation, getStockOperation } from '../actions/StockOperationActions';
import StockOperationInfo from '../components/StockOperationInfo';
import { getStockAccounts } from '../../stockAccount/actions/StockAccountActions';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import Request from '../../../util/Api';


class ViewStockOperation extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;

        this.state = {
            loading: 0
        }
    }
    
    async componentDidMount() {
        await this.props.dispatch(getStockAccounts());
        await this.props.dispatch(getSuppliers());
        await this.props.dispatch(getStockOperation(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteStockOperation(this.id));
    }

    markDelivered = async () => {
        this.setState({ loading: 1 });
        await Request.post(`/stock-operations/${this.id}/deliver`);
        window.location.reload();
    }

    markProcessed = async () => {
        this.setState({ loading: 1 });
        await Request.post(`/stock-operations/${this.id}/process`);
        window.location.reload();
    }

    render() {
        if(this.state.loading === 1) {
            return <Text>Se incarca...</Text>
        }

        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/stock-operations" marginRight={10}>
                            <Button iconBefore="arrow-left">
                                Lista
                            </Button>
                        </Link>
                        
                    </Pane>
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge"
                            title="Confirmare stergere operatie"
                            confirmLabel="Sterg definitiv operatia"
                            message="Esti sigur ca vrei sa stergi operatia?"/>
                    </Pane>
                </Pane>

                <Pane display="flex" alignItems="center" flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                       Operatie Stoc - {this.props.stockOperation.id}
                    </Heading>

                    <Pane marginLeft={10}>
                        {this.props.stockOperation.status === 'new' ?
                            <Button iconBefore="database" intent="success" appearance="primary" onClick={this.markDelivered}>
                            Operatie Noua - Marcheaza ca Livrata
                            </Button> :
                        this.props.stockOperation.status === 'delivered' ? 
                            <Button iconBefore="database" intent="warning" appearance="primary" onClick={this.markProcessed}>
                                Operatie Livrata - Intrare Gestiune
                            </Button> :
                        this.props.stockOperation.status === 'processed' ? 
                            <Text>Intrata in gestiune</Text> : <Text>Status Neclar</Text>}
                    </Pane>

                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <StockOperationInfo 
                        suppliers={this.props.suppliers}
                        stockAccounts={this.props.stockAccounts}
                        stockOperation={this.props.stockOperation}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        stockOperation: state.stockOperations.current,
        suppliers: state.suppliers.data,
        stockAccounts: state.stockAccounts.data,
    }
}

export default connect(mapStateToProps)(ViewStockOperation);