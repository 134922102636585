import React from 'react';
import Request from '../../../util/Api';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import { Table, Button } from 'evergreen-ui';

export default function SupplierOrderInfoBlock(props) {
    if(!props.supplierOrder.supplier) {
        return null;
    }

    const downloadLabels = async () => {
        const data = await Request.post('/products/price-labels', null, null, props.supplierOrder.products.map(product => ({
            id: product.productId, quantity: product.quantityBilled
        })))

        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    const downloadNir = async () => {
        const data = await Request.get('/supplier-orders/' + props.supplierOrder.id + '/nir');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    const exportNir = async () => {
        const data = await Request.get('/supplier-orders/' + props.supplierOrder.id + '/nir-export');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();
    }


    const commit = async () => {
        await Request.put('/supplier-orders/' + props.supplierOrder.id + '/commit'); 

        window.location.reload();
    }

    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Comanda Furnizor"
        button={{
            link:`/supplier-orders/${props.supplierOrder.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <Button marginBottom={12} type="button" onClick={() => { downloadNir() }}>
            Download PDF
        </Button>

        <Button marginBottom={12} type="button" onClick={() => { exportNir() }}>
            Download XLS
        </Button>


        <Button type="button" onClick={downloadLabels}>
            Download Labeluri Pret
        </Button>

        {props.supplierOrder.committed ? null : 
            <Button type="button" onClick={commit}>
                Integreaza in stoc
            </Button> 
        }
    
        <InfoLine label="Id Intern" value={props.supplierOrder.id}/>
        <InfoLine label="Furnizor" value={props.supplierOrder.supplier.name}/>
        <InfoLine label="Serie NIR" value={props.supplierOrder.nirSeries}/>
        <InfoLine label="Nr. NIR" value={props.supplierOrder.nirNumber}/>
        <InfoLine label="Gestiune" value={props.supplierOrder.stockAccount.name}/>


        <Table.Body>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    #
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={750} flexShrink={0} flexGrow={0}>
                    Produs
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cant. Fact.
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cant. Liv.
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Unitar
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cota TVA
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Unitar w/ Transp.
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    TVA Unitar
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Val. Total
                </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
                    {(props.supplierOrder.products && props.supplierOrder.products.length > 0) ? (props.supplierOrder.products).map((product, index) => (
                        <Table.Row>
                            <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                {product.position}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={750} flexShrink={0} flexGrow={0} title={product.label}>
                                {product.label}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.quantityBilled}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.quantityReceived}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.unitPurchasePrice}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.unitPurchaseVAT}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.unitPurchasePriceWithTransport}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.unitScalarVAT}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.totalPurchasePrice}
                            </Table.TextCell>
                        </Table.Row>
                    )) : null}
            </Table.Body>
        </Table.Body>
    </InfoBlock>
}