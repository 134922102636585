import React, { Component } from 'react';
import { Button, Dialog, TextInputField } from 'evergreen-ui';

class DiscountModal extends Component {
    constructor (props) {
        super(props);

        this.classes = props.classes;

        this.state = {
            discount: ''
        }

        this.onClose = this.onClose.bind(this);
    }

    onClose = () => {
        this.setState({discount: ''});
        this.props.onClose();
    }

    render () {
      return (<Dialog
        isShown={this.props.open}
        title= {"Aplica discount global NIR (DOAR ADC Blackfire GMBH)"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
             <TextInputField
                flex={1}
                marginLeft={5}
                required
                label="Discount Global (%)"
                name="globalDiscount"
                description="Se aplica pe toata factura"
                value={this.state.discount}
                onChange={(e) => {
                    this.setState({discount: e.target.value});
                }}/>

            <Button type="button" onClick={() => {
                this.props.handleDiscount(parseFloat(this.state.discount))
                this.props.onClose();
            }}>Aplica</Button>
      </Dialog>)
    }
}

export default DiscountModal;