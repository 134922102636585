import moment from 'moment';

import Base from './Base';

export default class Carousel extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        this.id = this.assign('id', props);
        this.variant = this.assign('variant', props);
        this.name = this.assign('name', props);

        this.backgroundColor = this.assign('backgroundColor', props);
        this.backgroundImage = this.assign('backgroundImage', props);
        this.graphicsImage = this.assign('graphicsImage', props);

        this.properties = this.assign('properties', props);
                
        this.active = this.assign('active', props, 'bool');
        this.position = this.assign('position', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            variant: this.variant,
            name: this.name,
            backgroundColor: this.backgroundColor,
            backgroundImage: this.backgroundImage,
            graphicsImage: this.graphicsImage,

            properties: this.properties,
            position: this.position,
            active: !!this.active
        }
    }
}