import React, { Component } from 'react';
import { Combobox } from 'evergreen-ui';
import Request from '../../../util/Api';
import Config from '../Config';
import _ from 'lodash';

class CardAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            options: []
        }

        this.defaultOption = {id: 0, cardId: 0, label: ""};
    }

    async handleChange(event) {
        let value = event.target.value;

        if(!value) {
            return;
        }

        let results = await Request.get(Config.Endpoints.cards, null, {q: value});
        
        let extras = [this.defaultOption];

        if(this.props.blankOption) {
            extras.push({ id: 0, cardId: 0, label: "Unlinked - " + value});
        }

        this.setState({
            options: [...extras, ...results.data.map(card =>  ({
                id: card.id,
                cardId: card.id,
                price: card.price,
                label: card.code + ' - ' + card.name,
                thumbnail: card.thumbnail,
                inStock: card.quantity
            }))]
        })
    }

    render() {

        return (
            <>
                <Combobox
                    width="100%"
                    initialSelectedItem={!_.isEmpty(this.props.value) ? this.props.value : this.defaultOption}
                    items={this.state.options}
                    onKeyUp={this.handleChange}
                    onChange={(value) => {
                        if(!value) {
                            return;
                        }

                        this.props.onChange(value);
                    }}
                    placeholder="Carte"
                    itemToString={item => item && item.label ? item.label : ""}
                    />
            </>
        );
    }

}

export default CardAutocomplete;