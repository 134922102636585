import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategories, getCategory } from '../../categories/actions/CategoryActions';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import { getUnits } from '../../units/actions/UnitActions';
import { createProduct } from '../actions/ProductActions';


import { Button, Heading, Pane } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import ProductForm from '../components/ProductForm';

import Configuration from '../Config';

const validation = Configuration.Validations.product;
const defaultValues = Configuration.Defaults.createProduct;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreateProduct extends Component {
    componentDidMount() {
        this.props.dispatch(getUnits());
        this.props.dispatch(getCategories());
        this.props.dispatch(getSuppliers());
    }

    handleSave = (values) => {
        this.props.dispatch(createProduct(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/products">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista produse
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Produs nou
                    </Heading>
                </Pane>

                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <ProductForm
                            isEdit={false}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            categories={this.props.categories}
                            suppliers={this.props.suppliers}
                            units={this.props.units}
                            onSave={this.handleSave}
                            errors={this.props.errors}
                            onCategoryChange={(id) => { this.props.dispatch(getCategory(id)) }}
                            category={this.props.selectedCategory}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.products.errors.type === 'validation' ? mapValidationErrors(state.products.errors.fields): [],
        selectedCategory: state.categories.current,
        categories: state.categories.flatData,
        suppliers: state.suppliers.data,
        units: state.units.data
    }
}

export default connect(mapStateToProps)(CreateProduct);
