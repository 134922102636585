import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoginBox from '../components/LoginBox';

import { login } from '../actions/Login';


class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(form) {
        this.props.dispatch(login(form));
    }

    render() {
        return (
            <LoginBox onSubmit={this.handleSubmit}/>
        )
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(LoginContainer);