import moment from 'moment';
import React from 'react';

import Info from '../../common/InfoBlock/Info';
import { StockOperationTypes } from '../models/StockOperationTypes';
import { StockOperationStatuses } from '../models/StockOperationStatuses';
import { Text } from 'evergreen-ui';
import { round } from 'lodash';
import { StockOperationLabels } from '../models/StockOperationLabels';

export default function OperationDetailsInfo(props) {
    if(!props.stockOperation.id) {
        return <Text>Se incarca...</Text>;
    }


    let label = StockOperationLabels.find(label => props.stockOperation.label === label.id) || {};
    let type = StockOperationTypes.find(type => props.stockOperation.type === type.id) || {};
    let status = StockOperationStatuses.find(status => props.stockOperation.status === status.id);
    let supplier = props.suppliers.find(sup => sup.id === props.stockOperation.supplierId);
    let stockAccount = props.stockAccounts.find(sa => sa.id === props.stockOperation.stockAccountId);

    return <>
        <Info label="Operatie">
            {type.id}-{props.stockOperation.number || 'x'}/{props.stockOperation.date ? 
                moment(props.stockOperation.date).format('DD.MM.YYYY') : 'xx.xx.xxxx'}
        </Info>
        <Info label="Document(e)">
            {props.stockOperation.documentName}/{moment(props.stockOperation.documentDate).format('DD.MM.YYYY')}
        </Info>
        <Info label="Gestiune">
            { stockAccount ? stockAccount.name : 'Nu exista'}
        </Info>
        <Info label="Tip">
            { type ? `${type.labelEx}` : 'Nedefinit'}
        </Info>
        <Info label="Label">
            { type ? `${label.labelEx}` : 'Nedefinit'}
        </Info>
        <Info label="Status Operatie">
            { status ? status.labelEx : 'Nedefinit' }
        </Info>
        <Info label="Furnizor">
            { supplier ? supplier.name : 'Nu exista'}
        </Info>
        <Info label="Moneda si rata schimb">
            { props.stockOperation.currency } ({props.stockOperation.exchange})
        </Info>
        <Info label="Valoare Totala">
            { props.stockOperation.value} {props.stockOperation.currency} ({round(props.stockOperation.value * props.stockOperation.exchange, 2)} Lei)
        </Info>
    </>
}