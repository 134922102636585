import React, { Component } from 'react';
import { Button, Dialog, Pane, TextInputField } from 'evergreen-ui';
import { round } from 'lodash';

class ValueModal extends Component {
    constructor (props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.setValues = this.setValues.bind(this);

        this.state = {
            value: 0
        }
    }

    onClose = () => {
        this.props.onClose();
    }

    setValues = () => {
        let totalPrice = 0;
        for(let i of this.props.selected) {
            totalPrice += round(this.props.items[i].price * this.props.items[i].quantity, 4);
        }

        let newItems = [];

        for(let i in this.props.items) {
            if(this.props.selected.indexOf(parseInt(i)) === -1) {
                newItems.push(this.props.items[i]);
                continue;
            }

            const item = this.props.items[i];

            let pcOf = round((item.price * item.quantity) / (totalPrice / 100), 4);
            let newValue = round((this.state.value * (pcOf / 100)) / item.quantity, 4);

            newItems.push({
                ...item,
                value: newValue
            })
        }

        this.props.setFieldValue('items', newItems);
        this.props.onClose();
    }

    render () {
      return (<Dialog
        shouldCloseOnOverlayClick={false}
        width={800}
        isShown={this.props.open}
        title= {"Imparte valoare intre cartile selectate"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                <TextInputField
                    flex={1}
                    marginLeft={5}
                    required
                    label="Valoare totala (in moneda nir-ului)"
                    value={this.state.value}
                    onChange={(e) => this.setState({ value: e.target.value })} />
            </Pane>
            
            <Button marginRight={10} type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>

            <Button marginRight={10} type="button" onClick={() => {
                this.setValues();
            }}>Proceseaza</Button>
      </Dialog>)
    }
}

export default ValueModal;