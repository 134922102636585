import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function UserInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Utilizator"
        button={{
            link:`/users/${props.user.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.user.id}/>
        <InfoLine label="Nume" value={props.user.name}/>
        <InfoLine label="Email" value={props.user.email}/>
        <InfoLine label="Rol" value={props.user.roleName}/>
        <InfoLine label="Newsletter" value={props.user.newsletters} type="bool"/>
        <InfoLine label="Activ" value={props.user.active} type="bool"/>
        <InfoLine label="Creat" value={props.user.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.user.updatedAt} type="date"/>
    </InfoBlock>
}