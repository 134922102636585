import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Attribute = ModuleConfig.Structures.Attribute;
const CRUDActions = ModuleConfig.CRUDActions;

export function getAttributes(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.attributes,
        mapStructure: Attribute,
        errorNotification: "Atributele nu au putut fi incarcate"
    });
}

export function getAttribute(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.attribute,
        mapStructure: Attribute,
        errorNotification: "Atributul nu a putut fi incarcat"
    })
}

export function createAttribute(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.attributes,
        mapStructure: Attribute,
        successNotification: "Atributul a fost creat",
        nextUrl: '/attributes/',
    })
}

export function updateAttribute(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.attribute,
        mapStructure: Attribute,
        successNotification: "Atributul a fost modificat",
        nextUrl: '/attributes/',
    })
}

export function deleteAttribute(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.attribute,
        successNotification: "Atributul a fost sters",
        nextUrl: '/attributes'
    });
}