import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { getStockOperations } from '../actions/StockOperations';
import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Badge, Button, Pane, Text } from 'evergreen-ui';

import { getStockOperations } from '../actions/StockOperationActions';
import { getStockAccounts } from '../../stockAccount/actions/StockAccountActions';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import IntervalModal from '../components/IntervalModal';
import { StockOperationTypes } from '../models/StockOperationTypes';

class ListStockOperations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalModalOpen: false
        }

        this.getSupplierName = this.getSupplierName.bind(this);
        this.getStockAccountName = this.getStockAccountName.bind(this);
        this.openIntervalModal = this.openIntervalModal.bind(this);
        this.closeIntervalModal = this.closeIntervalModal.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getSuppliers());
        this.props.dispatch(getStockAccounts());
    }

    handleTableChange = (opts) => {
        this.props.dispatch(getStockOperations(opts));
    }

    getSupplierName(item) {
        if (!this.props.suppliers) {
            return '';
        }

        let supplier = this.props.suppliers.find(s => s.id === item.supplierId);

        if (!supplier) {
            return 'N/A';
        }

        return supplier.name;
    }

    getStockAccountName(item) {
        if (!this.props.stockAccounts) {
            return '';
        }

        let stockAccount = this.props.stockAccounts.find(s => s.id === item.stockAccountId);

        if (!stockAccount) {
            return 'N/A';
        }

        return stockAccount.name;
    }

    openIntervalModal() {
        this.setState({
            intervalModalOpen: true
        })
    }

    closeIntervalModal() {
        this.setState({
            intervalModalClosed: true
        })
    }

    render() {
        if (this.props.stockOperations.length === 0 ||
            this.props.suppliers.length === 0 ||
            this.props.stockAccounts.length === 0) {
            return <Text>Se incarca...</Text>
        }

        return (<>
            <IntervalModal open={this.state.intervalModalOpen} onClose={this.closeIntervalModal} />
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to={`/stock-operations/create`}>
                        <Button iconBefore="add" appearance="primary" marginRight={10}>
                            Adauga
                        </Button>
                    </Link>

                    <Button onClick={this.openIntervalModal}>
                        Descarca Multiple
                    </Button>
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='id'
                defaultDirection='desc'
                columns={[
                    { id: 'id', label: 'Id Intern', visible: true },
                    { id: 'type', label: 'Serie', decorator: (item) => `${item.typeText}`, options: StockOperationTypes.map(type => ({
                        label: `${type.labelEx} - ${type.id}`,
                        value: type.id
                    })) },
                    { id: 'number', label: 'Serie si Numar', decorator: (item) => `${item.documentSeries}` },
                    {
                        id: 'label', filterId: 'label', label: 'Label', decorator: (item) => item.label === 'STD' ? null : <Badge color="blue">{item.labelText}</Badge>, options: [
                            { label: 'Toate', value: '' },
                            { label: 'Standard', value: 'STD' },
                            { label: 'Singles', value: 'CRD' },
                        ]
                    },

                    { id: 'status', label: 'Status', decorator: (item) => `${item.statusText}` },
                    { id: 'date', label: 'Data Livrare', decorator: 'date' },
                    { id: 'supplierId', label: 'Furnizor', decorator: (item) => this.getSupplierName(item), options: [
                        {
                            label: 'Toti',
                            value: null
                        },
                    ...this.props.suppliers.map(s => ({
                        label: s.name,
                        value: s.id
                    }))]},
            {id: 'stockAccountId', label: 'Gestiune', decorator: (item) => this.getStockAccountName(item), options: [
                {
                    label: 'Toate',
                    value: null
                },
                ...this.props.stockAccounts.map(s => ({
                    label: s.name,
                    value: s.id
                }))
            ]},
            {id: 'document', label: 'Document', decorator: (item) => `${item.documentName}`},
            {id: 'typeDetails', label: 'Detalii', decorator: (item) => `${item.typeDetails}`},
            {id: 'documentDate', label: 'Data Doc.', decorator: (item) => `${item.documentDate.format('DD.MM.YYYY')}`},
            {id: 'value', label: 'Valoare', decorator: (item) => `${item.value} ${item.currency}`}
                ]}

            data={this.props.stockOperations.data}
            updateKey={this.props.stockOperations.data}
            page={this.props.stockOperations.page}
            pageSize={this.props.stockOperations.pageSize}
            pageCount={this.props.stockOperations.pageCount}

            onTableChange={this.handleTableChange}

            itemMenu={[
                { getLink: (item) => `/stock-operations/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                { getLink: (item) => `/stock-operations/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
            ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return {
        stockOperations: state.stockOperations,
        suppliers: state.suppliers.data,
        stockAccounts: state.stockAccounts.data,
    }
}

export default connect(mapStateToProps)(ListStockOperations);
