import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';
const config =  {
    name: 'orders',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        orders: '/orders',
        order: '/orders/[id]'
    },
    defaults: {
        createOrder: {
            orderType: 'facebook',
            status: 'placed',
            paymentStatus: 'unpaid',
            paymentTypeId: 1,
            entityType: 'pf',
            delivery: "delivery",
            items: [],
            shippingCost: 0
        },
        createGlovoOrder: {
            orderType: 'glovo',
            status: 'placed',
            paymentStatus: 'unpaid',
            paymentTypeId: 1,
            entityType: 'pf',
            delivery: "personal",
            items: [],
            shippingCost: 0
        }
    },
    validations: {
        order: yup.object().shape({
            
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;

