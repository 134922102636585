import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCarts } from '../actions/CartActions';
import { Badge, EyeOpenIcon, Icon, Pane, Popover } from 'evergreen-ui';
import DataTable from '../../common/DataTable/DataTable';
import CartPreview from '../components/CartPreview';

class ListCarts extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getCarts(opts));
    }

    render() {
        return (<>
            <DataTable
                defaultFilter='updatedAt'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id', visible: true},
                    {id: 'user', label: 'Owner'},
                    {id: 'closed', label: 'Status', decorator: (vals) => {
                        return <><Badge  color={
                           vals.closed === 1 ? "neutral" : "green"
                        }>
                            {vals.closed === 1 ? 'Inchis' : 'Deschis'}
                        </Badge>
                            {vals.checkingOut === 1 ? <Badge color="purple">Checkout</Badge> : null}
                        </>
                    }},
                    {id: 'totalPrice', label: 'Valoare', decorator: (vals) => (
                            <Popover
                                content={
                                    <CartPreview products={vals.products} cards={vals.cards}/>
                                }
                                >
                                <Pane display="flex" alignItems="center" cursor="pointer">{vals.totalPrice} 
                                    <Icon icon={EyeOpenIcon} size={16} marginLeft={10} alignSelf="middle"/>
                                </Pane>
                            </Popover>
                    )},
                    {id: 'session.referrer', label: 'Sursa Sesiunii', decorator: (val) => (val.session ?? {}).referer},
                    {id: 'createdAt', label: 'Creat', decorator: 'datetime'},
                    {id: 'updatedAt', label: 'Ultima Modificare', decorator: 'datetime'}
                ]}

                data={this.props.data}
                updateKey={this.props.data}

                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/carts/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.carts;
}

export default connect(mapStateToProps)(ListCarts);