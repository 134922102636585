import React, { Component } from 'react';

import { Pane, Heading } from 'evergreen-ui'
import LoginForm from './LoginForm';

export default class LoginBox extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(form) {
        this.props.onSubmit(form);
    }

    render() {
        return (
            <Pane
            height="100vh"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
                <Pane display="flex" flexDirection="column" width={350}>
                    <Pane borderBottom="default" paddingBottom={10} marginBottom={10}>
                        <Heading size={500}>
                            Login
                        </Heading>
                    </Pane>
                    <LoginForm onSubmit={this.handleSubmit}/>
                </Pane>
            </Pane>
        )
    }
}