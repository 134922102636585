import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListAttributes from './containers/ListAttributes';
import CreateAttribute from './containers/CreateAttribute';
import EditAttribute from './containers/EditAttribute';
import ViewAttribute from './containers/ViewAttribute';

const routes = [
    {
        type: 'crud',
        list: ListAttributes,
        create: CreateAttribute,
        edit: EditAttribute,
        view: ViewAttribute
    }
]

export default ModuleBootstrapper.getRoutes("attributes", routes)