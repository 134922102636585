import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

import CategoryListCard from '../../categories/components/CategoryListCard';
import { Pane, Heading } from 'evergreen-ui';

export default function CategoryListInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Lista de Produse"
        button={{
            link:`/category-lists/${props.categoryList.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.categoryList.id}/>
        <InfoLine label="Nume" value={props.categoryList.name}/>
        <InfoLine label="SLUG" value={props.categoryList.slug}/>
        <InfoLine label="Creat" value={props.categoryList.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.categoryList.updatedAt} type="date"/>
        
        <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
            <Heading size={500}>Produse</Heading>
        </Pane>

        <Pane>
                {props.categoryList.categories ? props.categoryList.categories.map((category, index) => (
                    <CategoryListCard category={category} key={index} isView={true}/>
                )) : null }
            </Pane>
    </InfoBlock>
}