import React, { Component } from 'react';

import CreateAddress from './CreateAddress';
import InfoBlock from '../../common/InfoBlock/InfoBlock';
import AddressInfoBlock from '../components/AddressInfoBlock';

import { Button } from 'evergreen-ui';
import { connect } from 'react-redux';

import { openCreate, closeCreate, openEdit, closeEdit, deleteAddress } from '../actions/AddressActions';
import ModifyAddress from './ModifyAddress';

class ListAddresses extends Component {
    handleAddOpen = () => {
        this.props.dispatch(openCreate());
    }

    handleAddClose = () => {
        this.props.dispatch(closeCreate());
    }

    handleEditOpen = (address) => {
        this.props.dispatch(openEdit(address));
    }

    handleEditClose = (id) => {
        this.props.dispatch(closeEdit());
    }

    handleDelete = (id) => {
        this.props.dispatch(deleteAddress(id));
    }

    render() {
        return <>
            <InfoBlock 
                flex="100%" 
                label="Adrese Utilizator"
                header={<Button iconBefore="add" marginRight={16} onClick={this.handleAddOpen}>
                    Adresa noua
                </Button>}>
                {(this.props.addresses || []).map(
                    address => <AddressInfoBlock 
                        address={address} 
                        key={address.id} 
                        onModify={this.handleEditOpen}
                        onDelete={this.handleDelete}/> 
                )}
            </InfoBlock>

            <ModifyAddress
                isOpen={this.props.address.edit}
                onCloseComplete={this.handleEditClose}/>

            <CreateAddress 
                isOpen={this.props.address.create} 
                onCloseComplete={this.handleAddClose}/>
        </>
    }
}

function mapStateToProps(state) {
    return {
        user: state.users.current,
        address: state.address
    }
}

export default connect(mapStateToProps)(ListAddresses);