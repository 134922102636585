/**
 * src/utils/api.js
 * HTTP Request wrapper
 * Wraps around the AXIOS library
 */
import axios from "axios";
import Cookie from 'react-cookies';

import Config from "./Configuration";

const Wrapper = {
  _getUri(endpoint, query) {

    let uri = "";

    uri = Config.host + endpoint;
    let keys = Object.keys(query);
    let values = Object.values(query);

    for (let i = 0; i < keys.length; i++) {
      uri = uri.replace("[" + keys[i] + "]", values[i]);
    }

    return uri;
  },

  _getHeaders() {
    let headers = {
      "Content-Type": "application/json"
    };

    const sessionCookie = Cookie.load('nax-session');

    if(sessionCookie) {
      headers.session = sessionCookie;
    }

    return headers;
  },

  get(endpoint, query, params, opts) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();


    return new Promise((resolve, reject) => {
      axios
        .get(uri, {
          params,
          headers,
          ...opts
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  post(endpoint, query, params, data, opts) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();

    return new Promise((resolve, reject) => {
      axios
        .post(uri, data, {
          params,
          headers,
          ...opts
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  put(endpoint, query, params, data) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();

    return new Promise((resolve, reject) => {
      axios
        .put(uri, data, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  delete(endpoint, query, params) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();

    return new Promise((resolve, reject) => {
      axios
        .delete(uri, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },
};

export default Wrapper;
