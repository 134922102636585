import React from "react";

import { Heading, Label, Pane, SelectField, Textarea, TextInputField } from "evergreen-ui";
import { FieldArray } from "formik";
import KMultipleSelectField from "../../../common/FormComponents/KMultipleSelect";

import * as _ from 'lodash';

export const DynamicAttributes = ( {category, setFieldValue, handleBlur, values}) => {
    return (
        <>

            {category && category.name ?
                <Pane
                    borderBottom="default"
                    paddingBottom={8}
                    marginBottom={8}
                >
                    <Heading size={500}>Proprietati {category.name}</Heading>
                </Pane>
            : null}
            <FieldArray
                name="attributes"
                render={(arrayHelpers) =>
                (!_.isEmpty(category)
                    ? category.attributes
                    : []
                ).map((attribute, index) => {
                    switch (attribute.type) {
                    case "text-area":
                        return (
                        <React.Fragment key={attribute.name}>
                            <Label
                            htmlFor={attribute.name}
                            marginBottom={4}
                            display="block"
                            >
                                {attribute.name}
                            </Label>
                            <Textarea
                            id={attribute.name}
                            name={`attributes.attribute_${attribute.id}.value`}
                            onChange={(e) => {
                                setFieldValue(
                                `attributes.attribute_${attribute.id}`,
                                {
                                    ...attribute,
                                    value: e.target.value,
                                }
                                );
                            }}
                            onBlur={handleBlur}
                            placeholder={attribute.description}
                            value={
                                values.attributes[
                                `attribute_${attribute.id}`
                                ]
                                ? values.attributes[
                                    `attribute_${attribute.id}`
                                    ].value
                                : ""
                            }
                            />
                        </React.Fragment>
                        );
                    case "multiple-select-enum":
                        return (
                        <KMultipleSelectField
                            key={attribute.name}
                            label={attribute.name}
                            options={attribute.values}
                            description={attribute.description}
                            name={`attributes.attribute_${attribute.id}`}
                            onChange={(e) => {
                            setFieldValue(
                                `attributes.attribute_${attribute.id}`,
                                {
                                ...attribute,
                                value: e.target.value,
                                }
                            );
                            }}
                            onBlur={handleBlur}
                            value={
                            values.attributes[
                                `attribute_${attribute.id}`
                            ]
                                ? values.attributes[
                                    `attribute_${attribute.id}`
                                ].value
                                : ""
                            }
                        />
                        );
                    case "enum":
                        return (
                        <SelectField
                            inputHeight={48}
                            key={attribute.name}
                            label={attribute.name}
                            name={`attributes.attribute_${attribute.id}.value`}
                            onChange={(e) => {
                            setFieldValue(
                                `attributes.attribute_${attribute.id}`,
                                {
                                ...attribute,
                                value: e.target.value,
                                }
                            );
                            }}
                            onBlur={handleBlur}
                            value={
                            values.attributes[
                                `attribute_${attribute.id}`
                            ]
                                ? values.attributes[
                                    `attribute_${attribute.id}`
                                ].value
                                : ""
                            }
                        >
                            <option value="">
                            Selecteaza o valoare
                            </option>
                            {attribute.values.map((value) => (
                            <option
                                key={value.value}
                                value={value.value}
                            >
                                {value.value}
                            </option>
                            ))}
                        </SelectField>
                        );

                    case "text":
                    default:
                        return (
                        <TextInputField
                            inputHeight={48}
                            key={attribute.name}
                            label={attribute.name}
                            name={`attributes.attribute_${attribute.id}`}
                            description={attribute.description}
                            value={
                            values.attributes[
                                `attribute_${attribute.id}`
                            ]
                                ? values.attributes[
                                    `attribute_${attribute.id}`
                                ].value
                                : ""
                            }
                            onChange={(e) => {
                            setFieldValue(
                                `attributes.attribute_${attribute.id}`,
                                {
                                ...attribute,
                                value: e.target.value,
                                }
                            );
                            }}
                            onBlur={handleBlur}
                        />
                        );
                    }
                })
                }
            />
        </>
    )
}
