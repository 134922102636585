import {  Heading, Pane, Table, TableBody, TableHead, TextTableCell, TextTableHeaderCell } from 'evergreen-ui';
import React from 'react';



import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import { Link } from 'react-router-dom';

export default function CardInfoBlock(props) {

    return <InfoBlock
        flex="50%"
        marginRight={16}
        label="Detalii Carte"
        button={{
            link: `/cards/${props.card.id}/edit`,
            label: 'Modifica'
        }}
    >
        <Pane display="flex">
            <Pane minWidth={600} maxWidth={800}>
                <InfoLine label="Id Intern" value={props.card.id} />
                <InfoLine label="Id CardMarket" value={props.card.cardMarketId} />
                <InfoLine label="Nume" value={props.card.name} />
                <InfoLine label="Cod" value={props.card.code} />
                <InfoLine label="Raritate" value={props.card.rarity} />
                <InfoLine label="Stoc" value={props.card.quantity} />
                <InfoLine label="Cant. Max" value={props.card.maxQuantity} />
                <InfoLine label="Pret" value={`${props.card.price} LEI`} />
                <InfoLine label="Pret Blocat" value={props.card.priceLocked} type="bool" />
                
                <InfoLine label="Disponibil" value={props.card.active} type="bool" />
                <InfoLine label="Disponibil Online" value={props.card.activeOnline} type="bool" />
                <InfoLine label="Disponibil Third Party" value={props.card.activeThirdParty} type="bool" />


                <Heading marginTop={20} marginBottom={20}>
                    Extra Detalii
                </Heading>

                {props.card.cardData ? 
                    Object.keys(props.card.cardData).map((item) => <InfoLine label={item} value={props.card.cardData[item]}/>) : null}

                

            </Pane>
            
            <Pane marginLeft={100}>
                <Pane>
                    <img alt="card" style={{ height: '512px', width: 'auto' }}
                        src={props.card.image ?
                            process.env.REACT_APP_CARD_IMAGE_BUCKET + props.card.image : null} />
                </Pane>
            </Pane>
        </Pane>
        

       
        
        <Heading marginTop={20} marginBottom={20}>
            Preturi CardMarket
        </Heading>

        <Table>
            <TableHead>
                <TextTableHeaderCell>
                    Data
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Average
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Low
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Trend
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Avg1
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Avg7
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Avg30
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Computed
                </TextTableHeaderCell>
            </TableHead>
            <TableBody>
                {props.card.priceTable ?
                    <Table.Row>
                        <TextTableCell>
                            {props.card.priceTable.date}
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.average}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.low}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.trend}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.avg1}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.avg7}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.avg30}€
                        </TextTableCell>
                        <TextTableCell>
                            {props.card.priceTable.computed} LEI
                        </TextTableCell>
                    </Table.Row> : null}
            </TableBody>
        </Table>

        <Heading marginTop={20} marginBottom={20}>
            Reprinturi
        </Heading>

        <Table>
            <TableHead>
                <TextTableHeaderCell>
                    Id
                </TextTableHeaderCell>
                <TextTableHeaderCell>

                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Nume
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Cod
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Raritate
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Stoc
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Pret
                </TextTableHeaderCell>
            </TableHead>
            <TableBody>
                {(props.card.variants || []).map(variant => (
                    <Table.Row>
                        <TextTableCell>
                            {variant.id}
                        </TextTableCell>
                        <TextTableCell>
                            <img alt="thumbnail" style={{ height: '48px', width: 'auto' }}
                                src={variant.thumbnail ?
                                    process.env.REACT_APP_CARD_IMAGE_BUCKET + variant.thumbnail : null} />
                        </TextTableCell>
                        <TextTableCell>
                            <Link to={`/cards/${variant.id}`}>
                                {variant.name}
                            </Link>
                        </TextTableCell>
                        <TextTableCell>
                            {variant.code}
                        </TextTableCell>
                        <TextTableCell>
                            {variant.rarity}
                        </TextTableCell>
                        <TextTableCell>
                            {variant.quantity}
                        </TextTableCell>
                        <TextTableCell>
                            {variant.price} Lei
                        </TextTableCell>
                    </Table.Row>
                ))}
            </TableBody>
        </Table>
    </InfoBlock>
}