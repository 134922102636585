import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const SupplierOrder = ModuleConfig.Structures.SupplierOrder;
const CRUDActions = ModuleConfig.CRUDActions;

export function getSupplierOrders(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.supplierOrders,
        mapStructure: SupplierOrder,
        errorNotification: "Comenzile la furnizor nu au putut fi incarcate"
    });
}

export function getSupplierOrder(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.supplierOrder,
        mapStructure: SupplierOrder,
        errorNotification: "Comanda la furnizor nu a putut fi incarcata"
    })
}

export function createSupplierOrder(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.supplierOrders,
        mapStructure: SupplierOrder,
        successNotification: "Comanda la furnizor a fost creata",
        nextUrl: '/supplier-orders/',
    })
}

export function updateSupplierOrder(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.supplierOrder,
        mapStructure: SupplierOrder,
        successNotification: "Comanda la furnizor a fost modificata",
        nextUrl: '/supplier-orders/',
    })
}

export function deleteSupplierOrder(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.supplierOrder,
        successNotification: "Comanda la furnizor a fost stearsa",
        nextUrl: '/supplier-orders'
    });
}