import Base from './Base';

export default class Role extends Base {
    constructor(props) {
        super();
        this.import(props);
    }

    import(props = {}) {
        this.id = props.id || 0;
        this.level = props.level || 0;
        this.name = props.name || "No Role Found";
        this.createdAt = props.createdAt || null;
        this.updatedAt = props.updatedAt || null;
        this.grants = props.grants || [];
        this.deletable = props.deletable || false;
    }
}