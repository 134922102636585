import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane, Label, Textarea, InlineAlert , Text} from 'evergreen-ui';
import ProductPicker from '../../products/components/ProductPicker';
import CategoryPicker from '../../categories/components/CategoryPicker';

export default class VoucherForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    this.props.onSave(values);
                }}
            >
                {({ errors, handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <SelectField
                            label="Tip"
                            name="type"
                            description="Tipul Voucherului"
                            value={values.type}
                            onChange={(e) => {
                                setFieldValue("type", e.target.value);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value="">Selecteaza o valoare</option>
                            <option value="discount">Discount</option>
                            <option value="gift-card">Gift Card</option>
                        </SelectField>

                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele voucherului"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name} />

                        <TextInputField
                            required
                            label="Cod"
                            name="code"
                            description="Codul voucherului"
                            value={values.code || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.code} />

                        <TextInputField
                            required
                            label="Valoare"
                            name="value"
                            description="Valoarea voucherului"
                            value={values.value || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.value} />

                        <Checkbox
                            name="isPercentage"
                            label="Procentual"
                            checked={values.isPercentage || false}
                            onChange={handleChange} />

                        <Checkbox
                            name="unlimited"
                            label="Folosiri Nelimitate"
                            checked={values.unlimited || false}
                            onChange={handleChange} />

                        <Checkbox
                            name="used"
                            label="Folosit"
                            checked={values.used || false}
                            onChange={handleChange} />

                        <TextInputField
                            required
                            label="Valoare Minima Comanda"
                            name="minValue"
                            description="Valoarea minima a unei comenzi la care se poate aplica voucherul"
                            value={values.minValue || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.minValue} />


                        <Label
                            htmlFor="comment"
                            marginBottom={4}
                            marginTop={4}
                            display="block"
                        >
                            Produs
                        </Label>
                        <ProductPicker minimal={true} single={true}
                            value={values.productId}
                            onSubmit={(products) => {
                                setFieldValue(`productId`, products[0].id)
                                setFieldValue(`product`, products[0])
                            }} />
                        {values.product ? <Text>{values.product.title}</Text> : <Text>Niciun produs selectat</Text>}


                        <Label
                            htmlFor="comment"
                            marginBottom={4}
                            marginTop={4}
                            display="block"
                        >
                            Categorie
                        </Label>
                        <CategoryPicker minimal={true} single={false}
                            onSubmit={(categories) => {
                                setFieldValue('categoryHierarchyId', categories[0].categoryHierarchyId)
                                setFieldValue(`category`, categories[0])

                            }} />

                        {values.category ? <Text>{values.category.title}</Text> : <Text>Nicio categorie selectata</Text>}

                            <br/><br/>
                        <TextInputField
                            type="date"
                            flex={1}
                            marginLeft={5}
                            required
                            label="Valabil de la "
                            name="validFrom"
                            description="Data de inceput a valabilitatii voucherului"
                            value={values.validFrom || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.validFrom} />

                        <TextInputField
                            type="date"
                            flex={1}
                            marginLeft={5}
                            required
                            label="Valabil pana la"
                            name="validTo"
                            description="Data de sfarsit a valabilitatii voucherului"
                            value={values.validTo || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.validTo} />

                        <Label
                            htmlFor="comment"
                            marginBottom={4}
                            display="block"
                        >
                            Comentariu
                        </Label>
                        <Textarea
                            id="comment"
                            name="comment"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.comment}
                            value={values.comment}
                            placeholder="Comentariu"
                        />
                        {errors.comment ?
                            <InlineAlert intent="danger">{errors.comment}</InlineAlert>
                            : null}

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}
