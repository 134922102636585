import React, { Component } from 'react'
import { Pane } from 'evergreen-ui'

import Logo from './Logo';
import Account from './Account';

export default class OrchestraHeader extends Component {
    render() {
        return (
            <Pane background="tint2"
                elevation={1} 
                padding={8} 
                display="flex" 
                alignItems="center"
                justifyContent="space-between"
                >
                <Logo/>

                <Pane>
                    <Account/>
                </Pane>
                
            </Pane>
        )
    }
}