import React, { Component } from 'react';
import { Button, Checkbox, Dialog, ListItem, Pane, Spinner, Text, TextInput, UnorderedList } from 'evergreen-ui';
import Request from '../../../util/Api';
import moment from 'moment';

class MaintenanceModal extends Component {
    constructor (props) {
        super(props);

        this.classes = props.classes;

        this.state = {
            productId: null,
            cardId: null,
            messages: [],
            loading: false,
            progress: 0,
            showInfo: true,
            showWarning: true,
            showDanger: true,
            showVerbose: false,
            counts: {
                verbose: 0,
                danger: 0,
                warning: 0,
                info: 0
            }
        }

        this.onClose = this.onClose.bind(this);
        this.rebuildStocks = this.rebuildStocks.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    onClose = () => {
        this.setState({messages: []});
        this.props.onClose();
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }

    rebuildStocks = async (type, productId = null, cardId = null) => {
        if(type === 'rebuild') {
            if(window.confirm('Esti sigur ca doresti sa efectuezi aceasta operatie?') === false) {
                return
            }
        }


        this.setState({loading: true, progress: 0, messages: []});

        const validateUrl = `/inventory/validate${productId ? `?productId=${productId}` : ''}${cardId ? `?cardId=${cardId}` : ''}`

        const data = {};

        if(productId) {
            data.products = [productId]
        } else if(cardId) {
            data.cards = [cardId]
        }

        await Request.post(type === 'validate' ? validateUrl : '/inventory/rebuild',null,null, data, {
            onDownloadProgress: (event) => {
                try {
                    let responses =  event.currentTarget.response.split('@@@').filter(item => item && item.length && item.length > 0).map(item => JSON.parse(item));

                    let progress = 0;
                    const counts = {
                        verbose: 0,
                        danger: 0,
                        warning: 0,
                        info: 0
                    };

                    for(let i in responses) {
                        if(responses[i].message.indexOf('%') !== -1) {
                            progress = parseFloat(responses[i].message);
                        }

                        if(responses[i].type === 'verbose') {
                            counts.verbose = counts.verbose ? counts.verbose + 1 : 1
                        } else if(responses[i].type === 'error') {
                            counts.danger = counts.danger ? counts.danger + 1 : 1;
                        } else if(responses[i].type === 'warning') {
                            counts.warning = counts.warning ? counts.warning + 1 : 1;
                        } else if(responses[i].type === 'info') {
                            counts.info = counts.info ? counts.info + 1 : 1;
                        }
                    }

                    this.setState({
                        counts,
                        progress,
                        messages: responses
                    }, this.scrollToBottom)
                } catch (e) {
                    let serverError = false;
                    try {
                        let r = JSON.parse(event.currentTarget.response);

                        if(r.success === false) {
                            serverError = true;
                        }
                    } catch (eh) {

                    }

                    if(serverError) {
                        this.setState({
                            progress: 0,
                            loading: false,
                            messages: [{type: "error", timestamp: moment(), message: "API Error"}]
                        })
                    }

                }

            }
        });

        this.setState({loading: false, progress: 0})
    }

    rebuildPrices = async () => {
        this.setState({loading: true, progress: 0, messages: []});

        await Request.get('/stocks/update-product-prices',null,null, {
            onDownloadProgress: (event) => {
                try {
                    let responses =  event.currentTarget.response.split('@@@').filter(item => item && item.length && item.length > 0).map(item => JSON.parse(item));

                    let progress = 0;
                    const counts = {
                        verbose: 0,
                        danger: 0,
                        warning: 0,
                        info: 0
                    };

                    for(let i in responses) {
                        if(responses[i].message.indexOf('%') !== -1) {
                            progress = parseFloat(responses[i].message);
                        }

                        if(responses[i].type === 'verbose') {
                            counts.verbose = counts.verbose ? counts.verbose + 1 : 1
                        } else if(responses[i].type === 'error') {
                            counts.danger = counts.danger ? counts.danger + 1 : 1;
                        } else if(responses[i].type === 'warning') {
                            counts.warning = counts.warning ? counts.warning + 1 : 1;
                        } else if(responses[i].type === 'info') {
                            counts.info = counts.info ? counts.info + 1 : 1;
                        }
                    }

                    this.setState({
                        counts,
                        progress,
                        messages: responses
                    }, this.scrollToBottom)
                } catch (e) {
                    let serverError = false;
                    try {
                        let r = JSON.parse(event.currentTarget.response);

                        if(r.success === false) {
                            serverError = true;
                        }
                    } catch (eh) {

                    }

                    if(serverError) {
                        this.setState({
                            progress: 0,
                            loading: false,
                            messages: [{type: "error", timestamp: moment(), message: "API Error"}]
                        })
                    }

                }

            }
        });

        this.setState({loading: false, progress: 0})
    }

    render () {
      return (<Dialog
        shouldCloseOnOverlayClick={false}
        isShown={this.props.open}
        title= {"Mentenanta Produse"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                <Button iconBefore="database" intent="danger" appearance="primary" onClick={() => this.rebuildStocks('rebuild')}>
                    Reconstructie Stocuri
                </Button>
                <Button iconBefore="database" marginLeft={10} intent="danger" appearance="warning" onClick={this.rebuildPrices}>
                    Recalculare Preturi
                </Button>

                 <Button iconBefore="database" marginLeft={10} intent="success" appearance="primary" onClick={() => this.rebuildStocks('validate')}>
                    Validare Stocuri
                </Button>
            </Pane>
            <Pane marginTop={10} display='flex'>
                <Button iconBefore="database" intent="danger" appearance="primary" onClick={() => {
                    if(!this.state.productId && !this.state.cardId) {
                        alert('Introdu ID Produs sau ID Carte');
                        return;
                    }
                    this.rebuildStocks('rebuild', this.state.productId, this.state.cardId)
                }}>
                    R
                </Button>
                <Button iconBefore="database" intent="success" appearance="primary" onClick={() => {
                    if(!this.state.productId && !this.state.cardId) {
                        alert('Introdu ID Produs sau ID Carte');
                        return;
                    }
                    this.rebuildStocks('validate', this.state.productId, this.state.cardId)
                }}>
                    V
                </Button>
                <TextInput name="productId" value={this.state.productId} placeholder="ID Produs" onChange={e => this.setState({productId: e.target.value})} />
                <TextInput name="cardId" value={this.state.cardId} placeholder="ID Carte" onChange={e => this.setState({cardId: e.target.value})} />
            </Pane>
            {this.state.loading ? <Pane display="flex" alignItems="center" marginTop={10}>
                <Spinner size={32}></Spinner> <Text size="300" marginLeft={10}>{this.state.progress}%</Text>
            </Pane> : null}
            <Pane display="flex">
                <Checkbox marginRight={10}
                    label={`Info (${this.state.counts.info})`}
                    checked={this.state.showInfo}
                    onChange={e => this.setState({ showInfo: e.target.checked })}/>
                <Checkbox marginRight={10}
                    label={`Warning (${this.state.counts.warning})`}
                    checked={this.state.showWarning}
                    onChange={e => this.setState({ showWarning: e.target.checked })}/>
                <Checkbox marginRight={10}
                    label={`Danger (${this.state.counts.danger})`}
                    checked={this.state.showDanger}
                    onChange={e => this.setState({ showDanger: e.target.checked })}/>
                <Checkbox marginRight={10}
                    label={`Verbose (${this.state.counts.verbose})`}
                    checked={this.state.showVerbose}
                    onChange={e => this.setState({ showVerbose: e.target.checked })}/>
            </Pane>
            <Pane height={300} overflowY={'scroll'} marginTop={10} backgroundColor={"#dddddd"} padding={10} display="flex" flexDirection="column">
                <UnorderedList>
                    {this.state.messages.filter(message => message.type !== 'progress' &&
                            (message.type !== 'verbose' || this.state.showVerbose === true) &&
                            (message.type !== 'warning' || this.state.showWarning === true) &&
                            (message.type !== 'danger' || this.state.showDanger === true) &&
                            (message.type !== 'info' || this.state.showInfo === true)
                    ).map((message, index) =>
                        <ListItem icon={
                            message.type === 'info' ? 'info-sign' :
                            message.type === 'warning' ? 'warning-sign' :
                            message.type === 'error' ? 'ban-circle' :
                            message.type === 'verbose' ? 'arrow-right' : null
                        } color={
                            message.type === 'info' ? 'info' :
                            message.type === 'warning' ? 'warning' :
                            message.type === 'error' ? 'danger' :
                            message.type === 'verbose' ? 'muted' : null
                        }>({index+1}) {message.message} ({moment(message.timestamp).format('HH:mm:ss')})</ListItem>
                    )}
                </UnorderedList>

                <div ref={(el) => { this.messagesEnd = el; }}></div>
            </Pane>

            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Iesire</Button>
      </Dialog>)
    }
}

export default MaintenanceModal;
