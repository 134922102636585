import React, { Component } from 'react';

import { Dialog, Button } from 'evergreen-ui';

export default class DeleteDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        }
    }

    showDialog = () => {
        this.setState({
            showDialog: true
        })
    }

    hideDialog = () => {
        this.setState({
            showDialog: false
        })
    }

    handleDelete = () => {
        this.hideDialog();
        this.props.onDelete();
    }

    render() {
        return (<>
            <Dialog
                isShown={this.state.showDialog}
                title={this.props.title || "Confirmare stergere"}
                intent="danger"
                onConfirm={this.handleDelete}
                onCancel={this.hideDialog}
                cancelLabel="Nu vreau"
                confirmLabel={this.props.confirmLabel || "Sterg definitiv"}>
                {this.props.message || "Esti sigur ca vrei sa stergi?"}
            </Dialog>
            <Button iconBefore="trash" appearance="primary" intent="danger"
                onClick={this.showDialog}>
                {this.props.buttonLabel}
            </Button>
        </>)
    }
}