import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function StockAccountInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Cont"
        button={{
            link:`/stock-accounts/${props.stockAccount.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.stockAccount.id}/>
        <InfoLine label="Nume" value={props.stockAccount.name}/>
    </InfoBlock>
}