import React, { Component } from 'react';
import { SelectMenu, Text, TagInput, Pane, Label } from 'evergreen-ui';

export default class KMultipleSelectField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: []
        }
    }

    onSelect = (item) => {
        const selected = [...this.state.selected, item.value]
        
        this.setState({
            selected
        });

        this.props.onChange({ target: {
            value: selected.filter(v => v.trim().length > 0).join(",")
        }});
    }

    onDeselect = (item) => {
        const deselectedItemIndex = this.state.selected.indexOf(item.value);
        const selected = this.state.selected.filter((_item, i) => i!== deselectedItemIndex);

        this.setState({
            selected
        })  

        this.props.onChange({ target: {
            value: selected.filter(v => v.trim().length > 0).join(",")
        }});
    }

    render() {
        return (
            <SelectMenu
                marginBottom={16}
                isMultiSelect
                title={this.props.label}
                options={this.props.options.map(label => ({label, value: label}))}
                selected={this.state.selected}
                onSelect={this.onSelect}
                onDeselect={this.onDeselect}>
                    <Pane display="flex" flexDirection="column" marginBottom={16}>
                    <Label size={400}>{this.props.label}</Label>
                    <Text size={400} marginBottom={8}>{this.props.description}</Text>
                    <TagInput inputProps={{placeholder: "Click pentru optiuni"}} 
                        values={this.props.value.split(",")}/></Pane>
            </SelectMenu>
        )
    }
}

// const KMultipleSelectField = (props) => {

//     return (
        
//     )

//     // return (
//     //     <FormControl variant="outlined" style={{ width: "100%" }}>
//     //         <InputLabel>{props.label}</InputLabel>
//     //         <Select
//     //             label={props.label}
//     //             multiple
//     //             value={props.field.value ? props.field.value.split(",") : []}
//     //             name={props.field.name}
//     //             renderValue={selected => (
//     //                 <div className={classes.chips}>
//     //                     {selected.map(value => (
//     //                         <Chip className={classes.chip} key={value} label={value} />
//     //                     ))}
//     //                 </div>
//     //             )}
//     //             onChange={(e) => {
//     //                 e.target.value = e.target.value
//     //                     .filter(v => v.trim().length > 0)
//     //                     .join(",")

//     //                 props.onChange(e)
//     //             }}
//     //         >
//     //             {
//     //                 //This renders options already present on the field but that are no longer available; Will not be available anymore upon deselect
//     //                 (props.field.value ? props.field.value.split(",") : []).map((value, index) => {
//     //                     let found = props.options.find(option => option.value === value);

//     //                     return found ? null : <MenuItem key={`old-${index}`} value={value}>
//     //                         <Checkbox checked={(props.field.value || []).indexOf(value) > -1}/>
//     //                         <ListItemText primary={value}/>
//     //                     </MenuItem>
//     //                 })
//     //             }
//     //             {props.options.map((value, index) => (
//     //                 <MenuItem key={index} value={value.value}>
//     //                     <Checkbox checked={(props.field.value || []).indexOf(value.label) > -1} />
//     //                     <ListItemText primary={value.label} />
//     //                 </MenuItem>
//     //             ))}

//     //         </Select>
//     //     </FormControl>
//     // )
// }

// export default KMultipleSelectField;

