import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function UserInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Modalitate de plata"
        button={{
            link:`/payment-types/${props.paymentType.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.paymentType.id}/>
        <InfoLine label="Nume" value={props.paymentType.name}/>
        <InfoLine label="Descriere" value={props.paymentType.description}/>
        <InfoLine label="Tip" value={props.paymentType.type}/>
        <InfoLine label="Pozitie" value={props.paymentType.position}/>
        <InfoLine label="Procesare" value={props.paymentType.handler} type="map" map={{
            'direct-physical': 'La sediu',
            'direct-delivery': 'Ramburs',
            'bank-transfer': 'Transfer Bancar',
            'card-gateway': 'Card (Procesator)',
            'credit-gateway': 'Rate (Procesator)',
            'third-party': 'Intermediar',
            'prepaid': 'Preplata'
        }}/>
        <InfoLine label="Activ" value={props.paymentType.active} type="bool"/>
        <InfoLine label="Creat" value={props.paymentType.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.paymentType.updatedAt} type="date"/>
    </InfoBlock>
}