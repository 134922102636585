import { Alert, Heading, Pane, SegmentedControl, SelectField, Text, TextInputField } from 'evergreen-ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCategories } from '../../categories/actions/CategoryActions';

import { ResponsiveLine } from '@nivo/line';
import Request from '../../../util/Api';

import OrderDaily from '../components/OrderDaily';
import OrderSize from '../components/OrderSize';
import OrderTotal from '../components/OrderTotal';



class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reports: {
               averageOrderSize: [],
               totalOrderValue: [],
               dailyOrderValue: []
            },
            data: [],
            categoryData: [],
            categoryInputData: [],
            channels: {
                sales: true,
                cost: true,
                profit: true,
                profitMilestoneA: true,
                profitMilestoneB: true,
                profitMilestoneC: true
            },
            dataSize: 'month',
            startDate: null,
            endDate: null,
            category: null,
        }
    }

    componentDidUpdate(prevState, prevProps) {
        if (prevProps.dataSize === this.state.dataSize &&
            prevProps.startDate === this.state.startDate &&
            prevProps.endDate === this.state.endDate &&
            prevProps.category === this.state.category) {
            return;
        }

        this.getDashboardReports();
        this.getData();
    }

    componentDidMount() {
        this.props.dispatch(getCategories());

        this.getDashboardReports();
        this.getData();
    }

    async getDashboardReports() {
        const results = await Request.get('/dashboard/reports');

        this.setState({
            reports: results.reports
        })

    }


    async getData() {
        let opts = {
            interval: this.state.dataSize,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            category: this.state.category
        };

        const results = await Request.get('/reports/sale-report', null, opts);

        const perCategoryLabels = {};
        const perCategoryInputLabels = {};

        this.setState({
            data: [],
            categoryData: [],
            categoryInputData: [],
        })

        for(let i in results.perCategory) {
            if(typeof perCategoryLabels[results.perCategory[i].label + " (Income)"] === 'undefined') {
                perCategoryLabels[results.perCategory[i].label + " (Income)"] = {
                    "id": results.perCategory[i].label + " (Income)",
                    data: []
                }
            }

            perCategoryLabels[results.perCategory[i].label + " (Income)"].data.push({
                x: results.perCategory[i].date,
                y: parseInt(results.perCategory[i].income)
            })
        }

        for(let i in results.perCategoryInputs) {
            if(typeof perCategoryInputLabels[results.perCategoryInputs[i].label] === 'undefined') {
                perCategoryInputLabels[results.perCategoryInputs[i].label] = {
                    "id": results.perCategoryInputs[i].label,
                    data: []
                }
            }


            perCategoryInputLabels[results.perCategoryInputs[i].label].data.push({
                x: results.perCategoryInputs[i].date,
                y: parseInt(results.perCategoryInputs[i].cost || 0)
            })
        }

        this.setState({
            categoryInputData: Object.values(perCategoryInputLabels),
            categoryData: Object.values(perCategoryLabels),
            data: [{
                "id": "sales",
                "data": results.report.map(datapoint => ({
                    "x": datapoint.date,
                    "y": parseInt(datapoint.income)
                }))
            },
            {
                "id": "cost",
                "data": results.report.map(datapoint => ({
                    "x": datapoint.date,
                    "y": parseInt(datapoint.expenses)
                }))
            },
            {
                "id": "profit",
                "data": results.report.map(datapoint => ({
                    "x": datapoint.date,
                    "y": parseInt(datapoint.profit)
                }))
            },
        ]
        })
    }

    render() {

        return (
            <>
                <Heading size={600} marginBottom={20}>
                    Patch Notes
                </Heading>
                <Alert
                    intent="warning"
                    title="Patch Notes 2/8/2024"
                    marginBottom={32}
                >
                    <Text>
                        <div>- Se poate descarca si printa un PDF cu lista de itemi dintr-o comanda in pagina de comanda pe butonul "Printout Comanda"</div>
                    </Text>
                </Alert>
                <Alert
                    intent="warning"
                    title="Patch Notes 1/8/2024"
                    marginBottom={32}
                >
                    <Text>
                        <div>- POS: Comenzile se incarca dupa numarul de comanda (ex. 023456) , nu dupa id-ul intern </div>
                        <div>- POS: La comenzi incarcate, singles se ordoneaza automat dupa joc, lansare, cod carte - crescator</div>
                        <div>- POS: La comenzi incarcate, produsele se ordoneaza automat alfabetic</div>
                    </Text>
                </Alert>
                <Heading size={600} marginBottom={20}>
                    DASHBOARD
                </Heading>
                <Pane padding={16} paddingTop={0} width={"100%"} display="flex" flexDirection="column">

                    <Pane width="100%" display="flex" flexDirection="row">
                        <OrderDaily data={this.state.reports.dailyOrderValue}/>
                        <OrderSize data={this.state.reports.averageOrderSize}/>
                        <OrderTotal data={this.state.reports.totalOrderValue}/>
                    </Pane>
                    <Pane flexGrow={1} height={400}>
                        <Pane display="flex" marginBottom={10}>
                            <SegmentedControl
                                width={240}
                                options={[
                                    {
                                        label: 'Lunar',
                                        value: 'month'
                                    },
                                    {
                                        label: 'Saptamanal',
                                        value: 'week'
                                    },
                                    {
                                        label: 'Zilnic',
                                        value: 'day'
                                    }
                                ]}
                                value={this.state.dataSize}
                                onChange={value => this.setState({ dataSize: value })}
                            />
                        </Pane>
                        <Pane display="flex">
                            <TextInputField marginLeft={10} label="Data Inceput" type="date" placeholder="Start" onChange={event => this.setState({ startDate: event.target.value })} />
                            <TextInputField marginLeft={10} label="Data Sfarsit" type="date" placeholder="End" onChange={event => this.setState({ endDate: event.target.value })} />
                        </Pane>


                        <Heading>Global</Heading>
                        <ResponsiveLine
                            data={this.state.data}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'time',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            colors={{ "scheme": "category10" }}
                            curve="catmullRom"
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                        <Pane display="flex">
                            <SelectField
                                label="Categorie"
                                name="category"
                                value={this.state.category}
                                onChange={(e) => this.setState({category: e.target.value})}
                            >
                                <option value="">Selecteaza o valoare</option>
                                {this.props.categories.flatData.map(category => (
                                    <option key={category.id} value={category.id}>{category.label || category.title}</option>
                                ))}
                            </SelectField>

                        </Pane>


                        <Heading>Vanzari per categorie</Heading>
                        <ResponsiveLine
                            data={Object.values(this.state.categoryData)}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'time', format: '%Y-%m' }}
                            xFormat="time:%Y-%m-%d"
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: "%Y-%m",
                                legend: 'time',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            colors={{ "scheme": "category10" }}
                            curve="catmullRom"
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                        <Heading>Achizitii per categorie</Heading>
                        <ResponsiveLine
                            data={Object.values(this.state.categoryInputData)}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'time', format: '%Y-%m' }}
                            xFormat="time:%Y-%m"
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                format: "%Y-%m",
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'time',

                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            colors={{ "scheme": "category10" }}
                            curve="catmullRom"
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                    </Pane>



                </Pane>
            </>

        )
    }
}

function mapStateToProps(state) {
    return state
}

export default connect(mapStateToProps)(Dashboard);
