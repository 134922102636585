import moment from 'moment';

import Base from './Base';

export default class CategoryList extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        if (!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
        this.slug = this.assign('slug', props);
        this.categories = this.assign('categories', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            name: this.name,
            slug: this.slug,
            categories: this.categories
        }
    }
}