import messages from '../modules/common/constants/strings';

const Templater = (message, data = {}) => {
    let currentMessage = messages[message] || "";
    let key;
    
    for(key in data) {
        currentMessage = currentMessage.replace(new RegExp('{' + key + '}', 'g'), data[key]);
    }

    return currentMessage;
}

export default Templater;