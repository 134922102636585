import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const CardExpansion = ModuleConfig.Structures.CardExpansion;
const CRUDActions = ModuleConfig.CRUDActions;

export function getCardExpansions(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.cardExpansions,
        mapStructure: CardExpansion,
        errorNotification: "Cartile nu au putut fi incarcate"
    });
}

export function getCardExpansion(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.cardExpansion,
        mapStructure: CardExpansion,
        errorNotification: "Cartea nu a putut fi incarcata"
    })
}

export function createCardExpansion(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.cardExpansions,
        mapStructure: CardExpansion,
        successNotification: "Cartea a fost creata",
        nextUrl: '/card-expansions/',
    })
}

export function updateCardExpansion(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.cardExpansion,
        mapStructure: CardExpansion,
        successNotification: "Cartea a fost modificata",
        nextUrl: '/card-expansions/',
    })
}

export function deleteCardExpansion(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.cardExpansions,
        successNotification: "Cartea a fost stearsa",
        nextUrl: '/card-expansions/'
    });
}