import { setLoading, unsetLoading } from '../../common/Loading/Actions';
import { toaster } from 'evergreen-ui';
import { push } from 'connected-react-router';


import ModuleConfig from '../Config';
import Request from '../../../util/Api';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const ShopSale = ModuleConfig.Structures.ShopSale;
const CRUDActions = ModuleConfig.CRUDActions;

export function getShopSales(opts = { start: 0, limit: 0 }) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.shopSales,
        mapStructure: ShopSale,
        errorNotification: "Vanzarile nu au putut fi incarcate"
    });
}

export function getShopSale(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.shopSale,
        mapStructure: ShopSale,
        errorNotification: "Vanzarea nu a putut fi incarcata"
    })
}

export function confirmShopSale(id) {
    return async (dispatch) => {
        dispatch(setLoading(actions.confirmSale.request.id));
        dispatch(actions.confirmSale.request.thunk());

        try {
            await Request.put(endpoints.confirmSale, { id });
            dispatch(getShopSale(id));
            toaster.success("Vanzarea a fost confirmata!");
        } catch (err) {
            dispatch(actions.confirmSale.failure.thunk(err));
        }

        dispatch(unsetLoading(actions.confirmSale.request.id));
    }
}

export function deleteShopSale(id) {
    return async (dispatch) => {
        dispatch(setLoading(actions.cancelSale.request.id));
        dispatch(actions.cancelSale.request.thunk());

        try {
            await Request.delete(endpoints.cancelSale, { id });

            toaster.success("Vanzarea a fost anulata!");
            dispatch(push("/shop-sales"));
        } catch (err) {
            dispatch(actions.cancelSale.failure.thunk(err));
        }

        dispatch(unsetLoading(actions.cancelSale.request.id));
    }
}