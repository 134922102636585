import Request from '../../../util/Api';
import Cookie from 'react-cookies';
import Config from '../../../util/Configuration';

import { push } from 'connected-react-router';

import {
    loginThunks, loginActionIds,
    logoutThunks, logoutActionIds,
    profileActionIds,
} from './Constants';

import User from '../../../structures/User';

import { setLoading, unsetLoading } from '../../common/Loading/Actions';

import { toaster } from 'evergreen-ui';



const ENDPOINT_AUTHENTICATE = '/account/authenticate';
const ENDPOINT_DEAUTHENTICATE = '/account/deauthenticate';
const ENDPOINT_PROFILE = '/account';

export function checkAuth() {
    return async (dispatch, getState) => {

        const session = Cookie.load('nax-session');

        if (!session && getState().router.location === "/login") {
            return;
        } else if (!session) {
            dispatch(push('/login'));
            return;
        }

        dispatch(loginThunks.success({
            token: session
        }));

        dispatch(setLoading(profileActionIds.request));

        try {
            const result = await Request.get(ENDPOINT_PROFILE);

            dispatch(loginThunks.success({
                token: session,
                user: new User(result.user)
            }));
        } catch (e) {
            dispatch(push('/login'));
            dispatch(loginThunks.failure());
            await dispatch(logout());
        }

        dispatch(unsetLoading(profileActionIds.request));
    }
}

export function login(opts) {
    return async (dispatch) => {
        dispatch(setLoading(loginActionIds.request));
        dispatch(loginThunks.request());

        try {
            let result = await Request.post(ENDPOINT_AUTHENTICATE, null, null, {
                email: opts.email,
                password: opts.password,
                admin: true
            });

            const maxAge = opts.rememberMe ? Config.sessionMaxAgeRemember : Config.sessionMaxAge;
            const path = "/";

            Cookie.save('nax-session', result.token, {
                maxAge,
                path
            });

            dispatch(loginThunks.success(result));
            dispatch(push('/dashboard'));
        } catch (err) {
            toaster.danger('Email sau parola incorecte.')
            dispatch(loginThunks.failure(err));
        }

        dispatch(unsetLoading(loginActionIds.request));
    }
}

export function logout() {
    return async (dispatch) => {
        dispatch(setLoading(logoutActionIds.request));
        dispatch(logoutThunks.request());

        try {
            await Request.post(ENDPOINT_DEAUTHENTICATE);
            Cookie.remove('nax-session', { patch: '/' });
            toaster.success('Esti delogat din aplicatie');
            dispatch(logoutThunks.success());
            dispatch(push('/login'));
        } catch (err) {
            dispatch(logoutThunks.failure());
            toaster.danger('A fost o problema cu delogarea. Te rog incearca din nou');
        }

        dispatch(unsetLoading(logoutActionIds.request));
    }
}