import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStockAccounts } from '../actions/StockAccountActions';
import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Button, Pane } from 'evergreen-ui';

class ListStockAccounts extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getStockAccounts(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to="/stock-accounts/create">
                        <Button iconBefore="add" intent="none" appearance="primary">
                            Cont nou
                        </Button>
                    </Link>
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='id'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id'},
                    {id: 'name', label: 'Nume'},
                ]}

                data={this.props.data}
                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}
    

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/stock-accounts/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/stock-accounts/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.stockAccounts;
}

export default connect(mapStateToProps)(ListStockAccounts);