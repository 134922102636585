import React, { Component } from 'react';
import { Button, Dialog, Heading, Text, Pane, TextInputField } from 'evergreen-ui';
import Request from '../../../util/Api';


class CombineOrderModal extends Component {


    constructor (props) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onMerge = this.onMerge.bind(this);

        this.state = {
            error: '',
            targetOrderNumber: null
        }
    }

    onClose = () => {
        this.props.onClose();
    }

    handleChange(value) {
        this.setState({targetOrderNumber: value.target.value})
    }

    handleBlur(value) {
        this.setState({targetOrderNumber: value.target.value})
    }

    async onMerge() {
        const orderA = this.props.order.orderNumber;
        const orderB = this.state.targetOrderNumber;

        const data = await Request.post(`/orders/merge`, {}, {}, {orderA, orderB});

        if(data.issue) {
            this.setState({error: data.issue})
            return;
        }


        window.location.reload();
    }

    render () {
      return (<Dialog
        width={600}
        isShown={this.props.open}
        title= {"Combina 2 comenzi"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                {this.state.error ? <Text>{this.state.error}</Text> : null}
                <TextInputField
                    required
                    label="Numar Comanda"
                    name="orderNumber"
                    description="Numarul comenzii"
                    value={this.state.targetOrderNumber || ""}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur} />
            </Pane>

            <Button type="button" marginRight={10} onClick={() => {
                this.onMerge();
            }}>Combina</Button>

            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
      </Dialog>)
    }
}

export default CombineOrderModal;
