import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategoryList, updateCategoryList } from '../actions/CategoryListActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import CategoryListForm from '../components/CategoryListForm';

import Configuration from '../Config';

const validation = Configuration.Validations.categoryList;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditCategoryList extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getCategoryList(this.id));
    }

    handleSave = (values) => {
        this.props.dispatch(updateCategoryList(this.props.categoryList.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/category-lists">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la liste categorii
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/category-lists/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Lista categorii - <strong>{this.props.categoryList.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <CategoryListForm 
                            isEdit={true}
                            initialValues={this.props.categoryList}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.categoryLists.errors.type === 'validation' ? mapValidationErrors(state.categoryLists.errors.fields): [],
        categoryList: state.categoryLists.current
    }
}

export default connect(mapStateToProps)(EditCategoryList);