import _ from 'lodash';
import moment from 'moment';

import Base from './Base';
import Voucher from './Voucher';
import Product from './Product';
import User from './User';
import Category from './Category';
import PaymentType from './PaymentType';


export default class Offer extends Base {
    constructor(props) {
        super();
        this.import(props);
    }

    import(props = {}) {
        this.id = this.assign('id', props);
        this.stage = this.assign('stage', props);
        this.name = this.assign('name', props);
        this.valueType = this.assign('valueType', props);
        this.value = this.assign('value', props, 'float');
        this.startDate = props.startDate ? moment(props.startDate) : this.startDate;
        this.endDate = props.endDate ? moment(props.endDate) : this.endDate;
        this.active = this.assign('active', props, 'bool');
        this.cumulative = this.assign('cumulative', props, 'bool');
        this.position = this.assign('position', props);
        this.isVoucher = this.assign('isVoucher', props, 'bool');
    
        this.rules = (props.rules || []).map(rule => {
            if(rule.Product) {
                rule.value = new Product(rule.Product);
            } else if (rule.Category) {
                rule.value = new Category(rule.Category);
            } else if (rule.User) {
                rule.value = new User(rule.User);
            } else if (rule.PaymentType) {
                rule.value = new PaymentType(rule.PaymentType);
            }

            delete rule.Product;
            delete rule.Category;
            delete rule.User;
            delete rule.PaymentType;

            return rule;
        });

        this.vouchers = _.isEmpty(props.vouchers) ? 
            (props.vouchers || []) : 
            props.vouchers.map(voucher => new Voucher(voucher));

        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    

    }

    export() {
        return {
            id: this.id,
            stage: this.stage,
            name: this.name,
            valueType: this.valueType,
            value: this.value,
            startDate: moment(this.startDate),
            endDate: moment(this.endDate),
            active: !!this.active,
            cumulative: !!this.cumulative,
            position: this.position,
            isVoucher: this.isVoucher || false,
            rules: this.rules, 
            vouchers: this.vouchers,
        }
    }
}