import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPreorder, deletePreorder } from '../actions/PreorderActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import PreorderInfoBlock from '../components/PreorderInfoBlock';

class ViewPreorder extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    componentDidMount() {
        this.props.dispatch(getPreorder(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deletePreorder(this.id));
    }

    render() {
            
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/preorders">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista precomenzi
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge precomanda"
                            title="Confirmare stergere precomanda"
                            confirmLabel="Sterg definitiv precomanda"
                            message="Esti sigur ca vrei sa stergi precomanda?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Precomanda - <strong>{this.props.preorder.id}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <PreorderInfoBlock preorder={this.props.preorder}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        preorder: state.preorders.current
    }
}

export default connect(mapStateToProps)(ViewPreorder);