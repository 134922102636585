import React, { Component } from 'react';
import { Button, Pane, TextInputField } from 'evergreen-ui';

export default class ObjectPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            results: [],
            selected: []
        }

        this.searchTimeout = null;
    }

    componentDidMount() {
        if(this.props.search === false) {
            this.search();
        }
    }

    handleChange = (e) => {
        this.setState({
            searchValue: e.target.value
        })

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.search, 200);
    }

    handleResultClick = (result) => {
        if(this.props.single) {
            this.setState({
                selected: [result]
            });

            return;
        }

        let selected = this.state.selected;

        this.setState({
            selected: selected.indexOf(result) !== -1 ? 
            selected.filter(item => item !== result) :
            [...selected, result]
        })
    }

    search = async () => {
        if(this.state.searchValue.trim() === '' && this.props.search !== false) {
            this.setState({
                results: []
            });

            return;
        }

        const entities = await this.props.searchFunction(this.state.searchValue);
     
        this.setState({
            results: this.props.mapFunction(entities) 
        })
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.selected);

        this.setState({
            searchValue: '',
            results: [],
            selected: []
        })    

        this.props.onClose();
    }

    render () {
        return ( <Pane>
            {this.props.search !== false ?
                <Pane>
                    <TextInputField
                        required
                        label="Cautare"
                        value={this.state.searchValue}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}/>
                </Pane> : null}
            <Pane>
                <Pane maxHeight={"40vh"} overflowY="auto">
                    {this.state.results.map((result, index) => this.props.resultComponent(
                        {
                            key: `object-picker-${index}`,
                            item: result,
                            selected: this.state.selected.indexOf(result) !== -1,
                            onClick: this.handleResultClick
                        }
                    ))}
                </Pane>
                <Pane borderTop="default" paddingTop={16} marginTop={16}>
                    <Button type="button" onClick={this.props.onClose} marginRight={16}>
                        Inapoi
                    </Button>
                    <Button type="submit" appearance="primary" onClick={this.onSubmit}>
                        Alege
                    </Button>
                </Pane>
            </Pane>
        </Pane>)
      }

    
}