
import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Cart = ModuleConfig.Structures.Cart;
const CRUDActions = ModuleConfig.CRUDActions;

export function getCarts(opts = { start: 0, limit: 0 }) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.carts,
        mapStructure: Cart,
        errorNotification: "Cosurile de cumparaturi nu au putut fi incarcate"
    });
}

export function getCart(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.cart,
        mapStructure: Cart,
        errorNotification: "Vanzarea nu a putut fi incarcata"
    })
}