import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Button, EditIcon, Icon, LinkIcon, Pane, Text } from 'evergreen-ui';
import { getInventoryBuffers, updateInventoryQuantity } from '../actions/InventoryBufferActions';
import { UpdateQuantityModal } from '../components/UpdateQuantityModal';

class ListInventoryBuffer extends Component {

    constructor(props) {
        super(props);
        this.latestConfig = {};

        this.state = {
            showUpdateQuantityModal: false,
            item: null
        }
        this.handleTableChange = this.handleTableChange.bind(this);
        this.setShowUpdateQuantityModal = this.setShowUpdateQuantityModal.bind(this);

    }

    handleTableChange = (opts) => {
        this.latestConfig = opts;
        this.props.dispatch(getInventoryBuffers(opts));
    }

    handleUpdateQuantity = async (itemId, type, quantity) => {
        try {
            await this.props.dispatch(updateInventoryQuantity(itemId, type, quantity));
            await this.props.dispatch(getInventoryBuffers(this.latestConfig));
            await this.setShowUpdateQuantityModal(false)
        } catch (e) {
            console.log(e);
        }
    }

    setShowUpdateQuantityModal(show, item = null) {
        this.setState({showUpdateQuantityModal: show, item})
    }

    render() {
        return (<>
            <DataTable
                defaultFilter='id'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id', flexBasis: "80px"},
                    {id: 'thumbnail', label: 'Imagine', flexBasis: "96px", decorator: item => (
                        <img alt={item.type} style={{height: '48px', width: 'auto'}}
                            src={item.thumbnail ? item.type === 'product' ? process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + item.thumbnail + '.jpg'
                             : process.env.REACT_APP_CARD_IMAGE_BUCKET + item.thumbnail : null}/>)
                    },
                    {id: 'type', label: 'Tip', flexBasis: "100px", decorator: item => item.type === 'product' ? 'Produs' : 'Card',
                        filterId: 'type',
                        options: [
                            { label: 'Produs', value: 'product'},
                            { label: 'Carte', value: 'card'},
                        ]
                    },
                    {id: 'quantity', filterId: 'quantity', label: 'Cantitate', flexBasis:"150px", options: [
                        { label: 'Toate', value: ''},
                        { label: 'Erori', value: 'errors'},
                    ],
                    decorator: item => (<>
                        <Button marginRight={16} appearance="minimal" size="small" onClick={() => this.setShowUpdateQuantityModal(true, item)}>
                            <EditIcon/>
                        </Button>
                        <Text
                            color={item.quantityDiff === 0 ? 'green' : 'red'}
                            fontWeight={item.quantity === 0 && item.quantityDiff === 0 ? 'normal' : 'bold'}>
                                {item.quantity} {item.quantityDiff !== 0 ? `(${item.quantityDiff < 0 ? item.quantityDiff : `+${item.quantityDiff}`})`: ''}
                        </Text>
                    </>
                )},
                    {id: 'code', label: 'Cod', flexBasis: '100px'},
                    {id: 'name', label: 'Nume', flexBasis: "60%", decorator: item =>
                    <Link color="blue" to={item.type === 'product' ? `products/${item.id}` : `cards/${item.id}`} target="_blank">
                        {item.name}
                        {item.type === 'card' ? ` [R:${item.rarity}] [V:${item.variantName || '-'}]` : ''}
                    <LinkIcon size={12} marginLeft={5}/></Link>
                },
                ]}

                data={this.props.data}
                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}

                onTableChange={this.handleTableChange}
            />

            <UpdateQuantityModal
                item={this.state.item}
                open={this.state.showUpdateQuantityModal}
                onUpdate={(itemId, type, quantity) => this.handleUpdateQuantity(itemId, type, quantity)}
                onClose={() => this.setShowUpdateQuantityModal(false)}/>
        </>)
    }
}

function mapStateToProps(state) {
    return state.inventoryBuffer;
}

export default connect(mapStateToProps)(ListInventoryBuffer);
