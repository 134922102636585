import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListPreorders from './containers/ListPreorders';
import CreatePreorder from './containers/CreatePreorder';
import EditPreorder from './containers/EditPreorder';
import ViewPreorder from './containers/ViewPreorder';

const routes = [
    {
        type: 'crud',
        list: ListPreorders,
        create: CreatePreorder,
        edit: EditPreorder,
        view: ViewPreorder
    }
]

export default ModuleBootstrapper.getRoutes("preorders", routes)