import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Supplier = ModuleConfig.Structures.Supplier;
const CRUDActions = ModuleConfig.CRUDActions;

export function getSuppliers(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.suppliers,
        mapStructure: Supplier,
        errorNotification: "Furnizorii nu au putut fi incarcati"
    });
}

export function getSupplier(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.supplier,
        mapStructure: Supplier,
        errorNotification: "Furnizorul nu a putut fi incarcat"
    })
}

export function createSupplier(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.suppliers,
        mapStructure: Supplier,
        successNotification: "Furnizorul a fost creat",
        nextUrl: '/suppliers/',
    })
}

export function updateSupplier(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.supplier,
        mapStructure: Supplier,
        successNotification: "Furnizorul a fost modificat",
        nextUrl: '/suppliers/',
    })
}

export function deleteSupplier(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.supplier,
        successNotification: "Furnizorul a fost sters",
        nextUrl: '/suppliers'
    });
}