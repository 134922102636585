import tmt from './Templater.js';
import strings from '../modules/common/constants/strings';

const defaultMessage = {
    type: 'unmanaged',
    message: tmt('msg_failure_500')
}

const parse =  function(error) {
    if(!error.message) {
        return defaultMessage;
    }

    switch(error.message) {
        case 'validation':
            return {
                type: 'validation',
                message: tmt('msg_failure_400_validation'),
                fields: error.fields
            }
        case 'bad_credentials':
            return {
                type: 'unauthorised',
                message: tmt('msg_authentication_failure_401')
            }
        case 'unauthorized':
            return {
                type: 'unauthorized',
                message: tmt('msg_authorization_missing')
            }
        default:
            throw error;
    }
}

const mapValidationErrors = (errors) => {
    let parsedErrors = {}
    for(let i in errors) {
        parsedErrors[i] = strings[`error_validation_${errors[i]}`];
    }

    return parsedErrors;
}

const ErrorHandler = {
    parse,
    mapValidationErrors
}

export default ErrorHandler;