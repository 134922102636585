import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'stockAccount',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        stockAccounts: '/stock-accounts',
        stockAccount: '/stock-accounts/[id]'
    },
    defaults: {
        stockAccount: {
            name: '',
        }
    },
    validations: {
        stockAccount:  yup.object().shape({
            name: yup.string().required("Numele este obligatoriu"),
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}


export default Config;