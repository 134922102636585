import { Button, Card, EditIcon, Pane, TrashIcon } from 'evergreen-ui';
import React from 'react';

const KImageComponent = (props) => (
    <Card margin={12}>
        <Pane>
            <img alt="component" src={props.image} style={{height: "100px"}}/>
        </Pane>
        {!props.isView ? <Pane display="flex" gap={10} marginTop={10}>
            <Button type="button" appearance="primary" intent="danger" width="100%" onClick={props.onRemove}>
                <TrashIcon/>
            </Button>

             <Button type="button" appearance="primary" width="100%" onClick={props.onEdit}>
                <EditIcon/>
            </Button>
        </Pane> : null}
    </Card>
)

export default KImageComponent;
