import React, { Component } from 'react';
import { Dialog } from 'evergreen-ui';
import AddressForm from '../components/AddressForm';

import Configuration from '../Config';
import { connect } from 'react-redux';

import { createAddress } from '../actions/AddressActions';
import Address from '../../../structures/Address';

const validation = Configuration.Validations.address;
const defaultValues = Configuration.Defaults.createAddress;

class CreateAddress extends Component {
    handleSave = (values) => {
        this.props.dispatch(createAddress(values));
    }

    render() {
        let addressStub = new Address();

        if(this.props.user.addresses) {
            addressStub.importFromUser(this.props.user);
        }

        return (<Dialog 
            width="80vw"
            confirmLabel="Salveaza"
            cancelLabel="Inapoi"
            hasFooter={false}
            topOffset='2vmin'
            onCloseComplete={this.props.onCloseComplete}
            isShown={this.props.isOpen}>
            <AddressForm
                    isEdit={false}
                    initialValues={{...defaultValues, ...addressStub}}
                    validationSchema={validation}
                    onSave={this.handleSave}
                    errors={this.props.errors}/>
        </Dialog>)
    }
}

function mapStateToProps(state) {
    return {
        user: state.users.current
    }
}

export default connect(mapStateToProps)(CreateAddress);