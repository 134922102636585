import React from 'react';
import InfoLine from '../../common/InfoBlock/InfoLine';

const OfferRuleInfoBlock = (props) => {
    const rule = props.rule || {};

    const ruleOp = rule.relation === 'eq' ? 'Egal cu' :
            rule.relation === 'neq' ? 'Diferit de ' : 'Nespecificat : '

    return (
            
        rule.field === 'productId' ? 
            <InfoLine label="Produs" value={ruleOp + ' ' + rule.value.title + (rule.percentageDiscountOverride ? ' - [Discount Override: ' + rule.percentageDiscountOverride + '%]' : '')} /> :
        rule.field === 'categoryId' ? 
            <InfoLine label="Categorie" value={ruleOp + ' ' + rule.value.title}/> :
        rule.field === 'excludeProductId' ? 
            <InfoLine label="Exclude Produs" value={ruleOp + ' ' + rule.value.title} /> :
        rule.field === 'excludeCategoryId' ? 
            <InfoLine label="Exclude Categorie" value={ruleOp + ' ' + rule.value.title}/> :
        rule.field === 'userId' ?
            <InfoLine label="Utilizator" value={ruleOp + ' ' + rule.value.name}/> :
        rule.field === 'paymentTypeId' ? 
            <InfoLine label="Metoda de Plata" value={ruleOp + ' ' + rule.value.name}/> : null
      
    )
}


export default OfferRuleInfoBlock;