import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategory, deleteCategory } from '../actions/CategoryActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import CategoryInfoBlock from '../components/CategoryInfoBlock';
import CategoryTable from '../components/CategoryTable';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

class ViewCategory extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getCategory(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteCategory(this.props.category.id));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/categories">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista categorii
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge categoria"
                            title="Confirmare stergere categorie"
                            confirmLabel="Sterg definitiv categoria"
                            message="Esti sigur ca vrei sa stergi categoria?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Categorie - <strong>{this.props.category.title}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <CategoryInfoBlock category={this.props.category}/>
                    <Pane flex="50%">
                        {this.props.category.parent ? 
                            <InfoBlock marginBottom={16} label="Parinte">
                                <CategoryTable 
                                    height='60px'
                                    search={false}
                                    filters={false}
                                    onTableChange={() => {}}
                                    data={[this.props.category.parent]}/>
                            </InfoBlock> : null}
                        <InfoBlock label="Subcategorii">
                            <CategoryTable 
                                height='200px'
                                search={false}
                                filters={false}
                                onTableChange={() => {}}
                                data={this.props.category.children || []}/>
                        </InfoBlock>
                    </Pane>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        category: state.categories.current
    }
}

export default connect(mapStateToProps)(ViewCategory);