const strings =  {
    msg_authentication_success: 'Salut, {name}',
    msg_authentication_failure_401: 'Email sau parola incorecte. Incearca din nou.',
    msg_deauthentication_success: 'Ai fost delogat din aplicatie',
    msg_failure_500: 'Oops! A aparut o eroare ...',
    msg_failure_400_validation: 'Campurile marcate cu rosu sunt invalide.',

    msg_user_update_success: 'Utilizatorul {name} a fost actualizat',
    msg_user_create_success: 'Utilizatorul {name} a fost creat',
    msg_user_delete_success: 'Utilizatorul a fost sters',

    msg_address_update_success: 'Adresa {name} a fost actualizata',
    msg_address_create_success: 'Adresa {name} a fost creata',
    msg_address_delete_success: 'Adresa a fost stearsa',

    msg_authorization_missing: 'Nu esti autorizat sa faci asta',

    msg_category_update_success: 'Categoria {name} a fost actualizata',
    msg_category_create_success: 'Categoria {name} a fost creata',
    msg_category_delete_success: 'Categoria a fost stearsa',
    msg_category_restructure_success: 'Categoriile au fost restructurate',


    msg_product_update_success: 'Produsul {name} a fost actualizat',
    msg_product_create_success: 'Produsul {name} a fost creat',
    msg_product_delete_success: 'Produsul a fost sters',

    msg_attribute_update_success: 'Atributul {name} a fost actualizat',
    msg_attribute_create_success: 'Atributul {name} a fost creat',
    msg_attribute_delete_success: 'Atributul a fost sters',

    error_validation_invalid : "Campul este invalid",
    error_validation_required: "Campul nu poate fi gol",
    error_validation_duplicate: "Valoarea introdusa exista deja",
}

export default strings;