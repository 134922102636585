import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { TextInputField, SelectField, Button, Pane, Heading, Text } from 'evergreen-ui';
import SupplierProduct from './SupplierProduct';
import DiscountModal from './DiscountModal';

export default class SupplierOrder extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            discountModal: false
        }

        this.showDiscountModal = this.showDiscountModal.bind(this);
        this.hideDiscountModal = this.hideDiscountModal.bind(this);
    }

    showDiscountModal() {
        this.setState({
            discountModal: true
        })
    }

    hideDiscountModal() {
        this.setState({
            discountModal: false
        })
    }

    getTotalSum(values) {
        if(!values.products) {
            return;
        }
        let sum = 0;
        
        values.products.forEach(product => {
            sum += this.getLinePurchaseTotal(values, product);
        })

        return Math.round(sum * 100) / 100;
    }

    getTotalSumNoVat(values) {
        if(!values.products) {
            return;
        }
        let sum = 0;
        
        values.products.forEach(product => {
            sum += this.getPriceWithTransport(values, product) * product.quantityBilled; 
        })

        return Math.round(sum * 100) / 100;
    }

    convertToRON(values, target) {
        return target * values.exchangeRate;
    }

    getPriceWithTransport(values, product) {
        const transportPerUnit =  parseFloat(values.shipping) / parseFloat(values.supplierInvoiceValue);

        return product.unitPurchasePrice * (transportPerUnit + 1);
    }

    getVATPerUnit(values, product) {
        return this.getPriceWithTransport(values,product) * (parseFloat(product.unitPurchaseVAT) / 100);
    }

    getLinePurchaseTotal(values, product) {
        return (this.getPriceWithTransport(values, product) + this.getVATPerUnit(values, product)) * parseInt(product.quantityBilled);
    }

    round(value) {
        return Math.round(value * 100) / 100;
    }

    convert(value, values) {
        return Math.round((value * values.exchangeRate) * 100)/100;
    }

    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, setFieldValue, values}) => {
   
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>

                         <DiscountModal 
                            open={this.state.discountModal} 
                            onClose={this.hideDiscountModal}    
                            handleDiscount={(value) => {
                                let discountSum = (sum, amount) => Math.round(sum * (100 - amount)) / 100;

                                setFieldValue('supplierInvoiceValue', discountSum(values.supplierInvoiceValue, value));
                                setFieldValue('shipping', discountSum(values.shipping, value));

                                for(let i in values.products) {
                                    setFieldValue('products.' + i +'.unitPurchasePrice', discountSum(values.products[i].unitPurchasePrice, value));
                                }
                            }}
                        />

                        <Pane>
                            <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                                <Heading size={500}>
                                    1. DATE GENERALE NIR
                                </Heading>
                            </Pane>
                        
                            <Pane display="flex" justifyContent="spaceBetween">
                                <SelectField
                                    flex={1}
                                    marginLeft={5}
                                    label="Serie NIR"
                                    name="nirSeries"
                                    description="Seria NIR-ului"
                                    value={values.nirSeries}
                                    onChange={(e) => {
                                        setFieldValue("nirSeries", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value="GEP">GE (Gestiune Primara)</option>
                                    <option value="RET">RET (Returur)</option>
                                    <option value="RETF">RETF (Returur Furnizor)</option>
                                    <option value="INT">INT (Uz Intern)</option>
                                </SelectField>

                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Nr. NIR"
                                    name="nirNumber"
                                    description="Numar Nota Intrare Receptie"
                                    value={values.nirNumber || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.nirNumber}/>

                                <SelectField
                                    flex={1}
                                    marginLeft={5}
                                    label="Gestiune"
                                    name="stockAccountId"
                                    description="Gestiunea comenzii"
                                    value={values.stockAccountId}
                                    onChange={(e) => {
                                        setFieldValue("stockAccountId", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value="">Selecteaza o gestiune</option>
                                    {this.props.stockAccounts.map(stockAccount => (
                                        <option key={`stocka-account-${stockAccount.id}`} value={parseInt(stockAccount.id)}>{stockAccount.name}</option>
                                    ))}
                                </SelectField>

                            </Pane>

                            <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                                <Heading size={500}>
                                    2. DATE FACTURA &amp; FURNIZOR
                                </Heading>
                            </Pane>

                            <Pane display="flex" justifyContent="spaceBetween">
                                <SelectField
                                    flex={1}
                                    marginLeft={5}
                                    label="Furnizor"
                                    name="supplierId"
                                    description="Furnizorul Comenzii"
                                    value={values.supplierId}
                                    onChange={(e) => {
                                        setFieldValue("supplierId", parseInt(e.target.value));
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value="">Selecteaza un furnizor</option>
                                    {this.props.suppliers.map(supplier => (
                                        <option key={`supplier-${supplier.id}`} value={parseInt(supplier.id)}>{supplier.name}</option>
                                    ))}      
                                </SelectField>
                            </Pane>
                            <Pane display="flex" justifyContent="spaceBetween">
                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Valoare factura fara transport"
                                    name="supplierInvoiceValue"
                                    description="Costul comenzii fara transport"
                                    value={values.supplierInvoiceValue || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.supplierInvoiceValue}/>
                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Nr. Factura"
                                    name="supplierInvoiceNumber"
                                    description="Numarul facturii emise de furnizor"
                                    value={values.supplierInvoiceNumber || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.supplierInvoiceNumber}/>

                                <TextInputField
                                    type="date"
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Data Factura"
                                    name="supplierInvoiceDate"
                                    description="Data facturii emise de furnizor"
                                    value={values.supplierInvoiceDate || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.supplierInvoiceDate}/>
                            </Pane>

                            <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                                <Heading size={500}>
                                3.  DATE LIVRARE
                                </Heading>
                            </Pane>

                            <Pane display="flex" justifyContent="spaceBetween">

                                <SelectField
                                    flex={1}
                                    marginLeft={5}
                                    label="Status"
                                    name="status"
                                    description="Statusul comenzii"
                                    value={values.status}
                                    onChange={(e) => {
                                        setFieldValue("status", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value="pending">In curs de livrare</option>
                                    <option value="delivered">Livrata</option>                               
                                </SelectField>

                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Cost Livrare"
                                    name="shipping"
                                    description="Costul de transport al comenzii"
                                    value={values.shipping || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.shipping}/>

                                <TextInputField
                                    type="date"
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Data Livrarii"
                                    name="deliveryDate"
                                    description="Data in care s-a receptionat marfa"
                                    value={values.deliveryDate || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.deliveryDate}/>
                            </Pane>

                            <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                                <Heading size={500}>
                                    4. DATE FINANCIARE
                                </Heading>
                            </Pane>

                            <Pane display="flex" justifyContent="spaceBetween">
                                <SelectField
                                    flex={1}
                                    marginLeft={5}
                                    label="Moneda"
                                    name="currency"
                                    description="Moneda in care a fost emisa factura"
                                    value={values.currency}
                                    onChange={(e) => {
                                        setFieldValue("currency", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value="RON">Leu (RON)</option>
                                    <option value="EUR">Euro (EUR)</option>
                                    <option value="GBP">Lira Sterlina (GBP)</option>
                                    <option value="YEN">Yen</option>
                                </SelectField>

                                <TextInputField
                                        flex={1}
                                        marginLeft={5}
                                        required
                                        label="Rata Schimb"
                                        name="exchangeRate"
                                        description="Rata schimb cu RON la data facturarii"
                                        value={values.exchangeRate || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.exchangeRate}/>
                            </Pane>

                            <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                                <Heading size={500}>
                                    5. COMISIE RECEPTIE
                                </Heading>
                            </Pane>

                            <Pane display="flex" justifyContent="space-between">
                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Comisie Receptie 1"
                                    name="receptionName1"
                                    description="Persoana nr. 1 din comisia de receptie a marfii"
                                    value={values.receptionName1 || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.receptionName1}/>

                                
                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Comisie Receptie 2"
                                    name="receptionName2"
                                    description="Persoana nr. 2 din comisia de receptie a marfii"
                                    value={values.receptionName2 || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.receptionName2}/>

                                
                                <TextInputField
                                    flex={1}
                                    marginLeft={5}
                                    required
                                    label="Comisie Receptie 3"
                                    name="receptionName3"
                                    description="Persoana nr. 3 din comisia de receptie a marfii"
                                    value={values.receptionName3 || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.receptionName3}/>
                            </Pane>
                        </Pane>

                        

                        <Pane backgroundColor="#EEEEEE" padding="5px" marginBottom="10px"> 
                            <Heading size={500}>
                                6. CONTINUT NIR
                            </Heading>
                        </Pane>

                        <Pane width="100%">
                        <Pane marginBottom={0} display="flex" padding="10px" backgroundColor="#444444">
                            <Pane width="50px">
                                <Text color="#FFFFFF" fontWeight="bold">Index</Text>
                            </Pane>
                            <Pane flexGrow={1} minWidth="500px">
                                <Text color="#FFFFFF" fontWeight="bold">Produs</Text>
                            </Pane>
                            <Pane width="150px">
                                <Text color="#FFFFFF" fontWeight="bold">Livrata / Facturata</Text>
                            </Pane>
                            <Pane width="150px">
                                <Text color="#FFFFFF" fontWeight="bold">Pret Cumparare</Text>
                            </Pane>
                            <Pane width="100px">
                                <Text color="#FFFFFF" fontWeight="bold">Cota TVA</Text>
                            </Pane>
                            <Pane width="100px">
                                <Text color="#FFFFFF" fontWeight="bold">Pret + Transp.</Text>
                            </Pane>
                            <Pane width="100px">
                                <Text color="#FFFFFF" fontWeight="bold">TVA Unitar</Text>
                            </Pane>
                            <Pane width="100px">
                                <Text color="#FFFFFF" fontWeight="bold">Total Linie</Text>
                            </Pane>
                        </Pane>

                            <FieldArray name="products" render={arrayHelpers => (
                                <>
                                    <Pane margin={5}>
                                        <Button type="button" margin={5} onClick={() => arrayHelpers.push({um: 'buc.', position: values.products.length + 1, unitPurchaseVAT: 19})}>Rand Nou</Button>
                                        <Button type="button" margin={5} onClick={this.showDiscountModal}>
                                            Aplica Discount Global
                                        </Button>

                                        
                                            <Text marginLeft={10}>
                                                Total (No VAT): <strong>{this.getTotalSumNoVat(values)} {values.currency}</strong>
                                            </Text>

                                            <Text marginLeft={10}>
                                                Total General: <strong>{this.getTotalSum(values)} {values.currency}</strong>
                                            </Text>
                                    </Pane>
                                    {(values.products && values.products.length > 0) ? (values.products).map((product, index) => (
                                        <SupplierProduct 
                                            key={index}
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}

                                            pricePlusTransport={this.round(this.getPriceWithTransport(values, product))}
                                            vatPerUnit={this.round(this.getVATPerUnit(values, product))}
                                            lineTotal={this.round(this.getLinePurchaseTotal(values, product))}

                                            data={product} 
                                            index={index}
                                            onRemove={(index) => arrayHelpers.remove(index) } 
                                        />
                                    )) : null}

                                    <Button type="button" margin={5} onClick={() => arrayHelpers.push({um: 'buc.', position: values.products.length + 1, unitPurchaseVAT: 19})}>Rand Nou</Button>
                                </>
                            )}/>
                        </Pane>
                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}