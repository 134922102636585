import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getShopSale, deleteShopSale, confirmShopSale } from '../actions/ShopSalesActions';
import { Pane, Heading, Button, Alert } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import ShopSaleInfoBlock from '../components/ShopSaleInfoBlock';

class ViewShopSale extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getShopSale(this.id))
    }

    cancelSale = () => {
        this.props.dispatch(deleteShopSale(this.id));
    }

    confirmSale = () => {
        this.props.dispatch(confirmShopSale(this.id));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/shop-sales">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista vanzari
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        {/* <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge furnizorul"
                            title="Confirmare stergere furnizor"
                            confirmLabel="Sterg definitiv furnizorul"
                            message="Esti sigur ca vrei sa stergi furnizorul?"/> */}
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Vanzare - {this.props.shopSale ? 
                         <strong>
                             Bon Fiscal {this.props.shopSale.documentId} / {(this.props.shopSale.saleDate || moment()).format('D MMM \'YY HH:mm')}
                         </strong> :
                         'Se incarca'}
                    </Heading>
                </Pane>

                        
                {!this.props.shopSale.confirmed ? <>
                    <Alert intent="danger" title="EROARE: Vanzarea nu s-a confirmat automat!" marginTop={16} marginBottom={16}>
                        <Pane display="flex" marginTop={16}>
                            <Button intent="success" type="button" onClick={this.confirmSale} marginRight={16}>
                                Confirma Vanzarea
                            </Button> 
                            <DeleteDialog
                                onDelete={this.cancelSale}
                                buttonLabel="Anuleaza Vanzarea"
                                title="Confirmare anulare vanzare"
                                confirmLabel="Sterg definitiv vanzarea"
                                message="Esti sigur ca vrei sa stergi vanzarea?"/>
                        </Pane>
                    </Alert>
                </> : null}
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <ShopSaleInfoBlock shopSale={this.props.shopSale}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        shopSale: state.shopSales.current
    }
}

export default connect(mapStateToProps)(ViewShopSale);