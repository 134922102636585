import { toaster } from 'evergreen-ui';

import { setLoading, unsetLoading } from '../../common/Loading/Actions';
import Request from '../../../util/Api';

import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Offer = ModuleConfig.Structures.Offer;
const CRUDActions = ModuleConfig.CRUDActions;

export function getOffers(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.offers,
        mapStructure: Offer,
        errorNotification: "Ofertele nu au putut fi incarcate"
    });
}

export function getOffer(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.offer,
        mapStructure: Offer,
        errorNotification: "Oferta nu a putut fi incarcata"
    })
}

export function createOffer(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.offers,
        mapStructure: Offer,
        successNotification: "Oferta a fost creata",
        nextUrl: '/offers/'
    })
}

export function updateOffer(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.offer,
        mapStructure: Offer,
        successNotification: "Oferta a fost modificata",
        nextUrl: '/offers/',
    })
}

export function deleteOffer(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.offer,
        successNotification: "Oferta a fost stearsa",
        nextUrl: '/offers'
    });
}

export function generateVouchers(offerId, amount) {
    return async (dispatch) => {
        dispatch(setLoading(actions.generateVouchers.request.id));
        dispatch(actions.generateVouchers.request.thunk());

        try {
            await Request.post(endpoints.generateVouchers, { id: offerId }, null, { amount });
            toaster.success(`Au fost generate ${amount} vouchere`);
            dispatch(getOffer(offerId));
            dispatch(actions.generateVouchers.success.thunk());
        } catch (err) {
            dispatch(actions.generateVouchers.failure.thunk(err));
            toaster.danger("Voucherele nu au putut fi generate");
        }

        dispatch(unsetLoading(actions.generateVouchers.request.id));
    }
}