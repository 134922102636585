import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createSupplierOrder } from '../actions/SupplierOrderActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import SupplierOrderForm from '../components/SupplierOrderForm';

import Configuration from '../Config';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import { getStockAccounts } from '../../stockAccount/actions/StockAccountActions';

const validation = Configuration.Validations.supplierOrder;
const defaultValues = Configuration.Defaults.createSupplierOrder;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreateSupplierOrder extends Component {
    componentDidMount() {
        this.props.dispatch(getSuppliers());
        this.props.dispatch(getStockAccounts());
    }

    handleSave = (values) => {
        this.props.dispatch(createSupplierOrder(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/supplier-orders">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista comenzi furnizori
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Comanda Furnizor noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <SupplierOrderForm 
                            isEdit={false}
                            suppliers={this.props.suppliers}
                            stockAccounts={this.props.stockAccounts}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        suppliers: state.suppliers.data,
        stockAccounts: state.stockAccounts.data,
        errors: state.supplierOrders.errors.type === 'validation' ? mapValidationErrors(state.supplierOrders.errors.fields): [],
    }
}

export default connect(mapStateToProps)(CreateSupplierOrder);