import React from 'react';

import { Route } from 'react-router-dom';

import LoginContainer from './containers/Login';

const Routes = [
    <Route key="authentication-login" 
        exact path="/login"
        component={LoginContainer}/>
]

export default Routes;