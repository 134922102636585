import React, { Component } from 'react';
import LoadingBlock from "../Loading/LoadingBlock"
import { connect } from 'react-redux';

import { checkAuth } from '../../authentication/actions/Login';

import './App.css';

class AppContainer extends Component {
    componentDidMount() {
        this.props.dispatch(checkAuth());
    }

    render() {
        return <div id="kritware-orchestrator-app">
                <LoadingBlock/>
                {this.props.children}
        </div>
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(AppContainer);