import ModuleConfig from '../Config';


import { push } from 'connected-react-router';
import { toaster } from 'evergreen-ui';
import { setLoading, unsetLoading } from '../../common/Loading/Actions';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Product = ModuleConfig.Structures.Product;
const CRUDActions = ModuleConfig.CRUDActions;
const ErrorHandler = ModuleConfig.ErrorHandler;
const Request = ModuleConfig.Api;


export function getProducts(opts = { start: 0, limit: 0}, extras = {}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.products,
        mapStructure: Product,
        errorNotification: "Produsele nu au putut fi incarcate"
    }, extras);
}

export function getProduct(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.product,
        mapStructure: Product,
        errorNotification: "Produsul nu a putut fi incarcat"
    })
}

export function createProduct(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.products,
        mapStructure: Product,
        successNotification: "Produsul a fost creat",
        nextUrl: '/products/'
    })
}

export function updateProduct(id, values) {
    return CRUDActions.update(actions.edit, {
        id,
        values,
        endpoint: endpoints.product,
        mapStructure: Product,
        successNotification: "Produsul a fost modificat",
        nextUrl: '/products/',
    })
}

export function deleteProduct(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.product,
        successNotification: "Produsul a fost sters",
        nextUrl: '/products'
    });
}

export function duplicateProduct(id) {
     return async (dispatch) => {
        dispatch(setLoading(actions.duplicate.request.id));
        dispatch(actions.duplicate.request.thunk());

        try {
            const result = await Request.put(endpoints.duplicate, {id}, null);

            dispatch(actions.duplicate.success.thunk({id}));
            dispatch(push(`/products/${result.data.id}`));

            toaster.success("Produsul a fost duplicat cu succes");
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            dispatch(actions.duplicate.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.duplicate.request.id));
    }
}


export const activateProduct = (id) => {
    return async (dispatch) => {
        dispatch(setLoading(actions.activate.request.id));
        dispatch(actions.activate.request.thunk());

        try {
            await Request.put(endpoints.activate, {id}, null);
            dispatch(actions.activate.success.thunk({id}));

            toaster.success("Produsul a fost activat");
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            dispatch(actions.activate.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.activate.request.id));
    }
}

export const deactivateProduct = (id) => {
    return async (dispatch) => {
        dispatch(setLoading(actions.deactivate.request.id));
        dispatch(actions.deactivate.request.thunk());

        try {
            await Request.put(endpoints.deactivate, {id}, null);
            dispatch(actions.deactivate.success.thunk({id}));

            toaster.success("Produsul a fost dezactivat");
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            dispatch(actions.deactivate.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.deactivate.request.id));
    }
}
