import React, { Component } from 'react';

import { Pane, Button, Popover, Position, Menu, LogOutIcon } from 'evergreen-ui'
import { connect } from 'react-redux';

import LogoutDialog from '../../authentication/components/LogoutDialog';
import { logout } from '../../authentication/actions/Login';

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLogout: false
        }
    }

    showLogout = () => {
        this.setState({
            showLogout: true
        })
    }

    hideLogout = () => {
        this.setState({
            showLogout: false
        })
    }

    handleLogout = () => {
        this.props.dispatch(logout());
        this.setState({
            showLogout: false
        })
    }

    render() {
        return (<Pane display="flex" alignItems="center">
            <Popover   
                position={Position.BOTTOM_LEFT}
                content={
                    <Menu>
                        <Menu.Group>
                            <Menu.Item icon={LogOutIcon} onSelect={this.showLogout}>
                                Logout
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                }>
                <Button iconBefore="user" iconAfter="chevron-down">
                    {this.props.user ? this.props.user.name : ""}
                </Button>
            </Popover>

            <LogoutDialog
                show={this.state.showLogout}
                onConfirm={this.handleLogout}
                onCancel={this.hideLogout}/>
        </Pane>)
    }
}

function mapStateToProps(state) {
    return state.authentication;
}

export default connect(mapStateToProps)(Account);