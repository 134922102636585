import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Label, Textarea, Checkbox, Pane, Heading, Button } from 'evergreen-ui';

export default class AddressForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={500}>Configuratie Adresa</Heading>
                        </Pane>
                        <Pane display="flex">
                            <Checkbox
                                marginRight={16}
                                name="isCompany"
                                label="Persoana Juridica"
                                checked={values.isCompany || false}
                                onChange={handleChange}/>
                            <Checkbox
                                marginRight={16}
                                name="isBilling"
                                label="Adresa de facturare"
                                checked={values.isBilling || false}
                                onChange={handleChange}/>
                            <Checkbox
                                name="isDelivery"
                                label="Adresa de livrare"
                                checked={values.isDelivery || false}
                                onChange={handleChange}/>
                        </Pane>

                        <TextInputField
                            required
                            label="Nume Adresa"
                            name="addressName"
                            description="Numele de identificare al adresei"
                            value={values.addressName || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.addressName}/>

                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={500}>Date Contact</Heading>
                        </Pane>

                        <Pane display="flex" width="100%">
                            <Pane flex="1">
                                <TextInputField
                                    marginBottom={8}
                                    label="Email"
                                    type="email"
                                    name="email"
                                    description="Adresa de email a persoanei de contact"
                                    value={values.email || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.email}/>

                                <TextInputField
                                    marginBottom={8}
                                    label="Telefon"
                                    type="tel"
                                    name="phoneNumber"
                                    description="Numarul de telefon al persoanei de contact"
                                    value={values.phoneNumber || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.phoneNumber}/>

                                <TextInputField
                                    marginBottom={8}
                                    required
                                    label="Nume"
                                    name="lastName"
                                    description="Numele persoanei de contact"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.name}/>

                                {values.isCompany === true ? <>
                                    <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Numele Societatii"
                                        name="companyName"
                                        description="Ex. SC VALAR CURSURI AUTORIZATE SRL"
                                        value={values.companyName || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.companyName}/>

                                    <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Cod Unic Identificare (CUI)"
                                        name="vatNumber"
                                        description="Ex. 41064290 sau RO42563284"
                                        value={values.vatNumber || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.vatNumber}/>

                                    <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Nr. Ordine Registrul Comertului"
                                        name="registryNumber"
                                        description="Ex. J29/1696/2019"
                                        value={values.registryNumber || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.registryNumber}/>
                                    </>: null }
                            </Pane>
                            <Pane marginLeft={16} flex="1">
                                <TextInputField
                                    marginBottom={8}
                                    required
                                    label="Locatie"
                                    name="location"
                                    description="Ex. Str. Buna Vestire, Ploiesti"
                                    value={values.location || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validarionMessage={errors.location}/>
                                <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Detalii Adresa"
                                        name="address"
                                        description="Ex. Str. Buna Vestire, Nr. 55"
                                        value={values.address || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.address}/>
                                <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Tara"
                                        name="country"
                                        description="De regula Romania"
                                        value={values.country || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.country}/>
                                {/* <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Localitate"
                                        name="city"
                                        description="Localitatea adresei"
                                        value={values.city || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.city}/>
                                <TextInputField
                                        marginBottom={8}
                                        required
                                        label="Judet / Regiune"
                                        name="state"
                                        description="Judetul sau regiunea administrativa"
                                        value={values.state || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.state}/> */}
                                <Pane>
                                    <Label
                                        htmlFor="other-textarea"
                                        marginBottom={4}
                                        display="block"
                                    >
                                        Observatii
                                    </Label>
                                    <Textarea
                                        id="other-textarea"
                                        name="other"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Eventuale observatii legate de adresa"
                                    />
                                </Pane>
                            </Pane>
                        </Pane>
                        <Pane borderTop="default" paddingTop={16} marginTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}
