import React from 'react';
import { Pane, Text } from 'evergreen-ui';

export default function InfoLine(props) {
    return (
        <Pane display="flex" marginBottom={0}> 
            <Pane padding={0} width={200} >
                <Text><strong>{props.label}</strong></Text>
            </Pane>
            <Pane flexGrow={1} marginLeft={10}>
                {
                    props.custom === true ? 
                        props.childsren : <Text>{props.children}</Text>
                }
            </Pane>
        </Pane>
    )
}