import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'supplierOrders',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        supplierOrders: '/supplier-orders',
        supplierOrder: '/supplier-orders/[id]'
    },
    defaults: {
        createSupplierOrder: {
            nirNumber: 0,
            nirSeries: 'GEP',
            stockAccountId: '',
            currency: 'LEU',
            exchangeRate: 1,
            shipping: 0,
            supplierInvoiceValue: 0,
            supplierInvoice: '',
            supplierInvoiceNumber: '',
            supplierInvoiceDate: '',
            deliveryDate: '',
            receptionName1: '',
            receptionName2: '',
            receptionName3: '',
            status: 'pending',
            products: []
        }
    },
    validations: {
        supplierOrder:  yup.object().shape({
            nirSeries: yup.string().required("Seria este obligatorie"),
            nirNumber: yup.number().required("NIR-ul este obligatoriu"),
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}


export default Config;