import * as yup from "yup";
import ModuleBootstrapper from "../common/ModuleBootstrapper/ModuleBootstrapper";

const config = {
  name: "products",
  actions: [
    { type: "request", name: "getAll" },
    { type: "request", name: "getOne" },
    { type: "request", name: "create" },
    { type: "request", name: "edit" },
    { type: "request", name: "delete" },
    { type: "request", name: "duplicate"},
    { type: "request", name: "activate" },
    { type: "request", name: "deactivate" },
  ],
  endpoints: {
    products: "/products",
    product: "/products/[id]",
    activate: "/products/[id]/activate",
    deactivate: "/products/[id]/deactivate",
    duplicate: "/products/[id]/duplicate"
  },
  defaults: {
    createProduct: {
      maxQuantity: 10,
      categoryId: "",
      variantId: "",
      unitId: 1,
      barcode: "",
      sku: "",
      title: "",
      description: "",
      richDescription: "",
      metaTitle: "",
      metaDescription: "",
      slug: "",
      sourcePrice: "",
      active: true,
      activeOnline: false,
      service: false,
      vatBracket: "19",
      availability: "unavailable",
      images: "",
      availableFrom: null,
      preorderAvailable: false,
      preorderStart: null,
      preorderEnd: null,
      preorderQuantity: 0,
      attributes: {},
      recipe: [],
    },
  },
  validations: {
    product: yup.object().shape({
      categoryId: yup.number().required("Categoria este obligatorie"),
      barcode: yup.string().required("Codul de bare este obligatoriu"),
      sku: yup.string(),
      title: yup.string().required("Numele este obligatoriu"),
      description: yup.string().required("Descrierea este obligatorie"),
      metaTitle: yup.string().required("Meta Titlul este obligatoriu"),
      metaDescription: yup
        .string()
        .required("Meta Descrierea este obligatorie"),
      sourcePrice: yup.number().required("Pretul este obligatoriu"),
      availability: yup.string(),
      active: yup.boolean(),
      service: yup.boolean(),
      slug: yup.string().required("SLUG este obligatoriu"),
      vatBracket: yup.number().required("Cota TVA este obligatorie"),
    }),
  },
};

const Config = {
  Actions: ModuleBootstrapper.getActions(config.name, config.actions),
  Endpoints: config.endpoints,
  Defaults: config.defaults,
  Validations: config.validations,
  ...ModuleBootstrapper.getUtils(),
};

export default Config;
