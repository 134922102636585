import {
  Button,
  Image,
  Pane,
  Tab
} from "evergreen-ui";
import { Formik } from "formik";
import React, { Component } from "react";


import { CustomSEO } from "./ProductForm/CustomSeo";
import { MainTab } from './ProductForm/MainTab';


import { slugify } from "../../../util/Misc";
import { HeoImport } from "./ProductImport/HeoImport";
import { SimonSchusterImport } from "./ProductImport/SimonSchusterImport";



const generateMetas = (values, setFieldValue) => {
    setFieldValue("metaTitle", values.title);
    setFieldValue("slug", slugify(values.title));
  };


export default class ProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedTab: "main",
      openImportDialog: false,
      openHeoImportDialog: false
    };
  }



  onCategoryChange = (value, setFieldValue) => {
    setFieldValue("categoryId", value);
    this.props.onCategoryChange(value);
  };

  openImportDialog = () => {
    this.setState({ openImportDialog: true });
  }

  openHeoImportDialog = () => {
    this.setState({ openHeoImportDialog: true });
  }

  importProduct = (data, setFieldValue, values) => {
    if(data.title) {
      setFieldValue("title", data.title);
    }

    if(data.description) {
      setFieldValue("description", data.description);
    }

    if(data.vatBracket) {
      setFieldValue("vatBracket", data.vatBracket);
    }

    if(data.images) {
      setFieldValue("images", data.images.map((image, index) => ({data: image, position: index})));
    }

    if(data.isbn) {
      setFieldValue("barcode", data.isbn);
    }

    if(data.category) {
      const foundCategory = this.props.categories.find((category) => category.slug === data.category);
      if(foundCategory) {
        this.onCategoryChange(foundCategory.id, setFieldValue);

        if(data.author) {
          setTimeout(() => {
            const field = Object.values(foundCategory.attributes).find((attribute) => attribute.textId === "author");

            if(field && field.id) {
              setFieldValue(`attributes.attribute_${field.id}.value`, data.author)
            }
          }, 100)

        }
      }
    }

    generateMetas({ title: data.title }, setFieldValue);
  }

  importProductHeo = (data, setFieldValue, values) => {
    if(data.productname) {
      setFieldValue("title", data.productname);
    }

    if(data.price) {
      const calculatedPrice = Math.round(parseFloat(data.price) * (data.vatBracket/100 + 1) * 1.4 * 4.98 * 100) / 100;
      setFieldValue("sourcePrice", calculatedPrice);
    }

    if(data.image) {
      setFieldValue("images", [{data: data.image, position: 0}])
    }

    if(data.barcode) {
      setFieldValue("barcode", data.barcode);
    }

    if(data.itemnumber) {
      setFieldValue("sku", data.itemnumber);
    }

    if(data.description) {
      setFieldValue("description", data.description);
    }

    if(data.category) {
      const foundCategory = this.props.categories.find((category) => category.slug === data.category);
      if(foundCategory) {
        this.onCategoryChange(foundCategory.id, setFieldValue);
      }
    }

    generateMetas({ title: data.productname }, setFieldValue);

  }

  render() {
    return (
      <>
        <Formik
          initialValues={this.props.initialValues}
          enableReinitialize={true}
          validationSchema={this.props.validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            if (
              values.sourcePrice === 0 &&
              !window.confirm("Pretul produsului este 0. Continui?")
            ) {
              return false;
            }

            this.props.onSave(values);
          }}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            setFieldValue,
          }) => {
            errors = Object.assign({}, this.props.errors, errors);

            return (
              <Pane display="flex" justifyContent="center">
                <SimonSchusterImport open={this.state.openImportDialog}
                  onImport={(data) => this.importProduct(data, setFieldValue, values)}
                  onClose={() => this.setState({ openImportDialog: false })}/>
                <HeoImport open={this.state.openHeoImportDialog}
                  onImport={(data) => this.importProductHeo(data, setFieldValue, values)}
                  onClose={() => this.setState({ openHeoImportDialog: false })}/>

                <Pane maxWidth={1280} flexGrow={1}>
                  <form>
                    <Pane marginBottom={20} display="flex" gap={10}>
                      <Button type="submit" appearance="primary" intent="success" onClick={handleSubmit} marginBottom={20} >
                        Salveaza
                      </Button>

                      <Button type="button" onClick={this.openImportDialog}>
                        <Image src="/simonschuster.jpg" width={24} marginLeft={10}/> Import Simon & Schuster (VIZ Mangas)
                      </Button>

                      <Button type="button" onClick={this.openHeoImportDialog}>
                        <Image src="/heo.svg" width={24} marginRight={10}/> Import Heo
                      </Button>

                       <Pane flexGrow="1" display="flex" justifyContent="flex-end">
                        <Tab
                          isSelected={this.state.selectedTab === "main"}
                          onSelect={() => this.setState({ selectedTab: "main" })}
                        >
                          Date Produs
                        </Tab>
                        <Tab
                          isSelected={this.state.selectedTab === "custom-seo"}
                          onSelect={() => this.setState({ selectedTab: "custom-seo" })}
                        >
                          Custom SEO
                        </Tab>
                      </Pane>
                    </Pane>
                    <Pane>
                      {this.state.selectedTab === "main" ? (
                        <MainTab
                          errors={errors}
                          values={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          categories={this.props.categories}
                          suppliers={this.props.suppliers}
                          category={this.props.category}
                          onCategoryChange={this.onCategoryChange}
                          generateMetas={generateMetas}/>
                      ) : this.state.selectedTab === "custom-seo" ? (
                        <CustomSEO
                          errors={errors}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          generateMetas={generateMetas}/>
                      ) : null}
                    </Pane>

                    <Button type="submit" appearance="primary" intent="success" onClick={handleSubmit}>
                      Salveaza
                    </Button>
                  </form>
                </Pane>
            </Pane>
            );
          }}
        </Formik>
      </>
    );
  }
}
