import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'addresses',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' },
        { type: 'simple', name: 'openCreate'},
        { type: 'simple', name: 'openEdit'},
        { type: 'simple', name: 'closeEdit'},
        { type: 'simple', name: 'closeCreate'},
        { type: 'simple', name: 'changeCurrent'}
    ],
    endpoints: {
        addresses: '/addresses',
        address: '/addresses/[id]'
    },
    defaults: {
        createAddress: {
            isCompany: false,
            isBilling: true,
            isDelivery: true,
            addressName: "",
            email: "",
            phoneNumber: "",
            name: "",
            companyName: "",
            vatNumber: "",
            registryNumber: "",
            address: "",
            country: "Romania",
            city: "",
            state: "",
            other: ""
        }
    },
    validations: {
        address:yup.object().shape({
            isCompany: yup.bool().required(),
            isBilling: yup.bool().required(),
            isDelivery: yup.bool().required(),
            addressName: yup.string().required('Numele adresei este obligatoriu'),
            email: yup.string().email('Adresa de email este invalida').required('Adresa de email este obligatorie'),
            phoneNumber: yup.string().required('Numarul de telefon este obligatoriu'),
            name: yup.string().required('Numele este obligatoriu'),
            address: yup.string().required('Adresa este obligatorie'),
            country: yup.string().required('Tara este obligatorie'),
            city: yup.string().required('Localitatea este obligatorie'),
            state: yup.string().required('Judetul / Regiunea este obligatoriu'),
            other: yup.string(),
            companyName: yup.string().when('isCompany', {
                is: true,
                then: yup.string().required('Numele companiei este obligatoriu')
            }),
            vatNumber:  yup.string().when('isCompany', {
                is: true,
                then: yup.string().required('CUI-ul companiei este obligatoriu')
            }),
            registryNumber: yup.string().when('isCompany', {
                is: true,
                then: yup.string().required('Nr. inregistrare in registrul comertului este obligatoriu')
            })
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;

