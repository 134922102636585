import { Button, Heading, Table, Text } from 'evergreen-ui';
import { round } from 'lodash';
import React from 'react';
import Request from '../../../util/Api';


import InfoBlock from '../../common/InfoBlock/InfoBlock';
import OperationDetailsInfo from './OperationDetailsInfo';

const getTotal = (data) => {
    let total = 0;

    for(let i in data) {
        total += data[i].value * data[i].quantity;
    }

    return round(total, 2);
}

const getTotalAdjusted = (data) => {
    let total = 0;

    for(let i in data) {
        total += (data[i].adjustedValue * data[i].quantity) || 0;
    }

    return round(total, 2);
}

const getTotalSalePrice = (data) => {
    let total = 0;

    for(let i in data) {
        total += data[i].price * data[i].quantity;
    }

    return round(total, 2);
}


export default function StockOperationInfoBlock(props) {

    const downloadPurchaseEval = async () => {
        const postData = props.stockOperation.items.filter(item => item.type === 'Carte').map(item => {
            return {
                id: item.card.id,
                quantity: item.quantity
            }
        })

        const data = await Request.post('/stock-operations/evaluate-card-purchase', null, null, {
            data: postData
        });

        const file_path = "data:text/csv;charset=utf-8," + data;
        var a = document.createElement('A');
        a.href = file_path;

        a.download = 'evaluation.csv';
        document.body.appendChild(a);
        a.click();
    }

    const exportNir = async () => {
        const data = await Request.get('/stock-operations/' + props.stockOperation.id + '/nir-export');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();
    }

    if(!props.stockOperation || !props.stockOperation.items) {
        return <Text>Se incarca...</Text>;
    }

    return <InfoBlock
        flex="50%"
        marginRight={16}
        label="Detalii Operatie Stoc"
        button={{
            link: `/stock-operations/${props.stockOperation.id}/edit`,
            label: 'Modifica'
        }}
    >
        <OperationDetailsInfo
            suppliers={props.suppliers}
            stockAccounts={props.stockAccounts}
            stockOperation={props.stockOperation} />

        <Button marginBottom={12} type="button" onClick={() => { exportNir() }}>
            Download XLS
        </Button>


        <Button marginBottom={12} type="button" onClick={() => { downloadPurchaseEval(props.stockOperation.items) }}>
            Evaluare Achizitie
        </Button>

        <Heading marginTop={20} marginBottom={20}>
            Produse
        </Heading>
        <Table>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    #
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Tip
                </Table.TextHeaderCell>
                <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.Cell>
                <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                    Nume
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cant.
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare Totala
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare Ajustata
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Total
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Sell Through
                </Table.TextHeaderCell>
            </Table.Head>
            <Table.VirtualBody height={800}>
                {props.stockOperation.items.map(item =>
                    <Table.Row>
                        <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                            <Text>{item.position}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                            <Text>{item.type}</Text>
                        </Table.TextHeaderCell>
                        <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>
                        { item.thumbnail && item.type === 'Produs' ?
                                <img style={{ height: '40px', width: 'auto' }} alt="product-thumbnail"
                                    src={item.thumbnail ?
                                    process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + item.thumbnail + '.jpg' : null} /> :
                            item.thumbnail && item.type === 'Carte' ?
                                <img style={{ height: '40px', width: 'auto' }} alt="product-thumbnail"
                                    src={item.thumbnail ?
                                    process.env.REACT_APP_CARD_IMAGE_BUCKET + '' + item.thumbnail : null} /> : null }
                        </Table.Cell>
                        <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                            <Text>{item.name}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                            <Text>{item.quantity}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                            <Text> {round(item.value * props.stockOperation.exchange, 2)} Lei
                            {props.stockOperation.currency !== 'LEU' ? ` (${item.value} ${props.stockOperation.currency})` : ''}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                           <Text> {round(item.value * item.quantity * props.stockOperation.exchange, 2)} Lei
                            {props.stockOperation.currency !== 'LEU' ? ` (${round(item.value * item.quantity, 2)} ${props.stockOperation.currency})` : ''}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                            <Text>{round(item.adjustedValue * props.stockOperation.exchange, 2)} Lei
                            {props.stockOperation.currency !== 'LEU' ? ` (${item.adjustedValue} ${props.stockOperation.currency})` : ''}</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                            <Text>{item.price} Lei</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                            <Text>{round(item.price * item.quantity, 2)} Lei</Text>
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                            <Text>{item.lineValue} Lei</Text>
                        </Table.TextHeaderCell>
                    </Table.Row>
                )}
            </Table.VirtualBody>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.Cell>
                <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                    <Text>Total</Text>
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    <Text>{round(getTotal(props.stockOperation.items) * props.stockOperation.exchange, 2)} Lei {props.currency !== 'LEU' ? `(${round(getTotal(props.stockOperation.items), 2)} ${props.stockOperation.currency})` : ''}</Text>
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    <Text>{round(getTotalAdjusted(props.stockOperation.items) * props.stockOperation.exchange, 2)} Lei {props.currency !== 'LEU' ? `(${round(getTotalAdjusted(props.stockOperation.items), 2)} ${props.stockOperation.currency})` : ''}</Text>
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    <Text>{getTotalSalePrice(props.stockOperation.items)} Lei</Text>
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    <Text>{props.stockOperation.sellThrough} Lei</Text>
                </Table.TextHeaderCell>
            </Table.Head>
        </Table>
    </InfoBlock>
}
