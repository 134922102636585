import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const StockAccount = ModuleConfig.Structures.StockAccount;
const CRUDActions = ModuleConfig.CRUDActions;

export function getStockAccounts(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.stockAccounts,
        mapStructure: StockAccount,
        errorNotification: "Conturile nu au putut fi incarcate"
    });
}

export function getStockAccount(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.stockAccount,
        mapStructure: StockAccount,
        errorNotification: "Contul nu a putut fi incarcat"
    })
}

export function createStockAccount(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.stockAccounts,
        mapStructure: StockAccount,
        successNotification: "Contul a fost creat",
        nextUrl: '/stock-accounts/',
    })
}

export function updateStockAccount(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.stockAccount,
        mapStructure: StockAccount,
        successNotification: "Contul a fost modificat",
        nextUrl: '/stock-accounts/',
    })
}

export function deleteStockAccount(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.stockAccount,
        successNotification: "Contul a fost sters",
        nextUrl: '/stock-accounts'
    });
}