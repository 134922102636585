import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Pane, SelectMenu, Text } from "evergreen-ui";
import Request from "../../../util/Api";
import DataTable from "../../common/DataTable/DataTable";
import Config from "../Config";

import { getCardGames } from "../../cards/actions/CardActions";
import { getCardExpansions } from "../actions/cardExpansionActions";
import CardExpansionLabelModal from "../components/CardExpansionLabelModal";

class ListCardExpansions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGame: null,
      loading: false,
      labelModal: false,
    };

    this.resync = this.resync.bind(this);
    this.resyncGames = this.resyncGames.bind(this);
    this.closeLabelModal = this.closeLabelModal.bind(this);
  }

  async componentDidMount() {
    await this.props.dispatch(getCardGames());
  }

  async resync() {
    this.setState({
      loading: true,
    });

    const gameId = this.state.selectedGame;

    if (!gameId) {
      alert("Selecteaza un joc");
      this.setState({
        loading: false,
      });

      return;
    }

    await Request.post(
      `${Config.Endpoints.resyncExpansions}${gameId ? `?gameId=${gameId}` : ""}`
    );

    this.setState({
      loading: false,
    });
  }

  async resyncGames() {
    this.setState({
      loading: true,
    });
    await Request.post(Config.Endpoints.resyncGames);

    this.setState({
      loading: false,
    });
  }

  tptListDownload = async () => {
    if(!this.state.selectedGame) {
      alert('Selecteaza un joc');
      return;
    }

    const data = await Request.get(`/reports/cards-tcgpowerools?gameId=${this.state.selectedGame}`);
    const file_path = data.data;
    var a = document.createElement('A');
    a.href = file_path;
    a.download = data.filename;
    document.body.appendChild(a);
    a.click();
  }

  handleTableChange = (opts) => {
    this.props.dispatch(getCardExpansions(opts));
  };

  openLabelModal = () => {
    this.setState({
      labelModal: true,
    });
  };

  closeLabelModal = () => {
    this.setState({
      labelModal: false,
    });
  };

  render() {
    if (!this.props.cardExpansions || this.props.cardExpansions.length === 0) {
      return <Text>Se incarca...</Text>;
    }

    return (
      <>
        <CardExpansionLabelModal
          open={this.state.labelModal}
          onClose={this.closeLabelModal}
        />
        <Pane display="flex" marginBottom={16}>
          {this.state.loading ? (
            <Text>Se incarca...</Text>
          ) : (
            <>
              <SelectMenu
                title="Selecteaza joc"
                options={this.props.cardGames.map((game) => ({
                  label: game.name,
                  value: game.cardMarketId,
                }))}
                selected={this.state.selectedGame}
                onSelect={({ value }) => this.setState({ selectedGame: value })}
              >
                <Button style={{ marginRight: 10 }}>
                  {this.state.selectedGame ||
                    (this.props.cardGames.length > 0
                      ? "Selecteaza joc"
                      : "Se incarca...")}
                </Button>
              </SelectMenu>
              <Button onClick={this.tptListDownload}>Descarca CSV TCGPowerTools</Button>
              <Button onClick={this.resync}>
                Resincronizeaza toate seturile
              </Button>
              <Button onClick={this.resyncGames} marginLeft={20}>
                Resincronizeaza toate jocurile
              </Button>
              <Button onClick={this.openLabelModal} marginLeft={20}>
                Genereaza label-uri
              </Button>
            </>
          )}
        </Pane>
        <DataTable
          defaultFilter="updatedAt"
          defaultDirection="desc"
          columns={[
            { id: "id", label: "Id Intern", visible: false },
            {
              id: "cardGameId",
              label: "Joc",
              decorator: (cardExpansion) =>
                cardExpansion.cardGame ? cardExpansion.cardGame.name : null,
              options: this.props.cardGames.map((game) => ({
                label: game.name,
                value: game.cardMarketId,
              })),
            },
            { id: "code", label: "Cod" },
            { id: "name", label: "Nume" },
            { id: "releaseDate", label: "Lansare", decorator: "date" },
            { id: "cardsSynced", label: "Synced", decorator: "boolean" },
          ]}
          data={this.props.cardExpansions.data}
          updateKey={this.props.cardExpansions.data}
          page={this.props.cardExpansions.page}
          pageSize={this.props.cardExpansions.pageSize}
          pageCount={this.props.cardExpansions.pageCount}
          onTableChange={this.handleTableChange}
          itemMenu={[
            {
              getLink: (item) => `/card-expansions/${item[0]}`,
              label: "Detalii",
              icon: "eye-open",
            },
            {
              getLink: (item) => `/card-expansions/${item[0]}/edit`,
              label: "Editeaza",
              icon: "edit",
            },
          ]}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    cardExpansions: state.cardExpansions,
    cardGames: state.cards.cardGames,
    loading: false,
  };
}

export default connect(mapStateToProps)(ListCardExpansions);
