import moment from 'moment';
import Base from './Base';

export const textValues = {
    orderType: [
        { label: 'Facebook', value: 'facebook', saleChannel: 'online'},
        { label: 'Glovo', value: 'glovo', saleChannel: 'glovo'},
        { label: 'Online', value: 'online', saleChannel: 'online'},
        { label: 'Fizic', value: 'physical', saleChannel: 'store'},
        { label: 'Telefonic', value: 'phone', saleChannel: 'online'},
        { label: 'Email', value: 'email', saleChannel: 'online'},
    ],
    paymentStatus: [
        { label: 'Neplatita', value: 'unpaid'},
        { label: 'Plata Partiala', value: 'partial_payment'},
        { label: 'Platita', value: 'paid'},
        { label: 'Plata Esuata', value: 'payment_failed'},
        { label: 'Returnata', value: 'refunded'}
    ],
    status: [
        { label: 'Plasata', value: 'placed'},
        { label: 'Confirmata', value: 'confirmed'},
        { label: 'Procesata', value: 'processed'},
        { label: 'Livrata', value: 'delivered'},
        { label: 'Finalizata', value: 'completed'},
        { label: 'Anulata', value: 'cancelled'},
        { label: 'Retur in curs', value: 'returning' },
        { label: 'Returnata', value: 'returned' }
    ],

    deliveryStatus: [
        { label: 'Expeditie comandata', value: 'ordered'},
        { label: 'Expeditie ridicata', value: 'picked-up'},
        { label: 'Expeditie livrata', value: 'delivered'},
        { label: 'Expeditie anulata', value:'canceled'}
    ],
    entity: [
        { label: 'Persoana Fizica', value: 'pf' },
        { label: 'Persoana Juridica', value: 'pj' }
    ],
    delivery: [
        { label: 'Livrare Curier', value: 'delivery' },
        { label: 'Ridicare Magazin', value: 'personal' }
    ]
}

export default class Order extends Base {
    constructor(props, children = []) {
        super();

        this.import(props, children);
    }

    import(props) {
        this.id = this.assign('id', props);
        this.orderNumber = this.assign('orderNumber', props);
        this.orderType = this.assign('orderType', props);
        this.orderTypeText = (textValues.orderType.find(ot => ot.value === this.orderType) || { label: this.orderType + "[INV]" }).label;

        this.email = this.assign('email', props);
        this.status = this.assign('status', props);
        this.statusText = (textValues.status.find(st => st.value === this.status) || { label:  this.status + "[INV]" }).label;
        this.isPreorder = props.isPreorder;
        this.isBackorder = props.isBackorder;
        this.paymentTypeId = this.assign('paymentTypeId', props);
        this.paymentType = this.assign('paymentType', props);

        this.paymentStatus = this.assign('paymentStatus', props);
        this.paymentStatusText = (textValues.paymentStatus.find(st => st.value === this.paymentStatus) || { label:  this.paymentStatus + "[INV]"  }).label;
        this.billingName = this.assign('billingName', props);
        this.billingAddress = this.assign('billingAddress', props);
        this.billingPhoneNumber = this.assign('billingPhoneNumber', props);
        this.delivery = this.assign('delivery', props);
        this.deliveryText = (textValues.delivery.find(dt => dt.value === this.delivery) || { label:  this.delivery + "[INV]"  }).label;
        this.deliveryStatus = this.assign('deliveryStatus', props);
        this.deliveryStatusText = (textValues.deliveryStatus.find(dt => dt.value === this.deliveryStatus) || { label:  this.entityType + "[INV]"  }).label;
        this.deliveryName = this.assign('deliveryName', props);
        this.deliveryAddress = this.assign('deliveryAddress', props);
        this.deliveryPhoneNumber = this.assign('deliveryPhoneNumber', props);
        this.entityType = this.assign('entityType', props);
        this.entityTypeText = (textValues.entity.find(et => et.value === this.entityType) || { label:  this.entityType + "[INV]"  }).label;

        this.companyName = this.assign('companyName', props);
        this.vatNumber = this.assign('vatNumber', props);
        this.registryNumber = this.assign('registryNumber', props);

        this.total = this.assign('total', props);
        this.vat = this.assign('vat', props);
        this.grandTotal = this.assign('grandTotal', props);
        this.bankPaymentReference = this.assign('bankPaymentReference', props);
        this.cardPaymentReference = this.assign('cardPaymentReference', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;

        this.preorder = this.assign('preorder', props);
        this.emailOrderCount = props.emailOrderCount;

        if(props.items) {
            this.items = props.items
        }



        if(props.products || props.cards) {
            let products = (props.products || []).map(product => {
                    return {
                        id: product.productId,
                        type: 'product',
                        title: product.product.title,
                        sku: product.product.sku,
                        price: product.unitPrice ? product.unitPrice : product.product.price,
                        quantity: product.quantity ? product.quantity : product.product.quantity,
                        quantityReturned: product.quantityReturned ? product.quantityReturned : 0,
                        vatBracket: product.product.vatBracket,
                        vatRate: product.vatRate ? product.vatRate : product.product.vatBracket,
                        preorder: product.preorder ? product.preorder : product.product.preorder,
                        thumbnail: product.thumbnail ? product.thumbnail : product.product.thumbnail
                    }
                })

            let cards = (props.cards || []).map(card => {
                    if(!card.card) {
                        return null;
                    }
                    return {
                        id: card.cardId,
                        type: 'card',
                        name: card.card.name,
                        code: card.card.code,
                        price: card.unitPrice,
                        quantity: card.quantity,
                        thumbnail: card.card.thumbnail,
                        releaseDate: card.card.expansion ? card.card.expansion.releaseDate : null,
                        rarity: card.card.rarity,
                        variantName: `[V ${card.card.variantName}]` || ''
                    }
                })


            this.items = [
                ...products,
                ...cards
            ]
        }




        this.vouchers = (props.vouchers || []).map(voucher => {
            if(!voucher.voucher) {
                return {
                    ...voucher
                }
            } else {
                return {
                    ...voucher.voucher,
                    value: voucher.value,
                }
            }
        })

        this.invoices = props.invoices;

        this.billingCity = props.billingCity;
        this.billingCounty = props.billingCounty;
        this.billingLocation = props.billingLocation;

        this.deliveryCity = props.deliveryCity;
        this.deliveryCounty = props.deliveryCounty;
        this.deliveryLocation = props.deliveryLocation;

        this.deliveryLocation = props.deliveryLocation;
        this.billingLocation = props.billingLocation;

        this.deliveryReference = props.deliveryReference;
        this.shippingCost = props.shippingCost;
        this.realShippingCost = props.realShippingCost;
        this.voucherValue = props.voucherValue;

        this.extraData = typeof props.extraData === 'string' ? JSON.parse(props.extraData) : props.extraData;
        this.refundData = typeof props.refundData === 'string' ? JSON.parse(props.refundData) : props.refundData;
        this.documentId = this.assign('documentId', props);

        this.comment  = this.assign('comment', props);

        this.history = (props.history || []).sort((a, b) => {
            return moment(a.statusDate).isAfter(moment(b.statusDate)) ? -1 : 1;
        })
    }

    export() {
        const products = this.items.filter(item => item.type === 'product');
        const cards = this.items.filter(item => item.type === 'card');

        const order = {
            id: this.id,
            preorderId: this.preorder ? this.preorder.id : null,
            orderNumber: this.orderNumber,
            orderType: this.orderType,
            paymentTypeId: this.paymentTypeId,
            email: this.email,
            status:this.status,
            paymentStatus: this.paymentStatus,
            billingName: this.billingName,
            billingAddress: this.billingAddress,
            billingPhoneNumber: this.billingPhoneNumber,
            billingCityId: this.billingCity ? this.billingCity.id : null,
            billingCountyId: this.billingCounty ? this.billingCounty.id : null,
            billingLocationId: this.billingLocation ? this.billingLocation.id : null,
            delivery: this.delivery,
            deliveryName: this.deliveryName,
            deliveryAddress: this.deliveryAddress,
            deliveryPhoneNumber: this.deliveryPhoneNumber,
            deliveryCityId: this.deliveryCity ? this.deliveryCity.id : null,
            deliveryCountyId: this.deliveryCounty ? this.deliveryCounty.id : null,
            deliveryLocationId: this.deliveryLocation ? this.deliveryLocation.id : null,
            entityType:this.entityType,
            companyName: this.companyName,
            vatNumber: this.vatNumber,
            registryNumber: this.registryNumber,
            products: products,
            cards: cards,
            shippingCost: this.shippingCost,
            comment: this.comment
        }

        return order;
    }
}
