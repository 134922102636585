import Base from './Base';
import moment from 'moment';

export default class Voucher extends Base {
    constructor(props) {
        super();
        this.import(props);
    }

    import(props = {}) {
        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
        this.code = this.assign('code', props);
        this.value = this.assign('value', props);
        this.isPercentage = this.assign('isPercentage', props, 'boolean');
        this.used = this.assign('used', props, 'boolean');
        this.validFrom = props.validFrom ? moment(props.validFrom) : this.validFrom;
        this.validTo = props.validTo ? moment(props.validTo) : this.validTo;
        this.productId = this.assign('productId', props);
        this.unlimited = this.assign('unlimited', props, 'boolean');
        this.type = this.assign('type', props);
        this.categoryHierarchyId = this.assign('categoryHierarchyId', props);
        this.minValue = this.assign('minValue', props);
        this.comment = this.assign('comment', props);

        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;

        this.product = props.product || null;
        this.category = props.category || null;
    }

    export() {
        return {
            name: this.name,
            code: this.code,
            value: this.value,
            isPercentage: this.isPercentage || false,
            used: this.used || false,
            unlimited: this.unlimited || false,
            validFrom: this.validFrom,
            validTo: this.validTo,
            productId: this.productId || null,
            categoryHierarchyId: this.categoryHierarchyId || '',
            minValue: this.minValue,
            comment: this.comment,
            type: this.type
        }
    }
}