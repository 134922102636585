import React from 'react';
import { Pane, Select, Text, Button, TextInputField } from 'evergreen-ui';
import ProductPicker from '../../products/components/ProductPicker';
import CategoryPicker from '../../categories/components/CategoryPicker';
import UserPicker from '../../users/components/UserPicker';
import PaymentTypePicker from '../../paymentTypes/components/PaymentTypePicker';

const OfferRuleBlock = (props) => (
    <Pane display="flex" marginTop={16} alignItems="center">
        <Pane marginRight={16}>
            <Button iconBefore="trash" type="button" appearance="primary" intent="danger" onClick={props.onDelete}>
                Sterge
            </Button>
        </Pane>
        <Pane marginRight={16}>
            <Select 
                name={`rules.${props.index}.field`}
                value={props.field}
                onChange={props.onChange}
                onBlur={props.onBlur}
                >
                    <option value="">Selecteaza</option>
                    {props.restrictions.indexOf('productId') !== -1 ?
                        <option value="productId">Produs</option> : null}
                    {props.restrictions.indexOf('categoryId') !== -1 ?
                        <option value="categoryId">Categorie</option> : null}
                    {props.restrictions.indexOf('userId') !== -1 ?
                        <option value="userId">Utilizator</option> : null }
                    {props.restrictions.indexOf('paymentTypeId') !== -1 ?
                        <option value="paymentTypeId">Metoda de Plata</option> : null}
                    {props.restrictions.indexOf('excludeProductId') !== -1 ?
                        <option value="excludeProductId">Exclude Produs</option> : null}
                    {props.restrictions.indexOf('excludeCategoryId') !== -1 ?
                        <option value="excludeCategoryId">Exclude Categorie</option> : null}
                    
            </Select>
        </Pane>
  
        <Pane>
            <Pane width={200}>
                <Text>{props.value ? props.value.name : "Neselectat"}</Text>
            </Pane>
        </Pane>
        <Pane>
            {   props.field === 'productId' || props.field === 'excludeProductId' ?  <ProductPicker minimal={true} single={true}
                                onSubmit={(products) => props.setFieldValue(`rules.${props.index}.value`, products[0])}/> :
                props.field === 'categoryId' || props.field === 'excludeCategoryId' ? <CategoryPicker minimal={true} single={true} 
                                onSubmit={(categories) => props.setFieldValue(`rules.${props.index}.value`, categories[0])}/> :
                props.field === 'userId' ? <UserPicker minimal={true} single={true}
                                onSubmit={(users) => props.setFieldValue(`rules.${props.index}.value`, users[0])}/> :
                props.field === 'paymentTypeId' ? <PaymentTypePicker minimal={true} single={true} 
                                onSubmit={(paymentTypes) => props.setFieldValue(`rules.${props.index}.value`, paymentTypes[0])}/> : null
            }

        </Pane>

        <Pane width={200} marginLeft={16}>
            <TextInputField
                name={`rules.${props.index}.percentageDiscountOverride`}
                value={props.percentageDiscountOverride || 0}
                onChange={props.onChange}
                onBlur={props.onBlur}/>
        </Pane>
    </Pane>
)

export default OfferRuleBlock;