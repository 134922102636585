import moment from 'moment';

import Base from './Base';

export default class Address extends Base {
    constructor(props, children = []) {
        super();

        this.import(props, children);
    }

    import(props) {
        if(!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
        this.notes = this.assign('notes', props);
        this.deliveryTime = this.assign('deliveryTime', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            name: this.name,
            notes: this.notes,
            deliveryTime: this.deliveryTime
        }
    }
}
