import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListShopSales from './containers/ListShopSales';
import ViewShopSale from './containers/ViewShopSale';

const routes = [
    {
        type: 'crud',
        list: ListShopSales,
        view: ViewShopSale
    }
]

export default ModuleBootstrapper.getRoutes("shop-sales", routes)