import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const StockOperation = ModuleConfig.Structures.StockOperation;
const CRUDActions = ModuleConfig.CRUDActions;

export function getStockOperations(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.stockOperations,
        mapStructure: StockOperation,
        errorNotification: "Operatiile nu au putut fi incarcate"
    });
}

export function getStockOperation(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.stockOperation,
        mapStructure: StockOperation,
        errorNotification: "Operatia nu a putut fi incarcata"
    })
}

export function createStockOperation(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.stockOperations,
        mapStructure: StockOperation,
        successNotification: "Operatia a fost creata",
        nextUrl: '/stock-operations/',
    })
}

export function updateStockOperation(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.stockOperation,
        mapStructure: StockOperation,
        successNotification: "Operatia a fost modificata",
        nextUrl: '/stock-operations/',
    })
}

export function deleteStockOperation(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.stockOperations,
        successNotification: "Operatia a fost stearsa",
        nextUrl: '/stock-operations'
    });
}