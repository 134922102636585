import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListUsers from './containers/ListUsers';
import CreateUser from './containers/CreateUser';
import EditUser from './containers/EditUser';
import ViewUser from './containers/ViewUser';

const routes = [
    {
        type: 'crud',
        list: ListUsers,
        create: CreateUser,
        edit: EditUser,
        view: ViewUser
    }
]

export default ModuleBootstrapper.getRoutes("users", routes)