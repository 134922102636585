import * as yup from 'yup';
import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import CardExpansion from './models/CardExpansion';

const config =  {
    name: 'cardExpansions',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        cardExpansions: '/card-games/card-expansions',
        cardExpansion: '/card-games/card-expansions/[id]',
        resyncExpansion: '/card-games/sync-cards',
        resyncExpansions: '/card-games/sync-expansions',
        resyncPrices:'/card-games/sync-prices',
        resyncGames: '/card-games/sync-games',
    },
    defaults: {
        createCardExpansion: {

        }
    },
    validations: {
        cardExpansion: yup.object().shape({

        })
    }
}

const Config ={
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
    Structures: {
        CardExpansion
    },
}

export default Config;
