import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Pane, Tab, Tablist } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import DataTable from '../../common/DataTable/DataTable';
import { getOrders } from '../actions/OrderActions';
import { AlertBlock, BackorderBlock, CompanyBlock, PaymentBlock, PreorderBlock, SinglesBlock, StatusBlock, StorePickupBlock, TypeBlock } from '../components/ComponentBlocks';
import IntervalModal from '../components/IntervalModal';

class ListOrders extends Component {
    constructor(props) {
        super(props);

        this.openIntervalModal = this.openIntervalModal.bind(this);
        this.closeIntervalModal = this.closeIntervalModal.bind(this);
        this.setOrderView = this.setOrderView.bind(this);


        this.state = {
            activeOrders: 0,
            intervalModalOpen: false,
            selectedTab: 'orders',
            currentOpts: {}
        }
    }

    openIntervalModal() {
        this.setState({
            intervalModalOpen: true
        })
    }

    closeIntervalModal() {
        this.setState({
            intervalModalClosed: true
        })
    }


    handleTableChange = (opts) => {
        this.setState({
            currentOpts: opts
        })


        opts.start = opts.start || 0;
        opts.limit = opts.limit || 20;
        opts.sort = opts.sort || 'id';
        opts.direction = opts.direction || 'desc';

        let extraFilters = {
            ...opts.extraFilters,
        };

        if(this.state.selectedTab === 'orders') {
            extraFilters = {
                ...opts.extraFilters,
                isPreorder: 0,
                isBackorder: null,
                isSingles: null,
                delivery: 'delivery'
            }
        }

        if(this.state.selectedTab === 'singles') {
            extraFilters = {
                ...opts.extraFilters,
                isSingles: 1,
                delivery: 'delivery'
            }
        }

        if(this.state.selectedTab === 'pickup') {
            extraFilters = {
                ...opts.extraFilters,
                delivery: 'personal'
            }
        }

        if(this.state.selectedTab === 'shop-sales') {
            extraFilters = {
                ...opts.extraFilters,
                orderType: 'physical'
            }
        }

        if(this.state.selectedTab === 'preorders') {
            extraFilters = {
                ...opts.extraFilters,
                isPreorder: 1
            }
        }

        if(this.state.selectedTab === 'backorders') {
            extraFilters = {
                ...opts.extraFilters,
                isBackorder: 1
            }
        }

        this.props.dispatch(getOrders({
            ...opts,
            extraFilters
        }));
    }

    setOrderView(view) {
        this.setState({
            selectedTab: view
        }, () => {
            this.handleTableChange(this.state.currentOpts);
        });
    }

    render() {
        return (<>
            <IntervalModal open={this.state.intervalModalOpen} onClose={this.closeIntervalModal} />

            <Pane display="flex" flexDirection="column" gap={10} marginBottom={16}>
                <Pane display="flex" justifyContent="space-between" width="100%">
                    <Pane display="flex">
                        <Link to="/orders/create">
                            <Button iconBefore="add" intent="none" appearance="primary">
                                Comanda noua
                            </Button>
                        </Link>
                    </Pane>

                    <Pane display="flex">
                        <Button intent="none" onClick={this.openIntervalModal}>
                            Export Facturi
                        </Button>

                        {this.state.activeOrders > 0 ?
                            <Button iconBefore="truck" intent="warning" appearance="primary" style={{ marginLeft: '10px' }} onClick={() => { this.sendShipperOrders() }}>
                                Trimite Comanda Curier ({this.state.activeOrders} Colete)
                            </Button> : null}
                    </Pane>
                </Pane>
            </Pane>

            <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                <Tab
                    aria-controls={`panel-orders`}
                    isSelected={this.state.selectedTab === 'orders'}
                    key={'orders'}
                    onSelect={() => this.setOrderView('orders')}
                >
                    Comenzi Online
                </Tab>
                <Tab
                    aria-controls={`panel-singles`}
                    isSelected={this.state.selectedTab === 'singles'}
                    key={'singles'}
                    onSelect={() => this.setOrderView('singles')}
                >
                    Singles
                </Tab>
                <Tab
                    aria-controls={`panel-pickup`}
                    isSelected={this.state.selectedTab === 'pickup'}
                    key={'pickup'}
                    onSelect={() => this.setOrderView('pickup')}
                >
                    Comenzi Ridicare
                </Tab>
                <Tab
                    aria-controls={`panel-preorders`}
                    isSelected={this.state.selectedTab === 'preorders'}
                    key={'preorders'}
                    onSelect={() => this.setOrderView('preorders')}
                >
                    Precomenzi
                </Tab>
                <Tab
                    aria-controls={`panel-backorders`}
                    isSelected={this.state.selectedTab === 'backorders'}
                    key={'backorders'}
                    onSelect={() => this.setOrderView('backorders')}
                >
                    Comenzi Furnizor
                </Tab>
                <Tab
                    aria-controls={`panel-shop-sales`}
                    isSelected={this.state.selectedTab === 'shop-sales'}
                    key={'shop-sales'}
                    onSelect={() => this.setOrderView('shop-sales')}
                >
                    Vanzari Magazin
                </Tab>
                <Tab
                    aria-controls={`panel-all`}
                    isSelected={this.state.selectedTab === 'all'}
                    key={'all'}
                    onSelect={() => this.setOrderView('all')}
                >
                    Toate
                </Tab>
            </Tablist>

            <DataTable
                defaultFilter='orderNumber'
                defaultDirection='desc'
                columns={[
                    { id: 'id', label: 'Id', show: false },
                    { id: 'orderNumber', label: 'Nr. Comanda' },
                    {
                        id: 'isPreorder', label: 'Tags', decorator: (row) => <>
                            <PreorderBlock {...row} />
                            <BackorderBlock {...row} />
                            <SinglesBlock {...row}/>
                            <AlertBlock {...row}/>
                            <StorePickupBlock {...row}/>
                            <CompanyBlock {...row}/>

                        </>, options: [
                            { label: 'Tags', value: 1 },
                        ]
                    },
                    {
                        id: 'orderType', label: 'Tip Comanda', decorator: (row) => <TypeBlock {...row} />, options: [
                            { label: 'Toate', value: '' },
                            { label: 'Facebook', value: 'facebook' },
                            { label: 'Glovo', value: 'glovo' },
                            { label: 'Online', value: 'online' },
                            { label: 'Fizic', value: 'physical' },
                            { label: 'Telefonic', value: 'phone' },
                            { label: 'Email', value: 'email' },
                        ]
                    },
                    {
                        id: 'statusText', filterId: 'status', label: 'Status', decorator: (row) => <StatusBlock {...row} />, options: [
                            { label: 'Toate', value: '' },
                            { label: 'Plasata', value: 'placed' },
                            { label: 'Confirmata', value: 'confirmed' },
                            { label: 'Procesata', value: 'processed' },
                            { label: 'Livrata', value: 'delivered' },
                            { label: 'Finalizata', value: 'completed' },
                            { label: 'Anulata', value: 'cancelled' },
                            { label: 'Returnata', value: 'returned' }
                        ]
                    },
                    {
                        id: 'paymentStatusText', filterId: 'paymentStatus', label: 'Plata', decorator: (row) => <PaymentBlock {...row} />, options: [
                            { label: 'Neplatita', value: 'unpaid' },
                            { label: 'Plata Partiala', value: 'partial_payment' },
                            { label: 'Platita', value: 'paid' },
                            { label: 'Plata Esuata', value: 'payment_failed' },
                            { label: 'Returnata', value: 'refunded' }
                        ]
                    },
                    { id: 'billingName', label: 'Nume' },
                    { id: 'emailOrderCount', label: 'Nr. Comenzi'},
                    { id: 'email', label: 'Email'},
                    { id: 'grandTotal', label: 'Valoare', decorator: (row) => (row.grandTotal > 0 ? `${row.grandTotal} RON` : '-') },
                    { id: 'createdAt', label: 'Creat', decorator: 'datetime' },
                    { id: 'updatedAt', label: 'Updated', decorator: 'datetime' }
                ]}

                data={this.props.data}
                onTableChange={this.handleTableChange}

                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}

                itemMenu={[
                    { getLink: (item) => `/orders/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/orders/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.orders;
}

export default connect(mapStateToProps)(ListOrders);
