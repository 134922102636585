import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import { Pane, Heading } from 'evergreen-ui';
import KImageComponent from '../../common/FormComponents/KImageComponent';

import configuration from '../../../util/Configuration';

import CarouselShowcaseInfo from './CaruselShowcaseInfo';

export default function CarouselInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Slide"
        button={{
            link:`/carousel/${props.carousel.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.carousel.id}/>
        <InfoLine label="Varianta" value={props.carousel.variant}/>
        <InfoLine label="Nume" value={props.carousel.name}/>

        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
            <Heading size={500}>Imagine Background</Heading>
        </Pane>
        <KImageComponent 
            isView
            image={`${configuration.images}/carousel_bg_default_${props.carousel.backgroundImage}.webp`}/>

        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
            <Heading size={500}>Imagine Grafica</Heading>
        </Pane>
        <KImageComponent 
            isView
            image={`${configuration.images}/carousel_gfx_default_${props.carousel.graphicsImage}.webp`}/>

        {props.carousel.properties ?
            <CarouselShowcaseInfo carousel={props.carousel}/> : null}
        
        <InfoLine label="Pozitie" value={props.carousel.position}/>
        <InfoLine label="Activ" value={props.carousel.active} type="bool"/>
        <InfoLine label="Creat" value={props.carousel.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.carousel.updatedAt} type="date"/>
    </InfoBlock>
}