import React from 'react';
import {Pane, Card, Checkbox, Text } from 'evergreen-ui';

export default function ProductSearchCard(props) {
    return (
        <Card onClick={() => props.onClick(props.item)} cursor="pointer">
            <Pane display="flex" alignItems="center" background="tint2" margin={16} padding={16}>
                <Checkbox checked={props.selected} marginRight={16}/>
                <img style={{height: '64px', width: 'auto'}} alt="product-thumbnail"
                            src={props.item.thumbnail ? 
                                process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + props.item.thumbnail + '.jpg' : null}/>
                <Text size={400} marginLeft={10}>
                    {props.item.title} ({props.item.sku || "NO SKU"} - {props.item.barcode})
                </Text>
            </Pane>
        </Card>
    )
}