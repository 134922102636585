import { push } from 'connected-react-router';
import { toaster } from 'evergreen-ui';

import Request from '../../../util/Api';
import ErrorHandler from '../../../util/ErrorHandler';
import { setLoading, unsetLoading } from '../../common/Loading/Actions';

export const getAll = (actionGroup, opts, extraFind) => {
    return async (dispatch) => {
        dispatch(setLoading(actionGroup.request.id));
        dispatch(actionGroup.request.thunk());

        try {

            const result = await Request.get(opts.endpoint, null, {
                _start: opts.start,
                _end: opts.start + opts.limit,
                _sort: opts.sort,
                _order: opts.direction,
                q: opts.search,
                extraFilters: JSON.stringify(opts.extraFilters),
                ...extraFind
            });

            const data = result.data.map(item => new opts.mapStructure(item));

            if(opts.successNotification) {
                toaster.success(opts.successNotification);
            }

            dispatch(actionGroup.success.thunk({
                data,
                count: result.count,
                pageSize: opts.limit,
                page: Math.ceil(opts.start / opts.limit) + 1,
                pageCount: Math.ceil(result.count / opts.limit),
                search: opts.search || "",
                sort: opts.sort,
                direction: opts.direction
            }))
        } catch (err) {
            console.log(err);
            dispatch(actionGroup.failure.thunk(err));
            if(opts.errorNotification) {
                toaster.danger(opts.errorNotification);
            }
        }

        dispatch(unsetLoading(actionGroup.request.id));
    }
}

export const getOne = (actionGroup, opts) => {
    return async (dispatch) => {
        dispatch(setLoading(actionGroup.request.id));
        dispatch(actionGroup.request.thunk());

        try {
            const result = await Request.get(opts.endpoint, { id: opts.id });
            const data = new opts.mapStructure(result.data);

            if(opts.successNotification) {
                toaster.success(opts.successNotification);
            }

            dispatch(actionGroup.success.thunk(data));
        } catch (err) {
            console.log(err);
            dispatch(actionGroup.failure.thunk(err));

            if(opts.errorNotification) {
                toaster.danger(opts.errorNotification);
            }
        }

        dispatch(unsetLoading(actionGroup.request.id));
    }
}

export const create = (actionGroup, opts) => {
    return async (dispatch) => {
        dispatch(setLoading(actionGroup.request.id));
        dispatch(actionGroup.request.thunk());

        let crudObject = new opts.mapStructure(opts.values);

        const data = crudObject.export(opts.exportOpts || {})

        for(let i in data) {
            if(data[i] === "" && i === "id") {
                delete data[i];
            }
        }

        try {
            const result = await Request.post(opts.endpoint, null, null, data);
            const createData = new opts.mapStructure(result.data);

            dispatch(actionGroup.success.thunk(createData));

            if(opts.successNotification) {
                toaster.success(opts.successNotification);
            }

            if(opts.nextUrl) {
                dispatch(push(opts.nextUrl + createData.id));
            }
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            if(opts.errorNotification) {
                toaster.danger(opts.errorNotification);
            }

            dispatch(actionGroup.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actionGroup.request.id));
    }
}

export const update = (actionGroup, opts) => {
    return async (dispatch) => {
        dispatch(setLoading(actionGroup.request.id));
        dispatch(actionGroup.request.thunk());

        let crudObject = new opts.mapStructure(opts.values);

        const data = crudObject.export()

        try {
            const result = await Request.put(opts.endpoint, { id: opts.id }, null, data);
            const updateData = new opts.mapStructure(result.data);

            dispatch(actionGroup.success.thunk(updateData));

            if(opts.successNotification) {
                toaster.success(opts.successNotification);
            }

            if(opts.nextUrl) {
                dispatch(push(opts.nextUrl + updateData.id));
            }

        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            if(opts.errorNotification) {
                toaster.danger(opts.errorNotification);
            }

            dispatch(actionGroup.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actionGroup.request.id));
    }
}

export const remove = (actionGroup, opts) => {
    return async (dispatch) => {
        dispatch(setLoading(actionGroup.request.id));
        dispatch(actionGroup.request.thunk());

        try {
            await Request.delete(opts.endpoint, {id: opts.id});

            dispatch(actionGroup.success.thunk());

            if(opts.successNotification) {
                toaster.success(opts.successNotification);
            }

            if(opts.nextUrl) {
                dispatch(push(opts.nextUrl));
            }
        } catch (e) {
            dispatch(actionGroup.failure.thunk(e));

            if(opts.errorNotification) {
                toaster.danger(opts.errorNotification);
            }
        }

        dispatch(unsetLoading(actionGroup.request.id));
    }
}
