import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import CreateProduct from './containers/CreateProduct';
import EditProduct from './containers/EditProduct';
import ImportProducts from './containers/ImportProducts';
import ListProducts from './containers/ListProducts';
import ViewProduct from './containers/ViewProduct';

const routes = [
    {
        type: 'simple',
        name: 'import-products',
        view: ImportProducts,
        path: '/products/import'
    },
    {
        type: 'crud',
        list: ListProducts,
        create: CreateProduct,
        edit: EditProduct,
        view: ViewProduct,
    },
]

export default ModuleBootstrapper.getRoutes("products", routes)
