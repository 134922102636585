import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const ProductList = ModuleConfig.Structures.ProductList;
const CRUDActions = ModuleConfig.CRUDActions;

export function getProductLists(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.productLists,
        mapStructure: ProductList,
        errorNotification: "Listele de produse nu au putut fi incarcate"
    });
}

export function getProductList(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.productList,
        mapStructure: ProductList,
        errorNotification: "Lista de produse nu a putut fi incarcata"
    })
}

export function createProductList(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.productLists,
        mapStructure: ProductList,
        successNotification: "Lista de produse a fost creata",
        nextUrl: '/product-lists/',
    })
}

export function updateProductList(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.productList,
        mapStructure: ProductList,
        successNotification: "Lista de produse a fost modificata",
        nextUrl: '/product-lists/',
    })
}

export function deleteProductList(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.productList,
        successNotification: "Lista de produse a fost stearsa",
        nextUrl: '/product-lists'
    });
}