import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { Alert, Button, Checkbox, Dialog, Pane, Text, Heading, TextInputField, WarningSignIcon, CrossIcon } from 'evergreen-ui';
import { Icon } from 'evergreen-ui'



class OrderStatusProcessedModal extends Component {
    constructor (props) {
        super(props);

        this.classes = props.classes;
        this.onClose = this.onClose.bind(this);

    }
    
    onClose = () => {
        this.props.onClose();
    }

    render () {
      return (<Dialog
        shouldCloseOnOverlayClick={false}
        width={1000}
        isShown={this.props.open}
        title= {"Procesare comanda"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>

            <Pane>
                <Formik initialValues={{
                            packed: false,
                            stocked: false,
                            emailNotifications: true,
                            smsNotifications: true,
                            packages: []
                        }}
                        enableReinitialize={true}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit = {(values) => {
                            this.props.confirmOperation(values);
                            this.props.onClose();
                        }}
                >
                    {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                        return <form>
                                    {!this.props.checks.stock ? 
                                        <Alert
                                            intent="danger"
                                            title="Produsele nu par a fi in stoc."
                                            marginBottom={32}
                                        /> : null}
                                    <Pane display="flex">
                                        <Pane width={600}>
                                            <Heading size={400}>Dimensiuni colete</Heading>
                                            <Text color="muted">Introdu dimensiunile coletelor care vor fi livrate</Text>
                                            {
                                                values.packages.length > 2 ? 
                                                <Text color="red" display="block">Nu putem folosi o cutie mai mare?</Text> : null
                                            }
                                            <FieldArray name="packages" render={arrayHelpers => (
                                                <Pane marginTop={10}>
                                                    {this.props.order.delivery === 'delivery' ?
                                                        <Button type="button" onClick={() => arrayHelpers.push({width: 10, height: 10, length: 10})}>Adauga colet</Button>
                                                    : <Alert intent="none" title="Comanda fara livrare prin curier, nu contine colete" marginBottom={16}/>}
                                                    {values.packages.map((pkg, i) => (<Pane display="flex" marginTop={10}>
                                                        <TextInputField
                                                            width={150}
                                                            marginRight={5}
                                                            required
                                                            label="Latime (cm)"
                                                            name={`packages.${i}.width`}
                                                            value={pkg.width || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            />
                                                        <TextInputField
                                                            width={150}
                                                            marginRight={5}
                                                            required
                                                            label="Lungime (cm)"
                                                            name={`packages.${i}.length`}
                                                            value={pkg.length || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            />
                                                        <TextInputField
                                                            width={150}
                                                            marginRight={5}
                                                            required
                                                            label="Inaltime (cm)"
                                                            name={`packages.${i}.height`}
                                                            value={pkg.height || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            />
                                                        <Button type="button" onClick={() => arrayHelpers.remove(i)} marginTop={24} intent="danger">X</Button>
                                                    </Pane> ))}
                                                    
                                                </Pane>
                                            )} />
                                        </Pane>

                                        <Pane padding={10} border="1px solid #444444" flexGrow={1}>
                                            <Heading size={400}>Confirmare procesare</Heading>
                                            <Pane display="flex">
                                                <Checkbox label="Produsele sunt in stoc" 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="stocked"
                                                    checked={values.stocked}
                                                />

                                                {!this.props.checks.stock ? 
                                                    <Icon title="Verifica stocul produselor" icon={WarningSignIcon} color="warning" marginLeft={16} marginTop={16} /> : null}
                                            </Pane>
                                            <Pane display="flex">
                                                <Checkbox label="Comanda a fost impachetata"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="packed"
                                                    checked={values.packed}
                                                />
                                            </Pane>
                                       
                                            <Heading size={400}>Optiuni Notificari</Heading>
                                            <Pane display="flex">
                                                <Checkbox label="Trimite notificari prin email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="emailNotifications"
                                                    checked={values.emailNotifications}
                                                    disabled={!this.props.checks.emailNotifications}
                                                />
                                                {!this.props.checks.emailNotifications ? 
                                                    <Icon title="Clientul a ales sa nu fie notificat pe email" icon={CrossIcon} color="muted" marginLeft={16} marginTop={16} /> : null}
                                            </Pane>
                                            
                                            <Pane display="flex">
                                                <Checkbox label="Trimite notificari prin sms"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="smsNotifications"
                                                    checked={values.smsNotifications}
                                                    disabled={!this.props.checks.smsNotifications}

                                                />

                                                {!this.props.checks.smsNotifications ? 
                                                    <Icon title="Clientul a ales sa nu fie notificat pe SMS" icon={CrossIcon} color="muted" marginLeft={16} marginTop={16} /> : null}
                                            </Pane>
                                        </Pane>
                                        
                                    </Pane>
                                
                                    <Pane borderTop="default" marginTop={16} paddingTop={16}>
                                        <Button type="submit" onClick={handleSubmit} 
                                            disabled={(values.packages.length === 0 && this.props.order.delivery === 'delivery') || !values.packed || !values.stocked}>
                                            Confirmare
                                        </Button>
                                    </Pane>
                                </form>
                    }}
                </Formik>
            </Pane>
      </Dialog>)
    }
}

export default OrderStatusProcessedModal;