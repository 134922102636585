import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import { Pane, Heading, Button } from 'evergreen-ui';
import OfferRuleInfoBlock from './OfferRuleInfoBlock';
import VoucherDialog from '../containers/VoucherDialog';

export default function OfferInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Oferta"
        button={{
            link:`/offers/${props.offer.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.offer.id}/>
        <InfoLine label="Aplicabilitate" value={props.offer.stage} type="map" map={{
            product: 'Produs',
            cart: 'Cos',
            order: 'Comanda'
        }}/>
        <InfoLine label="Nume" value={props.offer.name}/>
        <InfoLine label="Tipul" value={props.offer.valueType} type="map" map={{
            percentage: 'Procentaj',
            flat: 'Valoare Fixa'
        }}/>
        <InfoLine label="Valoarea" value={props.offer.value}/>

        {props.offer.target === 'voucher' ? <>
            <Button appearance="primary" type="button" margin={16} width={200}>
                Gestioneaza Vouchere
            </Button>
        </> : null}

        {props.offer.duration === 'interval' ? <>
            <InfoLine label="Data Inceput" value={props.offer.startDate} type="date"/>
            <InfoLine label="Data Sfarsit" value={props.offer.endDate} type="date"/>
        </> : null}

        <InfoLine label="Cumulativa" value={props.offer.cumulative} type="bool"/>
        <InfoLine label="Activa" value={props.offer.active} type="bool"/>
        <InfoLine label="Pozitie" value={props.offer.position}/>

        <InfoLine label="Creat" value={props.offer.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.offer.updatedAt} type="date"/>

        {props.offer.isVoucher ? 
            <VoucherDialog offer={props.offer}/> : null}


        <Pane flex={1} padding={16} marginTop={16}>
            <Heading size={600}>
                Reguli
            </Heading>
        </Pane>

        {props.offer.rules ? props.offer.rules.map(rule => 
            <OfferRuleInfoBlock rule={rule} key={rule.id}/>
        ) : null}
    </InfoBlock>
}