import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pane, Spinner } from 'evergreen-ui';

class LoadingBlock extends Component {
    render() {
        return (
            this.props.isLoading > 0 ? <Pane 
                        position="fixed"
                        zIndex={10000}
                        bottom={30}
                        right={30}
                        padding={10}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        background="tint2"
                        elevation={1}>
                        
                        <Spinner/>
                    </Pane> : null
        )
    }
}

function mapStateToProps(state) {
    return state.loading
}

export default connect(mapStateToProps)(LoadingBlock);