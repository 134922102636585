import React, { Component } from 'react';
import { Button, Dialog, Heading, Pane, SegmentedControl, Text, TextInputField } from 'evergreen-ui';
import ProductPicker from '../../products/components/ProductPicker';
import CardPicker from '../../cards/components/CardPicker';
import { StockType } from '../models/StockType';

class OperationItemModal extends Component {
    constructor (props) {
        super(props);

        this.onClose = this.onClose.bind(this);

        this.state = {
            type: 'Produs'
        }
    }

    onClose = () => {
        this.props.onClose();
    }

    render () {
        const selectedIndex =  (this.props.selected || [])[0];

        const item = this.props.items[selectedIndex] || {
            quantity: 0,
            value: 0
        };

      return (<Dialog
        shouldCloseOnOverlayClick={false}
        width={800}
        isShown={this.props.open}
        title= {""}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane display="flex" flexDirection="row">
                <Pane marginBottom={10} width={400}>
                    <SegmentedControl
                        marginBottom={16}
                        width={300}
                        options={StockType}
                        value={this.state.type}
                        onChange={value => {
                            this.props.setFieldValue(`items.${selectedIndex}.type`, value);
                            this.setState({
                                type: value
                            })
                        }}/>

                    {this.state.type === 'Carte' ? <>
                    <Heading marginBottom={10}>Alege o carte:</Heading>
                    <CardPicker onlyButton={true} minimal={true} single={true}
                                    onSubmit={(products) => {
                                        this.props.setFieldValue(`items.${selectedIndex}`, {
                                            ...item,
                                            id: products[0].id,
                                            type: 'Carte',
                                            price: products[0].price,
                                            thumbnail: products[0].thumbnail,
                                            name: `${products[0].id} ${products[0].name} ${products[0].code} ${products[0].rarity} ${products[0].variantName}`,
                                            quantity: item.quantity || 0,
                                            value: item.value || 0
                                        })
                                    }}/>
                    </>
                    : this.state.type === 'Produs' ?
                    <>
                    <Heading marginBottom={10}>Alege un produs:</Heading>
                    <ProductPicker onlyButton={true} minimal={true} single={true}
                                    onSubmit={(products) => {
                                        this.props.setFieldValue(`items.${selectedIndex}`, {
                                            ...item,
                                            id: products[0].id,
                                            type: 'Produs',
                                            price: products[0].price,
                                            thumbnail: products[0].thumbnail,
                                            name: `#${products[0].id} ${products[0].title}`,
                                            quantity: item.quantity || 0,
                                            value: item.value || 0
                                        })
                                    }}/>
                    </>
                        :
                    <TextInputField
                        flex={1}
                        marginTop={10}
                        marginLeft={5}
                        required
                        label="Nume"
                        name="name"
                        value={item.name}
                        onChange={(e) => {
                            this.props.setFieldValue(`items.${selectedIndex}.name`, e.target.value)
                        }}/>
                    }

                    <TextInputField
                        flex={1}
                        marginTop={10}
                        marginLeft={5}
                        required
                        label="Cantitate"
                        name="quantity"
                        description="Cantitatea intrata in stoc"
                        value={item.quantity}
                        onChange={(e) => {
                            this.props.setFieldValue(`items.${selectedIndex}.quantity`, e.target.value)
                        }}/>

                    <TextInputField
                        flex={1}
                        marginLeft={5}
                        required
                        label="Valoare"
                        name="value"
                        description="Valoarea unei unitati (fara tva), in moneda NIR-ului"
                        value={item.value}
                        onChange={(e) => {
                            this.props.setFieldValue(`items.${selectedIndex}.value`, e.target.value)
                            if(!item.price) {
                                this.props.setFieldValue(`items.${selectedIndex}.price`, 0)
                            }
                        }}/>
                </Pane>

                { item && item.id ?
                    <Pane width={380} marginLeft={20}>
                        <Heading size={500}>Produs Selectat:</Heading>
                        <Heading size={400}>{item.name}</Heading>
                        <Text><strong>Pret Curent</strong> {item.price} Lei</Text>

                        <Pane marginTop={20}>
                            <img style={{height: '128px', width: 'auto'}} alt="product-thumbnail"
                                    src={item.thumbnail ?
                                        process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + item.thumbnail + '.jpg' : null}/>
                        </Pane>
                    </Pane> : null }
            </Pane>


            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
      </Dialog>)
    }
}

export default OperationItemModal;
