
import { ThemeProvider, defaultTheme } from 'evergreen-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Modules from './modules';

import configureStore, { history } from './configureStore';
import AppContainer from './modules/common/App/AppContainer';

import "react-datepicker/dist/react-datepicker.css";

// dotenv.config();

const store = configureStore();

const root = createRoot(document.getElementById('root'));

root.render( <Provider store={store}>
    <ThemeProvider value={defaultTheme}>
        <AppContainer>
            <Router>
                <ConnectedRouter history={history}>
                        <Switch>
                            {Modules}
                        </Switch>
                </ConnectedRouter>
            </Router>
        </AppContainer>
    </ThemeProvider>
</Provider>)
