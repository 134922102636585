import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pane, Heading, Button } from 'evergreen-ui';
import StockOperationForm from '../components/StockOperationForm';
import { Link } from 'react-router-dom';
import { getStockAccounts } from '../../stockAccount/actions/StockAccountActions';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import { getStockOperation, updateStockOperation } from '../actions/StockOperationActions';

import Configuration from '../Config';
import InfoBlock from '../../common/InfoBlock/InfoBlock';
const validation = Configuration.Validations.stockOperation;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;


class EditStockOperation extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getStockOperation(this.id));
        this.props.dispatch(getStockAccounts());
        this.props.dispatch(getSuppliers());
    }

    handleSave = (values) => {
        this.props.dispatch(updateStockOperation(this.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/stock-operations">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la operatii stocuri
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Editare Operatie
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <StockOperationForm 
                            view="edit"
                            stockAccounts={this.props.stockAccounts} 
                            suppliers={this.props.suppliers}
                            initialValues={this.props.stockOperation}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}
                        />
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        stockOperation: state.stockOperations.current,
        suppliers: state.suppliers.data,
        stockAccounts: state.stockAccounts.data,
        errors: state.stockOperations.errors.type === 'validation' ? mapValidationErrors(state.stockOperations.errors.fields): [],
    }
}

export default connect(mapStateToProps)(EditStockOperation);