import React, { Component } from 'react';
import { Button, Dialog, Pane, TextInputField } from 'evergreen-ui';

class LabelModal extends Component {
    constructor (props) {
        super(props);

        this.classes = props.classes;

        this.state = {
            title: props.title || '',
            totalPrice: props.totalPrice || 0,
            totalOldPrice: props.totalOldPrice || 0
        }

        this.onClose = this.onClose.bind(this);
    }

    onClose = () => {
        this.setState({});
        this.props.onClose();
    }
   
    render () {
      return (<Dialog
        isShown={this.props.open}
        title= {"Label Pret Custom"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                <TextInputField
                    required
                    type="text"
                    label="Nume"
                    name="title"
                    description="Numele produsului"
                    value={this.state.title || ""}
                    onChange={(e) => {
                        this.setState({
                            title: e.target.value
                        })
                    }}/>
                     <TextInputField
                    required
                    type="text"
                    label="Pret Nou"
                    name="totalPruce"
                    description="Pretul activ"
                    value={this.state.totalPrice || ""}
                    onChange={(e) => {
                        this.setState({
                            totalPrice: e.target.value
                        })
                    }}/>
                     <TextInputField
                    required
                    type="text"
                    label="Pret Vechi"
                    name="totalOldPrice"
                    description="Pretul anterior al produsului"
                    value={this.state.totalOldPrice || ""}
                    onChange={(e) => {
                        this.setState({
                            totalOldPrice: e.target.value
                        })
                    }}/>
            </Pane>

            <Button type="button" onClick={() => {
                this.props.onDownload(this.state.title, parseFloat(this.state.totalPrice), parseFloat(this.state.totalOldPrice));
            }}>Download</Button>
           
            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
             
            
      </Dialog>)
    }
}

export default LabelModal;