import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const PaymentType = ModuleConfig.Structures.PaymentType;
const CRUDActions = ModuleConfig.CRUDActions;

export function getPaymentTypes(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.paymentTypes,
        mapStructure: PaymentType,
        errorNotification: "Modalitatile de plata nu au putut fi incarcate"
    });
}

export function getPaymentType(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.paymentType,
        mapStructure: PaymentType,
        errorNotification: "Modalitatea de plata nu a putut fi incarcata"
    })
}

export function createPaymentType(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.paymentTypes,
        mapStructure: PaymentType,
        successNotification: "Modalitatea de plata a fost creata",
        nextUrl: '/payment-types/',
    })
}

export function updatePaymentType(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.paymentType,
        mapStructure: PaymentType,
        successNotification: "Modalitatea de plata a fost modificata",
        nextUrl: '/payment-types/',
    })
}

export function deletePaymentType(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.paymentType,
        successNotification: "Modalitatea de plata a fost stearsa",
        nextUrl: '/payment-types'
    });
}