import React from 'react';
import { Pane, Text, Checkbox } from 'evergreen-ui';
import ReactHtmlParser from 'react-html-parser';

import moment from 'moment';

export default function InfoLine(props) {
    let value = props.value;
    if(typeof props.value === 'object') {
        value = JSON.stringify(props.value);
    }

    return (
        <Pane display="flex" marginBottom={4} borderBottom="default">
            <Pane padding={8} width={150} minWidth={150} >
                <Text><strong>{props.label}</strong></Text>
            </Pane>
            <Pane flexGrow={1} padding={8}>
                 {  props.type === "bool" ? <Checkbox checked = {value} margin={3} disabled/> :
                    props.type === "date" ? <Text>{moment(props.value).format('D MMM YYYY HH:mm:ss')}</Text> :
                    props.type === "rich-text" ? <Text>{ReactHtmlParser(value)}</Text> :
                    props.type === "map" ? <Text>{props.map[value]}</Text> :
                    <Text>{value}</Text>}
            </Pane>
        </Pane>
    )
}
