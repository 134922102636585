import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSupplier, deleteSupplier } from '../actions/SupplierActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import SupplierInfoBlock from '../components/SupplierInfoBlock';

class ViewSupplier extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getSupplier(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteSupplier(this.id));
    }

    render() {
            
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/suppliers">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista furnizori
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge furnizorul"
                            title="Confirmare stergere furnizor"
                            confirmLabel="Sterg definitiv furnizorul"
                            message="Esti sigur ca vrei sa stergi furnizorul?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Furnizor - <strong>{this.props.supplier.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <SupplierInfoBlock supplier={this.props.supplier}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        supplier: state.suppliers.current
    }
}

export default connect(mapStateToProps)(ViewSupplier);