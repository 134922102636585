import React from 'react';

import { Switch } from 'react-router-dom';

import Authentication from './authentication/Module';
import Users from './users/Module';
import Attributes from './attributes/Module';
import Categories from './categories/Module';
import Products from './products/Module';
import PaymentTypes from './paymentTypes/Module';
import ProductLists from './productLists/Module';
import Offers from './offers/Module';
import Orders from './orders/Module';
import Carousel from './carousel/Module';
import Suppliers from './suppliers/Module';
import SupplierOrders from './supplierOrders/Module';
import CategoryLists from './categoryLists/Module';
import ShopSales from './shopSales/Module';
import StockAccounts from './stockAccount/Module';
import Dashboard from './dashboard/Module';
import Carts from './carts/Module';
import Vouchers from './vouchers/Module';
import Preorders from './preorders/Module';
import Cards from './cards/Module';
import CardExpansions from './cardExpansions/Module';
import StockOperations from './stockOperations/Module';
import InventoryBuffer from './inventoryBuffer/Module';


import OrchestraLayout from './common/Layouts/OrchestraLayout';

const modules = [
    ...Authentication,
    <OrchestraLayout key="admin-interface-routes">
        <Switch>
            {[
                ...Carts,
                ...Users,
                ...Attributes,
                ...Categories,
                ...Products,
                ...PaymentTypes,
                ...Offers,
                ...Orders,
                ...ProductLists,
                ...Carousel,
                ...Suppliers,
                ...SupplierOrders,
                ...StockOperations,
                ...CategoryLists,
                ...ShopSales,
                ...StockAccounts,
                ...Vouchers,
                ...Preorders,
                ...CardExpansions,
                ...Cards,
                ...InventoryBuffer,
                ...Dashboard,
            ]}
        </Switch>
    </OrchestraLayout>
]

export default modules;
