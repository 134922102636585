import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { Pane, Heading } from 'evergreen-ui';

export default function OrderSize(props) {
    return <Pane marginBottom={50} height={300} width="100%">
        <Heading>Valoare medie comenzi</Heading>
        <ResponsiveBar
            data={props.data}
            keys={['average']}
            indexBy="period"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.1}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'category10' }}
    />
    </Pane>
}