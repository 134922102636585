import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSupplier, updateSupplier } from '../actions/SupplierActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import SupplierForm from '../components/SupplierForm';

import Configuration from '../Config';

const validation = Configuration.Validations.editSupplier;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditSupplier extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getSupplier(this.id));
    }

    handleSave = (values) => {
        this.props.dispatch(updateSupplier(this.props.supplier.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/suppliers">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista furnizori
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/suppliers/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Atribut - <strong>{this.props.supplier.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <SupplierForm 
                            isEdit={true}
                            initialValues={this.props.supplier}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.suppliers.errors.type === 'validation' ? mapValidationErrors(state.suppliers.errors.fields): [],
        supplier: state.suppliers.current
    }
}

export default connect(mapStateToProps)(EditSupplier);