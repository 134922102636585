import React, { Component } from 'react';
import ObjectPickerDialog from '../../common/ObjectPicker/ObjectPickerDialog';
import CategorySearchCard from './CategorySearchCard';
import { Button, Pane, Heading } from 'evergreen-ui';

import Request from '../../../util/Api';

import Config from '../Config';
import CategoryListCard from './CategoryListCard';



class CategoryPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false
        }
    }

    toggleSearch(show = true) {
        this.setState({
            showSearch: show
        })
    }

    searchFunction(value) {
        return Request.get(Config.Endpoints.categories, null, {q: value});
    }

    mapperFunction(value) {
        return value.data.map(entry => ({...entry, name: entry.title}));
    }

    handleSubmit = (selection) => {
        this.props.onSubmit(selection);
    }

    handleDelete = (product) => {
        const values = this.props.values.filter(value => value !== product);
        this.props.onSubmit(values);
    }


    render() {
        return (<Pane>
                    <ObjectPickerDialog
                        single={this.props.single || false}
                        label="Selecteaza una sau mai multe categorii"
                        open={!!this.state.showSearch}
                        onClose={() => { this.toggleSearch(false); }}
                        onSubmit={(selection) => { this.handleSubmit(selection); }}
                        searchFunction = {this.searchFunction}
                        mapFunction = {this.mapperFunction}
                        resultComponent = {(props) => <CategorySearchCard {...props}/>}/>

                    <Button 
                        type="button"
                        appearance="primary"
                        iconBefore="add"
                        onClick={() => { this.toggleSearch(true)}}>
                        {this.props.label ? this.props.label : this.props.single ? "Selecteaza Categorie" : "Adauga Categorii"}
                    </Button>
                    
                    {this.props.minimal ? null :
                        <Pane>
                            <Pane paddingBottom={8} marginBottom={8} borderBottom="default">
                                <Heading size={400}>Categorii Selectate</Heading>
                            </Pane>
                            <Pane>
                                {this.props.values.length === 0 ? 
                                    <Heading size={400}>Nicio categorie adaugata</Heading> : 
                                    null}
                                {this.props.error ? 
                                    <Heading size={300} color="red">{this.props.error}</Heading> : null}

                                {(this.props.values || []).map(category => <CategoryListCard 
                                    key={`category-picker-${category.id}`} 
                                    category={category} 
                                    onDelete={() => this.handleDelete(category)}/>)}
                            </Pane>
                        </Pane>
                    }
            </Pane>
        );
    }

}

export default CategoryPicker;