import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import { Heading, Pane } from 'evergreen-ui';

export default function AttributeInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Atribut"
        button={{
            link:`/attributes/${props.attribute.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.attribute.id}/>
        <InfoLine label="Identificator Text" value={props.attribute.textId}/>
        <InfoLine label="Nume" value={props.attribute.name}/>
        <InfoLine label="Descriere" value={props.attribute.description}/>
        <InfoLine label="Tip" value={props.attribute.type}/>

        <Pane padding={16} marginBottom={16} marginTop={16} background="tint2">
            <Heading size={400}>
                Valori Posibile
            </Heading>
        </Pane>

       {(props.attribute.values || []).map((value, index) => (
            <InfoLine label={`Valoare ${index}`} value={value.toString()}/>
        ))}
    </InfoBlock>
}