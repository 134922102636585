import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Carousel = ModuleConfig.Structures.Carousel;
const CRUDActions = ModuleConfig.CRUDActions;

export function getCarousels(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.carousels,
        mapStructure: Carousel,
        errorNotification: "Slide-urile de carusel nu au putut fi incarcate"
    });
}

export function getCarousel(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.carousel,
        mapStructure: Carousel,
        errorNotification: "Slide-ul nu a putut fi incarcat"
    })
}

export function createCarousel(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.carousels,
        mapStructure: Carousel,
        successNotification: "Slide-ul a fost creat",
        nextUrl: '/carousel/',
    })
}

export function updateCarousel(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.carousel,
        mapStructure: Carousel,
        successNotification: "Slide-ul a fost modificat",
        nextUrl: '/carousel/',
    })
}

export function deleteCarousel(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.carousel,
        successNotification: "Slide-ul a fost sters",
        nextUrl: '/carousel'
    });
}