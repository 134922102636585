export function ActionId(spec, action) {
    return {
        request: `act_${spec}_${action}_request`.toUpperCase(),
        success: `act_${spec}_${action}_success`.toUpperCase(),
        failure: `act_${spec}_${action}_failure`.toUpperCase(),
    }
}

export function Actions(action, spec = "ns") {
    return {
        request: (options = {}) => {
            return {
                type: ActionId(action, spec).request,
                options
            }
        },
        success: (options = {}) => {
            return {
                type: ActionId(action, spec).success,
                options
            }
        },
        failure: (options = {}) => {
            return {
                type: ActionId(action, spec).failure,
                options
            }
        }
    }
}