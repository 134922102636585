import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListStockOperations from './containers/ListStockOperations';
import CreateStockOperation from './containers/CreateStockOperation';
import EditStockOperation from './containers/EditStockOperation';
import ViewStockOperation from './containers/ViewStockOperation';

const routes = [
    {
        type: 'crud',
        list: ListStockOperations,
        create: CreateStockOperation,
        edit: EditStockOperation,
        view: ViewStockOperation
    }
]

export default ModuleBootstrapper.getRoutes("stock-operations", routes)