import React from 'react';
import { Pane, Heading, Button } from 'evergreen-ui';
import { Link } from 'react-router-dom';

export default function InfoBlock(props) {
    return (
        <Pane elevation={1} {...props}> 
            {props.label ? <Pane background="tint1" padding={8} display="flex" alignItems="center">
                <Heading size={500} margin={8}>
                    {props.label}
                </Heading>

                <Pane display="flex" flex="1" justifyContent="flex-end">

                    {props.header ? props.header : null}

                    {props.button ?
                        <Link to={props.button.link}>
                            <Button 
                                iconBefore="edit"
                                marginLeft={16} 
                                height={32}  
                                justifySelf="flex-end" 
                                appearance="primary">{props.button.label}</Button>
                        </Link> : null
                    }
                </Pane>

            </Pane> : null }
            <Pane margin={16} paddingBottom={16} display="flex" flexDirection="column">
                {props.children}                
            </Pane>
        </Pane>
    )
}