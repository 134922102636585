import React from 'react';
import {Pane, Card, Checkbox, Text } from 'evergreen-ui';

export default function CardSearchCard(props) {
    return (
        <Card onClick={() => props.onClick(props.item)} cursor="pointer">
            <Pane display="flex" alignItems="center" background="tint2" margin={16} padding={16}>
                <Checkbox checked={props.selected} marginRight={16}/>
                <img style={{height: '64px', width: 'auto'}} alt="product-thumbnail"
                            src={props.item.thumbnail ?
                                process.env.REACT_APP_CARD_IMAGE_BUCKET + '' + props.item.thumbnail : null}/>
                <Text size={400} marginLeft={10}>

                    {props.item.name} ({props.item.code || "NO CODE"} - {props.item.rarity}) [{props.item.variantName}]
                </Text>
            </Pane>
        </Card>
    )
}
