import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers
} from 'redux';

import { createBrowserHistory } from 'history';

import reducers from './reducers';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import thunkMiddleware from 'redux-thunk';

export const history = createBrowserHistory();

let middleware = [thunkMiddleware, routerMiddleware(history)];

const reducer = combineReducers({
    router: connectRouter(history),
    ...reducers
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
    return createStore(
        reducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );
}