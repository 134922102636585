import Base from './Base';
import moment from 'moment';


export default class Cart extends Base {
    constructor(props, children = []) {
        super();
        
        this.import(props, children);
    }

    import(props) {
        if(!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.closed = this.assign('closed', props);
        this.checkingOut = this.assign('checkingOut', props);
        this.products = props.products;
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;

        this.totalPrice = (Math.round(props.products.reduce((memo, item) => {
            memo += item.totalPrice * item.CartProduct.quantity;

            return memo;
        }, 0) * 100) / 100);

        this.totalPrice += (Math.round(props.cards.reduce((memo, item) => {
            memo += item.price * item.CartCard.quantity;

            return memo;
        }, 0) * 100) / 100);


        this.totalPrice += ' Lei'

        this.user = props.user ? props.user.name + ' (' + props.user.id + ')' : 
                    props.session ? 'Sesiune ' + props.session.id : 'Untracked';

        this.session = props.session;
        this.products = props.products || [];
        this.cards = props.cards  || [];
    }
}