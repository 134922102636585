import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategoryList, deleteCategoryList } from '../actions/CategoryListActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import CategoryListInfoBlock from '../components/CategoryListInfoBlock';

class ViewCategoryList extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    componentDidMount() {
        this.props.dispatch(getCategoryList(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteCategoryList(this.id));
    }

    render() {
            
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/category-lists">
                            <Button iconBefore="arrow-left">
                                Inapoi la liste categorii
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge lista de categorii"
                            title="Confirmare stergere lista de categorii"
                            confirmLabel="Sterg definitiv lista de categorii"
                            message="Esti sigur ca vrei sa stergi lista de categorii?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Lista de categorii - <strong>{this.props.categoryList.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <CategoryListInfoBlock categoryList={this.props.categoryList}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        categoryList: state.categoryLists.current
    }
}

export default connect(mapStateToProps)(ViewCategoryList);