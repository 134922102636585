import { Button, Heading, Link, Pane, Table, TableBody, TableHead, Text, TextTableCell, TextTableHeaderCell } from 'evergreen-ui';
import moment from 'moment';
import React, { useState } from 'react';
import Request from '../../../util/Api';
import Config from '../Config';


import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoForm from '../../common/InfoBlock/InfoForm';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function CardExpnsionInfoBlock(props) {
    const [loading, setLoading] = useState(false);

    const resync = async () => {
        setLoading(true);
        await Request.post(Config.Endpoints.resyncExpansion + '?expansionId=' + props.cardExpansion.id);
        setLoading(false);
    }

    const resyncPrices = async() => {
        setLoading(true);
        await Request.post(Config.Endpoints.resyncPrices+'?expansionId=' + props.cardExpansion.id + '&gameId=' + props.cardExpansion.cardGameId);
        await props.onReload();
        setLoading(false);
    }

    const inlineUpdate = async (field, value) => {
        const fields = field.split('.');
        let updatedExpansion = { ...props.cardExpansion };

        // Get the current value from the cardExpansion object
        const currentValue = fields.reduce((acc, key) => acc && acc[key], props.cardExpansion);

        // Check if the new value is the same as the current value
        if (currentValue === value) {
            return; // Exit the function if the values are the same
        }

        fields.reduce((acc, key, index) => {
            if (index === fields.length - 1) {
                acc[key] = value;
            } else {
                acc[key] = { ...acc[key] };
            }
            return acc[key];
        }, updatedExpansion);

        await props.onSave(updatedExpansion);
    }

    const inlineCardUpdate = async (field, value, card) => {
        if (value && value.length > 0) {
            const fields = field.split('.');
            let updatedCard = { ...card };

            // Get the current value from the card object
            const currentValue = fields.reduce((acc, key) => acc && acc[key], card);

            // Check if the new value is the same as the current value
            if (currentValue === value) {
                return; // Exit the function if the values are the same
            }

            fields.reduce((acc, key, index) => {
                if (index === fields.length - 1) {
                    acc[key] = value;
                } else {
                    acc[key] = { ...acc[key] };
                }
                return acc[key];
            }, updatedCard);

            await props.onCardSave(updatedCard);
        }
    }


    return <InfoBlock
        flex="50%"
        marginRight={16}
        label="Detalii Cont"
        button={{
            link:`/card-expansions/${props.cardExpansion.id}/edit`,
            label: 'Modifica'
        }}
    >
        <InfoLine label="Id Intern" value={props.cardExpansion.id}/>
        <InfoLine label="Cod" value={props.cardExpansion.code}/>
        <InfoLine label="Nume" value={props.cardExpansion.name}/>
        <InfoLine label="Lansare" value={moment(props.cardExpansion.releaseDate).format('DD/MM/YYYY')}/>
        <Pane display="flex">
            <InfoLine label="Sincronizat" value={props.cardExpansion.cardsSynced} type="bool"/>
            {loading ? <Text>Se sincronizeaza...</Text> :
                <Button onClick={resync}>Resincronizeaza</Button>}

            <Button style={{ marginLeft: '10px' }} onClick={resyncPrices}>Sincronizeaza Preturile</Button>
        </Pane>

        <InfoLine label="Disponibil" value={props.cardExpansion.active} type="bool" />
        <InfoLine label="Disponibil Online" value={props.cardExpansion.activeOnline} type="bool" />
        <InfoLine label="Disponibil Third Party" value={props.cardExpansion.activeThirdParty} type="bool" />

        <InfoForm label="Global Pricemod (%)" value={props.cardExpansion.customData ? props.cardExpansion.customData.priceMutationGlobal : 0} onSave={(value) => inlineUpdate('customData.priceMutationGlobal', value)}/>
        <InfoForm label="Playset Pricemod (%)" value={props.cardExpansion.customData ? props.cardExpansion.customData.priceMutationPlayset : 0} onSave={(value) => inlineUpdate('customData.priceMutationPlayset', value)}/>
        <InfoForm label="Foil Pricemod (%)" value={props.cardExpansion.customData ? props.cardExpansion.customData.priceMutationFoil : 0} onSave={(value) => inlineUpdate('customData.priceMutationFoil', value)}/>

        <Heading marginTop={20} marginBottom={20}>
            Lista Carti
        </Heading>

        <Table>
            <TableHead>
                <TextTableHeaderCell>
                    Id
                </TextTableHeaderCell>
                <TextTableHeaderCell>

                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Nume
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Cod
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Raritate
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Stoc
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Pret
                </TextTableHeaderCell>
                 <TextTableHeaderCell>
                    Varianta
                </TextTableHeaderCell>
                <TextTableHeaderCell>
                    Price Modifier
                </TextTableHeaderCell>
            </TableHead>
            <TableBody>
                {(props.cardExpansion.cards || []).map(card => (
                    <Table.Row>
                        <TextTableCell>
                            {card.id}
                        </TextTableCell>
                        <TextTableCell>
                            <img alt="thumbnail" style={{ height: '48px', width: 'auto' }}
                                src={card.thumbnail ?
                                    process.env.REACT_APP_CARD_IMAGE_BUCKET + card.thumbnail : null} />
                        </TextTableCell>
                        <TextTableCell>
                            <Link to={`/cards/${card.id}`} href={`/cards/${card.id}`}>
                                {card.name}
                            </Link>
                        </TextTableCell>
                        <TextTableCell>
                            {card.code}
                        </TextTableCell>
                        <TextTableCell>
                            {card.rarity}
                        </TextTableCell>
                        <TextTableCell>
                            {card.quantity}
                        </TextTableCell>
                        <TextTableCell>
                            {card.price} Lei
                        </TextTableCell>
                        <TextTableCell>
                            {card.variantName}
                        </TextTableCell>
                        <TextTableCell>
                            <InfoForm value={card.customData ? card.customData.priceModifier : 0} onSave={(value) => inlineCardUpdate('customData.priceModifier', value, card)}/>
                        </TextTableCell>
                    </Table.Row>
                ))}
            </TableBody>
        </Table>

    </InfoBlock>
}
