import React from 'react';
import {Heading, Pane, Card, Checkbox } from 'evergreen-ui';

export default function PaymentTypeSearchCard(props) {
    return (
        <Card onClick={() => props.onClick(props.item)} cursor="pointer">
            <Pane display="flex" alignItems="center" background="tint2" margin={16} padding={16}>
                <Checkbox checked={props.selected} marginRight={16}/>
                <Heading size={400}>
                    {props.item.name}
                </Heading>
            </Pane>
        </Card>
    )
}