import ModuleConfig from '../Config';

import { toaster } from 'evergreen-ui';

import { setLoading, unsetLoading } from '../../common/Loading/Actions';

import { getUser } from '../../users/actions/UserActions';


const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Address = ModuleConfig.Structures.Address;
const CRUDActions = ModuleConfig.CRUDActions;
const ErrorHandler = ModuleConfig.ErrorHandler;
const Request = ModuleConfig.Api;

export function getAddresses(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.addresses,
        mapStructure: Address,
        errorNotification: "Adresele nu au putut fi incarcate"
    });
}

export function getAddress(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.address,
        mapStructure: Address,
        errorNotification: "Adresa nu a putut fi incarcata"
    })
}

export const createAddress = (values) => {
    return async (dispatch) => {
        dispatch(setLoading(actions.create.request.id));
        dispatch(actions.create.request.thunk());

        let crudObject = new Address(values);

        const data = crudObject.export()

        try {
            const result = await Request.post(endpoints.addresses, null, null, data);
            const address = new Address(result.data);
            await dispatch(getUser(address.userId));
            dispatch(actions.create.success.thunk(address));

            toaster.success("Adresa a fost creata");
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            dispatch(actions.create.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.create.request.id));
    }
}

export function updateAddress(id, values) {
    return async (dispatch) => {
        dispatch(setLoading(actions.edit.request.id));
        dispatch(actions.edit.request.thunk());

        let crudObject = new Address(values);

        const data = crudObject.export()

        try {
            const result = await Request.put(endpoints.address, { id }, null, data);
            const address = new Address(result.data);
            await dispatch(getUser(address.userId));
            dispatch(actions.edit.success.thunk(address));
            toaster.success("Adresa a fost modificata");
        
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);
            dispatch(actions.edit.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.edit.request.id));
    }
}


export const deleteAddress = (address) => {
    return async (dispatch) => {
        dispatch(setLoading(actions.delete.request.id));
        dispatch(actions.delete.request.thunk());

        try {
            await Request.delete(endpoints.address, {id: address.id});

            await dispatch(getUser(address.userId));
            dispatch(actions.delete.success.thunk());
            
            toaster.success("Adresa a fost stearsa");
        } catch (e) {
            dispatch(actions.delete.failure.thunk(e));
            
            toaster.danger("Adresa nu a putut fi stearsa");
        }

        dispatch(unsetLoading(actions.delete.request.id));
    }
}

export function openCreate() {
    return actions.openCreate.thunk();
}

export function closeCreate() {
    return actions.closeCreate.thunk();
}

export function openEdit(address) {
    return actions.openEdit.thunk(address);
}

export function closeEdit() {
    return actions.closeEdit.thunk();
}