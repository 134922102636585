import { Button, Dialog, FilePicker, Heading, Pane, Text, TextInputField } from "evergreen-ui";
import { useState } from "react";
import Request from "../../../../util/Api";



export const HeoImport = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ syncFile, setSyncFile ] = useState(null);
    const [ sku, setSku ] = useState("");

    const syncData = async () => {
        if (!syncFile) {
            alert("Please select a file first.");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("file", syncFile);

        try {
            const data = await Request.post(`/integrations/heo/sync`, null, null, formData, { multipart: true });

            if (!data.success) {
                throw new Error("Failed to upload file");
            }

            console.log("File uploaded successfully:");
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = async () => {
        try {
            const response = await Request.get(`/integrations/heo/find-by-sku`, null, { sku });
            if (response.success) {
                if(response.data) {

                    if(response.data.existingProduct) {
                        if(!window.confirm("SKU already exists. Do you still want to import the product?")) {
                            return;
                        }
                    }

                    props.onImport(response.data);
                    props.onClose();
                }
            } else {
                alert('Eroare la incarcare');
                throw new Error("Failed to fetch files");
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    }

    return (
        <Dialog
            shouldCloseOnOverlayClick={false}
            isShown={props.open}
            title="Importa din Heo"
            onCloseComplete={props.onClose}
            onConfirm={fetchData}>
            <Pane>
                <Pane display="flex" flexDirection="column" gap={10} elevation={1} padding={10}>
                    <Heading>Import SKU</Heading>
                    <TextInputField
                        placeholder="SKU"
                        value={sku}
                        onChange={(e) => setSku(e.target.value)}/>
                    <Button marginTop={8} onClick={fetchData}>Import {loading ? '(loading ...)' : ''}</Button>
                </Pane>
                <Pane display="flex" flexDirection="column" gap={10} elevation={1} padding={10}>
                    <Heading>Actualizare Catalog</Heading>
                    <FilePicker multiple width={250} onChange={(files) => setSyncFile(files[0])} placeholder="Importa Catalog" />
                    <Text>
                        <a href="https://www.heo.com/export/V2/en/wholesale-enEN/" target="_blank" rel="noreferrer">Link Catalog</a>
                    </Text>


                    <Button marginTop={8} onClick={syncData}>Importa Fisier {loading ? '(loading ...)' : ''}</Button>
                </Pane>
            </Pane>
        </Dialog>
    )
}
