import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pane } from 'evergreen-ui';

class CreateCard extends Component {
   

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
              

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
      
    }
}

export default connect(mapStateToProps)(CreateCard);