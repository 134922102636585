import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

import configuration from '../../../util/Configuration';
import KImageComponent from '../../common/FormComponents/KImageComponent';

import { Heading, Text, Pane } from 'evergreen-ui';

export default function CategoryInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Categorie"
        button={{
            link:`/categories/${props.category.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.category.id}/>
        <InfoLine label="Parinte" value={props.category.parentName}/>
        <InfoLine label="Titlu" value={props.category.title}/>
        <InfoLine label="Meta Titlu" value={props.category.metaTitle}/>
        <InfoLine label="Descriere" value={props.category.description}/>
        <InfoLine label="Meta Descriere" value={props.category.metaDescription}/>

        <InfoLine label="Slug" value={props.category.slug}/>
        <InfoLine label="Activa" value={props.category.active} type="bool"/>
        <InfoLine label="Pozitie" value={props.category.position}/>

        <InfoLine label="Creat" value={props.category.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.category.updatedAt} type="date"/>

        <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
            <Heading size={500}>Imagine</Heading>
        </Pane>

        <Pane display="flex">
            {props.category.image ?
                <KImageComponent isView={true} image={`${configuration.images}/category_image_default_${props.category.image}.jpg`}/> : null }
        </Pane>

        <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
            <Heading size={500}>Thumbnail</Heading>
        </Pane>

        <Pane display="flex">
            {props.category.thumbnail ? 
                <KImageComponent isView={true} 
                image={`${configuration.images}/category_thumbnail_default_${props.category.thumbnail}.jpg`}/> : null }
        </Pane>

        <Pane marginTop={16}>
            <Pane padding={16}  marginBottom={8} background="tint2">
                <Heading size={500}>Atribute</Heading>
            </Pane>
            {(props.category.attributes || []).map(attribute => (<Pane marginLeft={16} marginBottom={8} key={attribute.id}>
                <Heading size={400}>{attribute.name}</Heading>
                <Text>{attribute.description}</Text>
            </Pane>))}
        </Pane>
    </InfoBlock>
}