import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import Dashboard from './containers/Dashboard';

const routes = [
    {
        type: 'simple',
        name: 'dashboard',
        view: Dashboard,
        path: ''
    }
]

export default ModuleBootstrapper.getRoutes("dashboard", routes)