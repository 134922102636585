import {
    SET_LOADING,
    UNSET_LOADING
} from './Constants';

export function setLoading(id = null) {
    return {
        type: SET_LOADING,
        id
    }
}

export function unsetLoading(id = null) {
    return {
        type: UNSET_LOADING,
        id
    }
}