import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListOrders from './containers/ListOrders';
import CreateOrder from './containers/CreateOrder';
import EditOrder from './containers/EditOrder';
import ViewOrder from './containers/ViewOrder';

const routes = [
    {
        type: 'crud',
        list: ListOrders,
        create: CreateOrder,
        edit: EditOrder,
        view: ViewOrder
    }
]

export default ModuleBootstrapper.getRoutes("orders", routes)