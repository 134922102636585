import _ from 'lodash';

const ActionGenerator = function(spec, actions) {
    let mappedActions = {};

    _.forEach(actions, (action) => {
        switch(action.type) {
            case 'simple':
                const actionId = `act_${spec}_${action.name}_simple`.toUpperCase();
                mappedActions[action.name] = {
                    id: actionId,
                    thunk: (options = {}) => {
                        return {
                            type: actionId,
                            options 
                        }
                    }
                }
                break;
            case 'request':
                const actionIdTrunk = `act_${spec}_${action.name}_`;

                mappedActions[action.name] =  {
                    request: {
                        id: (actionIdTrunk + 'request').toUpperCase(),
                        thunk: (options) => {
                            return {
                                type: (actionIdTrunk + 'request').toUpperCase(),
                                options
                            }
                        }
                    },
                    success: {
                        id: (actionIdTrunk + 'success').toUpperCase(),
                        thunk: (options) => {
                            return {
                                type: (actionIdTrunk + 'success').toUpperCase(),
                                options
                            }
                        }
                    },
                    failure: {
                        id: (actionIdTrunk + 'failure').toUpperCase(),
                        thunk: (options) => {
                            return {
                                type: (actionIdTrunk + 'failure').toUpperCase(),
                                options
                            }
                        }
                    }
                }
                break;
            default: 
        }
    })
    
    return mappedActions
}

export default ActionGenerator;