import React, { Component } from 'react';

import { arrayMoveImmutable as arrayMove } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { Button, Pane, PlusIcon } from 'evergreen-ui';

import configuration from '../../../util/Configuration';
import KDropzoneDialog from './KDropzoneDialog';
import KImageComponent from './KImageComponent';

import { ReactPhotoEditor } from 'react-photo-editor';
import 'react-photo-editor/dist/style.css';

const SortableItem = SortableElement((props) => {
    const currentThumbnail =  props.value.id ?
                (`${configuration.images}/thumb_${props.value.image}.jpg`) :
                    props.value.data
    const currentImage = props.value.id ?
                (`${configuration.images}/original_${props.value.image}.jpg`) :
                    props.value.data

    return (
        <Pane display="inline-block" cursor="pointer">
            <KImageComponent
                onRemove={props.onRemove}
                onEdit={() => props.onEdit(currentImage)}
                image={currentThumbnail}/>
        </Pane>)
})

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Pane display="flex" flexWrap="wrap">
            {items.map((value, index) => (
                <SortableItem onRemove={() => { props.onRemove(index) }}
                    onEdit={(value) => { props.onEdit(value, index) }}
                    key={`product-image-${index}`}
                    index={index}
                    value={value}/>
            ))}
        </Pane>
    )
})

export default class KFileDrop extends Component {
    constructor (props) {
        super(props);

        this.state = {
            open: false,
            editing: null,
            editingIndex: null,
        };
    }

    readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleRemove = (index) => {
        this.updateFiles(this.props.form.values.images.filter((v, i) => i !== index));
    }

    handleEditSave = (newFile) => {
        const reader = new FileReader();
        const index = this.state.editingIndex;

        reader.onloadend = () => {
            const newFileDataUrl = reader.result;
            const updatedFiles = [...this.props.form.values.images];

            updatedFiles[index] = { data: newFileDataUrl, position: index };
            this.updateFiles(updatedFiles);
            this.setState({
                editing: null,
                editingIndex: null
            });
        };

        reader.readAsDataURL(newFile);

    }

    handleEdit = (uri, index) => {
        fetch(uri)
            .then(res => res.blob())
            .then(blob => {
                const img = new Image();
                img.src = URL.createObjectURL(blob);
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const size = Math.max(img.width, img.height) * 1.5;
                    canvas.width = size;
                    canvas.height = size;
                    const ctx = canvas.getContext('2d');
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    const xOffset = (size - img.width) / 2;
                    const yOffset = (size - img.height) / 2;
                    ctx.drawImage(img, xOffset, yOffset, img.width, img.height);
                    canvas.toBlob((squareBlob) => {
                        const file = new File([squareBlob], "edited_image.jpg", { type: squareBlob.type });
                        this.setState({
                            editing: file,
                            editingIndex: index
                        });
                    }, blob.type);
                };
            })
            .catch(error => console.error('Error creating blob from URI:', error));
    }

    handleSave = async (files) => {
        let processedFiles = await Promise.all(files.map(async file => {
            const data = await this.readFileAsync(file);

            return {
                data
            }
        }))

        processedFiles = ((this.props.form.values[this.props.field.name] || []).concat(processedFiles))
        .map((image, index) => ({
            ...image,
            position: index
        }));

        this.updateFiles(processedFiles);

        this.setState({
            open: false
        })
    }

    updateFiles= (files) => {
        this.props.form.setFieldValue(this.props.field.name, files);
    }

    handleSortEnd = ({oldIndex, newIndex}) => {
        let reordered = arrayMove((this.props.form.values[this.props.field.name] || []), oldIndex, newIndex);
        reordered = reordered.map((image, index) => {
            return {
                ...image,
                position: index
            }
        });

        this.updateFiles(reordered);
    }

    render() {
        return (
            <Pane marginBottom={16}>
                <Button type="button" appearance="primary" intent="success" onClick={this.handleOpen.bind(this)}>
                    <PlusIcon/>
                </Button>
                <Pane>
                    <SortableList axis="xy" items={this.props.form.values[this.props.field.name] || []}
                        onSortEnd={this.handleSortEnd}
                        onRemove={this.handleRemove}
                        onEdit={this.handleEdit}
                        />
                </Pane>

                <KDropzoneDialog
                    onConfirm={this.handleSave}
                    onClose={this.handleClose}
                    isOpen={this.state.open}/>


                 <ReactPhotoEditor
                    open={!!this.state.editing}
                    onClose={() => { this.setState({ editing: null, editingIndex: null }) }}
                    file={this.state.editing}
                    allowColorEditing={true}
                    allowFlip={true}
                    allowRotate={true}
                    allowZoom={true}
                    onSaveImage={this.handleEditSave}
                    downloadOnSave={false}
        // labels={translationsFr}
      />
            </Pane>
        )
    }
}
