import ModuleConfig from '../Config';
import { setLoading, unsetLoading } from '../../common/Loading/Actions';


const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Category = ModuleConfig.Structures.Category;
const CRUDActions = ModuleConfig.CRUDActions;
const Request = ModuleConfig.Api;

export function getCategories() {
    return async (dispatch) => {
        dispatch(setLoading(actions.getAll.request.id));
        dispatch(actions.getAll.request.thunk());

        try {
            const result = await Request.get(endpoints.categories);
            const data = generateHierarchy(result.data);
            const flatData = result.data.map(item => new Category(item));
            dispatch(actions.getAll.success.thunk({
                data,
                flatData
            }))
        } catch (err) {
            dispatch(actions.getAll.failure.thunk(err));
        }

        dispatch(unsetLoading(actions.getAll.request.id));
    }
}

export function getCategory(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.category,
        mapStructure: Category,
        errorNotification: "Categoria nu a putut fi incarcata"
    })
}

export function createCategory(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.categories,
        mapStructure: Category,
        successNotification: "Categoria a fost creata",
        nextUrl: '/categories/'
    })
}

export function updateCategory(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.category,
        mapStructure: Category,
        successNotification: "Categoria a fost modificata",
        nextUrl: '/categories/',
    })
}

export function deleteCategory(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.category,
        successNotification: "Categoria a fost stearsa",
        nextUrl: '/categories'
    });
}

function generateHierarchy(categories, parentId = 0) {
    return categories.map(category => {
        if(category.parentId !== parentId) {
            return null;
        }
        
        return new Category(category, generateHierarchy(categories, category.id));
    }).filter(category => {
        return category !== null
    });
}
