import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'category-lists',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        categoryLists: '/category-lists',
        categoryList: '/category-lists/[id]'
    },
    defaults: {
        createCategoryList: {
            name: "",
            slug: "",
            categories: []
        }
    },
    validations: {
        categoryList: yup.object().shape({
            name: yup.string().required("Numele este obligatoriu"),
            slug: yup.string().required("SLUG este obligatoriu"),
            products: yup.array()
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;