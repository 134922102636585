import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function PreorderInfoBlock(props) {
    if(!props.preorder.product) {
        return <></>
    }

    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Precomanda"
        button={{
            link:`/preorders/${props.preorder.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.preorder.id}/>
        <InfoLine label="Produs" value={props.preorder.product.title}/>
        <InfoLine label="Cantitate Comandata" value={props.preorder.quantity}/>
        <InfoLine label="Cantitate Disponibila" value={props.preorder.quantityAvailable}/>
        <InfoLine label="Data Lansare" value={props.preorder.availabilityDate} type="date"/>

        <InfoLine label="Creat" value={props.preorder.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.preorder.updatedAt} type="date"/>
    </InfoBlock>
}