import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import { Dialog, Pane, Text, IconButton, Heading, TrashIcon } from 'evergreen-ui';

export default class KDropzoneDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: []
        }
    }

    onDrop = files => {;
        this.setState({
            files: this.state.files.concat(files)
        })
    }

    onRemove = file => {
        this.setState({
            files: this.state.files.filter(currentFile => currentFile !== file)
        });
    }

    clearFiles = () => {
        this.setState({
            files: []
        })
    }


    render() {
        return (
            <Dialog
                isShown={this.props.isOpen}
                title="Incarca fisiere"
                footer={false}
                onConfirm={() => { this.clearFiles(); this.props.onConfirm(this.state.files)}}
                onCloseComplete={() => { this.props.onClose() }}>
                <Dropzone onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <Pane>
                            <Pane {...getRootProps()} minHeight={400}>
                                <input {...getInputProps()}/>
                                <Text>Trage fisiere aici sau da click</Text>

                                <Pane>
                                    {this.state.files.length > 0 && (
                                        <>
                                            {this.state.files.map(file => (
                                                <Pane padding={8} 
                                                    background="tint2" 
                                                    margin={8} 
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    key={`file-${file.name}`} display="flex">
                                                    <Heading size={400}>{file.name}</Heading>
                                                    <IconButton 
                                                        type="button" icon={TrashIcon} intent="danger" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.onRemove(file)
                                                    }}/>
                                                </Pane>
                                            ))}
                                        </>
                                    )}
                                </Pane>
                            </Pane>
                        </Pane>
                    )}
                </Dropzone> 
            </Dialog>
        )
    }
}