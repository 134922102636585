import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListInventoryBuffer from './containers/ListInventoryBuffer';

const routes = [
    {
        type: 'crud',
        list: ListInventoryBuffer,
    }
]

export default ModuleBootstrapper.getRoutes("inventory-buffer", routes)
