import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOffer, updateOffer } from '../actions/OfferActions';
import { getPaymentTypes } from '../../paymentTypes/actions/PaymentTypeActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import OfferForm from '../components/OfferForm';

import Configuration from '../Config';

const validation = Configuration.Validations.offer;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditOffer extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getOffer(this.id));
        this.props.dispatch(getPaymentTypes());
    }

    handleSave = (values) => {
        this.props.dispatch(updateOffer(this.props.offer.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/offers">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista oferte
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/offers/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Oferta - <strong>{this.props.offer.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <OfferForm 
                            isEdit={true}
                            initialValues={this.props.offer}
                            validationSchema={validation}
                            paymentTypes={this.props.paymentTypes}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.offers.errors.type === 'validation' ? mapValidationErrors(state.offers.errors.fields): [],
        offer: state.offers.current,
        paymentTypes: state.paymentTypes.data
    }
}

export default connect(mapStateToProps)(EditOffer);