import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'categories',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' },
        { type: 'simple', name: 'openRestructure'},
        { type: 'simple', name: 'closeRestructure'}
    ],
    endpoints: {
        categories: '/categories',
        category: '/categories/[id]'
    },
    defaults: {
        createCategory: {
            facebookCategoryId: 0,
            googleCategoryId: 0,
            active: true,
            title: '',
            description: '',
            parentId: 0,
            metaTitle: '',
            metaDescription: '',
            slug: '',
            position: 1,
            attributes: []
        }
    },
    validations: {
        category: yup.object().shape({
            active: yup.bool().required(),
            title: yup.string().required('Titlul este obligatoriu'),
            description: yup.string().required('Descrierea este obligatorie'),
            metaTitle: yup.string().required('Meta Titlul este obligatoriu'),
            metaDescription: yup.string().required('Meta Descrierea este obligatorie'),
            slug: yup.string().required('Slug-ul este obligatoriu'),
            parentId: yup.number(),
            position: yup.number('Pozitia trebuie sa fie un numar')
        })
    }
}

const Config =  {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;