import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListStockAccounts from './containers/ListStockAccounts';
import CreateStockAccount from './containers/CreateStockAccount';
import EditStockAccount from './containers/EditStockAccount';
import ViewStockAccount from './containers/ViewStockAccount';

const routes = [
    {
        type: 'crud',
        list: ListStockAccounts,
        create: CreateStockAccount,
        edit: EditStockAccount,
        view: ViewStockAccount
    }
]

export default ModuleBootstrapper.getRoutes("stock-accounts", routes)