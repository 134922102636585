import moment from 'moment';

import Base from './Base';

export default class PaymentType extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        if (typeof props === 'number') {
            props = { id: props };
        }
        if (!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
        this.description = this.assign('description', props);
        this.type = this.assign('type', props);
        this.active = this.assign('active', props, 'bool');
        this.handler = this.assign('handler', props);
        this.position = this.assign('position', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            name: this.name,
            description: this.description,
            type: this.type,
            handler: this.handler,
            position: this.position,
            active: !!this.active
        }
    }
}