import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Voucher = ModuleConfig.Structures.Voucher;
const CRUDActions = ModuleConfig.CRUDActions;

export function getVouchers(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.vouchers,
        mapStructure: Voucher,
        errorNotification: "Voucherele nu au putut fi incarcate"
    });
}

export function getVoucher(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.voucher,
        mapStructure: Voucher,
        errorNotification: "Voucherul nu a putut fi incarcata"
    })
}

export function createVoucher(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.vouchers,
        mapStructure: Voucher,
        successNotification: "Voucherul a fost creata",
        nextUrl: '/vouchers/',
    })
}

export function updateVoucher(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.voucher,
        mapStructure: Voucher,
        successNotification: "Voucherul a fost modificata",
        nextUrl: '/vouchers/',
    })
}

export function deleteVoucher(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.voucher,
        successNotification: "Voucherul a fost stearsa",
        nextUrl: '/vouchers'
    });
}