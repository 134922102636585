import React, { Component } from 'react';
import { Field, Formik, FieldArray } from 'formik';
import { TextInputField, Button, SelectField, Label, Textarea, Checkbox, Pane, Heading, Combobox } from 'evergreen-ui';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import facebookCategories from '../../../resources/facebookCategories.json';
import googleCategories from '../../../resources/googleCategories.json';

import { slugify } from '../../../util/Misc';
import KSingleFileDrop from '../../common/FormComponents/KSingleFileDrop';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import CategoryPicker from './CategoryPicker';

const SortableItem = SortableElement((props) => 
    <Pane display="inline-block" cursor="pointer">
         <Pane marginBottom={16} paddingBottom={8} borderBottom="default" key={props.position} display="flex" alignItems="center">
             <Pane width="200px" marginRight="10">
                <SelectField
                    label={`Pozitie: ${props.position}`}
                    name={`attributes.${props.position}.id`}
                    value={props.value.id}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                >
                    <option value="">N/A</option>
                    {props.attributes.map(attribute => (
                        <option key={attribute.id} value={attribute.id}>{attribute.name}</option>
                    ))}                                
                </SelectField>
             </Pane>
            <Checkbox 
                marginRight={16}
                name={`attributes.${props.position}.CategoryAttribute.active`} 
                label="Activ"
                checked={!!props.value.CategoryAttribute.active}
                onChange={props.handleChange}/>       
            <Checkbox 
                marginRight={16}
                name={`attributes.${props.position}.CategoryAttribute.filter`} 
                label="Filtru"
                checked={!!props.value.CategoryAttribute.filter}
                onChange={props.handleChange}/>       
            <Button marginRight={16} type="button"
                onClick={props.onRemove}
            > Sterge </Button>
        </Pane>
    </Pane>
)

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Pane display="flex" flexDirection="column" flexWrap="wrap">
            {items.map((value, index) => (
                <SortableItem onRemove={() => { props.onRemove(index) }} 
                    key={`category-attribute-${index}`} 
                    position={index} 
                    index={index}
                    value={value}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    attributes={props.attributes}/>
            ))}
        </Pane>
    )
})


export default class CategoryForm extends Component {
    generateMetas = (values, setFieldValue) => {
        setFieldValue("metaTitle", values.title);
        setFieldValue("metaDescription", values.description);
        setFieldValue("slug", slugify(values.title));
    }

    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values, setFieldValue}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    const handleSortEnd = ({oldIndex, newIndex}) => {
                        let reordered = arrayMove((values.attributes || []), oldIndex, newIndex);
                        reordered = reordered.map((category, index) => {
                            return {
                                ...category,
                                CategoryAttribute: {
                                    ...category.CategoryAttribute,
                                    position: index
                                }
                            }
                        });
              
                        setFieldValue('attributes', reordered);
                    }

                    return <form>
                        <Pane display="flex">
                            <Pane flex="50%" marginRight={16}>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Date Categorie</Heading>
                                </Pane>
                                <SelectField
                                    label="Parinte"
                                    name="parentId"
                                    description="Daca e categorie principala, selecteaza 'Niciunul'"
                                    value={values.parentId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <option value="">Niciunul</option>
                                    {this.props.categories.map(category => (
                                        <option key={category.id} value={category.id}>{category.label}</option>
                                    ))}                                
                                </SelectField>
                                {!this.props.isEdit || values.id ? <>
                                    <Heading>Categorie Facebook</Heading>
                                    <Combobox
                                        marginBottom={10}
                                        width="100%"
                                        initialSelectedItem={facebookCategories.find(cat => {
                                            return cat.id === values.facebookCategoryId
                                        })}
                                        items={facebookCategories}
                                        onChange={(value) => {
                                            setFieldValue('facebookCategoryId', value.id)
                                        }}

                                        placeholder="Categorie Facebook"
                                        itemToString={item => item && item.name ? item.name : ""}
                                    /> </> : null}

                                {!this.props.isEdit || values.id ? <>
                                    <Heading>Categorie Google</Heading>
                                    <Combobox
                                        marginBottom={10}
                                        width="100%"
                                        initialSelectedItem={googleCategories.find(cat => {
                                            return cat.id === values.googleCategoryId
                                        })}
                                        items={googleCategories}
                                        onChange={(value) => {
                                            setFieldValue('googleCategoryId', value.id)
                                        }}

                                        placeholder="Categorie Facebook"
                                        itemToString={item => item && item.name ? item.name : ""}
                                    /> </> : null}

                                <TextInputField
                                        required
                                        type="title"
                                        label="Titlu"
                                        name="title"
                                        description="Numele categoriei"
                                        value={values.title || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.title}/>
                                        
                                <Label
                                    htmlFor="description"
                                    marginBottom={4}
                                    display="block"
                                >
                                    Descriere
                                </Label>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Descrierea vizibila in site a categoriei"
                                />
                            
                                <Checkbox 
                                    name="active" 
                                    label="Activ"
                                    checked={values.active || false}
                                    onChange={handleChange}/>
                            </Pane>
                            <Pane flex="50%">
                                <Pane display="flex" alignItems="center" borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Date SEO</Heading>
                                    <Button type="button" appearance="minimal" height={20} marginLeft={16}
                                        onClick={() => this.generateMetas(values, setFieldValue)}>
                                        Genereaza automat (nerecomandat)
                                    </Button>
                                </Pane>
                                <TextInputField
                                        required
                                        label="Meta Titlu"
                                        name="metaTitle"
                                        description="Titlul care apare pe Google"
                                        value={values.metaTitle || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.metaTitle}/>
                                        
                                <Label
                                    htmlFor="metaDescription"
                                    marginBottom={4}
                                    display="block"
                                >
                                    Meta Descriere
                                </Label>
                                <Textarea
                                    isInvalid={errors.metaDescription}
                                    id="metaDescription"
                                    name="metaDescription"
                                    value={values.metaDescription || ""}

                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Descrierea vizibila pe google"
                                />

                                <TextInputField
                                        required
                                        label="SLUG"
                                        name="slug"
                                        description="URL-ul la care va fi accesibila categoria"
                                        value={values.slug || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.slug}/>
                                
                                <TextInputField
                                        required
                                        label="Pozitie"
                                        name="position"
                                        description="Pozitia categoriei in ierarhie"
                                        value={values.position || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.position}/>
                            </Pane>
                        </Pane>

                        <Pane>
                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Imagine</Heading>
                            </Pane>

                            <Field component={KSingleFileDrop} single={true} name="image"/>

                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Imagine Thumbnail</Heading>
                            </Pane>

                            <Field component={KSingleFileDrop} single={true} name="thumbnail"/>
                        </Pane>

                        <Pane marginBottom={16}>
                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                <Heading size={500}>Atribute Categorie (hold 200ms to drag&amp;drop)</Heading>
                            </Pane>

                            <FieldArray name="attributes" render={arrayHelpers => (
                                <div >
                                    
                                    <SortableList axis="xy" items={values.attributes || []}
                                        pressDelay={200}
                                        onSortEnd={handleSortEnd}
                                        onRemove={arrayHelpers.remove}
                                        attributes={this.props.attributes}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                        
                                    <Pane display="flex">                                    
                                        <Button type="button" onClick={() => arrayHelpers.push({
                                            CategoryAttribute: {}
                                        })} marginRight="10px">
                                            Adauga Atribut
                                        </Button>

                                        <CategoryPicker minimal={true} single={false}
                                            label="Importa din alta categorie"
                                            onSubmit={(categories) => {
                                                let maxPos = 0;
                                                values.attributes.forEach(attr => {
                                                    if(attr.CategoryAttribute.position > maxPos) {
                                                        maxPos = attr.CategoryAttribute.position;
                                                    }
                                                })

                                                let attrsToPush = [];
                                                
                                                categories.forEach(category => {
                                                    category.attributes.forEach(attribute => {
                                                        if(!values.attributes.find(existing => existing.id === attribute.id)) {
                                                            maxPos = maxPos + 1;
                                                            attrsToPush.push({
                                                                ...attribute,
                                                                CategoryAttribute: {
                                                                    ...attribute.CategoryAttribute,
                                                                    position: maxPos
                                                                }
                                                            });
                                                        }
                                                    })
                                                })

                                                setFieldValue("attributes", [...values.attributes, ...attrsToPush]);
                                            }}/>
                                    </Pane>
                                </div>)}/>
                        </Pane>

                        <Button type="submit" onClick={handleSubmit}>
                            Salveaza
                        </Button>

                    </form>
                }}
            </Formik>
        );
    }
}