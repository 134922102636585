import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUser, deleteUser } from '../actions/UserActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import UserInfoBlock from '../components/UserInfoBlock';
import ListAddresses from '../../address/containers/Addresses';

class ViewUser extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getUser(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteUser(this.props.user.id));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/users">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista utilizatori
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge utilizatorul"
                            title="Confirmare stergere utilizator"
                            confirmLabel="Sterg definitiv utilizatorul"
                            message="Esti sigur ca vrei sa stergi utilizatorul?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Utilizator - <strong>{this.props.user.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <UserInfoBlock user={this.props.user}/>
                    <ListAddresses addresses={this.props.user.addresses}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.users.current
    }
}

export default connect(mapStateToProps)(ViewUser);