import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { TextInputField, Button, Pane, Heading } from 'evergreen-ui';
import CategoryPicker from '../../categories/components/CategoryPicker';
import CategoryListCard from '../../categories/components/CategoryListCard';

import { arrayMoveImmutable as arrayMove } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement((props) => 
    <Pane display="inline-block" cursor="pointer">
        <CategoryListCard category={props.value} onDelete={props.onRemove}/>
    </Pane>
)

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Pane display="flex" flexDirection="column" flexWrap="wrap">
            {items.map((value, index) => (
                <SortableItem onRemove={() => { props.onRemove(index) }} 
                    key={`category-image-${index}`} 
                    index={index} 
                    value={value}/>
            ))}
        </Pane>
    )
})

export default class CategoryListForm extends Component {

    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, setFieldValue, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    const handleSortEnd = ({oldIndex, newIndex}) => {
                        let reordered = arrayMove((values.categories || []), oldIndex, newIndex);
                        reordered = reordered.map((category, index) => {
                            return {
                                ...category,
                                position: index
                            }
                        });
                
                        setFieldValue('categories', reordered);
                    }
                

                    return <form>
                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele listei de categorii"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>
                        <TextInputField
                            required
                            label="SLUG"
                            name="slug"
                            description="SLUG lista de categorii"
                            value={values.slug || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.slug}/>

                        <Pane marginBottom={16}>
                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                <Heading size={500}>Produse in Lista</Heading>
                            </Pane>
                            <FieldArray name="categories" render={arrayHelpers => (
                                <>
                                    <CategoryPicker minimal={true} single={false}
                                        onSubmit={(categories) => categories.forEach(category => arrayHelpers.push(category))}/>
                                
                                <SortableList axis="xy" items={values.categories || []}
                                    onSortEnd={handleSortEnd}
                                    onRemove={arrayHelpers.remove}
                                    />
                                </>
                            )} />
                        </Pane>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}