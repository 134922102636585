import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function VoucherInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Voucher"
        button={{
            link:`/vouchers/${props.voucher.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.voucher.id}/>
        <InfoLine label="Tip" value={props.voucher.type}/>

        <InfoLine label="Nume" value={props.voucher.name}/>
        <InfoLine label="Cod" value={props.voucher.code}/>
        <InfoLine label="Valoare" value={props.voucher.value}/>
        <InfoLine label="Procentual" value={props.voucher.isPercentage} type="bool"/>
        <InfoLine label="Folosit" value={props.voucher.used} type="bool"/>
        <InfoLine label="Valoare" value={props.voucher.value}/>
        <InfoLine label="Nelimitat" value={props.voucher.unlimited} type="bool"/>

        <InfoLine label="Comentariu" value={props.voucher.comment}/>
        <InfoLine label="De La" value={props.voucher.validFrom} type="date"/>
        <InfoLine label="Pana La" value={props.voucher.validTo} type="date"/>


        <InfoLine label="Valoare Minima Comanda" value={props.voucher.minValue || 0}/>
        <InfoLine label="Produs" value={props.voucher.product ? props.voucher.product.title : 'N/A'}/>
        <InfoLine label="Categorie" value={props.voucher.category ? props.voucher.category.title : 'N/A'}/>

        <InfoLine label="Creat" value={props.voucher.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.voucher.updatedAt} type="date"/>
    </InfoBlock>
}