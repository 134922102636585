import React from 'react';
import InfoLine from '../../common/InfoBlock/InfoLine';

const CarouselShowcaseInfo = (props) => {
    return (
        <>
            <InfoLine label="Titlu" value={props.carousel.properties.title}/>
            <InfoLine label="Are subtitlu?" value={props.carousel.properties.hasSubtitle} type="bool"/>
            <InfoLine label="Subtitlu" value={props.carousel.properties.subtitle}/>
            <InfoLine label="Are text?" value={props.carousel.properties.hasText} type="bool"/>
            <InfoLine label="Text" value={props.carousel.properties.text}/>
            
            <InfoLine label="Are buton primar?" value={props.carousel.properties.hasPrimaryButton} type="bool"/>
            <InfoLine label="Text buton primar" value={props.carousel.properties.primaryButtonText}/>
            <InfoLine label="Link buton primar" value={props.carousel.properties.primaryButtonHref}/>
            <InfoLine label="Ruta buton primar" value={props.carousel.properties.primaryButtonAs}/>

            <InfoLine label="Are buton secundar?" value={props.carousel.properties.hasSecondaryButton} type="bool"/>
            <InfoLine label="Text buton secundar" value={props.carousel.properties.secondaryButtonText}/>
            <InfoLine label="Link buton secundar" value={props.carousel.properties.secondaryButtonHref}/>
            <InfoLine label="Ruta buton secundar" value={props.carousel.properties.secondaryButtonAs}/>
        </>
    )
}

export default CarouselShowcaseInfo;