import React from 'react';
import { useState } from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

import { Pane, Heading, Table, Button, Icon, Text, Tab, TruckIcon } from 'evergreen-ui';
import OrderControl from './OrderControl';
import HistoryModal from './HistoryModal';
import CombineOrderModal from './CombineOrderModal';
import moment from 'moment';

export default function OrderInfoBlock(props) {
    const [selectedTab, setSelectedTab] = useState('info');
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [showCombineOrderModal, setShowCombineOrderModal] = useState(false);

    return <Pane width="100%">
         <HistoryModal
            order={props.order}
            open={showHistoryModal}
            onClose={() => setShowHistoryModal(false)}/>

        <CombineOrderModal
            order={props.order}
            open={showCombineOrderModal}
            onClose={() => setShowCombineOrderModal(false)}/>

        <Pane marginBottom={20}>
            <Tab isSelected={selectedTab === 'info'} onSelect={() => setSelectedTab('info')}>
                Info
            </Tab>
            <Tab isSelected={selectedTab === 'products'} onSelect={() => setSelectedTab('products')}>
                Produse ({props.order.items ? props.order.items.length : '-'})
            </Tab>
            <Tab isSelected={selectedTab === 'return'} onSelect={() => setSelectedTab('return')}>
                Detalii Retur
            </Tab>


            <Button type="button" onClick={() => setShowHistoryModal(true)}>
                Istoric Livrare Comanda
            </Button>

            <Button type='button' onClick={() => setShowCombineOrderModal(true) } marginLeft={10}>
                Combina cu alta comanda
            </Button>



        </Pane>
        <Pane display="flex">
        {
            selectedTab === 'return' ?
            <InfoBlock
                flex="50%"
                marginRight={16}
                label={'Retururi'}>

                {props.order.refundData ? <>
                    {props.order.refundData.map((refund, index) => (<>
                        <Heading size={200}>Retur {index+1}</Heading>
                        <InfoLine label="Plata Retur" value={refund.refundType === 'cash' ? 'Numerar' : 'Transfer Bancar'}/>
                        {refund.refundType !== 'cash' ? <>
                            <InfoLine label="Cont Retur" value={refund.refundAccount}/>
                            <InfoLine label="Nume Retur" value={refund.refundName}/> </>
                        : null}
                        <InfoLine label="Valoare Retur" value={`${refund.refundValue} RON`}/>
                        <InfoLine label="Platit" value={refund.paid ? 'Da' : 'Nu'}/>

                        </>
                    ))}

                </> : null}
            </InfoBlock> : null}
        {selectedTab === 'info' ?
            <InfoBlock
                flex="50%"
                marginRight={16}
                label={`Detalii comanda`}
                button={{
                    link: `/orders/${props.order.id}/edit`,
                    label: 'Modifica'
                }}
            >
                <Pane>
                    <InfoLine label="Comentariu" value={props.order.comment} />
                    <InfoLine label="Tip" value={props.order.orderTypeText} />
                    <InfoLine label="Livrare" value={props.order.deliveryText} />
                    {props.order.delivery === 'delivery' ?
                        <InfoLine label="AWB" value={props.order.deliveryReference || "N/A"}/> : null}

                    <InfoLine label="Status" value={props.order.statusText}/>
                    <InfoLine label="Status Plata" value={props.order.paymentStatusText}/>
                    {props.order.delivery === "delivery" && props.order.status === "delivered" ?
                        <InfoLine label="Status Livrare" value={props.order.deliveryStatusText}/> : null}


                    {/* <InfoLine label="Cost Transport" value={`${props.order.shippingCost} RON (Real: ${props.order.realShippingCost} RON)`}/> */}
                    <InfoLine label="Total Comanda" value={`${props.order.grandTotal} RON`}/>
                    <InfoLine label="Plasata la" value={`${moment(props.order.createdAt).format('DD/MM/YYYY HH:mm:ss')}`}/>

                    {props.order.email || props.order.billingPhoneNumber || props.order.deliveryPhoneNumber ? <>
                        <Heading size={600} marginBottom={20} marginTop={20} borderBottom="1px solid #aaaaaa">Contact</Heading>
                        {props.order.email ?
                            <InfoLine label="Email" value={props.order.email} />
                        :null}

                        {props.order.billingPhoneNumber ?
                            <InfoLine label="Nr. Telefon Comanda" value={props.order.deliveryPhoneNumber} />
                        :null}

                        {props.order.deliveryPhoneNumber ?
                            <InfoLine label="Nr. Telefon Livrare" value={props.order.deliveryPhoneNumber} />
                        :null}
                    </> : null}
                </Pane>

                {props.order.orderType !== 'glovo' ? <>
                        <Pane marginRight={10} marginTop={20}>
                            <Pane flexGrow="0.5" marginRight={10}>
                                <Heading size={500} marginTop={10} borderBottom="1px solid #aaaaaa">FACTURARE</Heading>
                                <InfoLine label="Nume" value={props.order.billingName} />
                                <InfoLine label="Adresa" value={props.order.billingLocation ? props.order.billingLocation.publicName : 'Format Vechi'}/>
                                <InfoLine label="Detalii Adresa" value={props.order.billingAddress} />
                                <InfoLine label="Cod Postal" value={props.order.billingLocation ? props.order.billingLocation.postcode : 'Format Vechi'}/>
                                <InfoLine label="Tel." value={props.order.billingPhoneNumber} />
                                <InfoLine label="Judet" value={props.order.billingLocation ? props.order.billingLocation.countyName : (props.order.billingCounty || { name: 'N/A' }).name} />
                                <InfoLine label="Oras" value={props.order.billingLocation ? props.order.billingLocation.cityName : (props.order.billingCity || { name: 'N/A' }).name} />
                            </Pane>

                            <Pane flexGrow="0.5">
                                <Heading size={500} marginTop={30} borderBottom="1px solid #aaaaaa">LIVRARE</Heading>
                                <InfoLine label="Client" value={props.order.entityTypeText} />
                                <InfoLine label="Nume" value={props.order.deliveryName} />
                                <InfoLine label="Adresa" value={props.order.deliveryLocation ? props.order.deliveryLocation.publicName : 'Format Vechi'}/>
                                <InfoLine label="Detalii Adresa" value={props.order.deliveryAddress} />
                                <InfoLine label="Cod Postal" value={props.order.deliveryLocation ? props.order.deliveryLocation.postcode : 'Format Vechi'}/>
                                <InfoLine label="Judet" value={props.order.deliveryLocation ? props.order.deliveryLocation.countyName : (props.order.deliveryCounty || { name: 'N/A' }).name} />
                                <InfoLine label="Oras" value={props.order.deliveryLocation ? props.order.deliveryLocation.cityName : (props.order.deliveryCity || { name: 'N/A' }).name} />
                                <InfoLine label="Tel." value={props.order.deliveryPhoneNumber} />



                                {props.order.entityType === "pj" ? <>
                                    <InfoLine label="Nume Companie" value={props.order.companyName} />
                                    <InfoLine label="CUI/CIF" value={props.order.vatNumber} />
                                    <InfoLine label="NORC" value={props.order.registryNumber} />
                                </> : null}
                            </Pane>
                        </Pane>
                    </> : null}
            </InfoBlock> : null}

        {selectedTab === 'products' ?
            <InfoBlock
                flex="50%"
                marginRight={16}
                label={`Produse`}
            >
                <Pane display="flex" flexDirection="column">
                    <Pane marginRight={10} flexGrow="1">
                        <Pane>
                            <Table>
                                <Table.Head>
                                    <Table.TextHeaderCell>
                                        Pret Unitar
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell>
                                        Cantitate
                                    </Table.TextHeaderCell>

                                    <Table.TextHeaderCell>
                                        Pret Total
                                    </Table.TextHeaderCell>

                                    <Table.TextHeaderCell>
                                        Total Linie
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>

                                    {props.order.items ? props.order.items.map(product => (<>
                                        <Table.Row key={`pre-${product.id}`} height={100}>
                                            <Table.TextCell  flexBasis={100} flexGrow={0} flexShrink={0}>
                                                <img style={{height: '64px', width: 'auto'}}
                                                    alt="Product Thumbnail"
                                                    src={
                                                        product.type === 'product' && product.thumbnail ?
                                                                process.env.REACT_APP_IMAGE_STORAGE + '/thumb_' + product.thumbnail + '.webp'
                                                            :  product.type === 'card' && product.thumbnail ?
                                                                process.env.REACT_APP_CARD_IMAGE_BUCKET + product.thumbnail
                                                            : null
                                                        }/>
                                            </Table.TextCell>
                                            <Table.TextCell colspan="4">
                                                {product.preorder ? '(PRECOMANDA)' : ''}
                                                {product.sku || product.code} &nbsp;&nbsp;
                                                {product.title || (`${product.name} ${product.variantName}`)} &nbsp;&nbsp;
                                                <a target="_blank" rel="noopener noreferrer" href={`/products/${product.id}`}>(Link)</a>
                                                {product.type === 'card' ?
                                                    <>
                                                        <div><strong>{moment(product.releaseDate).format('MM-YYYY')}</strong> / <strong>{product.rarity}</strong></div>
                                                    </>: ''}
                                            </Table.TextCell>
                                        </Table.Row>
                                        <Table.Row key={product.id}>
                                            <Table.TextCell>
                                                {product.price} Lei
                                            </Table.TextCell>
                                            <Table.TextCell>
                                                <Text fontSize="16px" fontWeight="bold" backgroundColor={product.quantity > 1 ? "red" : "green"} padding="5px" borderRadius="3px" color="white">
                                                    +{product.quantity}
                                                </Text>
                                            </Table.TextCell>

                                            <Table.TextCell>
                                                {product.quantity * product.price} Lei
                                            </Table.TextCell>

                                            <Table.TextCell>
                                                {(product.price) * product.quantity} Lei
                                            </Table.TextCell>
                                        </Table.Row>
                                        {product.quantityReturned > 0 ?
                                        <Table.Row key={product.id}>
                                            <Table.TextCell>
                                                {product.price} Lei
                                            </Table.TextCell>
                                            <Table.TextCell>
                                                {product.quantityReturned * -1}
                                            </Table.TextCell>

                                            <Table.TextCell>
                                                {product.quantityReturned * product.price * -1} Lei
                                            </Table.TextCell>
                                            <Table.TextCell>
                                                {(product.vatRate / 100) * (process.env.REACT_APP_FEATURE_VAT === 'false' ? 0 : 1) * product.price * product.quantityReturned * -1} Lei
                                            </Table.TextCell>
                                            <Table.TextCell>
                                                {((product.vatRate * (process.env.REACT_APP_FEATURE_VAT === 'false' ? 0 : 1) * product.price / 100) + product.price) * product.quantityReturned * -1} Lei
                                            </Table.TextCell>
                                        </Table.Row> : null
                                    }
                                    </>)) : null}

                                    {props.order.vouchers ? props.order.vouchers.map(voucher => (<>
                                        <Table.Row key={`voucher-pre-${voucher.id}`}>
                                            <Table.TextCell style={{color: "red"}} flexBasis={100} flexGrow={1} flexShrink={0} colspan="5">
                                            REDUCERE: {voucher.name} <br/>
                                            Cod: {voucher.code}
                                            </Table.TextCell>
                                            <Table.TextCell  style={{color: "red"}}>
                                                {-1 * voucher.value} Lei
                                            </Table.TextCell>
                                        </Table.Row>
                                    </>)) : null}
                                    {props.order.shippingCost > 0 ?
                                        <Table.Row>
                                            <Table.TextCell colspan="4" flexGrow={1}>
                                                <Icon icon={TruckIcon} marginRight={5}/>
                                                Transport
                                            </Table.TextCell>
                                            <Table.TextCell>
                                                {props.order.shippingCost} Lei
                                            </Table.TextCell>
                                        </Table.Row> : null}
                                    <Table.Row borderTop="1px solid black">
                                        <Table.TextCell>
                                            Subtotal
                                        </Table.TextCell>
                                        <Table.TextCell>
                                            {props.order.total} Lei
                                        </Table.TextCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Reducere
                                        </Table.TextCell>
                                        <Table.TextCell>
                                            {-1 * (props.order.voucherValue) || 0} Lei
                                        </Table.TextCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                        <Text fontWeight="bold">TOTAL</Text>
                                        </Table.TextCell>
                                        <Table.TextCell>
                                            <Text fontWeight="bold">{props.order.grandTotal} Lei</Text>
                                        </Table.TextCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Pane>
                    </Pane>
                </Pane>
            </InfoBlock> : null}
            <InfoBlock
                flex="30%"
                marginRight={16}
                label={`Control Comanda`}
            >
                <Pane flexGrow="1">
                    <OrderControl order={props.order}
                        updateOrderStatus={props.updateOrderStatus}
                        confirmOrderPayment={props.confirmOrderPayment}/>
                </Pane>
            </InfoBlock>
        </Pane>
    </Pane>
}
