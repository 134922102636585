import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategories, createCategory } from '../actions/CategoryActions';
import { getAttributes } from '../../attributes/actions/AttributeActions';
import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import CategoryForm from '../components/CategoryForm';

import Configuration from '../Config';

const validation = Configuration.Validations.category;
const defaultValues = Configuration.Defaults.createCategory;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreateCategory extends Component {
    componentDidMount() {
        this.props.dispatch(getCategories());
        this.props.dispatch(getAttributes());
    }

    handleSave = (values) => {
        this.props.dispatch(createCategory(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/categories">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista categorii
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Categorie noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <CategoryForm 
                            isEdit={false}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            categories={this.props.categories}
                            attributes={this.props.attributes}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.categories.errors.type === 'validation' ? mapValidationErrors(state.users.errors.fields): [],
        categories: state.categories.flatData,
        attributes: state.attributes.data
    }
}

export default connect(mapStateToProps)(CreateCategory);