import React from 'react';
import DataTable from '../../common/DataTable/DataTable';

const CategoryTable = (props) => (
    <DataTable
        defaultFilter='id'
        defaultDirection='desc'
        columns={props.minimal ? [
            {id: 'title', label: 'Titlu'}
        ] : [
            {id: 'id', label: 'Id'},
            {id: 'title', label: 'Titlu', decorator: category => category.path ? category.path.split(';').join(" > ") : category.title},
            {id: 'description', label: 'Descriere'},
            {id: 'createdAt', label: 'Creata', decorator: 'date'},
            {id: 'updatedAt', label: 'Modificat', decorator: 'date'}
        ]}

        search={props.search}
        filters={props.filters}

        data={props.data}
        updateKey={props.data}
        page={props.page}
        pageSize={props.pageSize}
        pageCount={props.pageCount}

        onTableChange={props.onTableChange}

        dynamic={false}
        height={props.height}

        itemMenu={[
            { getLink: (item) => `/categories/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
            { getLink: (item) => `/categories/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
        ]}
    />
)

export default CategoryTable;