import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createOrder } from '../actions/OrderActions';
import { getPaymentTypes } from '../../paymentTypes/actions/PaymentTypeActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import OrderForm from '../components/OrderForm';

import Configuration from '../Config';

const validation = Configuration.Validations.order;
const defaultValues = Configuration.Defaults.createOrder;
const defaultValuesGlovo = Configuration.Defaults.createGlovoOrder;

const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreateOrder extends Component {
    componentDidMount() {
        this.props.dispatch(getPaymentTypes());
    }

    handleSave = (values) => {
        this.props.dispatch(createOrder(values));
    }

    render() {
        const type = window.location.hash.substr(1);

        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/orders">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista comenzi
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Comanda noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <OrderForm 
                            isEdit={false}
                            initialValues={type === "glovo" ? defaultValuesGlovo : defaultValues}
                            validationSchema={validation}
                            paymentTypes={this.props.paymentTypes}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.orders.errors.type === 'validation' ? mapValidationErrors(state.orders.errors.fields): [],
        paymentTypes: state.paymentTypes.data
    }
}

export default connect(mapStateToProps)(CreateOrder);