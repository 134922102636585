import { Button, Pane, TextInputField } from 'evergreen-ui';
import React from 'react';

import ProductAutocomplete from './ProductAutocomplete';

const ProductRecipe = (props) => {
    return (
        <>
            <ProductAutocomplete
                key={props.index}
                width={300}
                blankOption={true}
                value={props.data}
                onChange={(val) => {
                    props.setFieldValue(`recipe.${props.index}`, {...props.data,
                        sourceProductId: val.productId,
                        label: val.label
                    })
                }}/>

            <Pane display="flex" gap={10}>
                 <TextInputField
                    inputHeight={46}
                    placeholder="Cantitate"
                    name={`recipe.${props.index}.sourceQuantity`}
                    value={props.data ? props.data.sourceQuantity : ""}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                />

                <Button height={48} intent="danger" onClick={() => props.onRemove(props.index)} type="button" marginTop={10}>Sterge</Button>
            </Pane>
        </>
    )
}


export default ProductRecipe;
