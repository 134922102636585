import React, { Component } from 'react';
import { Combobox } from 'evergreen-ui';
import Request from '../../../util/Api';

class LocationAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            options: []
        }
    }

    async handleChange(event) {
        let value = event.target.value;

        if(!value) {
            return;
        }

        let results = await Request.get('/public/location-autocomplete', null, {
            name: value,
        });


        this.setState({
            options: results.data.locations.map(result => ({id: result.id, label: result.publicName}))
        })
    }

    render() {
        let opts = this.state.options;

        if(this.props.value) {
            opts = [this.props.value, ...this.state.options.filter(option => option.id === this.props.value.id ? false: true)];
        }


        return (
            <>
                <Combobox
                    width="100%"
                    selectedItem={this.props.value}
                    items={opts}
                    onKeyUp={this.handleChange}
                    onChange={(value) => {
                        if(!value) {
                            return;
                        }

                        this.props.onChange(value);
                    }}
                    placeholder="Strada, Oras, Judet"
                    itemToString={item => item ? item.label ? item.label : item.name ? item.name : "" : ""}
                    />
            </>
        );
    }

}

export default LocationAutocomplete;
