import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createOffer } from '../actions/OfferActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import OfferForm from '../components/OfferForm';

import Configuration from '../Config';

import { getPaymentTypes } from '../../paymentTypes/actions/PaymentTypeActions'

const validation = Configuration.Validations.offer;
const defaultValues = Configuration.Defaults.createOffer;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreateOffer extends Component {
    componentDidMount() {
        this.props.dispatch(getPaymentTypes());
    }

    handleSave = (values) => {
        this.props.dispatch(createOffer(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/offers">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista oferte
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Oferta noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <OfferForm 
                            isEdit={false}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            paymentTypes={this.props.paymentTypes}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.offers.errors.type === 'validation' ? mapValidationErrors(state.offers.errors.fields): [],
        paymentTypes: state.paymentTypes.data
    }
}

export default connect(mapStateToProps)(CreateOffer);