import {
    SET_LOADING,
    UNSET_LOADING,
} from './Constants';

const initialState = {
    isLoading: 0,
    ids: []
}

export default function loading(state = initialState, action) {
    switch(action.type) {
        case SET_LOADING:
            return Object.assign({}, state, {
                isLoading: state.isLoading + 1,
                ids: state.ids.concat(action.id)
            });
        case UNSET_LOADING:
            const idIndex = state.ids.indexOf(action.id);
            
            return Object.assign({}, state, {
                isLoading: state.isLoading <= 0 ? 0 : state.isLoading - 1,
                ids: [
                    ...state.ids.slice(0, idIndex),
                    ...state.ids.slice(idIndex + 1)
                ]
            });
        default:
            return state;
    }
}