import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

import ProductListCard from '../../products/components/ProductListCard';
import { Pane, Heading } from 'evergreen-ui';

export default function ProductListInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Lista de Produse"
        button={{
            link:`/product-lists/${props.productList.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.productList.id}/>
        <InfoLine label="Nume" value={props.productList.name}/>
        <InfoLine label="SLUG" value={props.productList.slug}/>
        <InfoLine label="Pozitie" value={props.productList.position}/>
        <InfoLine label="Tip" value={props.productList.type}/>
        <InfoLine label="Target" value={props.productList.target}/>
        <InfoLine label="Active" value={props.productList.active} type="bool"/>
        <InfoLine label="Has More" value={props.productList.hasMore} type="bool"/>
        <InfoLine label="URL Vizibil" value={props.productList.urlHref}/>
        <InfoLine label="URL Intern" value={props.productList.urlTarget}/>

        <InfoLine label="Creat" value={props.productList.createdAt} type="date"/>
        <InfoLine label="Modificat" value={props.productList.updatedAt} type="date"/>
        
        <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
            <Heading size={500}>Produse</Heading>
        </Pane>

        <Pane>
                {props.productList.products ? props.productList.products.map((product, index) => (
                    <ProductListCard product={product} key={index} isView={true}/>
                )) : null }
            </Pane>
    </InfoBlock>
}