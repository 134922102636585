import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCards from './containers/ListCards';
import CreateCard from './containers/CreateCard';
import EditCard from './containers/EditCard';
import ViewCard from './containers/ViewCard';

const routes = [
    {
        type: 'crud',
        list: ListCards,
        create: CreateCard,
        edit: EditCard,
        view: ViewCard
    }
]

export default ModuleBootstrapper.getRoutes("cards", routes)