import { Pane, Text, TextInput } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';

export default function InfoForm(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleSave = () => {
        props.onSave(value);
    };

    return (
        <Pane display="flex" flexDirection="column" marginBottom={4} borderBottom="default">
            <Pane display="flex">
                {props.label ?
                    <Pane padding={8} width={150} minWidth={150}>
                        <Text><strong>{props.label}</strong></Text>
                    </Pane> : null}
                <Pane flexGrow={1} padding={8}>
                    <TextInput
                        type="text"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        onBlur={handleSave}
                        width={50}
                    />
                </Pane>
            </Pane>
        </Pane>
    );
}
