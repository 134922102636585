import Address from './Address';
import Attribute from './Attribute';
import Category from './Category';
import Order from './Order';
import Product from './Product';
import Role from './Role';
import User from './User';
import Offer from './Offer';
import PaymentType from './PaymentType';
import ProductList from './ProductList';
import Carousel from './Carousel';
import Supplier from './Supplier';
import SupplierOrder from './SupplierOrder';
import CategoryList from './CategoryList';
import ShopSale from './ShopSale';
import StockAccount from './StockAccount';
import Unit from './Unit';
import Cart from './Cart';
import Voucher from './Voucher';
import Preorder from './Preorder';
import InventoryBuffer from './InventoryBuffer';

const Structures = {
    Address,
    Attribute,
    Category,
    Order,
    Product,
    Role,
    User,
    Offer,
    PaymentType,
    ProductList,
    Carousel,
    Supplier,
    SupplierOrder,
    CategoryList,
    ShopSale,
    StockAccount,
    Unit,
    Cart,
    Voucher,
    Preorder,
    InventoryBuffer
}

export default Structures;
