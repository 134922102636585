import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'vouchers',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        vouchers: '/vouchers',
        voucher: '/vouchers/[id]'
    },
    defaults: {
        createVoucher: {
            name: "",
            code: "",
            value: 0,
            isPercentage: 0,
            validFrom: "",
            validTo: "",
            productId: null,
            unlimited: 0,
            type: "discount",
            categoryHierarchyId: null,
            minValue: 0,
            comment: ""
        }
    },
    validations: {
        voucher: yup.object().shape({
            name: yup.string().required("Numele este obligatoriu"),
        })
    }
}

const Config ={
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;