import { loginActionIds, logoutActionIds } from './actions/Constants';

const initialState = {
    authenticated: false,
    token: null,
    user: {}
}


export default function authentication(state = initialState, action) {
    switch(action.type) {
        case loginActionIds.success:
            return Object.assign({}, state, {
                authenticated: true,
                token: action.options.token,
                user: action.options.user
            });
        case logoutActionIds.success:
            return Object.assign({}, state, initialState);
        default:
            return state;
    }
}