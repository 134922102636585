import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane } from 'evergreen-ui';

export default class PaymentTypeForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele modalitatii de plata"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>
                        <TextInputField
                            required
                            label="Descriere"
                            name="description"
                            description="Descrierea modalitatii de plata"
                            value={values.description || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.description}/>
                        <TextInputField
                            required
                            label="Tip"
                            name="type"
                            description="Id-ul modalitatii de plata"
                            value={values.type || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.type}/>

                        <SelectField
                            label="Procesare"
                            name="handler"
                            description="Tipul de procesare al platii"
                            value={values.handler}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option key="direct-physical" value="direct-physical">La sediu</option>         
                            <option key="direct-delivery" value="direct-delivery">Ramburs</option>
                            <option key="bank-transfer" value="bank-transfer">Transfer Bancar</option>
                            <option key="card-gateway" value="card-gateway">Card (Procesator)</option>
                            <option key="credit-gateway" value="credit-gateway">Rate (Procesator)</option>
                            <option key="third-party" value="third-party">Intermediar</option>
                            <option key="prepaid" value="prepaid">Preplata</option>

                        </SelectField>

                        <TextInputField
                            required
                            label="Pozitie"
                            name="position"
                            description="Pozitia in care apare pe site"
                            value={values.position || 0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.position}/>

                        <Checkbox 
                            name="active" 
                            label="Activ"
                            checked={values.active || false}
                            onChange={handleChange}/>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}