import React from 'react';
import { Dialog } from 'evergreen-ui';

export default function LogoutDialog(props) {
    return (
        <Dialog
            isShown={props.show}
            title="Confirmare"
            intent="danger"
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            cancelLabel="Nu vreau"
            confirmLabel="Ma deloghez">
            Esti sigur ca vrei sa te deloghezi?
        </Dialog>
    )
}