import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane, Heading } from 'evergreen-ui';
import ProductPicker from '../../products/components/ProductPicker';
import ProductListCard from '../../products/components/ProductListCard';

import { arrayMoveImmutable as arrayMove } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement((props) =>
    <Pane display="inline-block" cursor="pointer">
        <ProductListCard product={props.value} onDelete={props.onRemove} />
    </Pane>
)

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Pane display="flex" flexDirection="column" flexWrap="wrap">
            {items.map((value, index) => (
                <SortableItem onRemove={() => { props.onRemove(index) }}
                    key={`product-image-${index}`}
                    index={index}
                    value={value} />
            ))}
        </Pane>
    )
})

export default class ProductListForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    this.props.onSave(values);
                }}
            >
                {({ errors, setFieldValue, handleChange, handleBlur, handleSubmit, values }) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    const handleSortEnd = ({ oldIndex, newIndex }) => {
                        let reordered = arrayMove((values.products || []), oldIndex, newIndex);
                        reordered = reordered.map((product, index) => {
                            return {
                                ...product,
                                position: index
                            }
                        });

                        setFieldValue('products', reordered);
                    }


                    return <form>
                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele listei de produse"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name} />
                        <TextInputField
                            required
                            label="SLUG"
                            name="slug"
                            description="SLUG lista de produse"
                            value={values.slug || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.slug} />

                        <TextInputField
                            required
                            label="Pozitie"
                            name="position"
                            description="Pozitia listei"
                            value={values.position || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.position} />

                        <SelectField
                            label="Target"
                            name="target"
                            description="Unde apare lista"
                            value={values.target}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="none">Nu are target</option>
                            <option value="homepage">Pagina Principala</option>
                        </SelectField>

                        <SelectField
                            label="Tip"
                            name="type"
                            description="Tipul listei"
                            value={values.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="grid">Grid</option>
                            <option value="spotlight">Spotlight</option>
                        </SelectField>
                            
                        <Checkbox
                            name="active"
                            label="Activa"
                            checked={values.active || false}
                            onChange={handleChange} />

                        <Checkbox
                            name="hasMore"
                            label="Are buton de Mai Mult"
                            checked={values.hasMore || false}
                            onChange={handleChange} />

                        
                        <TextInputField
                            required
                            label="URL Vizibil"
                            name="urlHref"
                            description="URL-ul vizibil"
                            value={values.urlHref}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.urlHref} />

                        <TextInputField
                            required
                            label="URL Intern"
                            name="urlTarget"
                            description="URL-ul ruta interna (always ask Teo before changing)"
                            value={values.urlTarget}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.urlTarget} />

                        <Pane marginBottom={16}>
                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                <Heading size={500}>Produse in Lista</Heading>
                            </Pane>
                            <FieldArray name="products" render={arrayHelpers => (
                                <>
                                    <ProductPicker minimal={true} single={false}
                                        onSubmit={(products) => products.forEach(product => arrayHelpers.push(product))} />

                                    <SortableList axis="xy" items={values.products || []}
                                        onSortEnd={handleSortEnd}
                                        onRemove={arrayHelpers.remove}
                                    />
                                </>
                            )} />
                        </Pane>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}