import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Button, Pane } from 'evergreen-ui';
import ProductAutocomplete from '../../products/components/ProductAutocomplete';

export default class PreorderForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, setFieldValue, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);
       
                    return <form>
                         <ProductAutocomplete             
                            width={300} 
                            blankOption={true}
                            value={{...values.product, label: (values.product || {}).title}} 
                            onChange={(val) => {
                                setFieldValue(`productId`, val.id)
                                setFieldValue('product', { ...val})
                            }}/>

                        <TextInputField
                            required
                            label="Cantitate Comandata"
                            name="quantity"
                            description="Ce cantitate a fost comandata la furnizor"
                            value={values.quantity || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.quantity}/>
                        <TextInputField
                            required
                            label="Cantitate Disponibila"
                            name="quantityAvailable"
                            description="Ce cantitate va fi disponibila la precomanda"
                            value={values.quantityAvailable || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.quantityAvailable}/>
                        <TextInputField
                            required
                            type="date"
                            label="Data Lansarii"
                            name="availabilityDate"
                            description="Cand iese produsul de la precomanda"
                            value={values.availabilityDate || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.abailabilityDate}/>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}