import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCarousel from './containers/ListCarousel';
import CreateCarousel from './containers/CreateCarousel';
import EditCarousel from './containers/EditCarousel';
import ViewCarousel from './containers/ViewCarousel';

const routes = [
    {
        type: 'crud',
        list: ListCarousel,
        create: CreateCarousel,
        edit: EditCarousel,
        view: ViewCarousel
    }
]

export default ModuleBootstrapper.getRoutes("carousel", routes)