export const normalizeAlmaArtex = (data) => {
    const sampleData = data[0];
    if(!sampleData.codart || !sampleData.denumire || !sampleData['pret raft']) {
        alert("Invalid data format");
        return;
    }

    return data.map((product) => {
        return {
            barcode: product['codart'],
            name: product['denumire'],
            retailPrice: product['pret raft'],
        };
    });
}
