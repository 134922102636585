import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Preorder = ModuleConfig.Structures.Preorder;
const CRUDActions = ModuleConfig.CRUDActions;

export function getPreorders(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.preorders,
        mapStructure: Preorder,
        errorNotification: "Precomenzile nu au putut fi incarcate"
    });
}

export function getPreorder(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.preorder,
        mapStructure: Preorder,
        errorNotification: "Precomanda nu a putut fi incarcata"
    })
}

export function createPreorder(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.preorders,
        mapStructure: Preorder,
        successNotification: "Precomanda a fost creata",
        nextUrl: '/preorders/',
    })
}

export function updatePreorder(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.preorder,
        mapStructure: Preorder,
        successNotification: "Precomanda a fost modificata",
        nextUrl: '/preorders/',
    })
}

export function deletePreorder(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.preorder,
        successNotification: "Precomanda a fost stearsa",
        nextUrl: '/preorders'
    });
}