import _ from 'lodash';
import ModuleConfig from './Config';

const Actions = ModuleConfig.Actions;

const initialState = {
    edit: false,
    create: false,
    current: {},
    errors: {},
}

export default function users(state = initialState, action) {
    switch(action.type) {
        case Actions.openCreate.id:
            return Object.assign({}, state, {
                create: true,
            })
        case Actions.openEdit.id:
            return Object.assign({}, state, {
                edit: true,
                current: action.options
            })
        case Actions.closeEdit.id:
        case Actions.closeCreate.id:
        case Actions.create.success.id:
        case Actions.edit.success.id:
        case Actions.delete.success.id:
            return Object.assign({}, state, {
                create: false,
                edit: false,
                current: {},
                errors: {}
            })
        case Actions.changeCurrent.id:
            return Object.assign({}, state, {
                edited: _.merge({}, state.edited, {
                    [action.options.field] : action.options.value
                })
            });
        case Actions.create.failure.id:
        case Actions.edit.failure.id:
            return Object.assign({}, state, {
                errors: action.options
            })
        default:
            return state;
    }
}