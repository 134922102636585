import { Button, Heading, InlineAlert, Label, Pane, Textarea, TextInputField } from "evergreen-ui";


export const CustomSEO = ({values, handleChange, handleBlur, errors, setFieldValue, generateMetas}) => {

    return (<>
        <Pane
            display="flex"
            alignItems="center"
            borderBottom="default"
            paddingBottom={8}
            marginBottom={16}
        >
        <Heading size={500}>Custom SEO</Heading>
        <Button
            type="button"
            appearance="minimal"
            height={20}
            marginLeft={16}
            onClick={() =>
                generateMetas(values, setFieldValue)
            }
        >
            Genereaza automat
        </Button>
        </Pane>
        <TextInputField
            required
            inputHeight={48}
            label="Meta Titlu"
            name="metaTitle"
            description="Titlul care apare pe Google"
            value={values.metaTitle || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.metaTitle}
            validationMessage={errors.metaTitle}
        />

        <Label
            htmlFor="metaDescription"
            marginBottom={4}
            display="block"
        >
        Meta Descriere
        </Label>
        <Textarea
            id="metaDescription"
            inputHeight={48}
            name="metaDescription"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={errors.metaDescription}
            value={values.metaDescription}
            placeholder="Descrierea vizibila pe google"
        />
        {errors.metaDescription ? (
        <InlineAlert intent="danger">
            {errors.metaDescription}
        </InlineAlert>
        ) : null}

        <TextInputField
            inputHeight={48}
            required
            label="SLUG"
            name="slug"
            description="URL-ul la care va fi accesibil produsul"
            value={values.slug || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.slug}
            validationMessage={errors.slug}
        />
    </>
    )
}
