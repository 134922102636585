import moment from 'moment';
import _ from 'lodash';
import Base from './Base';

export default class Category extends Base {
    constructor(props, children = []) {
        super();

        this.import(props, children);
    }

    import(props, children = []) {
        this.id = this.assign('id', props);
        this.facebookCategoryId = this.assign('facebookCategoryId', props, "integer", 0);
        this.googleCategoryId = this.assign('googleCategoryId', props, "integer", 0);

        this.parentId = this.assign('parentId', props, "integer", 0);
        this.active = this.assign('active', props, "boolean", false);
        this.image = this.assign('image', props);
        this.title = this.assign('title', props);
        this.name = this.title;
        this.description = this.assign('description', props);
        this.metaDescription = this.assign('metaDescription', props);
        this.metaTitle = this.assign('metaTitle', props);
        this.slug = this.assign('slug', props);
        this.thumbnail = this.assign('thumbnail', props);
        this.position = this.assign('position', props, "integer", 0);
        this.path = this.assign('path', props);

        this.children = _.isEmpty(props.children) ? (children || []) : props.children.map(child => new Category(child));
        this.attributes = (props.attributes || []).sort((a, b) => {
            return a.CategoryAttribute.position > b.CategoryAttribute.position ? 1 : -1
        });
        this.parent = props.parent ? new Category(props.parent) : null;
        this.label = props.path ? props.path.split(';').join(' >> ') : props.title;
        this.categoryHierarchyId = props.categoryHierarchyId;

        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;


        //React sortable tree properties;
        this.subtitle = this.description;
    }

    export() {
        return {
            facebookCategoryId: this.facebookCategoryId || '0',
            googleCategoryId: this.googleCategoryId || '0',
            parentId: this.parentId || '0',
            active: this.active,
            title: this.title,
            description: this.description,
            metaDescription: this.metaDescription,
            metaTitle: this.metaTitle,
            slug: this.slug,
            image: this.image,
            thumbnail: this.thumbnail,
            position: this.position,
            attributes: this.attributes
        }
    }

    get parentName() {
        return this.parent ? this.parent.title : ""
    }
}