import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategories } from '../actions/CategoryActions';
import { Link } from 'react-router-dom';
import { Button, Pane } from 'evergreen-ui';
import CategoryTable from '../components/CategoryTable';

class ListCategories extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getCategories(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to="/categories/create">
                        <Button iconBefore="add" intent="none" appearance="primary">
                            Categorie noua
                        </Button>
                    </Link>
                </Pane>
            </Pane>
            
            <CategoryTable
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}
                search={true}
                data={this.props.flatData}
                onTableChange={this.handleTableChange}/>
        </>)
    }
}

function mapStateToProps(state) {
    return state.categories;
}

export default connect(mapStateToProps)(ListCategories);