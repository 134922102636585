import moment from 'moment';

import Base from './Base';

export default class Address extends Base {
    constructor(props, children = []) {
        super();
        
        this.import(props, children);
    }

    import(props) {
        if(!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.userId = this.assign('userId', props);
        this.active = this.assign('active', props);
        this.isCompany = this.assign('isCompany', props, 'boolean');
        this.isDelivery = this.assign('isDelivery', props, 'boolean');
        this.isBilling = this.assign('isBilling', props, 'boolean');
        this.email = this.assign('email', props);
        this.addressName = this.assign('addressName', props);
        this.name = this.assign('name', props);
        this.companyName = this.assign('companyName', props);
        this.vatNumber = this.assign('vatNumber', props);
        this.registryNumber = this.assign('registryNumber', props);
        this.address = this.assign('address', props);
        this.city = this.assign('city', props);
        this.country = this.assign('country', props);
        this.state = this.assign('state', props);
        this.phoneNumber = this.assign('phoneNumber', props);
        this.other = this.assign('other', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    importFromUser(user) {
        this.import({
            userId: user.id,
            isBilling: true,
            isDelivery: true,
            isCompany: false,
            active: true,
            email: user.email,
            name: user.name,
            country: 'Romania'
        })

        return this;
    }

    export() {
        return {
            userId: this.userId,
            active: this.active,
            isCompany: this.isCompany,
            isDelivery: this.isDelivery,
            isBilling: this.isBilling,
            email: this.email,
            addressName: this.addressName,
            name: this.name,
            companyName: this.companyName,
            vatNumber: this.vatNumber,
            registryNumber: this.registryNumber,
            address: this.address,
            city: this.city,
            zipcode: this.zipcode,
            country: this.country,
            state: this.state,
            phoneNumber: this.phoneNumber,
            other: this.other || ""
        }
    }
}