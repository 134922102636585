import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOffer, deleteOffer } from '../actions/OfferActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import OfferInfoBlock from '../components/OfferInfoBlock';

class ViewOffer extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    componentDidMount() {
        this.props.dispatch(getOffer(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteOffer(this.id));
    }

    render() {
            
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/offers">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista oferte
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge oferta"
                            title="Confirmare stergere oferta"
                            confirmLabel="Sterg definitiv oferta"
                            message="Esti sigur ca vrei sa stergi oferta?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                       Oferta - <strong>{this.props.offer.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <OfferInfoBlock offer={this.props.offer}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        offer: state.offers.current
    }
}

export default connect(mapStateToProps)(ViewOffer);