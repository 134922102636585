import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListVouchers from './containers/ListVouchers';
import CreateVoucher from './containers/CreateVoucher';
import EditVoucher from './containers/EditVoucher';
import ViewVoucher from './containers/ViewVoucher';

const routes = [
    {
        type: 'crud',
        list: ListVouchers,
        create: CreateVoucher,
        edit: EditVoucher,
        view: ViewVoucher
    }
]

export default ModuleBootstrapper.getRoutes("vouchers", routes)