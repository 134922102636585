import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

const config =  {
    name: 'shop-sales',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'confirmSale' },
        { type: 'request', name: 'cancelSale' }
    ],
    endpoints: {
        shopSales: '/shop-sales',
        shopSale: '/shop-sales/[id]',
        confirmSale: '/shop-sales/[id]/confirm',
        cancelSale: '/shop-sales/[id]/cancel'
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;