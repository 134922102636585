import React, { Component } from 'react';
import { Button, Dialog, FilePicker, Pane } from 'evergreen-ui';
import Request from '../../../util/Api';
import Config from '../Config';
import Papa from 'papaparse';

class ImportModal extends Component {
    constructor (props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.parseFiles = this.parseFiles.bind(this);
        this.getData = this.getData.bind(this);
    }

    onClose = () => {
        this.props.onClose();
    }

    parseFiles = (files) => {
        Papa.parse(files[0], {
            header: true,
            complete: (result) => {
                this.getData(result.data);
            }
        });
    }

    getData = async (result) => {
        const data = await Request.post(Config.Endpoints.evaluateCards, null, null, {data: result});

        return this.props.onImport(data.data);
    }

    render () {
      return (<Dialog
        shouldCloseOnOverlayClick={false}
        width={800}
        isShown={this.props.open}
        title= {"Importa carti din csv"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                <FilePicker multiple width={250} onChange={(files) => this.parseFiles(files)} placeholder="Importa Spreadsheet" marginBottom={10}/>
            </Pane>
            
            <Button marginRight={10} type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
      </Dialog>)
    }
}

export default ImportModal;