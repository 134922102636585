import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';
import { StockOperationTypes } from './models/StockOperationTypes';
import { StockOperation } from './models/StockOperation';
import moment from 'moment';

const config =  {
    name: 'stockOperations',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        stockOperations: '/stock-operations',
        stockOperation: '/stock-operations/[id]',
        evaluateCards: '/stock-operations/evaluate-cards'
    },
    defaults: {
        createStockOperation: {
            type: '',
            label: 'STD',
            typeDetails: 'N/A',
            stockAccountId: 0,
            supplierId: 0,
            documentName: '',
            documentDate: moment(),
            currency: 'LEU',
            exchange: 1,
            products: []
        }
    },
    validations: {
        stockOperation:  yup.object().shape({
            type: yup.string().oneOf(StockOperationTypes.map(op => op.id)).required('Obligatoriu'),
            stockAccountId: yup.number().not([0], 'Obligatoriu').required('Obligatoriu'),
            supplierId: yup.number().not([0], 'Obligatoriu').required('Obligatoriu'),
            documentName: yup.string().required('Obligatoriu'),
            documentDate: yup.string().required('Obligatoriu'),
            currency: yup.string().required('Obligatoriu'),
            exchange: yup.number().not([0], 'Nu poate fi 0').required('Obligatoriu')
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
    Structures: {
        StockOperation
    },
}


export default Config;