import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getVoucher, deleteVoucher } from '../actions/VoucherActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import VoucherInfoBlock from '../components/VoucherInfoBlock';

class ViewVoucher extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    componentDidMount() {
        this.props.dispatch(getVoucher(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteVoucher(this.id));
    }

    render() {
            
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/vouchers">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista vouchere
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge voucherul"
                            title="Confirmare stergere voucher"
                            confirmLabel="Sterg definitiv voucherul"
                            message="Esti sigur ca vrei sa stergi voucherul?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Voucher - <strong>{this.props.voucher.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <VoucherInfoBlock voucher={this.props.voucher}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        voucher: state.vouchers.current
    }
}

export default connect(mapStateToProps)(ViewVoucher);