export const StockOperationStatuses = [
    {
        id: 'new',
        label: 'Noua',
        labelEx: 'Noua (inca nu este procesata)'
    },
    {
        id: 'delivered',
        label: 'Livrata',
        labelEx: 'Livrata (asteapta procesarea)'
    },
    {
        id: 'processed',
        label: 'Procesata',
        labelEx: 'Procesata (apare in stoc)'
    },
   
]