import moment from 'moment';

import Base from './Base';

export default class ProductList extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        if (!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
        this.slug = this.assign('slug', props);
        this.position = this.assign('position', props);
        this.target = this.assign('target', props);
        this.type = this.assign('type', props);
        this.active = this.assign('active', props);
        this.urlHref = this.assign('urlHref', props);
        this.urlTarget = this.assign('urlTarget', props);
        this.hasMore = this.assign('hasMore', props)
        this.products = this.assign('products', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            name: this.name,
            slug: this.slug,
            position: this.position,
            target: this.target,
            type: this.type,
            active: !!this.active ? 1 : 0,
            urlHref: this.urlHref,
            urlTarget: this.urlTarget,
            hasMore: !!this.hasMore ? 1 : 0,
            products: this.products
        }
    }
}