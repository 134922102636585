import { toaster } from 'evergreen-ui';
import Request from '../../../util/Api';

import { 
    unitListThunks, unitListActionIds,
} from './Constants';

import { setLoading, unsetLoading } from '../../common/Loading/Actions';

import Unit from '../../../structures/Unit';

export const ENDPOINT_UNITS = '/units';

export function getUnits(opts = { start: 0, limit: 0 }) {
    return async (dispatch) => {
        dispatch(setLoading(unitListThunks.request));
        dispatch(unitListThunks.request());

        try {
            const result = await Request.get(ENDPOINT_UNITS, null, {
                _start: opts.start,
                _end: opts.start + opts.limit,
                _sort: opts.sort,
                _order: opts.direction,
                q: opts.search
            });
            
            const units = result.data.map(unit => new Unit(unit));

            dispatch(unitListThunks.success({
                units, 
                count: result.count, 
                pageSize: opts.limit,
                search: opts.search || "",
                sort: opts.sort,
                direction: opts.direction
            }));
        } catch (err) {
            dispatch(unitListThunks.failure(err));
            toaster.danger("Unitatile nu au putut fi incarcate");
        }

        dispatch(unsetLoading(unitListActionIds.request));

    }
}