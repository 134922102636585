import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { TextInputField, Button, Text, Checkbox, Pane, Heading, SegmentedControl } from 'evergreen-ui';
import DatePicker from "react-datepicker";
import moment from 'moment';
import OfferRuleBlock from './OfferRuleBlock';

const toggleValues = {
    stages: [
        { label: 'Produs', value: 'product'},
        { label: 'Cos', value: 'cart'},
        { label: 'Comanda', value: 'order'}
    ],
    valueType: [
        { label: 'Procentaj', value: 'percentage'},
        { label: 'Valoare Fixa', value: 'flat'},
    ],
    duration: [
        { label: 'Interval', value: 'interval'},
        { label: 'Nelimitat', value: 'ongoing'}
    ],
    ruleRestrictions: {
        'product': ['productId', 'categoryId', 'excludeProductId', 'excludeCategoryId'],
        'cart': ['productId', 'categoryId', 'userId'],
        'order': ['productId', 'categoryId', 'paymentTypeId']
    }
}

export default class OfferForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values, setFieldValue, resetForm}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <Pane marginBottom={8}>
                            <Heading size={400}>Aplicabilitatea Reducerii</Heading>
                            <Text>Schimbarea valorii reseteaza formularul</Text>
                        </Pane>
                        <SegmentedControl
                            marginBottom={16}
                            width={300}
                            options={toggleValues.stages}
                            value={values.stage}
                            onChange={value => {
                                resetForm();
                                setFieldValue('stage', value) 
                            }}/>

                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele ofertei"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>

                        <Heading size={400} marginBottom={8}>Tipul reducerii</Heading>
                        {values.stage !== 'product' ?
                            <SegmentedControl
                                marginBottom={16}
                                width={300}
                                options={toggleValues.valueType}
                                value={values.valueType}
                                onChange={value => setFieldValue('valueType', value) }/> :
                            <Heading size={300} marginBottom={8} color="red">Procentaj (doar reducerile de cos sau comanda pot avea valoare fixa)</Heading>}

                        <TextInputField
                            required
                            label="Valoare"
                            name="value"
                            description={values.valueType === 'percentage' ? "Valoarea reducerii (%)" : "Valoarea reducerii (RON)"}
                            value={values.value || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.value}/>

                        <Heading size={400}>Reguli</Heading>
                        <FieldArray name="rules" render={arrayHelpers => (
                                <Pane>
                                    <Button type="button" onClick={() => arrayHelpers.push({
                                        field: 'productId',
                                        value: '',
                                        percentageDiscountOverride: 0
                                    })}>
                                        Adauga Regula
                                    </Button>
                                    {(values.rules || []).map((value, index) => (
                                        <OfferRuleBlock
                                            restrictions={toggleValues.ruleRestrictions[values.stage]}
                                            key={`offer-rule-${index}`}
                                            index={index}
                                            field={value.field}
                                            value={value.value}
                                            percentageDiscountOverride={value.percentageDiscountOverride}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            onDelete={() => arrayHelpers.remove(index)}
                                        />
                                    ))}
                                </Pane>
                        )}/>
                    
                        <Pane display="flex" marginBottom={8} marginTop={16}>
                            <Pane marginRight={16} marginBottom={0}>
                                <DatePicker    
                                    marginBottom={0}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    maxDate={moment(values.endDate).toDate()}
                                    selected={moment(values.startDate).toDate()}
                                    onChange={value => 
                                        setFieldValue('startDate', moment(value))}
                                    customInput={<TextInputField 
                                            label="Data inceput" 
                                            validationMessage={errors.startDate}
                                        />}    
                                    />
                            </Pane>
                            
                            <Pane>
                                <DatePicker  
                                    marginBottom={0}  
                                    dateFormat="dd/MM/yyyy"
                                    minDate={values.startDate ? moment(values.startDate).toDate() : new Date()}
                                    selected={moment(values.endDate).toDate()}
                                    onChange={value => 
                                        setFieldValue('endDate', moment(value))}
                                    customInput={<TextInputField 
                                            label="Data sfarsit"
                                            validationMessage={errors.endDate}
                                            />} 
                                        />
                            </Pane>
                        </Pane>

                        <Heading size={400} marginBottom={8}>Reducerea este pe baza de voucher?</Heading>
                        {values.stage === 'cart' ?
                            <Checkbox 
                                name="isVoucher" 
                                label="Voucher"
                                checked={values.isVoucher || false}
                                onChange={handleChange}/> :
                            <Heading size={300} color='red' marginBottom={8}>Doar reducerile de cos pot avea voucher</Heading>}

                        <Heading size={400} marginBottom={8}>Reducerea este cumulativa?</Heading>
                        <Checkbox 
                            name="cumulative" 
                            label="Cumulativa"
                            checked={values.cumulative || false}
                            onChange={handleChange}/>

                        <Heading size={400} marginBottom={8}>Reducerea este activa?</Heading>
                        <Checkbox 
                            name="active" 
                            label="Activa"
                            checked={values.active || false}
                            onChange={handleChange}/>

                        <TextInputField
                            required
                            label="Pozitie"
                            name="position"
                            description="Pozitia reducerii in stackul de reduceri"
                            value={values.position || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.position}/>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}