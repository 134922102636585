import React from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function SupplierInfoBlock(props) {
    return <InfoBlock
        flex="50%"
        marginRight={16}
        label="Detalii Furnizor"
        button={{
            link:`/suppliers/${props.supplier.id}/edit`,
            label: 'Modifica'
        }}
    >
        <InfoLine label="Id Intern" value={props.supplier.id}/>
        <InfoLine label="Nume" value={props.supplier.name}/>
        <InfoLine label="Timp Livrare" value={props.supplier.deliveryTime}/>
        <InfoLine label="Note" value={props.supplier.notes}/>
    </InfoBlock>
}
