import { Combobox } from 'evergreen-ui';
import _ from 'lodash';
import React, { Component } from 'react';
import Request from '../../../util/Api';
import Config from '../Config';

class ProductAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            options: []
        }

        this.defaultOption = {id: 0, productId: 0, label: ""};
    }

    async handleChange(event) {
        let value = event.target.value;

        if(!value) {
            return;
        }

        let results = await Request.get(Config.Endpoints.products, null, {q: value});

        let extras = [this.defaultOption];

        if(this.props.blankOption) {
            extras.push({ id: 0, productId: 0, label: "Unlinked - " + value});
        }

        this.setState({
            options: [...extras, ...results.data.map(product =>  ({
                id: product.id,
                barcode: product.barcode,
                productId: product.id,
                price: product.price,
                vatBracket: product.vatBracket,
                label: product.sku + ' - ' + product.title + ' BC:' + product.barcode,
                inStock: product.quantity,
                prices: product.prices,
                thumbnail: product.thumbnail
            }))]
        })
    }

    render() {
        // let opts = this.state.options;

        // if(this.props.value) {
        //     opts = [this.props.value, ...this.state.options.filter(option => option.id === this.props.value.id ? false: true)];
        // }

        return (
            <>
                <Combobox
                    size="large"
                    height={48}
                    width="100%"
                    initialSelectedItem={!_.isEmpty(this.props.value) ? this.props.value : this.defaultOption}
                    items={this.state.options}
                    onKeyUp={this.handleChange}
                    onChange={(value) => {
                        if(!value) {
                            return;
                        }

                        this.props.onChange(value);
                    }}
                    placeholder="Produs"
                    itemToString={item => item && item.label ? item.label : ""}
                    />
            </>
        );
    }

}

export default ProductAutocomplete;
