import React, { Component } from 'react';
import { Combobox } from 'evergreen-ui';
import Request from '../../../util/Api';
import Config from '../Config';
import _ from 'lodash';

import moment from 'moment';

class OrderAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            options: []
        }

        this.defaultOption = {id: 0, orderId: 0, label: ""};
    }

    async handleChange(event) {
        let value = event.target.value;

        if(!value) {
            return;
        }

        let results = await Request.get(Config.Endpoints.orders, null, {q: value});
        
        let extras = [this.defaultOption];

        this.setState({
            options: [...extras, ...results.data.map(order =>  ({
                ...order,
                id: order.id,
                label: order.orderNumber + ' ' + order.billingName + ' (' + moment(order.createdAt).format('DD/MM/YYYY') + ')', 
            }))]
        })
    }

    render() {
        // let opts = this.state.options;

        // if(this.props.value) {
        //     opts = [this.props.value, ...this.state.options.filter(option => option.id === this.props.value.id ? false: true)];
        // }

        return (
            <>
                <Combobox
                    width="100%"
                    initialSelectedItem={!_.isEmpty(this.props.value) ? this.props.value : this.defaultOption}
                    items={this.state.options}
                    onKeyUp={this.handleChange}
                    onChange={(value) => {
                        if(!value) {
                            return;
                        }

                        this.props.onChange(value);
                    }}
                    placeholder="Comanda"
                    itemToString={item => item && item.label ? item.label : ""}
                    />
            </>
        );
    }

}

export default OrderAutocomplete;