import Request from '../../../util/Api';
import ErrorHandler from '../../../util/ErrorHandler';
import { setLoading, unsetLoading } from '../../common/Loading/Actions';
import ModuleConfig from '../Config';

import { toaster } from 'evergreen-ui';


const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Order = ModuleConfig.Structures.Order;
const CRUDActions = ModuleConfig.CRUDActions;

export function getOrders(opts = { start: 0, limit: 0}) {

    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.orders,
        mapStructure: Order,
        errorNotification: "Comenzile nu au putut fi incarcate"
    });
}

export function getOrder(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.order,
        mapStructure: Order,
        errorNotification: "Comanda nu a putut fi incarcata"
    })
}

export function createOrder(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.orders,
        mapStructure: Order,
        successNotification: "Comanda a fost creata",
        nextUrl: '/orders/'
    })
}

export function updateOrder(id, values) {
    return CRUDActions.update(actions.edit, {
        id,
        values,
        endpoint: endpoints.order,
        mapStructure: Order,
        successNotification: "Comanda a fost modificata",
        nextUrl: '/orders/',
    })
}

export function deleteOrder(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.order,
        successNotification: "Comanda a fost stearsa",
        nextUrl: '/orders'
    });
}

export function updateOrderStatus(id, status, values) {
    return async (dispatch) => {
        dispatch(setLoading(actions.edit.request.id));
        dispatch(actions.edit.request.thunk());


        try {
            const result = await Request.put(`/orders/[id]/status-update`, { id }, null, {status, extraData: values});
            const updateData = new Order(result.data);

            toaster.success("Operatiune efectuata!")
            dispatch(actions.edit.success.thunk(updateData));
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            switch(e) {
                case 'ERR_NO_STATUS':
                    toaster.danger('Eroare: Nu a fost trimis un status')
                    break;
                case 'ERR_ORDER_NEEDS_PAYMENT':
                    toaster.danger('Eroare: Plata nu a fost confirmata (plata card online)')
                    break;
                case 'ERR_NOT_ENOUGH_IN_STOCK':
                    toaster.danger('Eroare: Stocuri insuficiente, comanda nu poate fi confirmata pana se rezolva problema!');
                    break;
                case 'ERR_QUANTITY_RETURNED_TOO_LARGE':
                    toaster.danger('Eroare: Nu se pot returna mai multe produse decat au fost cumparate');
                    break;
                default:
                    toaster.danger('Eroare: Ceva nu merge bine :(')
            }


            // if(e === 'not_enough_in_stock') {
            //     toaster.danger("Stocuri insuficiente pentru a procesa comanda.")
            // } else if (e === 'quantity_returned_too_large') {
            //     toaster.danger('Nu se pot returna mai multe produse decat au fost cumparate')
            // } else {
            //     toaster.danger('Eroare nespecificata');
            // }

            dispatch(actions.edit.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.edit.request.id));
    }
}

export function confirmOrderPayment(id, status) {
    return async (dispatch) => {
        dispatch(setLoading(actions.edit.request.id));
        dispatch(actions.edit.request.thunk());


        try {
            const result = await Request.put(`/orders/[id]/payment-status-update`, {id }, null, { status });
            const updateData = new Order(result.data);

            toaster.success("Operatiune efectuata!")
            dispatch(actions.edit.success.thunk(updateData));
        } catch (e) {
            const parsedErrors = ErrorHandler.parse(e);

            dispatch(actions.edit.failure.thunk(parsedErrors));
        }

        dispatch(unsetLoading(actions.edit.request.id));
    }
}
