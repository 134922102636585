import moment from 'moment';

import Base from './Base';

export default class Attribute extends Base {
    constructor(props, children = []) {
        super();

        this.import(props, children);
    }

    import(props) {
        if (!props) {
            props = {}
        }

        if (typeof props === 'number') {
            this.id = props;
            return;
        }

        this.id = this.assign('id', props);
        this.textId = this.assign('textId', props);
        this.type = this.assign('type', props);
        this.name = this.assign('name', props);
        this.description = this.assign('description', props);
        this.values = (props.values || []).map(value => typeof value.value !== 'undefined' ? value.value : value);
        this.countable = this.assign('countable', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }

    export() {
        return {
            textId: this.textId,
            type: this.type,
            name: this.name,
            description: this.description,
            values: this.values || [],
            countable: !!this.countable ? 1 : 0
        }
    }
}