import React, { Component } from 'react';
import ObjectPicker from './ObjectPicker';
import { Dialog } from 'evergreen-ui';

class ObjectPickerDialog extends Component {
    constructor (props) {
        super(props);

        this.classes = props.classes;
    }

    render () {
      return (<Dialog
        isShown={this.props.open}
        title= {this.props.label || "Alegere Entitate"}
        hasFooter={false}
        onClose={this.props.onClose}>
            <ObjectPicker {...this.props}/>
      </Dialog>)
    }
}

export default ObjectPickerDialog;