import { Badge, Pane } from "evergreen-ui";

import React, { useState } from 'react';
import { TextInputField, SelectField, Button, Text } from 'evergreen-ui';
import ProductAutocomplete from '../../products/components/ProductAutocomplete';

const SupplierProduct = (props) => {
    const [ showAll, setShowAll ] = useState(false);

    return (
        <Pane width="100%" backgroundColor={`${props.index % 2 === 0 ? '#EEEEEE' : '#FFFFFF'}`}>
            <Pane marginBottom={0} display="flex" padding="10px"  
                backgroundColor={`${showAll ? "#FF4444" : 'transparent'}`}
                onClick={(e) => {
                    setShowAll(!showAll)
                }}
                cursor="pointer"
            >
                <Pane width="50px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.index + 1}</Text>
                </Pane>
                <Pane flexGrow={1} minWidth="500px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.data.label ? props.data.label : <Badge color="red">NICIUN PRODUS SELECTAT</Badge>}</Text>
                </Pane>
                <Pane width="150px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >
                        <Badge color={props.data.quantityBilled === props.data.quantityReceived ? "green" : "red"}>
                            {props.data.quantityBilled}/{props.data.quantityReceived}
                        </Badge>
                    </Text>
                </Pane>
                <Pane width="150px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.data.unitPurchasePrice}</Text>
                </Pane>
                <Pane width="100px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.data.unitPurchaseVAT}%</Text>
                </Pane>
                <Pane width="100px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.pricePlusTransport}</Text>
                </Pane>
                <Pane width="100px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.vatPerUnit}</Text>
                </Pane>
                <Pane width="100px">
                    <Text color={`${showAll ? "#FFFFFF" : "#000000"}`} >{props.lineTotal}</Text>
                </Pane>
            </Pane>

            {showAll ? 
                <Pane padding={10} marginTop={0}>
                    <Pane display="flex" marginBottom={0}>
                        <ProductAutocomplete 
                            key={props.index + '-' + props.data.label}
                            width={300} 
                            blankOption={true}
                            value={props.data} 
                            onChange={(val) => {
                                props.setFieldValue(`products.${props.index}`, {...props.data, ...val})
                                
                                if(val.barcode) {
                                    props.setFieldValue(`products.${props.index}.barcode`, val.barcode)
                                } else {
                                    props.setFieldValue(`products.${props.index}.barcode`, "Indisponibil")
                                }
                            }}/>

                        <TextInputField marginLeft={5} marginTop={-4} name={`products.${props.index}.barcode`}
                            placeholder="Cod de bare"
                            value={props.data.barcode}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </Pane>

                    <Pane display="flex" marginTop={5}>    
                        <TextInputField  margin={0} padding={0} name={`products.${props.index}.um`} 
                            label="Unitate Masura"
                            marginLeft={5}
                            value={props.data.um}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            />
                        <TextInputField name={`products.${props.index}.quantityBilled`} 
                            label="Cantitate Facturata"
                            marginLeft={5}
                            value={props.data.quantityBilled}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            />
                        <TextInputField name={`products.${props.index}.quantityReceived`} 
                            label="Cantitate Livrata"
                            marginLeft={5}
                            value={props.data.quantityReceived}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            />
                    </Pane>
                    <Pane display="flex" marginTop={5}>
                        <TextInputField name={`products.${props.index}.unitPurchasePrice`} 
                            label="Pret Cumparare"
                            value={props.data.unitPurchasePrice}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                
                        <SelectField
                            flex={1}
                            marginLeft={5}
                            label="Grupa TVA"
                            name="unitPurchaseVAT"
                            value={props.data.unitPurchaseVAT}
                            onChange={(e) => {
                                props.setFieldValue(`products.${props.index}.unitPurchaseVAT`, e.target.value);
                            }}
                            onBlur={props.handleBlur}
                        >
                            <option value="19">19%</option>
                            <option value="9">9%</option>
                            <option value="5">5%</option>
                            <option value="0">0%</option>

                        </SelectField>
                    </Pane>
                    <Pane backgroundColor="#EEEEEE" marginBottom={0} color="#FFFFFF" padding={10} display="flex">
                        <Pane flex={1}>
                            <Badge marginRight={5} color="orange">
                                Pret + Transport: {props.pricePlusTransport} RON
                            </Badge>

                            <Badge marginRight={5}  color="orange">
                                TVA / UM : {props.vatPerUnit} RON
                            </Badge>

                            <Badge marginRight={5}  color="orange">
                                Total Linie: {props.lineTotal} RON
                            </Badge>
                        </Pane>
                        
                        <Pane>
                            <Button intent="danger" onClick={() => props.onRemove(props.index)} type="button">Sterge</Button>
                        </Pane>
                    </Pane>
                </Pane> : null}
        </Pane>
    )
}

export default SupplierProduct;