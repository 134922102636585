import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCart} from '../actions/CartActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import { Link } from 'react-router-dom';
import CartInfoBlock from '../components/CartInfoBlock';

class ViewCart extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getCart(this.id))
    }

    render() {
              
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/carts">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista cosuri
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Cos 
                    </Heading>
                </Pane>

                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <CartInfoBlock cart={this.props.cart}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        cart: state.carts.current
    }
}

export default connect(mapStateToProps)(ViewCart);