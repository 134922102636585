import moment from 'moment';

import Base from './Base';

export default class Address extends Base {
    constructor(props, children = []) {
        super();
        
        this.import(props, children);
    }

    import(props) {
        if(!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.nirSeries = this.assign('nirSeries', props);
        this.nirNumber = this.assign('nirNumber', props);
        this.currency = this.assign('currency', props);
        this.exchangeRate = this.assign('exchangeRate', props);
        this.shipping = this.assign('shipping', props) || "0";
        this.supplierInvoiceValue = this.assign('supplierInvoiceValue', props);
        this.supplierInvoice = this.assign('supplierInvoice', props);
        this.supplierInvoiceNumber = this.assign('supplierInvoiceNumber', props);
        this.supplierInvoiceDate = this.assign('supplierInvoiceDate', props);
        this.deliveryDate = this.assign('deliveryDate', props);
        this.status = this.assign('status', props);
        this.receptionName1 = this.assign('receptionName1', props);
        this.receptionName2 = this.assign('receptionName2', props);
        this.receptionName3 = this.assign('receptionName3', props);
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
        this.committed = this.assign('committed', props);
    
        this.supplierId = this.assign('supplierId', props);
        this.supplier = props.supplier || {
            name: 'Nedefinit'
        };

        this.stockAccountId = this.assign('stockAccountId', props);
        this.stockAccount = props.stockAccount || {
            name: 'Nedefinit'
        };

        this.products = (props.orderLines || props.products || []).map(product => {
            let rval = {
                ...product,
                productId: (product.productId || 0).toString(),
                label: product.label || "Blank",
            }

            return rval;
        }).sort((a, b) => {
            return a.position > b.position ? 1 : -1;
        });    


    }

    export() {
        return {
            nirSeries: this.nirSeries,
            nirNumber: this.nirNumber,
            supplierId: parseInt(this.supplierId),
            stockAccountId: this.stockAccountId,
            currency: this.currency,
            exchangeRate: this.exchangeRate,
            shipping: this.shipping,
            supplierInvoice: this.supplierInvoice,
            supplierInvoiceValue: this.supplierInvoiceValue,
            supplierInvoiceNumber: this.supplierInvoiceNumber,
            supplierInvoiceDate: this.supplierInvoiceDate,
            deliveryDate: this.deliveryDate,
            receptionName1: this.receptionName1,
            receptionName2: this.receptionName2,
            receptionName3: this.receptionName3,
            status: this.status,
            products: this.products,
            committed: !!this.committed
        }
    }
}