import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCategories from './containers/ListCategories';
import CreateCategory from './containers/CreateCategory';
import EditCategory from './containers/EditCategory';
import ViewCategory from './containers/ViewCategory';

const routes = [
    {
        type: 'crud',
        list: ListCategories,
        create: CreateCategory,
        edit: EditCategory,
        view: ViewCategory
    }
]

export default ModuleBootstrapper.getRoutes("categories", routes)