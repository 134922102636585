import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const User = ModuleConfig.Structures.User;
const CRUDActions = ModuleConfig.CRUDActions;

export function getUsers(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.users,
        mapStructure: User,
        errorNotification: "Utilizatorii nu au putut fi incarcati"
    });
}

export function getUser(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.user,
        mapStructure: User,
        errorNotification: "Utilizatorul nu a putut fi incarcat"
    })
}

export function createUser(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.users,
        mapStructure: User,
        successNotification: "Utilizatorul a fost creat",
        nextUrl: '/users/',
        exportOpts: {
            password: true
        }
    })
}

export function updateUser(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.user,
        mapStructure: User,
        successNotification: "Utilizatorul a fost modificat",
        nextUrl: '/users/',
    })
}

export function deleteUser(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.user,
        successNotification: "Utilizatorul a fost sters",
        nextUrl: '/users'
    });
}