import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pane, Heading, Button } from 'evergreen-ui';
import StockOperationForm from '../components/StockOperationForm';
import { Link } from 'react-router-dom';
import { getStockAccounts } from '../../stockAccount/actions/StockAccountActions';
import { getSuppliers } from '../../suppliers/actions/SupplierActions';
import { createStockOperation } from '../actions/StockOperationActions';

import Configuration from '../Config';
import InfoBlock from '../../common/InfoBlock/InfoBlock';
const validation = Configuration.Validations.stockOperation;
const defaultValues = Configuration.Defaults.createStockOperation;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;


class CreateStockOperation extends Component {
    componentDidMount() {
        this.props.dispatch(getStockAccounts());
        this.props.dispatch(getSuppliers());
    }

    handleSave = (values) => {
        this.props.dispatch(createStockOperation(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/stock-operations">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la operatii stocuri
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Operatie Noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <StockOperationForm 
                            view="create"
                            stockAccounts={this.props.stockAccounts} 
                            suppliers={this.props.suppliers}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}
                        />
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        suppliers: state.suppliers.data,
        stockAccounts: state.stockAccounts.data,
        errors: state.stockOperations.errors.type === 'validation' ? mapValidationErrors(state.stockOperations.errors.fields): [],

    }
}

export default connect(mapStateToProps)(CreateStockOperation);