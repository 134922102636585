import { Button, CrossIcon, DocumentOpenIcon, Heading, Link, Pane, PlusIcon, Table, toaster, TruckIcon } from 'evergreen-ui';
import * as moment from 'moment';
import React from 'react';
import Request from "../../../../util/Api";
import { slugify } from '../../../../util/Misc';

const ImportProductsConfig = (props) => {

    const markProductsAsSupplierOrder = async (products) => {
        const barcodes = products.map(product => product.barcode);

        const results = await Request.post(`/products/set-supplier-stock`, null, null, { barcodes });

        if(!results.success) {
            toaster.error("Error setting supplier stock");
            return;
        } else {
            toaster.success("Products marked as supplier stock");
        }
    }

    const clearSupplierStock = async () => {
        const results = await Request.post(`/products/clear-supplier-stock`);

        if(!results.success) {
            toaster.error("Error clearing supplier stock");
            return;
        } else {
            toaster.success("Products cleared as supplier stock");
        }
    }

    const saveProducts = async (products) => {
        for(let i in products) {
            if(products[i].existing) {
                continue;
            }
            const product = products[i];
            await saveProduct(product);

            toaster.success(`Creat produs - ${product.name} (${parseInt(i)+1}/${products.length})`);
        }
    }

    const saveProduct = async (product) => {
        let productData = await Request.get('/integrations/isbndb/find-by-isbn?isbn='+product.barcode);
        productData = productData.result;

        const computedPrice = Math.round(product.retailPrice * 0.65 * 1.4 * 100) / 100;

        const volumeMatch = productData.title.match(/Vol\.\s*(\d+)/);
        const volumeNumber = volumeMatch ? volumeMatch[1] : "";

        const productPayload = {
            active: 1,
            activeOnline: 0,
            oneCLickBuy: 0,
            service: 0,
            availability: "unavailable",
            availableFrom: moment(productData.publishedDate).format("YYYY-MM-DD"),
            barcode: productData.barcode,
            categoryId: productData.categoryId,
            description: productData.description || " ",
            images: [
                {
                    data: productData.image,
                    position: 0
                }
            ],
            maxQuantity: 10,
            metaDescription: productData.description || " ",
            metaTitle: productData.barcode,
            preorderAvailable: 0,
            preorderQuantity: "0",
            recipe: {},
            sku: productData.sku,
            slug: slugify(productData.title),
            sourcePrice:computedPrice,
            title: productData.title,
            unitId: 1,
            vatBracket: 5,
            attributes: {
                "attribute_50": {
                    id: 50,
                    value: productData.authors || ""
                },
                "attribute_51": {
                    id: 51,
                    value: productData.pageCount ? productData.pageCount.toString() : ""
                },
                "attribute_52": {
                    id: 52,
                    value: volumeNumber || ""
                },
                "attribute_53": {
                    id: 53,
                    value: productData.edition || "",
                },
                "attribute_54": {
                    id: 54,
                    value: productData.publisher || ""
                },
                "attribute_55": {
                    id: 55,
                    value: productData.publishedDate.toString()
                },
                "attribute_56": {
                    id: 56,
                    value: ""
                }
            }
        }

        await Request.post('/products', null, null, productPayload);
    }

    return (<>
        <Pane display="flex" justifyContent="flex-start" gap={10}>
            {props.products.length > 0 ?
            <Button appearance="primary" intent="warning" marginLeft={10} onClick={() => markProductsAsSupplierOrder(props.products)}>
                <TruckIcon/>
                Seteaza In Stoc Furnizor
            </Button> : null }

                <Button appearance="primary" intent="danger" marginLeft={10} onClick={() => clearSupplierStock()}>
                <CrossIcon/>
                Goleste Stoc Furnizor
            </Button>
        </Pane>

        {props.products && props.products.length ?
            <Table>
                <Table.Head style={{paddingRight: 0}}>
                    <Table.TextHeaderCell>Barcode</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>SRP</Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                        Existing
                        <Button appearance="primary" intent="danger" marginLeft={10} onClick={() => saveProducts(props.products)}>
                            <PlusIcon/>
                            Creeaza
                        </Button>
                    </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {props.products.map((product, index) => (
                        <Table.Row key={index}>
                            <Table.TextCell>{product.barcode}</Table.TextCell>
                            <Table.TextCell>{product.name}</Table.TextCell>
                            <Table.TextCell>{product.retailPrice}</Table.TextCell>
                            <Table.TextCell>
                                {product.existing ?
                                    <Link href={`/products/${product.existing}`} target="_blank">
                                        <DocumentOpenIcon/></Link> : null
                                }</Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table> :
            <><Heading size={400}>Importa produse mai intai</Heading></>
        }
    </>
    )
};

export default ImportProductsConfig;
