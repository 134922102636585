import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUser, updateUser } from '../actions/UserActions';
import { getRoles } from '../../roles/actions/RoleActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import UserForm from '../components/UserForm';

import Configuration from '../Config';

const validation = Configuration.Validations.editUser;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getUser(this.id));
        this.props.dispatch(getRoles());
    }

    handleSave = (values) => {
        this.props.dispatch(updateUser(this.props.user.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/users">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista utilizatori
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/users/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Utilizator - <strong>{this.props.user.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <UserForm 
                            isEdit={true}
                            initialValues={this.props.user}
                            validationSchema={validation}
                            roles={this.props.roles}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.users.errors.type === 'validation' ? mapValidationErrors(state.users.errors.fields): [],
        user: state.users.current,
        roles: state.roles.data
    }
}

export default connect(mapStateToProps)(EditUser);