import { Table } from 'evergreen-ui';
import React from 'react';
import moment from 'moment';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function ShopSaleInfoBlock(props) {
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Vanzare Magazin"
    >
        <InfoLine label="Id Intern" value={props.shopSale.id}/>
        <InfoLine label="Document" value={'Bon Fiscal ' + props.shopSale.documentId}/>
        <InfoLine label="Plata" value={props.shopSale.paymentType}/>
        <InfoLine label="Produse" value={props.shopSale.quantity}/>
        <InfoLine label="Data vanzare" value={(props.shopSale.saleDate || moment()).format('D MMM \'YY HH:mm')}/>
        <InfoLine label="Valoare Totala" value={props.shopSale.totalPrice + ' RON'}/>
        <InfoLine label="Confirmata" value={props.shopSale.confirmed ? 'Da' : 'Nu'}/>
         
        <Table>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    #
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
                    Produs
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cantitate
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Unitar
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Total
                </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
                    {(props.shopSale.products && props.shopSale.products.length > 0) ? (props.shopSale.products).map((product, index) => (
                        <Table.Row>
                            <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                {index + 1}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} title={product.label}>
                                <strong>{product.title}</strong>
                                <br/>
                                {product.sku}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.quantity}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.unitPrice} RON
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.totalPrice} RON
                            </Table.TextCell>
                        </Table.Row>
                    )) : null}
            </Table.Body>
        </Table>

    </InfoBlock>
}