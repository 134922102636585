import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCategoryLists from './containers/ListCategoryLists';
import CreateCategoryList from './containers/CreateCategoryList';
import EditCategoryList from './containers/EditCategoryList';
import ViewCategoryList from './containers/ViewCategoryList';

const routes = [
    {
        type: 'crud',
        list: ListCategoryLists,
        create: CreateCategoryList,
        edit: EditCategoryList,
        view: ViewCategoryList
    }
]

export default ModuleBootstrapper.getRoutes("category-lists", routes)