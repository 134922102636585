import React, { Component } from 'react';
import { Button, Dialog, Heading, Text, Pane, TextInputField } from 'evergreen-ui';
import Request from '../../../util/Api';
import { useEffect } from 'react';

export const UpdateQuantityModal = (props) => {
    const [realQuantity, setRealQuantity] = React.useState(0);
    const [error, setError] = React.useState(null); 

    useEffect(() => {
        setRealQuantity(props.item ? props.item.quantity : 0);
      }, [props.item]);

    if(!props.item) {
        return null;
    }

    return <Dialog
        shouldCloseOnOverlayClick={false}
        width={600}
        isShown={props.open}
        title= {"Actualizeaza Cantitatea"}
        hasFooter={false}
        onCloseComplete={props.onClose}
        onClose={props.onClose}>
            <Pane>
                {error ? <Text>{error}</Text> : null}
                <TextInputField
                    type="number"
                    label="Cantitate Noua"
                    name="realQuantity"
                    description={`Cantitatea reala a produsului. Stoc curent: ${props.item.quantity}`}
                    value={realQuantity}
                    onChange={(e) => setRealQuantity(e.target.value)}
                    onFocus={(event) => event.target.select()}/>
            </Pane>

            <Button type="button" marginRight={10} onClick={() => {
                props.onUpdate(props.item.id, props.item.type, realQuantity);
            }}>Salveaza</Button>

            <Button type="button" onClick={() => {
                props.onClose();
            }}>Inchide</Button>
  </Dialog>
}