import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrder, deleteOrder, updateOrderStatus, confirmOrderPayment } from '../actions/OrderActions';
import { Pane, Heading, Button } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import OrderInfoBlock from '../components/OrderInfoBlock';

class ViewOrder extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }

    componentDidMount() {
        this.props.dispatch(getOrder(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteOrder(this.id));
    }

    handleUpdateOrderStatus = (status, values) => {
        if(status === this.props.order.status && status !== 'returned' && status !== 'processed') {
            return;
        }
        this.props.dispatch(updateOrderStatus(this.id, status, values))
    }

    handleConfirmOrderPayment = (status) => {
        this.props.dispatch(confirmOrderPayment(this.id, status));
    }

    render() {

        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/orders">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista comenzi
                            </Button>
                        </Link>
                    </Pane>

                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge comanda"
                            title="Confirmare stergere comanda"
                            confirmLabel="Sterg definitiv comanda"
                            message="Esti sigur ca vrei sa stergi comanda?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Comanda - <strong>#{this.props.order.orderNumber}</strong> (ID: {this.props.order.id})
                    </Heading>
                </Pane>

                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16} width="100%">
                    <OrderInfoBlock order={this.props.order}
                        updateOrderStatus={this.handleUpdateOrderStatus}
                        confirmOrderPayment={this.handleConfirmOrderPayment}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        order: state.orders.current
    }
}

export default connect(mapStateToProps)(ViewOrder);
