import moment from 'moment';

import Base from './Base';
import Role from './Role';
import Address from './Address';

export default class User extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        this.id = props.id !== undefined ? props.id : this.id;
        this.active = props.active !== undefined ? props.active : this.active;
        this.email = props.email !== undefined ? props.email : this.email;
        this.name = props.name !== undefined ? props.name : this.name;
        this.newsletters = this.assign('newsletters', props, 'boolean');
        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;

        this.password = props.password !== undefined ? props.password : this.password;
        this.passwordConfirmation = props.passwordConfirmation !== undefined ? props.passwordConfirmation : this.passwordConfirmation;
       
        //Apply roleId if present; It means we want to update the role; 
        //ToDo - Deconvolute logic
        if(props.roleId && props.role && props.roleId !== props.role.id) {
            props.role = null;
        }

        this.role = props.role ? new Role(props.role) : this.role;


        if(!this.role || (props.roleId && this.role.id !== props.roleId && !props.role)) {
            this.role = new Role({id: props.roleId});
        }

        this.roleId = this.role.id;

        if(props.addresses) {
            this.addresses = props.addresses.map(address => new Address(address));
        }
    }

    export(options = {}) {
        let exportObj = {
            active: this.active,
            email: this.email,
            name: this.name,
            roleId: this.role.id,
            newsletters: this.newsletters
        }

        if(options.password) {
            exportObj.password = this.password;
            exportObj.passwordConfirmation = this.passwordConfirmation;
        }

        return exportObj;
    }

    get roleName() {
        return this.role.name;
    }
}