import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListSupplierOrders from './containers/ListSupplierOrders';
import CreateSupplierOrder from './containers/CreateSupplierOrder';
import EditSupplierOrder from './containers/EditSupplierOrder';
import ViewSupplierOrder from './containers/ViewSupplierOrder';

const routes = [
    {
        type: 'crud',
        list: ListSupplierOrders,
        create: CreateSupplierOrder,
        edit: EditSupplierOrder,
        view: ViewSupplierOrder
    }
]

export default ModuleBootstrapper.getRoutes("supplier-orders", routes)