import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'carousel',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        carousels: '/carousel',
        carousel: '/carousel/[id]'
    },
    defaults: {
        createCarousel: {
            variant: "",
            name: "",
            backgroundImage: "",
            graphicsImage: "",

            properties: {},
            position: 1,
            active: false
        }
    },
    validations: {
        carousel: yup.object().shape({
            variant: yup.string().required("Varianta este obligatorie"),
            name: yup.string().required("Numele este obligatoriu"),
            backgroundColor: yup.string(),
            backgroundImage: yup.string(),
            graphicsImage: yup.string(),


            position: yup.number(),
            active: yup.boolean()
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;
