import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'preorders',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        preorders: '/preorders',
        preorder: '/preorders/[id]'
    },
    defaults: {
        createPreorder: {
            productId: 0,
            quantity: 0,
            quantityAvailable: 0
        }
    },
    validations: {
        preorder: yup.object().shape({
            productId: yup.number(),
            quantity: yup.number(),
            quantityAvailable: yup.number(),
        })
    }
}

const Config ={
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;