import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListProductLists from './containers/ListProductLists';
import CreateProductList from './containers/CreateProductList';
import EditProductList from './containers/EditProductList';
import ViewProductList from './containers/ViewProductList';

const routes = [
    {
        type: 'crud',
        list: ListProductLists,
        create: CreateProductList,
        edit: EditProductList,
        view: ViewProductList
    }
]

export default ModuleBootstrapper.getRoutes("product-lists", routes)