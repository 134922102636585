import Base from './Base';

export default class Unit extends Base {
    constructor(props, children = []) {
        super();
        
        this.import(props, children);
    }

    import(props) {
        if(!props) {
            props = {}
        }

        this.id = this.assign('id', props);
        this.name = this.assign('name', props);
    }

    export() {
        return {
            name: this.name
        }
    }
}