import React from 'react';
import { Pane, Heading } from 'evergreen-ui';
import configuration from '../../../util/Configuration';


const CarouselShowcasePreview = (props) => {
    if(!props.carousel.properties) {
        return <></>;
    }

    const backgroundImage = (props.carousel.backgroundImage && props.carousel.backgroundImage.length) < 100 ?
        `${configuration.images}/carousel_bg_default_${props.carousel.backgroundImage}.webp` :
        props.carousel.backgroundImage;

    const graphicsImage = (props.carousel.graphicsImage && props.carousel.graphicsImage.length) < 100 ?
        `${configuration.images}/carousel_gfx_default_${props.carousel.graphicsImage}.webp` :
        props.carousel.graphicsImage;

    return (
        <>
            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                <Heading size={500}>Preview</Heading>
            </Pane>

            <div style={{
                width:'500px',
                height: '300px',
                backgroundColor: props.carousel.backgroundColor,
                backgroundImage: `url('${backgroundImage}')`,
                backgroundSize: 'cover',
                border: '1px solid gray',
                fontFamily: 'Arial',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}>
                <div style={{
                    width: '60%',
                    zIndex: '1000'
                }}>
                    {props.carousel.properties.hasTitle ?
                        <div style={{
                            fontWeight: '900',
                            textTransform: 'uppercase',
                            fontSize: '28px',
                            color: props.carousel.properties.titleColor,
                            marginBottom: '5px'
                        }}>
                            {props.carousel.properties.title}
                        </div> : null}

                    {props.carousel.properties.hasSubtitle ?
                    <div style={{
                        color: props.carousel.properties.subtitleColor,
                        fontWeight: '700',
                        fontSize: '12px',
                        marginBottom: '5px'
                    }}>
                        {props.carousel.properties.subtitle}
                    </div> : null}

                    {props.carousel.properties.hasText ?
                    <div style={{
                        fontSize: '10px',
                        color: props.carousel.properties.textColor,
                        marginBottom: '5px'
                    }}>
                        {props.carousel.properties.text}
                    </div> : null}

                    <div style={{ display: "flex"}}>
                        {props.carousel.properties.hasPrimaryButton ?
                            <button style={{
                                border: '0',
                                backgroundColor: props.carousel.properties.primaryButtonColor,
                                color: props.carousel.properties.primaryButtonTextColor,
                                fontWeight: '900',
                                padding: '5px',
                                height: '24px',
                                lineHeight: '15px',
                                marginRight: '5px'
                            }}>
                                {props.carousel.properties.primaryButtonText}
                            </button> : null}

                        {props.carousel.properties.hasSecondaryButton ?
                            <button style={{
                                border: '0',
                                backgroundColor: props.carousel.properties.secondaryButtonColor,
                                color: props.carousel.properties.secondaryButtonTextColor,
                                fontWeight: '700',
                                lineHeight: '15px',
                                padding: '5px',
                                height: '24px'
                            }}>
                                {props.carousel.properties.secondaryButtonText}
                            </button> : null}
                    </div>
                </div>

                <div style={{
                    width: '70%',
                    bottom: '0',
                    right: '0',
                    height: '100%',
                    position: 'absolute',
                    zIndex: '0'
                }}>
                    <img style={{height: '100%'}} src={graphicsImage} alt="graphics"/>
                </div>


            </div>
        </>

    )
}

export default CarouselShowcasePreview;
