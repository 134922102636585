import React from 'react';
import {Heading, Pane, Card, Button, EditIcon, EyeOpenIcon, Switch, Text, Badge } from 'evergreen-ui';
import { Link } from 'react-router-dom';

export default function ProductCard(props) {
    return (
        <Card cursor="pointer">
            <Pane 
                width="300px" 
                height="400px"
                display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" 
                border="default" elevation={1} margin={16} padding={16}>
                <Pane>
                <img style={{height: '200px', width: 'auto'}} alt="product-thumbnail"
                            src={props.product.images[0] ? 
                                process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + props.product.images[0].image + '.webp' : null}/>
                </Pane>
                <Heading size={500}>
                    <Text color="orange" fontWeight="bold">#{props.product.id}</Text> {props.product.title}
                </Heading>
                <Pane>
                    <Text color={props.product.oldPrice !== props.product.price ? "red" : ""} fontWeight="bold">
                        {props.product.price} Lei 
                    </Text>
                    {props.product.oldPrice && props.product.oldPrice !== props.product.price ? <Text marginLeft={10} textDecoration="line-through">{props.product.oldPrice} Lei</Text> : null}
                
                    {/* <Badge marginLeft={10} color={props.product.score > 0 ? "green": "red"}>
                        Scor: {props.product.score || 0}
                    </Badge> */}

                    <Badge marginLeft={10} color={props.product.quantity > 0 ? "green": "red"}>
                        Stoc: {props.product.quantity || 0}
                    </Badge>
                    <Badge marginLeft={10} color={props.product.quantity > 0 ? "green": "red"}>
                        Viz: {props.product.views || 0}
                    </Badge>
                </Pane>
                <Pane display="flex">
                    <Link to={`/products/${props.product.id}`}>
                        <Button marginY={8} marginRight={12} iconBefore={EyeOpenIcon}>
                            Detalii
                        </Button>
                    </Link>

                    <Link to={`/products/${props.product.id}/edit`}>
                        <Button marginY={8} marginRight={12} iconBefore={EditIcon}>
                            Modifica
                        </Button>
                    </Link>
                    
                    <Switch marginTop={13} height={24} checked={props.product.activeOnline} onChange={props.toggleProduct}/>
                </Pane>
            </Pane>
        </Card>
    )
}