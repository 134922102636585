import React from 'react';
import * as yup from 'yup';

import { Pane, Text, Button, TextInputField, Checkbox } from 'evergreen-ui'
import { Formik, Form } from 'formik';

const loginValidation = yup.object().shape({
    email: yup.string().required('Adresa de email nu poate fi goala'),
    password: yup.string().required('Parola nu poate fi goala')
})


export default function LoginForm(props) {
    return <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{email: '', password: '', rememberMe: true}}
        onSubmit={async (values, { setSubmitting }) => {
            await props.onSubmit(values);
            setSubmitting(false);
        }}
        validationSchema={loginValidation}
        >

        {({
            values, errors, handleChange, handleBlur, isSubmitting
        }) => (         
            <Form>
                <Pane marginBottom={5}>
                    <TextInputField
                        type="email"
                        name="email" 
                        placeholder="Email utilizator" 
                        width="100%" 
                        label="Email"
                        marginBottom={0}
                        value={values.email}
                        isInvalid={!!errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Text color="red" marginBottom={5}>{errors.email}</Text>
                </Pane>
                <Pane marginBottom={5}>
                    <TextInputField
                        type="password"
                        name="password" 
                        width="100%"
                        placeholder="Parola" 
                        label="Parola"
                        marginBottom={0}
                        value={values.password}
                        isInvalid={!!errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Text color="red">{errors.password}</Text>
                </Pane>
                <Pane marginBottom={10}>
                    <Checkbox label="Tine-ma minte"
                        name="rememberMe"
                        checked={values.rememberMe}
                        onChange={handleChange}
                        onBlur={handleBlur}/>


                </Pane>
                <Button type="submit"
                    iconBefore="log-in"
                    disabled={isSubmitting}>
                    Login
                </Button>
            </Form>
        )}
    </Formik>
}