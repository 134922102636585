import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'users',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        users: '/users',
        user: '/users/[id]'
    },
    defaults: {
        createUser: {
            email: '',
            name: '', 
            roleId: 3,
            password: '',
            passwordConfirmation: '',
            active: false,
            newsletters: false
        }
    },
    validations: {
        createUser: yup.object().shape({
            email: yup.string().email('Adresa de email este invalida').required('Adresa de email este obligatorie'),
            roleId: yup.number().required(),
            name: yup.string().required('Numele este obligatoriu'),
            password: yup.string().required('Parola este obligatorie'),
            passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Parolele nu sunt identice').required('Confirmarea parolei este obligatorie')
        }),
        editUser: yup.object().shape({
            email: yup.string().email('Adresa de email este invalida').required('Adresa de email este obligatorie'),
            roleId: yup.number().required(),
            name: yup.string().required('Numele este obligatoriu'),
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;