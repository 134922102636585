import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Button, SelectField, Checkbox } from 'evergreen-ui';

export default class UserForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <TextInputField
                            required
                            label="Email"
                            name="email"
                            type="email"
                            description="Adresa de email a utilizatorului"
                            value={values.email || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.email}/>

                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele utilizatorului"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>

                        {this.props.isEdit ? null :   
                            <TextInputField
                                required
                                type="password"
                                label="Parola"
                                name="password"
                                description="Parola utilizatorului"
                                value={values.password || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validationMessage={errors.password}/>}
                        {this.props.isEdit ? null :   
                            <TextInputField
                                required
                                type="password"
                                label="Confirmare Parola"
                                name="passwordConfirmation"
                                description="Pentru siguranta, introdu din nou parola"
                                value={values.passwordConfirmation || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validationMessage={errors.passwordConfirmation}/>}

                        <SelectField
                            label="Rol"
                            name="roleId"
                            description="Rolul pe care doresti sa il aiba utilizatorul"
                            value={values.roleId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            {this.props.roles.map(role => (
                                <option key={role.id} value={role.id}>{role.name}</option>
                            ))}                                
                        </SelectField>

                        <Checkbox 
                            name="newsletters" 
                            label="Abonat la newsletter"
                            checked={values.newsletters || false}
                            onChange={handleChange}/>

                        <Checkbox 
                            name="active" 
                            label="Activ"
                            checked={values.active || false}
                            onChange={handleChange}/>

                        <Button type="submit" onClick={handleSubmit}>
                            Salveaza
                        </Button>

                    </form>
                }}
            </Formik>
        );
    }
}