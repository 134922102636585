import moment from "moment";

export default class CardExpansion {
  constructor(props) {
    this.id = props.id || 0;
    this.name = props.name;
    this.code = props.code;
    this.cardMarketId = props.cardMarketId;
    this.cardTraderId = props.cardTraderId;
    this.releaseDate = moment(props.releaseDate);
    this.cardsSynced = props.cardsSynced === 1 ? true : false;
    this.customData = {};
    this.cardGameId = props.cardGameId;
    this.cardGame = props.cardGame;

    this.active = props.active;
    this.activeOnline = props.activeOnline;
    this.activeThirdParty = props.activeThirdParty;

    try {
      this.customData = JSON.parse(props.customData);
    } catch (e) {
      if (typeof props.customData === "object") {
        this.customData = props.customData;
      }
    }

    this.cards = props.cards || [];

    if(this.cards.length > 0) {
      this.cards = this.cards.map(card => {
        try {
          return {
            ...card,
            customData: JSON.parse(card.customData)
          }
        } catch (e) {
          return {
            ...card,
            customData: card.customData
          }
        }

      });
    }

  }

  export() {
    return {
      id: this.id || null,
      customData: this.customData,
      name: this.name,
      cardMarketId: this.cardMarketId,
      cardTraderId: this.cardTraderId,
      code: this.code,
      releaseDate: this.releaseDate,
      active: this.active ? 1 : 0,
      activeOnline: this.activeOnline ? 1 : 0,
      activeThirdParty: this.activeThirdParty ? 1 : 0,
    };
  }
}
