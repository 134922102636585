import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListOffers from './containers/ListOffers';
import CreateOffer from './containers/CreateOffer';
import EditOffer from './containers/EditOffer';
import ViewOffer from './containers/ViewOffer';

const routes = [
    {
        type: 'crud',
        list: ListOffers,
        create: CreateOffer,
        edit: EditOffer,
        view: ViewOffer
    }
]

export default ModuleBootstrapper.getRoutes("offers", routes)