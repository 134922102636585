import { 
    roleListActionIds
} from './actions/Constants';

const initialState = {
    data: []
}

export default function roles(state = initialState, action) {
    switch(action.type) {
        case roleListActionIds.success:
            return Object.assign({}, state, {
                data: action.options.roles,
            })
        default:
            return state;
    }
}