import React, { Component } from 'react';
import ObjectPickerDialog from '../../common/ObjectPicker/ObjectPickerDialog';
import ProductSearchCard from './ProductSearchCard';
import { Button, Pane, Heading, Text } from 'evergreen-ui';

import Request from '../../../util/Api';

import Config from '../Config';
import ProductListCard from './ProductListCard';



class ProductPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false
        }
    }

    toggleSearch(show = true) {
        this.setState({
            showSearch: show
        })
    }

    searchFunction(value) {
        return Request.get(Config.Endpoints.products, null, {q: value, _start: 0, _end: 100});
    }

    mapperFunction(value) {
        return value.data.map(entry => ({...entry, name: entry.title}));
    }

    handleSubmit = (selection) => {
        this.props.onSubmit(selection);
    }

    handleDelete = (product) => {
        const values = this.props.values.filter(value => value !== product);
        this.props.onSubmit(values);
    }


    render() {
        return (this.props.minimal ? <>
            <ObjectPickerDialog
                single={this.props.single || false}
                label="Selecteaza unul sau mai multe produse"
                open={!!this.state.showSearch}
                onClose={() => { this.toggleSearch(false); }}
                onSubmit={(selection) => { this.handleSubmit(selection); }}
                searchFunction = {this.searchFunction}
                mapFunction = {this.mapperFunction}
                resultComponent = {(props) => <ProductSearchCard {...props}/>}/>

            <Button 
                appearance="primary"
                iconBefore="add"
                type="button"
                onClick={() => { this.toggleSearch(true)}}>
                <Text color="#FFFFFF">
                    {this.props.single ? 
                        this.props.value ? this.props.value.title : "Selecteaza Produs"  : "Adauga Produse"} 
                </Text>
            </Button>
             </> : <Pane marginTop={16} marginBottom={16} padding={16} background="tint2">
                <ObjectPickerDialog
                    label="Selecteaza unul sau mai multe produse"
                    open={!!this.state.showSearch}
                    onClose={() => { this.toggleSearch(false); }}
                    onSubmit={(selection) => { this.handleSubmit(selection); }}
                    searchFunction = {this.searchFunction}
                    mapFunction = {this.mapperFunction}
                    resultComponent = {(props) => <ProductSearchCard {...props}/>}/>

                <Button 
                    type="button"
                    marginBottom={16}
                    onClick={() => { this.toggleSearch(true)}}>
                    Adauga Produs(e)
                </Button>
                
                <Pane>
                    <Pane paddingBottom={8} marginBottom={8} borderBottom="default">
                        <Heading size={400}>Produse Selectate</Heading>
                    </Pane>
                    <Pane>
                        {this.props.values.length === 0 ? 
                            <Heading size={400}>Niciun produs adaugat</Heading> : 
                            null}
                        {this.props.error ? 
                            <Heading size={300} color="red">{this.props.error}</Heading> : null}

                        {(this.props.values || []).map(product => <ProductListCard 
                            key={`product-picker-${product.id}`} 
                            product={product} 
                            onDelete={() => this.handleDelete(product)}/>)}
                    </Pane>
                </Pane>
                
            </Pane>
        );
    }

}

export default ProductPicker;