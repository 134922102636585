import React, { Component, useState } from 'react';
import { Button, Dialog, Pane, Table, TextInputField } from 'evergreen-ui';
import Request from '../../../util/Api';


const CardExpansionLabelModal = (props) => {
    const [ search, setSearch ] = useState('');
    const [ searchResults, setSearchResults ] = useState([]);
    const [ includedResults, setIncludedResults ] = useState([]);

    const onSearchChange =  async (value) => {
        setSearch(value);

        const results = await Request.get('/card-games/card-expansions',{}, { q: value })

        setSearchResults(results.data);
    }

    const onIncludeResult = async (result) => {
        if(includedResults.find(r => r.id === result.id)) {
            return;
        }

        setIncludedResults([...includedResults, result]);
    }

    const onDeleteResult = async (result) => {
        setIncludedResults(includedResults.filter(r => r.id !== result.id));
    }

     const handleLabelDownload = async () => {
        const data = await Request.post('/card-games/card-expansions/label', {}, {}, { expansions: includedResults.map(r => r.id) }, { responseType: 'blob' });
        var a = document.createElement('A');
        a.href = URL.createObjectURL(data);
        a.download = 'label.pdf';
        document.body.appendChild(a);
        a.click();

    }

    return (<Dialog
            width={1300}
            isShown={props.open}
            title="Generare Label Extensii"
            hasFooter={false}
            onCloseComplete={props.onClose}
            onClose={props.onClose}>
                <Pane>
                    <TextInputField
                        label="Cautare"
                        placeholder="Cautare"
                        value={props.name}
                        onChange={e => onSearchChange(e.target.value)}
                    />
                </Pane>
                <Pane display="flex" flexDirection="row">
                    <Pane width="50%">
                        <Table>
                             <Table.Head>
                                <Table.TextHeaderCell>Rezultate</Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Head>
                                <Table.TextHeaderCell>Game</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Code</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Body height={240}>
                                {searchResults.map((result) => (
                                <Table.Row key={result.id} isSelectable>
                                    <Table.TextCell>{result.cardGame.name}</Table.TextCell>
                                    <Table.TextCell>{result.name}</Table.TextCell>
                                    <Table.TextCell>{result.code}</Table.TextCell>
                                    <Table.TextCell>
                                        <Button type="button" onClick={() => {
                                            onIncludeResult(result);
                                        }}>Adauga</Button>
                                    </Table.TextCell>

                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Pane>
                    <Pane width="50%">
                        <Table>
                            <Table.Head>
                                <Table.TextHeaderCell>Incluse</Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Head>
                                <Table.TextHeaderCell>Game</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Code</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Body height={240}>
                                {includedResults.map((result) => (
                                <Table.Row key={result.id} isSelectable>
                                    <Table.TextCell>{result.cardGame.name}</Table.TextCell>
                                    <Table.TextCell>{result.name}</Table.TextCell>
                                    <Table.TextCell>{result.code}</Table.TextCell>

                                    <Table.TextCell>
                                        <Button type="button" onClick={() => {
                                            onDeleteResult(result);
                                        }}>Sterge</Button>
                                    </Table.TextCell>

                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Pane>
                </Pane>

                <Button type="button" onClick={() => {
                    handleLabelDownload();
                }}>Download</Button>

                <Button type="button" onClick={() => {
                    props.onClose();
                }}>Inchide</Button>
            </Dialog>
            )
}


export default CardExpansionLabelModal;
