import ModuleConfig from '../Config';
import CardGame from '../models/CardGame';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const Card = ModuleConfig.Structures.Card;
const CRUDActions = ModuleConfig.CRUDActions;

export function getCardGames(opts) {
    return CRUDActions.getAll(actions.getCardGames, {
        ...opts,
        endpoint: endpoints.cardGames,
        mapStructure: CardGame,
        errorNotification: 'Jocurile de carti nu au putut fi incarcate'
    })
}

export function getCards(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.cards,
        mapStructure: Card,
        errorNotification: "Cartile nu au putut fi incarcate"
    });
}

export function getCard(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.card,
        mapStructure: Card,
        errorNotification: "Cartea nu a putut fi incarcata"
    })
}

export function createCard(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.cards,
        mapStructure: Card,
        successNotification: "Cartea a fost creata",
        nextUrl: '/cards/',
    })
}

export function updateCard(id, values, redirect=true) {
    const payload = {
        id,
        values,
        endpoint: endpoints.card,
        mapStructure: Card,
        successNotification: "Cartea a fost modificata",
    }

    if(redirect) {
        payload.nextUrl= '/cards/';
    }

    return CRUDActions.update(actions.edit, payload)
}

export function deleteCard(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.card,
        successNotification: "Cartea a fost stearsa",
        nextUrl: '/cards/'
    });
}
