import React, { Component } from 'react';

import { Button, Pane, FilePicker } from 'evergreen-ui';

import KImageComponent from './KImageComponent';
import configuration from '../../../util/Configuration';
import { isDataURL } from '../../../util/Misc';

export default class KSingleFileDrop extends Component {
    constructor (props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    handleSave = async (files) => {
        const data = await this.readFileAsync(files[0]);

        this.props.form.setFieldValue(this.props.field.name, data);
    }

    clear = () => {
        this.props.form.setFieldValue(this.props.field.name, '');
    }

    render() {
        const value = this.props.form.values[this.props.field.name] || "";

        const isEncoded = isDataURL(value);

        return (
            <Pane marginBottom={16}>
                <FilePicker 
                    width={230}
                    onChange={this.handleSave}
                    placeholder="Selecteaza fisier"/>

                {value ? 
                    <Pane>
                        <KImageComponent 
                            isView
                            image={isEncoded ? value : 
                                `${configuration.images}/${this.props.prefix}${value}.webp`}/>
                        <Button onClick={this.clear}>
                            Sterge
                        </Button>
                    </Pane> : null}

            </Pane>
        )
    }
}