import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getVoucher, updateVoucher } from '../actions/VoucherActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import VoucherForm from '../components/VoucherForm';

import Configuration from '../Config';

const validation = Configuration.Validations.voucher;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditVoucher extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getVoucher(this.id));
    }

    handleSave = (values) => {
        this.props.dispatch(updateVoucher(this.props.voucher.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/vouchers">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista vouchere
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/vouchers/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Voucher - <strong>{this.props.voucher.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <VoucherForm 
                            isEdit={true}
                            initialValues={this.props.voucher}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.vouchers.errors.type === 'validation' ? mapValidationErrors(state.vouchers.errors.fields): [],
        voucher: state.vouchers.current
    }
}

export default connect(mapStateToProps)(EditVoucher);