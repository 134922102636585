import loading from './common/Loading/Reducer';
import authentication from './authentication/Reducer';
import users from './users/Reducer';
import roles from './roles/Reducer';
import address from './address/Reducer';
import attributes from './attributes/Reducer';
import categories from './categories/Reducer';
import products from './products/Reducer';
import paymentTypes from './paymentTypes/Reducer';
import offers from './offers/Reducer';
import orders from './orders/Reducer';
import productLists from './productLists/Reducer';
import categoryLists from './categoryLists/Reducer';
import carousel from './carousel/Reducer';
import suppliers from './suppliers/Reducer';
import supplierOrders from './supplierOrders/Reducer';
import shopSales from './shopSales/Reducer';
import stockAccounts from './stockAccount/Reducer';
import units from './units/Reducer';
import carts from './carts/Reducer';
import vouchers from './vouchers/Reducer';
import preorders from './preorders/Reducer';
import stockOperations from './stockOperations/Reducer';
import cards from './cards/Reducer';
import cardExpansions from './cardExpansions/Reducer';
import inventoryBuffer from './inventoryBuffer/Reducer';

const reducers = {
    loading,
    authentication,
    users,
    roles,
    address,
    attributes,
    categories,
    products,
    paymentTypes,
    offers,
    orders,
    productLists,
    categoryLists,
    carousel,
    suppliers,
    supplierOrders,
    shopSales,
    stockAccounts,
    units,
    carts,
    vouchers,
    preorders,
    cards,
    cardExpansions,
    stockOperations,
    inventoryBuffer
}

export default reducers;
