import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPaymentTypes } from '../actions/PaymentTypeActions';
import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Button, Pane } from 'evergreen-ui';

class ListPaymentTypes extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getPaymentTypes(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to="/payment-types/create">
                        <Button iconBefore="add" intent="none" appearance="primary">
                            Modalitate de plata noua
                        </Button>
                    </Link>
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='id'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id'},
                    {id: 'name', label: 'Nume'},
                    {id: 'handler', label: 'Procesare', decorator: 'map', map: {
                        'direct-physical': 'La sediu',
                        'direct-delivery': 'Ramburs',
                        'bank-transfer': 'Transfer Bancar',
                        'card-gateway': 'Card (Procesator)',
                        'credit-gateway': 'Rate (Procesator)'
                    }},
                    {id: 'active', label: 'Activa',  decorator: 'boolean'},
                    {id: 'position', label: 'Pozitie'},
                    {id: 'createdAt', label: 'Creat', decorator: 'date'},
                    {id: 'updatedAt', label: 'Modificat', decorator: 'date'}
                ]}

                data={this.props.data}
                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}
    

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/payment-types/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/payment-types/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.paymentTypes;
}

export default connect(mapStateToProps)(ListPaymentTypes);