import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Pane, Heading, Button } from 'evergreen-ui';

import { Link } from 'react-router-dom';
import CardInfoBlock from '../components/CardInfoBlock';
import { getCard } from '../actions/CardActions';

class ViewCard extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getCard(this.id))
    }
    render() {
        
        if(!this.props.card) {
            return <>Se incarca...</>
        }

        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/cards">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista carti
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Set - <strong>{this.props.card.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <CardInfoBlock card={this.props.card}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        card: state.cards.current
    }
}

export default connect(mapStateToProps)(ViewCard);