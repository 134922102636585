import React, { Component } from 'react'
import { Pane, Menu, DashboardIcon, ShopIcon, ShoppingCartIcon, BoxIcon, SquareIcon, UserIcon, BankAccountIcon, LayoutLinearIcon, DataLineageIcon, DiagramTreeIcon, TruckIcon, CreditCardIcon, ClipboardIcon } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

class OrchestraMenu extends Component {
    render() {
        return (
            <Pane
                width={200} maxHeight="calc(100vh - 60px)" minWidth={200} overflowY="auto">
                <Menu>
                    <Menu.Group title="Principal">
                        <Link to="/dashboard">
                            <Menu.Item
                                backgroundColor={this.props.route.indexOf('/dashboard') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}
                                icon={DashboardIcon}>Dashboard</Menu.Item>
                        </Link>

                        <Link to="/orders">
                            <Menu.Item icon={ShopIcon}
                                backgroundColor={this.props.route.indexOf('/orders') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Comenzi
                            </Menu.Item>
                        </Link>
                        <Link to="/carts">
                            <Menu.Item icon={ShoppingCartIcon}
                                backgroundColor={this.props.route.indexOf('/carts') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Cosuri
                            </Menu.Item>
                        </Link>
                    </Menu.Group>
                    <Menu.Divider/>
                    <Menu.Group title="Date">
                        <Link to="/products">
                            <Menu.Item icon={BoxIcon}
                                backgroundColor={this.props.route.indexOf('/products') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Produse
                            </Menu.Item>
                        </Link>
                        <Link to="/cards">
                            <Menu.Item icon={SquareIcon}
                                backgroundColor={this.props.route.indexOf('/cards') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    TCG Singles
                            </Menu.Item>
                        </Link>
                        <Link to="/product-lists">
                            <Menu.Item icon={BoxIcon}
                                backgroundColor={this.props.route.indexOf('/product-lists') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Liste Produse
                            </Menu.Item>
                        </Link>
                        <Link to="/category-lists">
                            <Menu.Item icon={BoxIcon}
                                backgroundColor={this.props.route.indexOf('/category-lists') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Liste Categorii
                            </Menu.Item>
                        </Link>
                        <Link to="/users">
                            <Menu.Item icon={UserIcon}
                                backgroundColor={this.props.route.indexOf('/users') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Utilizatori
                            </Menu.Item>
                        </Link>
                        <Link to="/offers">
                            <Menu.Item icon={BankAccountIcon}
                                backgroundColor={this.props.route.indexOf('/offers') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Oferte si Vouchere
                            </Menu.Item>
                        </Link>
                    </Menu.Group>
                    <Menu.Divider/>
                    <Menu.Group title="CMS">
                        <Link to="/carousel">
                            <Menu.Item icon={LayoutLinearIcon}
                                backgroundColor={this.props.route.indexOf('/carousel') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Carusel
                            </Menu.Item>
                        </Link>
                    </Menu.Group>
                    <Menu.Divider/>
                    <Menu.Group title="Administrare">
                        <Link to="/attributes">
                            <Menu.Item icon={DataLineageIcon}
                                backgroundColor={this.props.route.indexOf('/attributes') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Atribute
                            </Menu.Item>
                        </Link>
                        <Link to="/categories">
                            <Menu.Item icon={DiagramTreeIcon}
                                backgroundColor={this.props.route.indexOf('/categories') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Categorii
                            </Menu.Item>
                        </Link>
                        <Link to="/suppliers">
                            <Menu.Item icon={BoxIcon}
                                backgroundColor={this.props.route.indexOf('/suppliers') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Furnizori
                            </Menu.Item>
                        </Link>
                        <Link to="/stock-operations">
                            <Menu.Item icon={TruckIcon}
                                backgroundColor={this.props.route.indexOf('/stock-operations') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Operatii Stocuri
                            </Menu.Item>
                        </Link>
                         <Link to="/inventory-buffer">
                            <Menu.Item icon={BoxIcon}
                                backgroundColor={this.props.route.indexOf('/inventory-buffer') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Inventory Buffer
                            </Menu.Item>
                        </Link>
                        <Link to="/payment-types">
                            <Menu.Item icon={CreditCardIcon}
                                backgroundColor={this.props.route.indexOf('/payment-types') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Modalitati Plata
                            </Menu.Item>
                        </Link>
                        <Link to="/stock-accounts">
                            <Menu.Item icon={ClipboardIcon}
                                backgroundColor={this.props.route.indexOf('/stock-accounts') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Conturi
                            </Menu.Item>
                        </Link>
                        <Link to="/vouchers">
                            <Menu.Item icon={BankAccountIcon}
                                backgroundColor={this.props.route.indexOf('/vouchers') === 0 ? 'rgba(67, 90, 111, 0.1)' : 'none'}>
                                    Vouchere
                            </Menu.Item>
                        </Link>
                    </Menu.Group>
                </Menu>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        route: state.router.location.pathname
    }
}

export default connect(mapStateToProps)(OrchestraMenu);
