import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { Button, Dialog, Pane, Text, Heading, TextInputField, Table, SegmentedControl } from 'evergreen-ui';

const refundValues = [
    { label: 'Cash', value: 'cash' },
    { label: 'Transfer Bancar', value: 'bank-transfer' }
]

class OrderStatusProcessedModal extends Component {
    constructor(props) {
        super(props);

        this.classes = props.classes;
        this.onClose = this.onClose.bind(this);

    }

    onClose = () => {
        this.props.onClose();
    }

    render() {
        return (<Dialog
            shouldCloseOnOverlayClick={false}
            width={1000}
            isShown={this.props.open}
            title={"Retur comanda"}
            hasFooter={false}
            onCloseComplete={this.onClose}
            onClose={this.onClose}>

            <Pane>
                <Formik initialValues={{
                    refundType: 'cash',
                    refundName: '',
                    refundAccount: '',
                    returnShipping: 0,
                    products: this.props.order.items ? this.props.order.items.filter(product => product.quantity > 0).map(product => ({
                        id: product.id,
                        sku: product.sku,
                        title: product.title,
                        quantity: product.quantity - product.quantityReturned
                    })) : []
                }}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values) => {
                        this.props.confirmOperation(values);
                        this.props.onClose();
                    }}
                >
                    {({ errors, handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
                        return <form>
                            <Pane display="flex">
                                <Pane width={600}>
                                    <Heading size={400}>Modalitate Plata</Heading>
                                        <SegmentedControl
                                        marginBottom={16}
                                        width={300}
                                        options={refundValues}
                                        value={values.refundType}
                                        onChange={value => setFieldValue('refundType', value)} />

                                        <TextInputField
                                            label="Transport Retur"
                                            required
                                            name={`returnShipping`}
                                            value={values.returnShipping}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            marginRight={10}
                                            width={300}
                                        />

                                    {values.refundType === 'bank-transfer' ? 
                                        <Pane>
                                            <TextInputField
                                                label="Nume Titular Cont"
                                                required
                                                name={`refundName`}
                                                value={values.refundName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                marginRight={10}
                                                width={300}
                                            />

                                            <TextInputField
                                                label="IBAN"
                                                marginRight={5}
                                                required
                                                name={`refundAccount`}
                                                value={values.refundAccount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                width={300}
                                            />
                                        </Pane>
                                    : null}

                                    <Heading size={400}>Produse returnate</Heading>
                                    <Text color="muted">Introdu cantitatile returnate</Text>

                                    <Table>
                                        <Table.Head>
                                            <Table.TextHeaderCell>
                                                Produs
                                                    </Table.TextHeaderCell>
                                            <Table.TextHeaderCell>
                                                Cantitate Returnata
                                            </Table.TextHeaderCell>
                                        </Table.Head>

                                        <FieldArray name="products" render={arrayHelpers => (
                                            values.products.map((product, i) => (
                                                <Table.Row>
                                                    <Table.TextCell title={`${product.sku} - ${product.title}`}>
                                                        {product.sku} - {product.title}
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <TextInputField
                                                            width={150}
                                                            marginRight={5}
                                                            required
                                                            name={`products.${i}.quantity`}
                                                            value={product.quantity || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Table.TextCell>
                                                </Table.Row>
                                            )))} />
                                    </Table>
                                </Pane>
                            </Pane>

                            <Pane borderTop="default" marginTop={16} paddingTop={16}>
                                <Button type="submit" onClick={handleSubmit} >
                                    Confirmare
                                        </Button>
                            </Pane>
                        </form>
                    }}
                </Formik>
            </Pane>
        </Dialog>)
    }
}

export default OrderStatusProcessedModal;