import { Button, FilePicker, Heading, Pane, toaster } from 'evergreen-ui';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Request from "../../../../util/Api";
import { normalizeAlmaArtex } from './alma-artex.normalize.js';

const normalizeData = (data, format) => {
    if(format === "format-alma-artex") {
        return normalizeAlmaArtex(data);
    } else if (format === "format-blackfire-ro") {
        return []
    }
}

const checkExistingProducts = async (products) => {
    const barcodes = products.map(product => product.barcode);

    const results = await Request.post(`/products/exists`, null, null, { barcodes });

    if(!results.success) {
        toaster.danger("Error checking existing products");
        return;
    }

    return products.map(product=> {
        const existing = results.result.find(r => r.barcode === product.barcode);

        return {
            ...product,
            existing: existing ? existing.id : null
        };
    }).sort((a, b) => a.existing - b.existing);
}


const ImportProductsForm = ({ handleProductImport }) => {
    const [file, setFile] = useState(null);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    const handleFileUpload = async (format) => {
        const reader = new FileReader();
        reader.onload = async(e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let normalizedJsonData = normalizeData(jsonData, format)

            normalizedJsonData = await checkExistingProducts(normalizedJsonData);

            handleProductImport(normalizedJsonData);
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <Pane display="flex" flexDirection="column" gap={10}>
            <Pane>
                <Heading size={400}>Incarca fisier import (Exclusiv Alma Artex)</Heading>
            </Pane>
            <Pane gap={10} display="flex">
                <FilePicker
                    onChange={files => handleFileChange({ target: { files } })}
                    placeholder="Select the file here!"
                    accept=".xlsx, .xls"
                />
                <Button appearance="primary" onClick={() => handleFileUpload('format-alma-artex')}>Upload Alma Artex</Button>
                {/* <Button appearance="primary" onClick={() => handleFileUpload('format-blackfire-ro')}>Upload Blackfire RO</Button> */}
            </Pane>
        </Pane>
    );
};

export default ImportProductsForm;
