import { toaster } from 'evergreen-ui';
import Request from '../../../util/Api';

import { 
    roleListThunks, roleListActionIds,
} from './Constants';

import { setLoading, unsetLoading } from '../../common/Loading/Actions';

import Role from '../../../structures/Role';

export const ENDPOINT_ROLES = '/roles';

export function getRoles(opts = { start: 0, limit: 0 }) {
    return async (dispatch) => {
        dispatch(setLoading(roleListThunks.request));
        dispatch(roleListThunks.request());

        try {
            const result = await Request.get(ENDPOINT_ROLES, null, {
                _start: opts.start,
                _end: opts.start + opts.limit,
                _sort: opts.sort,
                _order: opts.direction,
                q: opts.search
            });
            
            const roles = result.data.map(user => new Role(user));

            dispatch(roleListThunks.success({
                roles, 
                count: result.count, 
                pageSize: opts.limit,
                search: opts.search || "",
                sort: opts.sort,
                direction: opts.direction
            }));
        } catch (err) {
            dispatch(roleListThunks.failure(err));
            toaster.danger("Rolurile nu au putut fi incarcate");
        }

        dispatch(unsetLoading(roleListActionIds.request));

    }
}