import moment from 'moment';

import Base from './Base';

export default class InventoryBuffer extends Base {
    constructor(props) {
        super();

        this.import(props);
    }

    import(props) {
        if (typeof props === 'number') {
            props = { id: props };
        }
        if (!props) {
            props = {}
        }

        this.id = props.id;
        this.type = props.type;
        this.name = props.name;
        this.code = props.code;
        this.quantity = Number(props.quantity);
        this.quantityDiff = Number(props.quantityDiff);
        this.thumbnail = props.thumbnail;
        this.variantName = props.variantName || ''
        this.rarity = props.rarity || ''
    }

    export() {
        return {
            productId: this.productId || 0,
            cardId: this.cardId || 0,
            quantity: this.quantity,
            comment: this.comment,
        }
    }
}
