import ModuleConfig from '../Config';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const CategoryList = ModuleConfig.Structures.CategoryList;
const CRUDActions = ModuleConfig.CRUDActions;

export function getCategoryLists(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.categoryLists,
        mapStructure: CategoryList,
        errorNotification: "Listele de categorii nu au putut fi incarcate"
    });
}

export function getCategoryList(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.categoryList,
        mapStructure: CategoryList,
        errorNotification: "Lista de categorii nu a putut fi incarcata"
    })
}

export function createCategoryList(values) {
    return CRUDActions.create(actions.create, {
        values,
        endpoint: endpoints.categoryLists,
        mapStructure: CategoryList,
        successNotification: "Lista de categorii a fost creata",
        nextUrl: '/category-lists/',
    })
}

export function updateCategoryList(id, values) {
    return CRUDActions.update(actions.edit, {
        id, 
        values,
        endpoint: endpoints.categoryList,
        mapStructure: CategoryList,
        successNotification: "Lista de categorii a fost modificata",
        nextUrl: '/category-lists/',
    })
}

export function deleteCategoryList(id) {
    return CRUDActions.remove(actions.delete, {
        id,
        endpoint: endpoints.categoryList,
        successNotification: "Lista de categorii a fost stearsa",
        nextUrl: '/category-lists'
    });
}