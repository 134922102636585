import { Button, Checkbox, Pane, TextInputField } from "evergreen-ui";
import { Formik } from "formik";
import * as moment from "moment";
import React, { Component } from "react";

export default class CardExpansionForm extends Component {
  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        enableReinitialize={true}
        validationSchema={this.props.validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          this.props.onSave(values);
        }}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
        }) => {
          errors = Object.assign({}, this.props.errors, errors);

          return (
            <form>
              <TextInputField
                required
                label="Nume"
                name="name"
                description="Numele expansiunii"
                value={values.name || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="Code"
                name="code"
                description="Codul expansiunii"
                value={values.code || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="ID CardMarket"
                name="cardMarketId"
                description="Id-ul expansiunii pe cardmarket.com"
                value={values.cardMarketId || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="ID CardTrader"
                name="cardTraderId"
                description="Id-ul expansiunii pe cardtrader.com"
                value={values.cardTraderId || ""}
                onChange={handleChange}
              />

              <TextInputField
                required
                type="date"
                label="Data Lansare"
                name="releaseDate"
                description="Data lansarii expansiunii"
                value={values.releaseDate.format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFieldValue(
                    "releaseDate",
                    moment(e.target.value, "YYYY-MM-DD")
                  );
                }}
                onBlur={handleBlur}
              />

              <br />

              <Checkbox
                name="active"
                label="Disponibil in general"
                checked={values.active || false}
                onChange={handleChange}
              />

              <Checkbox
                name="activeOnline"
                label="Disponibil pe site"
                checked={values.activeOnline || false}
                onChange={handleChange}
              />

              <Checkbox
                name="activeThirdParty"
                label="Disponibil pe platforme partenere (ex. CardMarket)"
                checked={values.availableThirdParty || false}
                onChange={handleChange}
              />

              <br />

              <Pane borderTop="default" marginTop={16} paddingTop={16}>
                <Button type="submit" onClick={handleSubmit}>
                  Salveaza
                </Button>
              </Pane>
            </form>
          );
        }}
      </Formik>
    );
  }
}
