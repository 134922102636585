import React, { Component } from 'react';
import { Dialog, Pane, Button, TextInput, Table } from 'evergreen-ui';
import { connect } from 'react-redux';

import { generateVouchers } from '../actions/OfferActions';

class VoucherDialog extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isOpen: false,
            newCount: ""
        }
    }

    handleOpen = () => {
        this.setState({
            isOpen: true
        })
    }
    
    handleClose = () => {
        this.setState({
            isOpen: false
        })
    }

    handleChange = (e) => {
        this.setState({
            newCount: e.target.value > 100 ? 100 : e.target.value
        })
    }

    generate = () => {
        this.props.dispatch(generateVouchers(this.props.offer.id, this.state.newCount));

        this.setState({
            newCount: ""
        })
    }

    render () {
      return (<>
        <Dialog
            isShown={this.state.isOpen}
            title= "Vouchere"
            onCloseComplete={this.handleClose}>
            
            <Pane marginBottom={16}>
                <TextInput placeholder="Cate vouchere vrei sa generezi?" value={this.state.newCount} onChange={this.handleChange}/>
                <Button type="button" marginLeft={16} onClick={this.generate}>Genereaza</Button>
            </Pane>
            <Pane overflowY="auto">
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell>
                            Cod Voucher
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Folosit La
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body height={300}>
                        {(this.props.offer.vouchers || []).map(voucher => (
                            <Table.Row>
                                <Table.TextCell>{voucher.voucherCode}</Table.TextCell>
                                <Table.TextCell>{voucher.usedAt || "Nefolosit"}</Table.TextCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                   
            </Pane>
        </Dialog>

        <Button onClick={this.handleOpen} marginTop={16} appearance="primary">Gestioneaza Vouchere</Button> 
    </>)
    }
}

function mapStateToProps(state) {
    return {
        offer: state.offers.current
    }
}

export default connect(mapStateToProps)(VoucherDialog);