import ModuleConfig from './Config';
const actions = ModuleConfig.Actions;

const initialState = {
    data: [],
    count: 0,
    page: 1,
    pageCount: 1,
    pageSize: 20,
    search: "",
    searchColumn: "",
    sortDirection: "",
    current: {},
    errors: {}
}

export default function carts(state = initialState, action) {
    switch(action.type) {
        case actions.getAll.success.id:
            return Object.assign({}, state, {
                data: action.options.data,
                count: action.options.count,
                page: action.options.page,
                pageCount: action.options.pageCount,
                pageSize: action.options.pageSize,
                search: action.options.search,
                sortColumn: action.options.sortColumn,
                sortDirection: action.options.sortDirection
            })
        case actions.getOne.success.id:
            return Object.assign({}, state, {
                current: action.options,
                errors: {}
            })
        default:
            return state;
    }
}