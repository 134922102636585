import packageJson from '../../package.json';

const Configuration = {
    version: packageJson.version,
    host: process.env.REACT_APP_API,
    images: process.env.REACT_APP_IMAGE_STORAGE,
    //Max age of the session cookie without remember me
    sessionMaxAge: 60*60*24,
    //Max age of the session cookie with remember me
    sessionMaxAgeRemember: 60*60*24*30 
}

export default Configuration;