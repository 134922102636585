export const StockOperationLabels = [
    {
        id: 'STD',
        label: 'Standard',
        labelEx: 'Comanda Standard',
        important: true
    },
    {
        id: 'CRD',
        label: 'Singles',
        labelEx: 'Operatie YGO Singles'
    }
]