import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSupplierOrders } from '../actions/SupplierOrderActions';
import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Badge, Button, Pane } from 'evergreen-ui';

class ListSupplierOrders extends Component {
    handleTableChange = (opts) => {
        this.props.dispatch(getSupplierOrders(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to="/supplier-orders/create">
                        <Button iconBefore="add" intent="none" appearance="primary">
                            Comanda Furnizor noua
                        </Button>
                    </Link>
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='nirNumber'
                defaultDirection='desc'
                columns={[
                    {id: 'id', label: 'Id Intern', visible: false},
                    {id: 'status', label: 'Status', decorator: (values) => (
                        values.status === 'delivered' ? 
                            <Badge color="green">Livrata</Badge>
                        :  
                            <Badge color="red">Nelivrata</Badge>
                    )},
                    {id: 'nirNumber', label: 'Nr. NIR', decorator: (values) => (values.nirSeries || 'NS') + '/' + values.nirNumber },
                    {id: 'supplier', label: 'Furnizor', decorator: (values) => values.supplier.name},
                    {id: 'deliveryDate', label: 'Data'},
                    {id: 'invoiceValue', label: 'Valoare', decorator: (values) => (Math.round((values.supplierInvoiceValue + values.shipping) * 100) / 100) + ' ' + values.currency},
                    {id: 'stockAccount', label: 'Gestiune', decorator: (values) => values.stockAccount ? values.stockAccount.name : <Badge color="red">NU ARE CONT</Badge>}
                ]}

                data={this.props.data}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/supplier-orders/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/supplier-orders/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.supplierOrders;
}

export default connect(mapStateToProps)(ListSupplierOrders);