import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import * as yup from 'yup';

const config =  {
    name: 'attributes',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        attributes: '/attributes',
        attribute: '/attributes/[id]'
    },
    defaults: {
        createAttribute: {
            textId: '',
            name: '',
            description: '',
            type: '',
            values: [],
            countable: true
        }
    },
    validations: {
        attribute:  yup.object().shape({
            textId: yup.string().required("Identificatoril este obligatoriu"),
            type: yup.string().required("Tipul este obligatoriu"),
            name: yup.string().required("Numele este obligatoriu"),
            description: yup.string().required("Descrierea este obligatorie"),
        })
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;