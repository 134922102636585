import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Heading, Pane } from 'evergreen-ui';

import { Link } from 'react-router-dom';
import Request from '../../../util/Api';
import { updateCard } from '../../cards/actions/CardActions';
import { getCardExpansion, updateCardExpansion } from '../actions/cardExpansionActions';
import CardExpansionInfoBlock from '../components/CardExpansionInfoBlock';


class ViewCardExpansion extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }


    async downloadInvoices(code) {
        const data = await Request.get(`/reports/card-expansion-inventory-report?expansionCode=${code}`);
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();
    }

    handleSave = (values) => {
        this.props.dispatch(updateCardExpansion(this.props.params.id, values));
    }

    handleCardSave = async (values) => {
        await this.props.dispatch(updateCard(values.id, values, false));
        await this.props.dispatch(getCardExpansion(this.id))

    }

    handleLoad = async () => {
        await this.props.dispatch(getCardExpansion(this.id));
    }

    async componentDidMount() {
        this.handleLoad();
    }
    render() {

        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex" justifyContent="space-between">
                    <Pane>
                        <Link to="/card-expansions">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista seturi
                            </Button>
                        </Link>
                    </Pane>
                    <Pane>
                        <Button onClick={() => this.downloadInvoices(this.props.cardExpansion.code)}>
                            Raport Inventar
                        </Button>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Set - <strong>{this.props.cardExpansion.name}</strong>
                    </Heading>
                </Pane>

                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <CardExpansionInfoBlock cardExpansion={this.props.cardExpansion}
                        onReload={this.handleLoad}
                        onSave={(value) => this.handleSave(value)} onCardSave={(value) => this.handleCardSave(value)}
                        />
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        cardExpansion: state.cardExpansions.current
    }
}

export default connect(mapStateToProps)(ViewCardExpansion);
