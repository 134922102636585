import React, { Component } from 'react';
import { Button, Pane, Heading, Table, Checkbox, Text } from 'evergreen-ui';

import { FieldArray } from 'formik';
import OperationItemModal from './OperationItemModal';
import ImportModal from './ImportModal';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { round } from 'lodash';
import ValueModal from './ValueModal';

const getTotal = (data) => {
    let total = 0;

    for (let i in data) {
        total += data[i].value * data[i].quantity;
    }

    return round(total, 2);
}

const getTotalAdjusted = (data) => {
    let total = 0;

    for (let i in data) {
        total += (data[i].adjustedValue * data[i].quantity) || 0;
    }

    return round(total, 2);
}

const getTotalSalePrice = (data) => {
    let total = 0;

    for (let i in data) {
        total += data[i].price * data[i].quantity;
    }

    return round(total, 2);
}

const getAdjustedFactor = (data, setFieldValue) => {
    let totalValue = 0;
    let overheadValue = 0;

    for (let i in data) {
        if (data[i].type === 'Overhead') {
            overheadValue += parseFloat(data[i].value * data[i].quantity);
        } else {
            totalValue += parseFloat(data[i].value * data[i].quantity);
        }
    }

    let factor = overheadValue / (totalValue / 100);

    if (overheadValue === 0) {
        factor = 0;
    }

    const newData = [];

    for (let i in data) {
        let newVal = round(data[i].value * (100 + factor) / 100, 4);

        if (data[i].type === 'Overhead') {
            newVal = 0;
        }

        newData.push({
            ...data[i],
            adjustedValue: newVal
        });
    }

    setFieldValue(`items`, newData);
}


const SortableItem = SortableElement((props) => {
    return (
        <Table.Row isSelectable>
            <Table.Cell flexBasis={50} flexShrink={0} flexGrow={0}>
                <Checkbox
                    marginRight={16}
                    checked={props.selected.indexOf(props.idx) !== -1 || false}
                    onChange={() => { props.toggleSelected(props.idx) }} />
            </Table.Cell>
            <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                {props.idx + 1}
            </Table.TextCell>
            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                {props.product.type}
            </Table.TextCell>
            <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>
                {props.product.thumbnail && props.product.type === 'Produs' ?
                    <img style={{ height: '40px', width: 'auto' }} alt="product-thumbnail"
                        src={props.product.thumbnail ?
                            process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + props.product.thumbnail + '.jpg' : null} /> :
                    props.product.thumbnail && props.product.type === 'Carte' ?
                        <img style={{ height: '40px', width: 'auto' }} alt="product-thumbnail"
                            src={props.product.thumbnail ?
                                process.env.REACT_APP_CARD_IMAGE_BUCKET + '' + props.product.thumbnail : null} /> : null}
            </Table.Cell>
            <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
                {props.product.name}
            </Table.TextCell>
            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                {props.product.quantity}
            </Table.TextCell>
            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                {round(props.product.value * props.exchange, 2)} Lei
                {props.currency !== 'LEU' ? ` (${props.product.value} ${props.currency})` : ''}
            </Table.TextCell>
            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                {round((props.product.value * props.product.quantity) * props.exchange, 2)} Lei
                {props.currency !== 'LEU' ? ` (${round(props.product.value * props.product.quantity, 2)} ${props.currency})` : ''}
            </Table.TextCell>
            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                {round((props.product.adjustedValue * props.product.quantity) * props.exchange, 2)} Lei
                {props.currency !== 'LEU' ? ` (${round(props.product.adjustedValue * props.product.quantity, 2)} ${props.currency})` : ''}
            </Table.TextCell>
            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                {round(props.product.price, 2)} Lei
            </Table.TextCell>
            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                {round(props.product.price * props.product.quantity, 2)} Lei
            </Table.TextCell>
            <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0}>
                <Button intent="warning" onClick={() => { props.select(props.idx, true); props.openItemModal() }} type="button" marginRight={10}>Modifica</Button>
                <Button intent="danger" onClick={() => props.arrayHelpers.remove(props.idx)} type="button">Sterge</Button>
            </Table.TextCell>
        </Table.Row>
    )
})

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Table>
            <Table.Head>
                <Table.Cell flexBasis={50} flexShrink={0} flexGrow={0}>
                    <Checkbox
                        marginRight={16}
                        checked={props.selected.length === props.items.length && props.items.length > 0}
                        onChange={() => { props.toggleSelected(props.items.map((item, index) => index)) }} />
                </Table.Cell>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    #
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Tip
                </Table.TextHeaderCell>
                <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.Cell>
                <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                    Nume
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cant.
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare Totala
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    Valoare Ajustata
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Total
                </Table.TextHeaderCell>
                <Table.Cell flexBasis={300} flexShrink={0} flexGrow={0}>

                </Table.Cell>
            </Table.Head>
            <Table.Body>
                {items.map((value, index) =>
                    <SortableItem onRemove={() => props.onRemove(index)}
                        key={`stock-operation-item-${index}`}
                        product={value}
                        idx={index}
                        index={index}
                        disabled={true}
                        arrayHelpers={props.arrayHelpers}
                        select={props.select}
                        exchange={props.exchange}
                        currency={props.currency}
                        openItemModal={props.openItemModal}
                        selected={props.selected}
                        toggleSelected={props.toggleSelected} />
                )}
            </Table.Body>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                    Total
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    {round(getTotal(items) * props.exchange, 2)} Lei {props.currency !== 'LEU' ? `(${round(getTotal(items), 2)} ${props.currency})` : ''}
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
                    {round(getTotalAdjusted(items) * props.exchange, 2)} Lei {props.currency !== 'LEU' ? `(${round(getTotalAdjusted(items), 2)} ${props.currency})` : ''}
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    {getTotalSalePrice(items)} Lei
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={300} flexShrink={0} flexGrow={0}>

                </Table.TextHeaderCell>
            </Table.Head>
        </Table>)
})

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

export default class OperationDetailsForm extends Component {
    constructor(props) {
        super(props);

        this.openItemModal = this.openItemModal.bind(this);
        this.closeItemModal = this.closeItemModal.bind(this);
        this.openImportModal = this.openImportModal.bind(this);
        this.closeImportModal = this.closeImportModal.bind(this);
        this.openValueModal = this.openValueModal.bind(this);
        this.closeValueModal = this.closeValueModal.bind(this);
        this.importData = this.importData.bind(this);
        this.select = this.select.bind(this);

        this.state = {
            showDetails: false,
            itemModalOpen: false,
            importModalOpen: false,
            selected: [],
            loading: false,
        }
    }

    async importData(data, arrayHelper, setFieldValue) {
        this.setState({
            loading: true
        });

        for (let i in data) {
            this.setState({
                loadingDetails: `${i}/${data.length}`
            })
            console.log('Index:', i, data.length);
            const item = data[i];

            if (!item.quantity || parseInt(item.quantity) === 0) {
                continue;
            }

            arrayHelper.push({
                ...item,
                adjustedValue: 0,
                type: 'Carte'
            });

            await timeout(1);
        }

        this.setState({
            loading: false,
            loadingDetails: ''
        })
    }

    select(values, clear = false) {
        if (clear) {
            this.setState({
                selected: Array.isArray(values) ? values : [values]
            });
        } else {
            if (Array.isArray(values)) {
                if (this.state.selected.length < values.length) {
                    this.setState({
                        selected: values
                    })
                } else {
                    this.setState({
                        selected: []
                    })
                }
                return;
            }

            const value = values;
            let isSelected = this.state.selected.find(v => v === value);

            if (isSelected || isSelected === 0) {
                this.setState({
                    selected: this.state.selected.filter(v => v !== value)
                })
            } else {
                this.setState({
                    selected: [
                        ...this.state.selected,
                        value
                    ]
                })
            }
        }
    }

    openItemModal() {
        this.setState({
            itemModalOpen: true
        })
    }

    closeItemModal(data, setFieldValue) {
        this.setState({
            itemModalOpen: false
        })
    }

    openValueModal() {
        this.setState({
            valueModalOpen: true
        })
    }

    closeValueModal(data, setFieldValue) {
        this.setState({
            valueModalOpen: false
        })
    }

    openImportModal() {
        this.setState({
            importModalOpen: true
        })
    }

    closeImportModal() {
        this.setState({
            importModalOpen: false
        })
    }

    render() {
        const setFieldValue = this.props.setFieldValue;
        const values = this.props.values;

        const handleSortEnd = ({ oldIndex, newIndex }) => {
            let reordered = arrayMove((values.items || []), oldIndex, newIndex);
            setFieldValue('items', reordered);
        }

        return <Pane>
            <Pane padding="5px" marginBottom="10px">
                <Heading size={500}>
                    CONTINUT OPERATIE
                </Heading>
                <FieldArray name="items" render={arrayHelpers => (<>
                    <OperationItemModal
                        open={this.state.itemModalOpen}
                        onClose={() => this.closeItemModal(values.items, setFieldValue)}
                        selected={this.state.selected}
                        items={values.items || []}
                        setFieldValue={setFieldValue} />

                    <ImportModal
                        open={this.state.importModalOpen}
                        onClose={this.closeImportModal}
                        onImport={(data) => {
                            this.importData(data, arrayHelpers, setFieldValue);
                        }}

                        setFieldValue={setFieldValue} />

                    <ValueModal
                        selected={this.state.selected}
                        items={values.items || []}
                        open={this.state.valueModalOpen}
                        onClose={() => this.closeValueModal(values.items, setFieldValue)}
                        setFieldValue={setFieldValue} />

                    <Pane marginTop={10} marginBottom={10}>
                        <Button type="button" marginRight={10} onClick={() => {
                            const currentIndex = (values.items || []).length;

                            arrayHelpers.push({
                                selected: false,
                                type: 'Produs',
                                thumbnail: '',
                                name: "",
                                quantity: 0,
                                value: 0,
                                vatRate: 19
                            })

                            this.select(currentIndex, true)
                            this.openItemModal();
                        }}>
                            Adauga
                        </Button>
                        <Button type="button"
                            onClick={() => {
                                this.openImportModal();
                            }}>
                            Import Carti
                        </Button>
                        <Button type="button"
                            marginLeft={10}
                            onClick={() => {
                                getAdjustedFactor(values.items, setFieldValue);
                            }}>
                            Calculeaza Valoarea Ajustata
                        </Button>
                        {this.state.selected.length > 1 ?
                            <Button type="button"
                                onClick={() => { this.openValueModal() }}
                                marginLeft={10}
                            >
                                Seteaza Valoare
                            </Button>
                            : null}
                    </Pane>

                    {this.state.loading ?
                        <Text>Loading {this.state.loadingDetails}</Text>
                        :
                        <SortableList onSortEnd={handleSortEnd} axis="xy"
                            pressDelay={200}
                            select={this.select}
                            openItemModal={this.openItemModal}
                            items={values.items || []}
                            currency={values.currency}
                            exchange={values.exchange}
                            arrayHelpers={arrayHelpers}
                            selected={this.state.selected}
                            toggleSelected={this.select} />
                    }

                </>)} />

            </Pane>
        </Pane>
    }
}
