import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createPreorder } from '../actions/PreorderActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import PreorderForm from '../components/PreorderForm';

import Configuration from '../Config';

const validation = Configuration.Validations.preorder;
const defaultValues = Configuration.Defaults.createPreorder;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class CreatePreorder extends Component {
    componentDidMount() {
       
    }

    handleSave = (values) => {
        this.props.dispatch(createPreorder(values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/preorders">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista precomenzi
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Precomanda noua
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <PreorderForm 
                            isEdit={false}
                            initialValues={defaultValues}
                            validationSchema={validation}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>

            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.preorders.errors.type === 'validation' ? mapValidationErrors(state.preorders.errors.fields): [],
    }
}

export default connect(mapStateToProps)(CreatePreorder);