import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

const config =  {
    name: 'carts',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
    ],
    endpoints: {
        carts: '/carts',
        cart: '/carts/[id]',
    }
}

const Config = {
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
}

export default Config;