import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategory, getCategories, updateCategory } from '../actions/CategoryActions';

import { Pane, Heading, Button } from 'evergreen-ui';
import InfoBlock from '../../common/InfoBlock/InfoBlock';

import { Link } from 'react-router-dom';
import CategoryForm from '../components/CategoryForm';

import Configuration from '../Config';
import { getAttributes } from '../../attributes/actions/AttributeActions';

const validation = Configuration.Validations.category;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditCategory extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    componentDidMount() {
        this.props.dispatch(getCategory(this.id));
        this.props.dispatch(getCategories());
        this.props.dispatch(getAttributes({start: 0, limit: 9999}));
    }

    handleSave = (values) => {
        this.props.dispatch(updateCategory(this.props.category.id, values));
    }

    render() {
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/categories">
                            <Button iconBefore="arrow-left" intent="none">
                                Inapoi la lista categorii
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16}>
                        <Link to={`/categories/${this.id}`}>
                            <Button iconBefore="cross" intent="warning">
                                Anuleaza modificarile
                            </Button>
                        </Link>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Modificare Categorie - <strong>{this.props.category.name}</strong>
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <InfoBlock flex="1">
                        <CategoryForm 
                            isEdit={true}
                            initialValues={this.props.category}
                            validationSchema={validation}
                            categories={this.props.categories}
                            category={this.props.category}
                            attributes={this.props.attributes}
                            onSave={this.handleSave}
                            errors={this.props.errors}/>
                    </InfoBlock>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.categories.errors.type === 'validation' ? mapValidationErrors(state.categories.errors.fields): [],
        category: state.categories.current,
        categories: state.categories.flatData,
        attributes: state.attributes.data
    }
}

export default connect(mapStateToProps)(EditCategory);