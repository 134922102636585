import React, { Component } from 'react';
import { Formik } from 'formik';
import {  Button, Pane } from 'evergreen-ui';
import OperationDetailsForm from './OperationDetailsForm';
import OperationContentForm from './OperationContentForm';

export default class StockOperationForm extends Component {
    render() {
        const initialValues = this.props.initialValues;
        const urlParam = window.location.search.substr(1);

        if(urlParam) {
            initialValues.type = urlParam.split('=')[1];
        }

        return (
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    this.props.onSave(values);
                }}
            >
                {({ errors, handleChange, handleBlur, handleSubmit, setFieldValue, values }) => {
                    
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form >
                        <Pane>
                            <OperationDetailsForm
                                view={this.props.view}
                                values={values}
                                errors={errors}
                                suppliers={this.props.suppliers}
                                stockAccounts={this.props.stockAccounts}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}/>
                            {this.props.view === "edit" ?
                                <OperationContentForm
                                    view={this.props.view}
                                    values={values}
                                    errors={errors}
                                    suppliers={this.props.suppliers}
                                    stockAccounts={this.props.stockAccounts}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}/> : null}

                        </Pane>
                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }
}
            </Formik >
        );
    }
}