import React from 'react';
import {Heading, Pane, Card, IconButton, TrashIcon } from 'evergreen-ui';

export default function UserListCard(props) {
    return (
        <Card cursor="pointer">
            <Pane display="flex" justifyContent="space-between" alignItems="center" 
                border="default" elevation={1} background="default" margin={16} padding={16}>
                <Heading size={400}>
                    {props.user.name}
                </Heading>
                {!props.isView ?
                    <IconButton type="button" icon={TrashIcon} intent="danger" onClick={props.onDelete}>Sterge</IconButton>
                    : null}
                </Pane>
        </Card>
    )
}