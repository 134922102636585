import { Button, Heading, Pane } from "evergreen-ui"
import { FieldArray } from "formik"
import ProductRecipe from "../ProductRecipe"

export const ComponentForm = ({values, setFieldValue, handleBlur, handleChange}) => {
    return (<>
        <Pane
            borderBottom="default"
            paddingBottom={8}
            marginBottom={8}
        >
            <Heading size={500}>Componente</Heading>
        </Pane>

        <FieldArray
            name="recipe"
            render={(arrayHelpers) => (
            <Pane>
                <Button
                type="button"
                margin={5}
                onClick={() => arrayHelpers.push({})}
                >
                    Componenta Noua
                </Button>

                {values.recipe && values.recipe.length > 0
                ? values.recipe.map((product, index) => (
                    <Pane>
                        <ProductRecipe
                        key={index}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        data={product}
                        index={index}
                        onRemove={(index) =>
                            arrayHelpers.remove(index)
                        }
                        />
                    </Pane>
                    ))
                : null}
            </Pane>
            )}
        />
    </>)
}
