import React, { Component } from 'react';
import { Dialog } from 'evergreen-ui';
import AddressForm from '../components/AddressForm';

import Configuration from '../Config';
import { connect } from 'react-redux';

import { updateAddress } from '../actions/AddressActions';

const validation = Configuration.Validations.address;

class ModifyAddress extends Component {

    handleSave = (values) => {
        this.props.dispatch(updateAddress(this.props.address.current.id, values));
    }

    render() {    
        return (<Dialog 
            width="80vw"
            hasFooter={false}
            topOffset='2vmin'
            onCloseComplete={this.props.onCloseComplete}
            isShown={this.props.isOpen}>
            <AddressForm
                    isEdit={true}
                    initialValues={this.props.address.current}
                    validationSchema={validation}
                    onSave={this.handleSave}
                    errors={this.props.errors}/>
        </Dialog>)
    }
}

function mapStateToProps(state) {
    return {
        address: state.address
    }
}

export default connect(mapStateToProps)(ModifyAddress);