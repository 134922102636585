import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListCarts from './containers/ListCarts';
import ViewCart from './containers/ViewCart';

const routes = [
    {
        type: 'crud',
        list: ListCarts,
        view: ViewCart
    }
]

export default ModuleBootstrapper.getRoutes("carts", routes)