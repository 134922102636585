import React, { Component } from 'react';
import { Button, Dialog, Heading, Pane, Text } from 'evergreen-ui';

class HistoryModal extends Component {
   

    constructor (props) {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    onClose = () => {
        this.props.onClose();
    }
   
    render () {
      return (<Dialog
        width={1400}
        isShown={this.props.open}
        title= {"Istoric Livrare Comanda"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane>
                {(this.props.order.history || []).map(item => (
                    <Pane display="flex" flexDirection="column" marginTop={20}>
                        <Heading>{item.status} ({item.statusId})</Heading>
                        <Text><strong>Label: </strong>{item.statusLabel}</Text>
                        <Text><strong>Status: </strong>{item.statusState}</Text>
                        <Text><strong>Data: </strong>{item.statusDate}</Text>
                        <Text><strong>Judet: </strong>{item.county}</Text>
                        <Text><strong>Motiv: </strong>{item.reason}</Text>
                        <Text><strong>Tranzit: </strong>{item.transitLocation}</Text>
                    </Pane>
                ))}
            </Pane>
           
            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
             
            
      </Dialog>)
    }
}

export default HistoryModal;