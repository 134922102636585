import React, { Component } from 'react';

import OrchestraMenu from '../../common/OrchestraMenu/OrchestraMenu';
import OrchestraHeader from '../../common/OrchestraHeader/OrchestraHeader';

import { Pane } from 'evergreen-ui';

class OrchestraLayout extends Component {
    render() {
        return (<>
                <OrchestraHeader/>
                <Pane display="flex">
                    <OrchestraMenu/>
                    <Pane flexGrow="1" padding={30} maxHeight="calc(100vh - 60px)" overflowY="auto">
                        {this.props.children}
                    </Pane>
                </Pane>
            </>
        )
    }
}

export default OrchestraLayout;