import ActionGenerator from './ActionGenerator';
import RouteGenerator from './RouteGenerator';

import * as Structures from '../../../structures/index';
import Configuration from '../../../util/Configuration';
import Api from '../../../util/Api';
import ErrorHandler from '../../../util/ErrorHandler';
import * as Loading from '../../common/Loading/Actions';
import * as CrudActions from './CrudActions';

export default class ModuleBootstrapper {
    static getActions(name, actions) {
        return ActionGenerator(name, actions);
    }

    static getRoutes(name, routes) {
        return RouteGenerator(name, routes);
    }

    static getUtils() {
        return {
            Structures: Structures.default,
            AppConfig: Configuration,
            Api,
            ErrorHandler,
            Loading: Loading,
            CRUDActions: {...CrudActions}
        }
    }
}