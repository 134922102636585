import Base from './Base';
import moment from 'moment';

export default class ShopSale extends Base {
    constructor(props) {
        super();
        this.import(props);
    }

    import(props = {}) {
        this.id = props.id || props.requestId
        this.unitPrice = props.unitPrice
        this.totalPrice = props.totalPrice
        this.quantity = props.quantity

        this.saleDate = props.saleDate ? moment(props.saleDate) : this.saleDate;
        this.paymentType = props.paymentType;
        this.confirmed = props.confirmed === "1"
        this.documentId = this.assign('documentId', props);
        this.products = props.products;

        this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    }
}
