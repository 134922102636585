import React from 'react';

import { Pane, Text, Pill } from 'evergreen-ui';

export default function CartPreview(props) {
    return <Pane display="flex" padding={10} maxWidth={1200} height="auto" flexWrap="wrap" justifyContent="center">
        {props.products.length > 0 ?
            props.products.map(product => (
                <Pane marginRight={10} position="relative">
                    <img style={{height: '128px', width: 'auto'}}
                        alt="Product Thumbnail"
                        src={
                            product.thumbnail ?
                                process.env.REACT_APP_PRODUCT_IMAGE_BUCKET + 'thumb_' + product.thumbnail + '.webp' 
                                : null
                        }/>
                    <Pill margin={5} color="red" position="absolute" left="0" bottom="0">{product.CartProduct.quantity}</Pill>
                </Pane>
            )) : props.cards.length === 0 ? <div><Text>Cosul nu are produse</Text></div> : null
        }

        {props.cards.length > 0 ?
            props.cards.map(card => (
                <Pane marginRight={10} position="relative">
                    <img style={{height: '128px', width: 'auto'}}
                        alt="Product Thumbnail"
                        src={
                            card.thumbnail ?
                                process.env.REACT_APP_CARD_IMAGE_BUCKET + card.thumbnail
                                : null
                        }/>
                    <Pill margin={5} color="red" position="absolute" left="0" bottom="0">{card.CartCard.quantity}</Pill>
                </Pane>
            )) : props.products.length === 0 ? <div><Text>Cosul nu are produse</Text></div> : null
        }
    </Pane>
}